export const appendHeadScript = (
  scriptText: string,
  scriptId: string
): boolean => {
  try {
    const existentScript = document.getElementById(
      scriptId
    ) as HTMLScriptElement;
    const script = existentScript || document.createElement('script');
    script.id = scriptId;
    script.innerText = scriptText;
    script.crossOrigin = 'anonymous';

    existentScript || document.head.appendChild(script);

    return true;
  } catch {
    return false;
  }
};

//
// Hotjar
//
interface IWindowHotjarEmbedded extends Window {
  hj: (method: string, ...data: unknown[]) => void;
}

export function hotjarInitScript(
  hotjarId: number,
  hotjarVersion: number,
  userId: string,
  hotjardebug: boolean
): boolean {
  const hasWindow = typeof window !== 'undefined';

  if (!hasWindow) throw Error('Hotjar depends on window. Window is undefined.');

  const hotjarScriptCode = `(function (h, o, t, j, a, r) {h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments); }; h._hjSettings = { hjid: ${hotjarId}, hjsv: ${hotjarVersion}, hjdebug: ${!!hotjardebug} }; a = o.getElementsByTagName('head')[0]; r = o.createElement('script'); r.async = 1; r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv; a.appendChild(r); })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');`;
  const isAppended = appendHeadScript(hotjarScriptCode, 'hotjar-init-script');

  if (
    isAppended &&
    hasWindow &&
    (window as unknown as IWindowHotjarEmbedded).hj
  ) {
    userId &&
      (window as unknown as IWindowHotjarEmbedded).hj('identify', userId, {
        // XXX: 준호 - 무언가 참조데이터
      });
    return true;
  }

  throw Error('Hotjar initialization failed!');
}
