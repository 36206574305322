import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import rfdc from 'rfdc';

import {
  SELECTION_MARKER_TYPE,
  TEN_SEC_STRIP_EDIT,
} from 'constant/ChartEditConst';
import LocalStorageKey from 'constant/LocalStorageKey';
import {
  ADJACENT_TO,
  BEAT_TYPE,
  ECTOPIC_TYPE,
  METRIC,
} from 'constant/OpsConst';

import { transformMsToWaveformIndex } from 'util/validation/ValidateBeatsEdit';

import ApiManager from 'network/ApiManager';

import LocalStorageManager from 'manager/LocalStorageManager';

import { selectRecordingTime } from '../testResultDuck';

const rfdcClone = rfdc();

// Constants
// Selectors
// Actions
// etc function
// InitialState
// Reducer
// Action Creators
// Saga functions
// Saga

// Selectors
const selectInverseEcgUserInput = (state) =>
  state.opsReducer.inverseEcg.userInput;
const selectBulkUpdateBeat = (state) => state.opsReducer.bulkUpdateBeat;
const selectEcgTestId = (state) => state.testResultReducer.ecgTestId;

// Actions
// ## set - inverse ecg
const RESET_INVERSE_ECG_STATE = 'ops/RESET_INVERSE_ECG_STATE';
const SET_INVERSE_ECG_INTERVALS = 'ops/SET_INVERSE_ECG_INTERVALS';
const SET_INVERSE_ECG_DO_ANALYSIS = 'ops/SET_INVERSE_ECG_DO_ANALYSIS';
const SET_INVERSE_ECG_VALIDATION_STATUS =
  'ops/SET_INVERSE_ECG_VALIDATION_STATUS';
// ## set - bulk beat update
const RESET_BULK_BEAT_UPDATE_STATE = 'ops/RESET_BULK_BEAT_UPDATE_STATE';
const SET_BULK_BEAT_UPDATE_CHECKBOX_STATUS =
  'ops/SET_BATCH_BEAT_ALTERATION_CHECKBOX_STATUS';
const SET_BULK_BEAT_UPDATE_USER_INPUT = 'ops/SET_BULK_BEAT_UPDATE_USER_INPUT';
const SET_BULK_BEAT_UPDATE_VALIDATION_STATUS =
  'ops/SET_BULK_BEAT_UPDATE_VALIDATION_STATUS';
// ## api - inverse ecg
const PATCH_INVERSE_ECG_REQUESTED = 'ops/PATCH_INVERSE_ECG_REQUESTED';
const PATCH_INVERSE_ECG_SUCCEED = 'ops/PATCH_INVERSE_ECG_SUCCEED';
const PATCH_INVERSE_ECG_FAILED = 'ops/PATCH_INVERSE_ECG_FAILED';
// ## api - bulk beat update
const PATCH_BULK_BEAT_UPDATE_REQUESTED = 'ops/PATCH_BULK_BEAT_UPDATE_REQUESTED';
const PATCH_BULK_BEAT_UPDATE_SUCCEED = 'ops/PATCH_BULK_BEAT_UPDATE_SUCCEED';
const PATCH_BULK_BEAT_UPDATE_FAILED = 'ops/PATCH_BULK_BEAT_UPDATE_FAILED';
// ## api - refresh pause
const REFRESH_PAUSE_REQUESTED = 'ops/REFRESH_PAUSE_REQUESTED';
const REFRESH_PAUSE_SUCCEED = 'ops/REFRESH_PAUSE_SUCCEED';
const REFRESH_PAUSE_FAILED = 'ops/REFRESH_PAUSE_FAILED';
// ## api - revert save point
const REVERT_SAVE_POINT_REQUESTED = 'ops/REVERT_SAVE_POINT_REQUESTED';
const REVERT_SAVE_POINT_SUCCEED = 'ops/REVERT_SAVE_POINT_SUCCEED';
const REVERT_SAVE_POINT_FAILED = 'ops/REVERT_SAVE_POINT_FAILED';

// InitialState
const initialState = {
  inverseEcg: {
    pending: false,
    userInput: {
      intervals: [[]],
      doAnalysis: false,
    },
    validationStatus: { intervals: false },
    error: null,
  },
  bulkUpdateBeat: {
    pending: false,
    checkBoxStatus: {
      isCheckedMin: false,
      isCheckedMax: false,
      isCheckedChangeAdjacentBeatTo: false,
      isCheckedNotUpdateSInAfToN: false,
    },
    userInput: {
      ectopicTypeToFind: ECTOPIC_TYPE.ALL_TYPES,
      beatTypeToFind: BEAT_TYPE.S_BEAT,
      beatTypeToChange: {
        firstBeat: BEAT_TYPE.NORMAL_BEAT,
        secondBeat: BEAT_TYPE.NORMAL_BEAT,
      },
      intervals: [[]],
      metric: METRIC.BEAT_HR,
      min: '',
      max: '',
      adjacentBeatType: BEAT_TYPE.NORMAL_BEAT,
      adjacentTo: ADJACENT_TO.BEHIND_OF,
      isTotalRangeSelected: false,
    },
    validationStatus: {
      min: true,
      max: true,
      minMax: true,
      intervals: false,
      areBeatTypesUnequal: true,
    },
    error: null,
  },
  refreshPause: {
    pending: false,
    error: null,
  },
  revertSavePoint: {
    pending: false,
    error: null,
  },
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET_INVERSE_ECG_STATE: {
      return {
        ...state,
        inverseEcg: rfdcClone(initialState.inverseEcg),
      };
    }
    case RESET_BULK_BEAT_UPDATE_STATE: {
      return {
        ...state,
        bulkUpdateBeat: rfdcClone(initialState.bulkUpdateBeat),
      };
    }
    case SET_INVERSE_ECG_INTERVALS: {
      const { intervals } = action.payload;
      return {
        ...state,
        inverseEcg: {
          ...state.inverseEcg,
          userInput: {
            ...state.inverseEcg.userInput,
            intervals,
          },
        },
      };
    }
    case SET_INVERSE_ECG_DO_ANALYSIS: {
      const { doAnalysis } = action.payload;
      return {
        ...state,
        inverseEcg: {
          ...state.inverseEcg,
          userInput: {
            ...state.inverseEcg.userInput,
            doAnalysis,
          },
        },
      };
    }
    case SET_INVERSE_ECG_VALIDATION_STATUS: {
      const { validationStatus } = action.payload;
      return {
        ...state,
        inverseEcg: {
          ...state.inverseEcg,
          validationStatus,
        },
      };
    }
    case SET_BULK_BEAT_UPDATE_CHECKBOX_STATUS: {
      const { checkBoxStatus } = action.payload;
      return {
        ...state,
        bulkUpdateBeat: {
          ...state.bulkUpdateBeat,
          checkBoxStatus: {
            ...state.bulkUpdateBeat.checkBoxStatus,
            ...checkBoxStatus,
          },
        },
      };
    }
    case SET_BULK_BEAT_UPDATE_USER_INPUT: {
      const { userInput } = action.payload;
      return {
        ...state,
        bulkUpdateBeat: {
          ...state.bulkUpdateBeat,
          userInput: {
            ...state.bulkUpdateBeat.userInput,
            ...userInput,
          },
        },
      };
    }
    case SET_BULK_BEAT_UPDATE_VALIDATION_STATUS: {
      const { validationStatus } = action.payload;
      return {
        ...state,
        bulkUpdateBeat: {
          ...state.bulkUpdateBeat,
          validationStatus: {
            ...state.bulkUpdateBeat.validationStatus,
            ...validationStatus,
          },
        },
      };
    }
    case PATCH_INVERSE_ECG_REQUESTED: {
      return {
        ...state,
        inverseEcg: {
          ...state.inverseEcg,
          pending: true,
          error: null,
        },
      };
    }
    case PATCH_INVERSE_ECG_SUCCEED: {
      return {
        ...state,
        inverseEcg: {
          ...state.inverseEcg,
          pending: false,
          error: null,
        },
      };
    }
    case PATCH_INVERSE_ECG_FAILED: {
      return {
        ...state,
        inverseEcg: {
          ...state.inverseEcg,
          pending: false,
          error: action.error,
        },
      };
    }
    case PATCH_BULK_BEAT_UPDATE_REQUESTED: {
      return {
        ...state,
        bulkUpdateBeat: {
          ...state.bulkUpdateBeat,
          pending: true,
          error: null,
        },
      };
    }
    case PATCH_BULK_BEAT_UPDATE_SUCCEED: {
      return {
        ...state,
        bulkUpdateBeat: {
          ...state.bulkUpdateBeat,
          pending: false,
          error: null,
        },
      };
    }
    case PATCH_BULK_BEAT_UPDATE_FAILED: {
      return {
        ...state,
        bulkUpdateBeat: {
          ...state.bulkUpdateBeat,
          pending: false,
          error: action.error,
        },
      };
    }
    case REFRESH_PAUSE_REQUESTED: {
      return {
        ...state,
        refreshPause: {
          ...state.refreshPause,
          pending: true,
          error: null,
        },
      };
    }
    case REFRESH_PAUSE_SUCCEED: {
      return {
        ...state,
        refreshPause: {
          ...state.refreshPause,
          pending: false,
          error: null,
        },
      };
    }
    case REFRESH_PAUSE_FAILED: {
      return {
        ...state,
        refreshPause: {
          ...state.refreshPause,
          pending: false,
          error: action.error,
        },
      };
    }
    case REVERT_SAVE_POINT_REQUESTED: {
      return {
        ...state,
        revertSavePoint: {
          ...state.revertSavePoint,
          pending: true,
          error: null,
        },
      };
    }
    case REVERT_SAVE_POINT_SUCCEED: {
      return {
        ...state,
        revertSavePoint: {
          ...state.revertSavePoint,
          pending: false,
          error: null,
        },
      };
    }
    case REVERT_SAVE_POINT_FAILED: {
      return {
        ...state,
        revertSavePoint: {
          ...state.revertSavePoint,
          pending: false,
          error: action.error,
        },
      };
    }
    default:
      return state;
  }
}

// Action Creators
export function resetInverseEcgState() {
  return {
    type: RESET_INVERSE_ECG_STATE,
  };
}
export function resetBulkBeatUpdateState() {
  return {
    type: RESET_BULK_BEAT_UPDATE_STATE,
  };
}
export function setInverseEcgIntervals(payload) {
  return {
    type: SET_INVERSE_ECG_INTERVALS,
    payload,
  };
}
export function setInverseEcgDoAnalysis(payload) {
  return {
    type: SET_INVERSE_ECG_DO_ANALYSIS,
    payload,
  };
}
export function setInverseEcgValidationStatus(payload) {
  return {
    type: SET_INVERSE_ECG_VALIDATION_STATUS,
    payload,
  };
}

export function setBulkUpdateBeatCheckBoxStatus(payload) {
  return {
    type: SET_BULK_BEAT_UPDATE_CHECKBOX_STATUS,
    payload,
  };
}
export function setBulkUpdateBeatUserInput(payload) {
  return {
    type: SET_BULK_BEAT_UPDATE_USER_INPUT,
    payload,
  };
}
export function setBulkUpdateBeatValidationStatus(payload) {
  return {
    type: SET_BULK_BEAT_UPDATE_VALIDATION_STATUS,
    payload,
  };
}

export function patchInverseEcgRequested() {
  return {
    type: PATCH_INVERSE_ECG_REQUESTED,
  };
}
function patchInverseEcgSucceed() {
  return {
    type: PATCH_INVERSE_ECG_SUCCEED,
  };
}
function patchInverseEcgFailed(error) {
  return {
    type: PATCH_INVERSE_ECG_FAILED,
    error,
  };
}

export function patchBulkUpdateBeatRequested() {
  return {
    type: PATCH_BULK_BEAT_UPDATE_REQUESTED,
  };
}
function patchBulkUpdateBeatSucceed() {
  return {
    type: PATCH_BULK_BEAT_UPDATE_SUCCEED,
  };
}
function patchBulkUpdateBeatFailed(error) {
  return {
    type: PATCH_BULK_BEAT_UPDATE_FAILED,
    error,
  };
}

export function refreshPauseRequested() {
  return {
    type: REFRESH_PAUSE_REQUESTED,
  };
}
function refreshPauseSucceed() {
  return {
    type: REFRESH_PAUSE_SUCCEED,
  };
}
function refreshPauseFailed(error) {
  return {
    type: REFRESH_PAUSE_FAILED,
    error,
  };
}

export function revertSavePointRequested() {
  return {
    type: REVERT_SAVE_POINT_REQUESTED,
  };
}
function revertSavePointSucceed() {
  return {
    type: REVERT_SAVE_POINT_SUCCEED,
  };
}
function revertSavePointFailed(error) {
  return {
    type: REVERT_SAVE_POINT_FAILED,
    error,
  };
}

// Saga functions
function* _patchInverseEcg() {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const { recordingStartMs } = yield select(selectRecordingTime);
    const { intervals, doAnalysis } = yield select(selectInverseEcgUserInput);

    yield call(ApiManager.patchInverse, ecgTestId, {
      rawDataInverseSectionList: transformIntervalsToWI(
        recordingStartMs,
        intervals
      ),
      doAiAnalysis: doAnalysis,
      isReset: doAnalysis,
    });
    yield put(patchInverseEcgSucceed());
  } catch (error) {
    console.error(error);
    yield put(patchInverseEcgFailed(error));
  }
}
function* _patchBulkUpdateBeat() {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const { recordingStartMs } = yield select(selectRecordingTime);
    const { checkBoxStatus, userInput } = yield select(selectBulkUpdateBeat);

    /*  Set LocalStorage */
    const { LAST_VIEWED_TID_STATE } = LocalStorageKey;
    const lastViewedTidData = LocalStorageManager.getItem(
      LAST_VIEWED_TID_STATE
    );

    LocalStorageManager.setItem(LAST_VIEWED_TID_STATE, {
      ...lastViewedTidData,
      bulkUpdateBeat: {
        checkBoxStatus,
        userInput,
      },
    });
    /*  Set LocalStorage */

    const {
      isCheckedMin,
      isCheckedMax,
      isCheckedChangeAdjacentBeatTo,
      isCheckedNotUpdateSInAfToN,
    } = checkBoxStatus;

    const {
      ectopicTypeToFind,
      beatTypeToFind,
      beatTypeToChange: { firstBeat, secondBeat },
      intervals,
      metric,
      min,
      max,
      adjacentBeatType,
      adjacentTo,
      isTotalRangeSelected,
    } = userInput;

    const body = {
      currentEctopicType: ectopicTypeToFind.value,
      currentBeatType: beatTypeToFind.value,
      beatType: firstBeat.value,
    };

    if (!isTotalRangeSelected) {
      body.updateSectionList = transformIntervalsToWI(
        recordingStartMs,
        intervals
      );
    }
    if (isCheckedMin || isCheckedMax) {
      switch (metric) {
        case METRIC.BEAT_HR:
          if (isCheckedMin) body.gteBpm = Number(min);
          if (isCheckedMax) body.lteBpm = Number(max);
          break;
        case METRIC.RRI:
          if (isCheckedMin) body.gteRri = Math.floor(Number(min) / 4);
          if (isCheckedMax) body.lteRri = Math.floor(Number(max) / 4);
          break;
        default:
          break;
      }
    }

    if (isCheckedChangeAdjacentBeatTo) {
      body.sideBeatType = adjacentBeatType.value;
      body.isNext = adjacentTo === ADJACENT_TO.BEHIND_OF;
    }
    if (!isCheckedNotUpdateSInAfToN) {
      body.isUpdateSInAfToN = true;
    }

    if (ectopicTypeToFind.value === ECTOPIC_TYPE.ISOLATED_AND_COUPLET.value) {
      const isolatedBody = {
        ...body,
        currentEctopicType: ECTOPIC_TYPE.ISOLATED.value,
      };
      const coupletBody = {
        ...body,
        currentEctopicType: ECTOPIC_TYPE.COUPLET.value,
      };

      yield all([
        call(ApiManager.patchUpdateBeatsByEctopicType, ecgTestId, isolatedBody),
        call(ApiManager.patchUpdateBeatsByEctopicType, ecgTestId, coupletBody),
      ]);
    } else if (ectopicTypeToFind.value === ECTOPIC_TYPE.COUPLET.value) {
      delete body.beatType;
      body.onsetBeatType = firstBeat.value;
      body.terminationBeatType = secondBeat.value;
      yield call(ApiManager.patchUpdateCoupletSeparately, ecgTestId, body);
    } else {
      if (ectopicTypeToFind.value === ECTOPIC_TYPE.ALL_TYPES.value) {
        delete body.currentEctopicType;
      }
      yield call(ApiManager.patchUpdateBeatsByEctopicType, ecgTestId, body);
    }

    yield put(patchBulkUpdateBeatSucceed());
  } catch (error) {
    console.error(error);
    yield put(patchBulkUpdateBeatFailed(error));
  }
}

export function* _refreshPause() {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    yield call(ApiManager.refreshPause, ecgTestId);
    yield put(refreshPauseSucceed());
  } catch (error) {
    console.error(error);
    yield put(refreshPauseFailed(error));
  }
}

export function* _revertSavePoint() {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    yield call(ApiManager.revertSavePoint, ecgTestId);
    yield put(revertSavePointSucceed());
  } catch (error) {
    console.error(error);
    yield put(revertSavePointFailed(error));
  }
}

export function* _devMode(action) {
  try {
    const devMode = LocalStorageManager.getItem(LocalStorageKey.DEV_MODE);
    if (!devMode) return;

    const {
      clickedWaveformIndex,
      representativeTimestamp,
      representativeWaveformIndex,
      selectionMarkerType,
    } = action.selectionStrip;

    if (selectionMarkerType === SELECTION_MARKER_TYPE.RESET) return;

    const clickedTimestamp = representativeTimestamp + clickedWaveformIndex * 4;

    const { beats } = yield select(
      (state) =>
        state.testResultReducer.beatsNEctopicList.data[
          representativeWaveformIndex
        ]
    );

    const { nearestRPI, idx } = beats.waveformIndex.reduce(
      (acc, cur, idx) => {
        const diff = Math.abs(
          cur - (clickedWaveformIndex + representativeWaveformIndex)
        );
        if (diff < acc.minDiff) {
          return { minDiff: diff, nearestRPI: cur, idx };
        }
        return acc;
      },
      { minDiff: Infinity, nearestRPI: null, idx: null }
    );

    const typeOfNearestRPI = beats.beatType[idx];

    const table = {};
    table['30s Chart'] = {
      nearestRPI,
      typeOfNearestRPI: TEN_SEC_STRIP_EDIT.BEAT_TYPE[typeOfNearestRPI],
      clickedWI: representativeWaveformIndex + clickedWaveformIndex,
      clickedMs: clickedTimestamp,
      repWI: representativeWaveformIndex,
      repMs: representativeTimestamp,
    };
    console.table(table);
  } catch (error) {
    console.error(error);
  }
}

// Saga
export function* saga() {
  // :: Inverse ECG
  yield takeLatest(PATCH_INVERSE_ECG_REQUESTED, _patchInverseEcg);
  // :: Bulk beat update
  yield takeLatest(PATCH_BULK_BEAT_UPDATE_REQUESTED, _patchBulkUpdateBeat);
  // :: Refresh Pause
  yield takeLatest(REFRESH_PAUSE_REQUESTED, _refreshPause);
  // :: Reverse Save Point
  yield takeLatest(REVERT_SAVE_POINT_REQUESTED, _revertSavePoint);
}

// util funcs
function transformIntervalsToWI(recordingStartMs, intervals) {
  return intervals
    .map((intervalElement) =>
      intervalElement.map(({ timestamp }) =>
        transformMsToWaveformIndex(recordingStartMs, timestamp)
      )
    )
    .filter((intervalElement) => intervalElement.length > 0);
}
