/**
 *
 * 에러 코드 정의.
 *
 * @link https://www.notion.so/huinno/Memo-service-Error-Code-log-32c90b561a3e4c9eb56c900076a8060d
 */
export const ErrorCode = {
  EXPIRED_ACCESS_TOKEN: 'not_authenticated',

  /**
   * @description 허용되지 않은 IP로 접근 시도시 발생하는 에러코드
   */
  IP_NOT_ALLOWED: 'E10002',
};

export const ErrorDetails = {
  EXCEED_HR_BPM:
    'Some waveform indexes exceeds valid HR range, waveform indexes',
};
