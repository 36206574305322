export const CLASS_CONST = {
  CALIPER: {
    MOUSE_TRACKER: 'huinno-caliper-mouse-tracker',
    GROUP: 'huinno-caliper-group',
    LEFT_LEG_GROUP: 'huinno-caliper-left-leg-group',
    RIGHT_LEG_GROUP: 'huinno-caliper-right-leg-group',
    CENTER_LEG_GROUP: 'huinno-caliper-center-leg-group',
    CENTER_UNDER_LINE: 'huinno-caliper-center-under-line',
    CENTER_CONTROL_HANDLE: 'huinno-caliper-center-control-handle',
    INTERVAL: 'huinno-caliper-interval',
    TICK_MARK_GROUP: 'huinno-caliper-tick-marks-group',
    TICK_MARK: 'huinno-caliper-tick-mark',
  },
};
