import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
`;

const LabelText = styled.div`
  width: 96px;
  font-size: 14px;
  color: ${(props) => props.theme.color.BLACK};
`;

const InputContainer = styled.div`
  flex: 1;
`;

function DialogRowItem(props) {
  return (
    <Wrapper>
      <LabelText>{props.label}</LabelText>
      <InputContainer>{props.children}</InputContainer>
    </Wrapper>
  );
}

export default DialogRowItem;
