import { call, debounce, put, select, takeLatest } from 'redux-saga/effects';

import LocalStorageKey from 'constant/LocalStorageKey';
import Const from 'constant/Const';
import { tableSortInfo } from 'constant/TestTableConst';

import {
  getFetchOrderListParam,
  getFilterExceptNoneState,
  isObjectEmpty,
} from 'util/Utility';

import ApiManager from 'network/ApiManager';

import LocalStorageManager from 'manager/LocalStorageManager';

import { getEcgTestSucceed } from './testResultDuck';

// Selectors
const selectOrderBy = (state) => {
  const fetchOrderList = state.ecgTestsReducer.fetchOrderList;

  let orderingParam = getFilterExceptNoneState(fetchOrderList);
  if (isObjectEmpty(orderingParam)) {
    orderingParam = [];
  }

  const orderBy = getFetchOrderListParam(orderingParam);

  return orderBy;
};

// Actions
// get hospitals hid
const FETCH_HOSPITALSHID_REQUESTED =
  'memo-web/ecgTest/FETCH_HOSPITALSHID_REQUESTED';
const FETCH_HOSPITALSHID_SUCCEED =
  'memo-web/ecgTest/FETCH_HOSPITALSHID_SUCCEED';
const FETCH_HOSPITALSHID_FAILED = 'memo-web/ecgTest/FETCH_HOSPITALSHID_FAILED';
// Init. Fetch Data
const INIT_ECG_TESTS = 'memo-web/ecg-tests/INIT_ECG_TESTS';

// Create
const CREATE_ECG_TEST_REQUESTED =
  'memo-web/ecg-tests/CREATE_ECG_TEST_REQUESTED';
const CREATE_ECG_TEST_SUCCEED = 'memo-web/ecg-tests/CREATE_ECG_TEST_SUCCEED';
const CREATE_ECG_TEST_FAILED = 'memo-web/ecg-tests/CREATE_ECG_TEST_FAILED';

// Fetch - Test Status
const FETCH_ECGTESTS_BY_TEST_STATUS_REQUESTED =
  'memo-web/ecgTest/FETCH_ECGTESTS_BY_TEST_STATUS_REQUESTED';
const FETCH_ECGTESTS_BY_TEST_STATUS_SUCCEED =
  'memo-web/ecgTest/FETCH_ECGTESTS_BY_TEST_STATUS_SUCCEED';
const FETCH_ECGTESTS_BY_TEST_STATUS_FAILED =
  'memo-web/ecgTest/FETCH_ECGTESTS_BY_TEST_STATUS_FAILED';
// Fetch - Cloud Analysis Status
const FETCH_ECGTESTS_REQUESTED = 'memo-web/ecgTest/FETCH_ECGTESTS_REQUESTED';
const FETCH_ECGTESTS_SUCCEED = 'memo-web/ecgTest/FETCH_ECGTESTS_SUCCEED';
const FETCH_ECGTESTS_FAILED = 'memo-web/ecgTest/FETCH_ECGTESTS_FAILED';
// get ecg tests detail
const GET_ECGTESTS_DETAIL_REQUESTED =
  'memo-web/ecgTest/GET_ECGTESTS_DETAIL_REQUESTED';
const GET_ECGTESTS_DETAIL_SUCCEED =
  'memo-web/ecgTest/GET_ECGTESTS_DETAIL_SUCCEED';
const GET_ECGTESTS_DETAIL_FAILED =
  'memo-web/ecgTest/GET_ECGTESTS_DETAIL_FAILED';
// Search
const SEARCH_ECGTESTS_REQUESTED = 'memo-web/ecgTest/SEARCH_ECGTESTS_REQUESTED';
const SEARCH_ECGTESTS_SUCCEED = 'memo-web/ecgTest/SEARCH_ECGTESTS_SUCCEED';
const SEARCH_ECGTESTS_FAILED = 'memo-web/ecgTest/SEARCH_ECGTESTS_FAILED';
// get validReport latest value from ECG Test API
const GET_VALID_REPORT_REQUESTED =
  'memo-web/ecg-tests/GET_VALID_REPORT_REQUESTED';
const GET_VALID_REPORT_SUCCEED = 'memo-web/ecg-tests/GET_VALID_REPORT_SUCCEED';
const GET_VALID_REPORT_FAILED = 'memo-web/ecg-tests/GET_VALID_REPORT_FAILED';

// Return Device
const ECG_TEST_RETURN_DEVICE_REQUESTED =
  'memo-web/ecg-tests/ECG_TEST_RETURN_DEVICE_REQUESTED';
const ECG_TEST_RETURN_DEVICE_SUCCEED =
  'memo-web/ecg-tests/ECG_TEST_RETURN_DEVICE_SUCCEED';
const ECG_TEST_RETURN_DEVICE_FAILED =
  'memo-web/ecg-tests/ECG_TEST_RETURN_DEVICE_FAILED';
// Patch
const PATCH_ECG_TEST_REQUESTED = 'memo-web/ecg-tests/PATCH_ECG_TEST_REQUESTED';
const PATCH_ECG_TEST_SUCCEED = 'memo-web/ecg-tests/PATCH_ECG_TEST_SUCCEED';
const PATCH_ECG_TEST_FAILED = 'memo-web/ecg-tests/PATCH_ECG_TEST_FAILED';
// Edit Done Review
const ECG_TEST_EDIT_DONE_REVIEW_REQUESTED =
  'memo-web/ecgTest/ECG_TEST_EDIT_DONE_REVIEW_REQUESTED';
const ECG_TEST_EDIT_DONE_REVIEW_SUCCEED =
  'memo-web/ecgTest/ECG_TEST_EDIT_DONE_REVIEW_SUCCEED';
const ECG_TEST_EDIT_DONE_REVIEW_FAILED =
  'memo-web/ecgTest/ECG_TEST_EDIT_DONE_REVIEW_FAILED';
// Confirm Review
const ECG_TEST_CONFIRM_REVIEW_REQUESTED =
  'memo-web/ecg-tests/ECG_TEST_CONFIRM_REVIEW_REQUESTED';
const ECG_TEST_CONFIRM_REVIEW_SUCCEED =
  'memo-web/ecg-tests/ECG_TEST_CONFIRM_REVIEW_SUCCEED';
const ECG_TEST_CONFIRM_REVIEW_FAILED =
  'memo-web/ecg-tests/ECG_TEST_CONFIRM_REVIEW_FAILED';
// Revert ECG Test Status CUSTOMER_CONFIRMED to CLOUD_CONFIRMED
const REVERT_STATUS_REQUESTED = 'memo-web/ecg-tests/REVERT_STATUS_REQUESTED';
const REVERT_STATUS_SUCCEED = 'memo-web/ecg-tests/REVERT_STATUS_SUCCEED';
const REVERT_STATUS_FAILED = 'memo-web/ecg-tests/REVERT_STATUS_FAILED';
// Revert ECG Test Status 내부검토중 -> 파트너 편집 중
const REVERT_TO_PARTNER_EDIT_DONE_REQUESTED =
  'memo-web/ecg-tests/REVERT_TO_PARTNER_EDIT_DONE_REQUESTED';
const REVERT_TO_PARTNER_EDIT_DONE_SUCCEED =
  'memo-web/ecg-tests/REVERT_TO_PARTNER_EDIT_DONE_SUCCEED';
const REVERT_TO_PARTNER_EDIT_DONE_FAILED =
  'memo-web/ecg-tests/REVERT_TO_PARTNER_EDIT_DONE_FAILED';

// Delete
const DELETE_ECG_TEST_REQUESTED =
  'memo-web/ecg-tests/DELETE_ECG_TEST_REQUESTED';
const DELETE_ECG_TEST_SUCCEED = 'memo-web/ecg-tests/DELETE_ECG_TEST_SUCCEED';
const DELETE_ECG_TEST_FAILED = 'memo-web/ecg-tests/DELETE_ECG_TEST_FAILED';

// Set fromTable
const SET_FROM_TABLE = 'memo-web/ecgTest/SET_FROM_TABLE';

// Set FetchOrderList
const SET_FETCH_ORDER_LIST = 'memo-web/ecgTest/SET_FETCH_ORDER_LIST';

// Reducer
const initialState = {
  hospitalsHid: {
    data: [],
    pending: false,
    error: null,
  },

  //
  create: {
    pending: false,
    data: null,
    error: null,
  },

  //
  fetch: {
    pending: false,
    pendingForTestStatus: false,
    firstPage: 1,
    currentPage: 1,
    lastPage: 0,
    totalItemCount: 0,
    previousFetchTestOption: {
      testStatus: [],
      currentPage: 0,
      pageSize: 0,
      testType: [],
      hid: [],
    },
    data: [],
    error: null,
  },
  detail: {
    pending: false,
    data: null,
    fromTable: '',
    error: null,
  },
  validReport: {
    pending: false,
    data: false,
    error: null,
  },

  //
  returnDevice: {
    pending: false,
    data: null,
    error: null,
  },
  patch: {
    pending: false,
    data: null,
    error: null,
  },
  editDone: {
    pending: false,
    data: null,
    error: null,
  },
  confirm: {
    pending: false,
    data: null,
    error: null,
  },
  revertStatus: {
    pending: false,
    error: null,
  },
  fetchOrderList: tableSortInfo.default,

  //
  delete: {
    pending: false,
    data: null,
    error: null,
  },
};

// padStart with 0 to hospital id field

const makeHospitalIdFormat = (results) => {
  return results.map((result) => {
    result.hid = String(result.hid).padStart(3, 0);
    return result;
  });
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    // Create
    case CREATE_ECG_TEST_REQUESTED:
      return {
        ...state,
        create: {
          ...state.create,
          pending: true,
          error: null,
        },
      };
    case CREATE_ECG_TEST_SUCCEED:
      return {
        ...state,
        create: {
          ...state.create,
          pending: false,
          data: action.data,
        },
      };
    case CREATE_ECG_TEST_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          pending: false,
          error: action.error,
        },
      };

    // Hospitals Hid
    case FETCH_HOSPITALSHID_REQUESTED:
      return {
        ...state,
        hospitalsHid: {
          ...state.hospitalsHid,
          pending: true,
          error: null,
        },
      };
    case FETCH_HOSPITALSHID_SUCCEED:
      return {
        ...state,
        hospitalsHid: {
          ...state.hospitalsHid,
          data: action.hospitalsHid,
          pending: false,
        },
      };
    case FETCH_HOSPITALSHID_FAILED:
      return {
        ...state,
        hospitalsHid: {
          ...state.hospitalsHid,
          pending: false,
          error: action.error,
        },
      };

    // Init. Fetch Data
    case INIT_ECG_TESTS:
      return {
        ...state,
        fetch: {
          pending: false,
          firstPage: 1,
          currentPage: 1,
          lastPage: 0,
          totalItemCount: 0,
          previousFetchTestOption: {
            testStatus: [],
            currentPage: 0,
            pageSize: 0,
            testType: [],
            hid: [],
          },
          data: [],
          error: null,
        },
      };

    // Fetch - Cloud Analysis Status
    case FETCH_ECGTESTS_BY_TEST_STATUS_REQUESTED:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          pendingForTestStatus: true,
          error: null,
        },
      };
    case FETCH_ECGTESTS_REQUESTED:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          // data: [],
          pending: true,
          error: null,
        },
      };
    case FETCH_ECGTESTS_BY_TEST_STATUS_SUCCEED:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          pendingForTestStatus: false,
          currentPage: action.currentPage,
          lastPage: action.lastPage,
          totalItemCount: action.totalItemCount,
          data: action.data,
          previousFetchTestOption: action.previousFetchTestOption,
        },
      };
    case FETCH_ECGTESTS_SUCCEED:
    case SEARCH_ECGTESTS_SUCCEED:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          pending: false,
          currentPage: action.currentPage,
          lastPage: action.lastPage,
          totalItemCount: action.totalItemCount,
          data: action.data,
          previousFetchTestOption: action.previousFetchTestOption,
        },
      };
    case FETCH_ECGTESTS_BY_TEST_STATUS_FAILED:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          pendingForTestStatus: false,
          error: action.error,
        },
      };
    case FETCH_ECGTESTS_FAILED:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          pending: false,
          error: action.error,
        },
      };
    // Search
    case SEARCH_ECGTESTS_REQUESTED:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          pending: true,
          error: null,
        },
      };
    // FETCH_ECGTESTS_SUCCEED와 동일
    // case SEARCH_ECGTESTS_SUCCEED
    case SEARCH_ECGTESTS_FAILED:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          pending: false,
          error: action.error,
        },
      };
    // get ecg tests detail
    case GET_ECGTESTS_DETAIL_REQUESTED:
      return {
        ...state,
        detail: {
          ...state.detail,
          pending: true,
          error: null,
        },
      };
    case GET_ECGTESTS_DETAIL_SUCCEED:
      return {
        ...state,
        detail: {
          ...state.detail,
          pending: false,
          data: action.data,
        },
      };
    case GET_ECGTESTS_DETAIL_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          pending: false,
          error: action.error,
        },
      };
    // get validReport latest value from ECG Test API
    case GET_VALID_REPORT_REQUESTED:
      return {
        ...state,
        validReport: {
          ...state.validReport,
          pending: true,
          data: false,
          error: null,
        },
      };
    case GET_VALID_REPORT_SUCCEED:
      return {
        ...state,
        validReport: {
          ...state.validReport,
          pending: false,
          data: action.data.latestReport.validReport,
        },
      };
    case GET_VALID_REPORT_FAILED:
      return {
        ...state,
        validReport: {
          ...state.validReport,
          pending: false,
          data: false,
          error: action.error,
        },
      };

    // Return Device
    case ECG_TEST_RETURN_DEVICE_REQUESTED:
      return {
        ...state,
        returnDevice: {
          ...state.returnDevice,
          pending: true,
          error: null,
        },
      };
    case ECG_TEST_RETURN_DEVICE_SUCCEED:
      return {
        ...state,
        returnDevice: {
          ...state.returnDevice,
          pending: false,
          data: action.data,
        },
      };
    case ECG_TEST_RETURN_DEVICE_FAILED:
      return {
        ...state,
        returnDevice: {
          ...state.returnDevice,
          pending: false,
          error: action.error,
        },
      };
    // Patch
    case PATCH_ECG_TEST_REQUESTED:
      return {
        ...state,
        patch: {
          ...state.patch,
          pending: true,
          error: null,
        },
      };
    case PATCH_ECG_TEST_SUCCEED:
      return {
        ...state,
        patch: {
          ...state.patch,
          pending: false,
          data: action.data,
        },
      };
    case PATCH_ECG_TEST_FAILED:
      return {
        ...state,
        patch: {
          ...state.patch,
          pending: false,
          error: action.error,
        },
      };
    // Edit Done Review
    case ECG_TEST_EDIT_DONE_REVIEW_REQUESTED:
      return {
        ...state,
        editDone: {
          pending: true,
          error: null,
        },
      };
    case ECG_TEST_EDIT_DONE_REVIEW_SUCCEED:
      return {
        ...state,
        editDone: {
          pending: false,
          data: action.data,
        },
      };
    case ECG_TEST_EDIT_DONE_REVIEW_FAILED:
      return {
        ...state,
        editDone: {
          pending: false,
          error: action.error,
        },
      };
    // Confirm Review
    case ECG_TEST_CONFIRM_REVIEW_REQUESTED:
      return {
        ...state,
        confirm: {
          ...state.confirm,
          pending: true,
          error: null,
        },
      };
    case ECG_TEST_CONFIRM_REVIEW_SUCCEED:
      return {
        ...state,
        confirm: {
          ...state.confirm,
          pending: false,
          data: action.data,
        },
      };
    case ECG_TEST_CONFIRM_REVIEW_FAILED:
      return {
        ...state,
        confirm: {
          ...state.confirm,
          pending: false,
          error: action.error,
        },
      };
    // Revert ECG Test Status CUSTOMER_CONFIRMED to CLOUD_CONFIRMED
    // Revert ECG Test Status 내부검토중 -> 파트너 편집 중
    case REVERT_TO_PARTNER_EDIT_DONE_REQUESTED:
    case REVERT_STATUS_REQUESTED:
      return {
        ...state,
        revertStatus: {
          pending: true,
          error: null,
        },
      };
    case REVERT_TO_PARTNER_EDIT_DONE_SUCCEED:
    case REVERT_STATUS_SUCCEED:
      return {
        ...state,
        revertStatus: {
          ...state.revertStatus,
          pending: false,
        },
      };
    case REVERT_TO_PARTNER_EDIT_DONE_FAILED:
    case REVERT_STATUS_FAILED:
      return {
        ...state,
        revertStatus: {
          ...state.revertStatus,
          pending: false,
          error: action.error,
        },
      };

    // Delete
    case DELETE_ECG_TEST_REQUESTED:
      return {
        ...state,
        delete: {
          ...state.delete,
          pending: true,
          error: null,
        },
      };
    case DELETE_ECG_TEST_SUCCEED:
      return {
        ...state,
        delete: {
          ...state.delete,
          pending: false,
          data: action.data,
        },
      };
    case DELETE_ECG_TEST_FAILED:
      return {
        ...state,
        delete: {
          ...state.delete,
          pending: false,
          error: action.error,
        },
      };

    case SET_FROM_TABLE:
      return {
        ...state,
        detail: {
          ...state.detail,
          fromTable: action.fromTable,
        },
      };
    case SET_FETCH_ORDER_LIST:
      return {
        ...state,
        fetchOrderList: action.fetchOrderList,
      };
    default:
      return state;
  }
}

// Action Creators
// Create
export function createEcgTestRequested(
  patientName,
  patientNumber,
  patientSex,
  patientPhoneNumber,
  patientBirth,
  testDuration,
  referredBy
) {
  return {
    type: CREATE_ECG_TEST_REQUESTED,
    patientName,
    patientNumber,
    patientSex,
    patientPhoneNumber,
    patientBirth,
    testDuration,
    referredBy,
  };
}
function createEcgTestSucceed(data) {
  return {
    type: CREATE_ECG_TEST_SUCCEED,
    data: data,
  };
}
function createEcgTestFailed(error) {
  return {
    type: CREATE_ECG_TEST_FAILED,
    error: error,
  };
}

// get hospitals hid
export function fetchHospitalsHidRequested() {
  return {
    type: FETCH_HOSPITALSHID_REQUESTED,
  };
}
function fetchHospitalsHidSucceed({ hospitalsHid }) {
  return {
    type: FETCH_HOSPITALSHID_SUCCEED,
    hospitalsHid,
  };
}
function fetchHospitalsHidFailed(error) {
  return { type: FETCH_HOSPITALSHID_FAILED, error };
}
// Init. Fetch Data
export function initEcgTests() {
  return {
    type: INIT_ECG_TESTS,
  };
}
// Fetch - Test Status
export function fetchEcgTestsByTestStatusRequested(
  testStatus,
  currentPage,
  pageSize,
  testType,
  hid
) {
  return {
    type: FETCH_ECGTESTS_BY_TEST_STATUS_REQUESTED,
    testStatus,
    currentPage,
    pageSize,
    testType,
    hid,
  };
}
export function fetchEcgTestsByTestStatusSucceed(
  currentPage,
  lastPage,
  totalItemCount,
  data
) {
  return {
    type: FETCH_ECGTESTS_BY_TEST_STATUS_SUCCEED,
    currentPage,
    lastPage,
    totalItemCount,
    data,
  };
}
export function fetchEcgTestsByTestStatusFailed(error) {
  return { type: FETCH_ECGTESTS_BY_TEST_STATUS_FAILED, error };
}
// Fetch - Cloud Analysis Status
export function fetchEcgTestsRequested(
  testStatus,
  currentPage,
  pageSize,
  testType,
  hid
) {
  return {
    type: FETCH_ECGTESTS_REQUESTED,
    testStatus,
    currentPage,
    pageSize,
    testType,
    hid,
  };
}
export function fetchEcgTestsSucceed(
  currentPage,
  lastPage,
  totalItemCount,
  data,
  previousFetchTestOption
) {
  return {
    type: FETCH_ECGTESTS_SUCCEED,
    currentPage,
    lastPage,
    totalItemCount,
    data,
    previousFetchTestOption,
  };
}
export function fetchEcgTestsFailed(error) {
  return { type: FETCH_ECGTESTS_FAILED, error };
}
// get ecg tests detail
export function getEcgTestsDetailRequested(tid) {
  return { type: GET_ECGTESTS_DETAIL_REQUESTED, tid };
}
function getEcgTestsDetailSucceed(data) {
  return {
    type: GET_ECGTESTS_DETAIL_SUCCEED,
    data,
  };
}
function getEcgTestsDetailFailed(error) {
  return { type: GET_ECGTESTS_DETAIL_FAILED, error };
}
// Search
export function searchEcgTestsRequested(
  statusList,
  page,
  pageSize,
  keyword,
  orderBy
) {
  return {
    type: SEARCH_ECGTESTS_REQUESTED,
    statusList,
    page,
    pageSize,
    keyword,
    orderBy,
  };
}
function searchEcgTestSucceed(currentPage, lastPage, totalItemCount, data) {
  return {
    type: SEARCH_ECGTESTS_SUCCEED,
    currentPage,
    lastPage,
    totalItemCount,
    data,
  };
}
function searchEcgTestFailed(error) {
  return { type: SEARCH_ECGTESTS_FAILED, error };
}
// get validReport latest value from ECG Test API
export function getValidReportRequested({ ecgTestId }) {
  return {
    type: GET_VALID_REPORT_REQUESTED,
    ecgTestId,
  };
}
function getValidReportSucceed(data) {
  return {
    type: GET_VALID_REPORT_SUCCEED,
    data,
  };
}
function getValidReportFailed(error) {
  return { type: GET_VALID_REPORT_FAILED, error: error };
}

// Return Device
export function ecgTestReturnDeviceRequested(ecgTestId) {
  return {
    type: ECG_TEST_RETURN_DEVICE_REQUESTED,
    ecgTestId: ecgTestId,
  };
}
export function ecgTestReturnDeviceSucceed(data) {
  return {
    type: ECG_TEST_RETURN_DEVICE_SUCCEED,
    data: data,
  };
}
export function ecgTestReturnDeviceFailed(error) {
  return {
    type: ECG_TEST_RETURN_DEVICE_FAILED,
    error: error,
  };
}
// Patch
export function patchEcgTestRequested({ ecgTestId, form, callback }) {
  return {
    type: PATCH_ECG_TEST_REQUESTED,
    ecgTestId,
    form,
    callback,
  };
}
function patchEcgTestSucceed(data) {
  return {
    type: PATCH_ECG_TEST_SUCCEED,
    data,
  };
}
function patchEcgTestFailed(error) {
  return {
    type: PATCH_ECG_TEST_FAILED,
    error,
  };
}
// Edit Done Review
export function editDoneEcgTestReviewRequested({
  ecgTestId,
  isSendToHospitalUser,
}) {
  return {
    type: ECG_TEST_EDIT_DONE_REVIEW_REQUESTED,
    ecgTestId,
    isSendToHospitalUser,
  };
}
function editDoneEcgTestReviewSucceed(data) {
  return { type: ECG_TEST_EDIT_DONE_REVIEW_SUCCEED, data };
}
function editDoneEcgTestReviewFailed(error) {
  return { type: ECG_TEST_EDIT_DONE_REVIEW_FAILED, error };
}
// Confirm Review
export function confirmEcgTestReviewRequested({
  ecgTestId,
  reGenerateReport,
  callback,
}) {
  return {
    type: ECG_TEST_CONFIRM_REVIEW_REQUESTED,
    ecgTestId,
    reGenerateReport,
    callback,
  };
}
function confirmEcgTestReviewSucceed(data) {
  return {
    type: ECG_TEST_CONFIRM_REVIEW_SUCCEED,
    data,
  };
}
function confirmEcgTestReviewFailed(error) {
  return {
    type: ECG_TEST_CONFIRM_REVIEW_FAILED,
    error,
  };
}
// Revert ECG Test Status CUSTOMER_CONFIRMED to CLOUD_CONFIRMED
export function revertStatusRequested(ecgTestId, callback) {
  return {
    type: REVERT_STATUS_REQUESTED,
    ecgTestId,
    callback,
  };
}
function revertStatusSucceed(data) {
  return {
    type: REVERT_STATUS_SUCCEED,
    data: data,
  };
}
function revertStatusFailed(error) {
  return {
    type: REVERT_STATUS_FAILED,
    error: error,
  };
}
// Revert ECG Test Status 내부검토중 -> 파트너 편집 중
export function revertToPartnerEditDoneRequested(ecgTestId, callback) {
  return {
    type: REVERT_TO_PARTNER_EDIT_DONE_REQUESTED,
    ecgTestId,
    callback,
  };
}
function revertToPartnerEditDoneSucceed(data) {
  return {
    type: REVERT_TO_PARTNER_EDIT_DONE_SUCCEED,
    data: data,
  };
}
function revertToPartnerEditDoneFailed(error) {
  return {
    type: REVERT_TO_PARTNER_EDIT_DONE_FAILED,
    error,
  };
}

// Delete
export function deleteEcgTestRequested(ecgTestId) {
  return {
    type: DELETE_ECG_TEST_REQUESTED,
    ecgTestId: ecgTestId,
  };
}
export function deleteEcgTestSucceed(data) {
  return {
    type: DELETE_ECG_TEST_SUCCEED,
    data: data,
  };
}
export function deleteEcgTestFailed(error) {
  return {
    type: DELETE_ECG_TEST_FAILED,
    error: error,
  };
}

export function setFromTable(fromTable) {
  return { type: SET_FROM_TABLE, fromTable };
}

export function setFetchOrderList(fetchOrderList) {
  return { type: SET_FETCH_ORDER_LIST, fetchOrderList };
}

// Sagas
function* createEcgTest(action) {
  try {
    const { status, data } = yield call(
      ApiManager.createEcgTest,
      action.patientName,
      action.patientNumber,
      action.patientSex,
      action.patientPhoneNumber,
      action.patientBirth,
      action.testDuration,
      action.referredBy,
      action.referredBy
    );

    yield put(createEcgTestSucceed(data));
  } catch (error) {
    yield put(createEcgTestFailed(error));
  }
}

function* readHospitalsHid(action) {
  try {
    const {
      data: { results },
    } = yield call(ApiManager.getHospitalsHid);

    const newData = results.map((result) => {
      result.id = result.hid;
      result.type = 'check';
      result.label = String(result.hid).padStart(3, 0);
      result.status = true;
      return result;
    });

    yield put(fetchHospitalsHidSucceed({ hospitalsHid: newData }));
  } catch (error) {
    yield put(
      fetchHospitalsHidFailed({
        name: error.name,
        status: error.status,
        message: error.message,
      })
    );
  }
}
function* readEcgTestsByTestStatus(action) {
  try {
    const { testStatus, currentPage, pageSize, testType, hid } = action;
    const { data } = yield call(
      ApiManager.readEcgTestsByTestStatus,
      testStatus,
      currentPage,
      pageSize,
      testType,
      hid
    );

    const { count, results } = data;
    const lastPage = Math.ceil(count / pageSize);

    const newResults = makeHospitalIdFormat(results);

    const previousFetchTestOption = {
      testStatus,
      currentPage,
      pageSize,
      testType,
      hid,
    };

    yield put(
      fetchEcgTestsByTestStatusSucceed(
        currentPage,
        lastPage,
        count,
        newResults,
        previousFetchTestOption
      )
    );
  } catch (error) {
    yield put(
      fetchEcgTestsByTestStatusFailed({
        name: error.name,
        status: error.status,
        message: error.message,
      })
    );
  }
}
function* readEcgTestsByCloudAnalysisStatus(action) {
  try {
    const { testStatus, currentPage, pageSize, testType, hid } = action;

    const orderBy = yield select(selectOrderBy);

    const { data } = yield call(
      ApiManager.readEcgTestsByCloudAnalysisStatus,
      testStatus,
      currentPage,
      pageSize,
      testType,
      hid,
      orderBy
    );
    const { count, results } = data;
    const lastPage = Math.ceil(count / pageSize);

    const newResults = makeHospitalIdFormat(results);

    const previousFetchTestOption = {
      testStatus,
      currentPage,
      pageSize,
      testType,
      hid,
      orderBy,
    };

    yield put(
      fetchEcgTestsSucceed(
        currentPage,
        lastPage,
        count,
        newResults,
        previousFetchTestOption
      )
    );
  } catch (error) {
    yield put(
      fetchEcgTestsFailed({
        name: error.name,
        status: error.status,
        message: error.message,
      })
    );
  }
}
function* getEcgTestsDetail(action) {
  try {
    const { data } = yield call(ApiManager.getEcgTestDetail, {
      ecgTestId: action.tid,
    });
    const { result } = data;

    yield put(getEcgTestsDetailSucceed(result));
  } catch (error) {
    yield put(
      getEcgTestsDetailFailed({
        name: error.name,
        status: error.status,
        message: error.message,
      })
    );
  }
}
function* searchEcgTests(action) {
  try {
    const { statusList, page, pageSize, keyword } = action;

    const orderBy = yield select(selectOrderBy);

    const currentLocation = window.location.search?.split('=')[1] || 'ongoing';

    const testType = (
      LocalStorageManager.getItem(LocalStorageKey.TEST_OPTION_TYPE)?.[
        currentLocation
      ] ||
      Const.TABLE_FILTER_TEST_TYPE_OPTION.map((state) => {
        state.status = true;
        return state;
      })
    )
      ?.filter((testType) => testType.status)
      .map((test) => test.id);

    const hospitalsHid = LocalStorageManager.getItem(
      LocalStorageKey.HOSPITAL_FILTER_TYPE
    )
      ?.[currentLocation]?.filter((hid) => hid.status)
      .map((hid) => hid.hid);

    if (hospitalsHid?.length === 0) {
      const page = 1;
      const lastPage = 0;
      const count = 0;
      const newResults = [];
      yield put(searchEcgTestSucceed(page, lastPage, count, newResults));
      return;
    }

    const { data } = yield call(
      ApiManager.searchEcgTests,
      statusList,
      page,
      pageSize,
      keyword,
      testType,
      hospitalsHid,
      orderBy
    );
    const { count, results } = data;
    const lastPage = Math.ceil(count / pageSize);

    const newResults = makeHospitalIdFormat(results);

    yield put(searchEcgTestSucceed(page, lastPage, count, newResults));
  } catch (error) {
    yield put(
      searchEcgTestFailed({
        name: error.name,
        status: error.status,
        message: error.message,
      })
    );
  }
}
function* getValidReport(action) {
  try {
    const { data } = yield call(ApiManager.getEcgTestDetail, {
      ecgTestId: action.ecgTestId,
    });

    yield put(getValidReportSucceed(data.result));
  } catch (error) {
    yield put(getValidReportFailed(error));
  }
}

function* ecgTestReturnDevice(action) {
  try {
    const { data } = yield call(
      ApiManager.ecgTestReturnDevice,
      { ecgTestId: action.ecgTestId },
      action.callback
    );

    yield put(ecgTestReturnDeviceSucceed(data));
  } catch (error) {
    yield put(ecgTestReturnDeviceFailed(error));
  }
}
function* patchEcgTest(action) {
  try {
    const { data } = yield call(
      ApiManager.patchEcgTest,
      {
        ecgTestId: action.ecgTestId,
        body: action.form,
      },
      action.callback
    );

    yield put(patchEcgTestSucceed(data.result));
    // yield put(getEcgTestSucceed(data.result));
  } catch (error) {
    yield put(patchEcgTestFailed(error));
  }
}
function* ecgTestEditDoneReview(action) {
  try {
    const { ecgTestId, isSendToHospitalUser, callback } = action;
    const { data: editDoneData } = yield call(
      ApiManager.ecgTestEditDoneReview,
      { ecgTestId, isSendToHospitalUser },
      callback
    );
    const { result } = editDoneData;

    yield put(editDoneEcgTestReviewSucceed(result));

    if (!!action.previousFetchTestOption) {
      const { testStatus, currentPage, pageSize, testType, hid } =
        action.previousFetchTestOption;

      yield put(
        fetchEcgTestsRequested(testStatus, currentPage, pageSize, testType, hid)
      );
    }
  } catch (error) {
    yield put(editDoneEcgTestReviewFailed(error));
  }
}
function* ecgTestConfirmReview(action) {
  try {
    const { data } = yield call(
      ApiManager.ecgTestConfirmReview,
      { ecgTestId: action.ecgTestId },
      action.callback
    );

    yield put(confirmEcgTestReviewSucceed(data));
  } catch (error) {
    yield put(confirmEcgTestReviewFailed(error));
  }
}
function* revertStatus(action) {
  try {
    const { data } = yield call(
      ApiManager.patchRevertStatus,
      { ecgTestId: action.ecgTestId },
      action.callback
    );

    yield put(getEcgTestSucceed(data.result));
    yield put(revertStatusSucceed(data.result));
  } catch (error) {
    yield put(revertStatusFailed(error));
  }
}
function* revertToPartnerEditDone(action) {
  try {
    const { data } = yield call(
      ApiManager.postRevertToPartnerEditDone,
      { ecgTestId: action.ecgTestId },
      action.callback
    );

    yield put(revertToPartnerEditDoneSucceed(data.result));
  } catch (error) {
    yield put(revertToPartnerEditDoneFailed(error));
  }
}
function* deleteEcgTest(action) {
  try {
    const { data } = yield call(
      ApiManager.deleteEcgTest,
      { ecgTestId: action.ecgTestId },
      action.callback
    );

    yield put(deleteEcgTestSucceed(data));
  } catch (error) {
    yield put(deleteEcgTestFailed(error));
  }
}

export function* saga() {
  yield takeLatest(CREATE_ECG_TEST_REQUESTED, createEcgTest);

  yield takeLatest(FETCH_HOSPITALSHID_REQUESTED, readHospitalsHid);

  yield debounce(
    350,
    FETCH_ECGTESTS_BY_TEST_STATUS_REQUESTED,
    readEcgTestsByTestStatus
  );
  yield debounce(
    350,
    FETCH_ECGTESTS_REQUESTED,
    readEcgTestsByCloudAnalysisStatus
  );
  yield takeLatest(GET_ECGTESTS_DETAIL_REQUESTED, getEcgTestsDetail);
  yield takeLatest(SEARCH_ECGTESTS_REQUESTED, searchEcgTests);
  yield takeLatest(GET_VALID_REPORT_REQUESTED, getValidReport);

  yield takeLatest(ECG_TEST_RETURN_DEVICE_REQUESTED, ecgTestReturnDevice);
  yield takeLatest(PATCH_ECG_TEST_REQUESTED, patchEcgTest);
  yield takeLatest(ECG_TEST_EDIT_DONE_REVIEW_REQUESTED, ecgTestEditDoneReview);
  yield takeLatest(ECG_TEST_CONFIRM_REVIEW_REQUESTED, ecgTestConfirmReview);
  yield takeLatest(REVERT_STATUS_REQUESTED, revertStatus);
  yield takeLatest(
    REVERT_TO_PARTNER_EDIT_DONE_REQUESTED,
    revertToPartnerEditDone
  );

  yield takeLatest(DELETE_ECG_TEST_REQUESTED, deleteEcgTest);
}
