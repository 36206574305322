import { CLASS_CONST } from 'constant/ClassConst';
import { TEN_SEC_STRIP_DETAIL } from 'constant/ChartEditConst';
import { convertMsToBpm } from './ChartUtil';

const CLASS_CONST_CALIPER_GROUP = CLASS_CONST.CALIPER.GROUP;
const CLASS_CONST_CALIPER_TICK_MARK_GROUP = CLASS_CONST.CALIPER.TICK_MARK_GROUP;
const CLASS_CONST_CALIPER_LEFT_LEG_GROUP = CLASS_CONST.CALIPER.LEFT_LEG_GROUP;
const CLASS_CONST_CALIPER_RIGHT_LEG_GROUP = CLASS_CONST.CALIPER.RIGHT_LEG_GROUP;
const CLASS_CONST_CALIPER_CENTER_LEG_GROUP =
  CLASS_CONST.CALIPER.CENTER_LEG_GROUP;
const CLASS_CONST_CALIPER_CENTER_UNDER_LINE =
  CLASS_CONST.CALIPER.CENTER_UNDER_LINE;
const CLASS_CONST_CALIPER_CENTER_CONTROL_HANDLE =
  CLASS_CONST.CALIPER.CENTER_CONTROL_HANDLE;
const CLASS_CONST_CALIPER_INTERVAL = CLASS_CONST.CALIPER.INTERVAL;

const CONST_TEN_SEC_STRIP_DETAIL_EDIT_MODE_ADD_BEAT =
  TEN_SEC_STRIP_DETAIL.EDIT_MODE.ADD_BEAT;

const TEN_SEC_TO_MS = 10000;

/*
 * CaliperGroup을 제외한 Group의 좌표계는 Chart Container에 대한 절대좌표(Abs)이고,
 * 각 그룹의 하위요소는 그룹의 절대좌표(Absolute Coord)에 대한 상대좌표(Relation Coord) 입니다.
 *
 * ⬇️⬇️ CaliperGroup의 hierarch ⬇️⬇️
 * https://user-images.githubusercontent.com/105628936/221561443-2295b3d7-43e7-45e4-9983-f8d411ad7cf1.png
 * ⬇️⬇️ CaliperGroup의 hierarch (w/Img) ⬇️⬇️
 * https://user-images.githubusercontent.com/105628936/222306876-223cf906-eda2-4a5a-a81f-ca05a2a219ec.png
 * ⬇️⬇️ CaliperGroup의 구성요소 ⬇️⬇️
 * https://user-images.githubusercontent.com/105628936/221836750-99b69bf2-be21-4c09-963f-e76399f03d85.png
 */
export const CaliperUtil = {
  /**
   * 캘리퍼 버튼을 누르고, 차트를 클릭할 때, caliperGroup이 생성됩니다. 이후 부터는 document.querySelector로 caliperGroup이 있는지 확인하고, 없을 경우에만 재생성합니다.
   * makeGroup 에서는, CaliperCenterGroup을 제외한 모든 그룹을 생성합니다. (caliperCenterGroup은 caliperPlotLine이 2개 이상일 경우에만 생성되어야 하기 때문에)
   * caliperGroup을 제외한 모든 그룹들은 절대 좌표를 가지고 있고, 절대 좌표를 기준으로 이동합니다.
   * @param {chartRef} chart
   */
  makeGroups: function (chart) {
    const caliperGroup = _createGroup(chart, CLASS_CONST_CALIPER_GROUP).attr({
      zIndex: 8,
    });
    const caliperTickMarksGroup = _createGroup(
      chart,
      CLASS_CONST_CALIPER_TICK_MARK_GROUP,
      caliperGroup
    );
    const caliperLeftLegGroup = _createGroup(
      chart,
      CLASS_CONST_CALIPER_LEFT_LEG_GROUP,
      caliperGroup
    );
    const caliperRightLegGroup = _createGroup(
      chart,
      CLASS_CONST_CALIPER_RIGHT_LEG_GROUP,
      caliperGroup
    );

    chart.caliperGroup = caliperGroup;
    chart.caliperTickMarksGroup = caliperTickMarksGroup;
    chart.caliperLeftLegGroup = caliperLeftLegGroup;
    chart.caliperRightLegGroup = caliperRightLegGroup;
  },
  /**
   * LegGroup의 구성요소들을 render하는 메서드
   * legGroup은 , clickArea, leg, legControlHandle로 구성됨
   * 구성요소들은 모두 상위 그룹에 대한 상대좌표를 가지고 있음
   * @param {chartRef} chart
   * @param {number[a,b]} caliperPlotLines  a : 캘리퍼의 왼쪽 플롯라인, b : 캘리퍼의 오른쪽 플롯라인
   * @param {useTheme} theme
   */
  renderLegElement: function (chart, caliperPlotLines, theme) {
    const renderer = chart.renderer;
    const { y: plotY, height: plotHeight } = chart.plotBox;

    caliperPlotLines.forEach((plotLineAxis, index) => {
      const leftRightMap = ['Left', 'Right'];
      const legGroupKey = `caliper${leftRightMap[index]}LegGroup`;
      const legClickAreaKey = `caliper${leftRightMap[index]}LegClickArea`;
      const legKey = `caliper${leftRightMap[index]}Leg`;
      const controlHandleKey = `caliper${leftRightMap[index]}ControlHandle`;

      const legClickAreaId = `huinno-caliper-${leftRightMap[
        index
      ].toLowerCase()}-leg-click-area`;
      const legId = `huinno-caliper-${leftRightMap[index].toLowerCase()}-leg`;
      const legControlHandleId = `huinno-caliper-${leftRightMap[
        index
      ].toLowerCase()}-control-handle`;

      if (chart[legClickAreaKey]) {
        _destroyLeg(chart, legClickAreaKey, legKey, controlHandleKey);
      }

      // LegGroup의 abs X좌표를 PlotLineAxis좌표로 초기화
      chart[legGroupKey].translate(plotLineAxis);

      // Caliper Left,Right ClickArea
      const legClickArea = renderer
        .rect({
          x: -4,
          y: plotY,
          width: 10,
          height: plotHeight,
          zIndex: 8,
        })
        .attr({
          id: legClickAreaId,
          zIndex: 9,
          fill: theme.color.BLACK,
          'fill-opacity': 0,
        })
        .add(chart[legGroupKey]);
      // Caliper Left,RightLeg
      const leg = renderer
        .rect({
          x: 0,
          y: plotY,
          width: 1,
          height: plotHeight,
          zIndex: 8,
        })
        .attr({
          id: legId,
          zIndex: 8,
          fill: theme.color.BLACK,
        })
        .add(chart[legGroupKey]);
      // Caliper Left,Right Leg Control Handle
      const legControlHandle = renderer
        .rect({
          x: -3.5,
          y: plotY / 2 + plotHeight,
          width: 8,
          height: 8,
        })
        .attr({
          id: legControlHandleId,
          fill: theme.color.WHITE,
          stroke: theme.color.BLACK,
          'stroke-width': 1,
          zIndex: 8,
        })
        .add(chart[legGroupKey]);

      chart[legClickAreaKey] = legClickArea;
      chart[legKey] = leg;
      chart[controlHandleKey] = legControlHandle;
    });
  },
  /**
   * centerLegGroup의 구성요소들을 render하는 메서드
   * centerLegGroup은 underLine, controlHandle, interval 로 구성됨
   * centerLegGroup의 구성요소들은 그룹의 절대좌표를 기준으로 이동 (centerLegGroup의 절대좌표는 leftLegGroup의 절대좌표와 동일)
   * @param {chartRef} chart
   * @param {number[a,b]} caliperPlotLines  a : 캘리퍼의 왼쪽 플롯라인, b : 캘리퍼의 오른쪽 플롯라인
   * @param {useTheme} theme
   */
  renderCenterLegGroup: function (chart, caliperPlotLines, theme) {
    const renderer = chart.renderer;
    const { y: plotY, width: plotWidth, height: plotHeight } = chart.plotBox;
    const [leftPlotLineXCoord, rightPlotLineXCoord] = caliperPlotLines;
    const caliperPlotLinesGap = rightPlotLineXCoord - leftPlotLineXCoord;
    const msPerWidth = TEN_SEC_TO_MS / plotWidth;
    const caliperPlotLinesGapToMs = parseInt(caliperPlotLinesGap * msPerWidth);

    // Make CenterLegGroup
    const caliperCenterLegGroup = _createGroup(
      chart,
      CLASS_CONST_CALIPER_CENTER_LEG_GROUP,
      chart.caliperGroup
    );

    // Caliper UnderLine
    const caliperCenterUnderLine = renderer
      .rect(0.5, plotY + plotHeight - 1, caliperPlotLinesGap + 1, 2, 0, 0)
      .attr({
        class: CLASS_CONST_CALIPER_CENTER_UNDER_LINE,
        fill: 'black',
        zIndex: 7,
      })
      .add(caliperCenterLegGroup);

    // Caliper Center Control Handle
    const caliperCenterControlHandle = renderer
      .rect({
        x: -3.5,
        y: plotY + plotHeight - 5,
        width: 8,
        height: 8,
      })
      .attr({
        class: CLASS_CONST_CALIPER_CENTER_CONTROL_HANDLE,
        fill: theme.color.WHITE,
        stroke: theme.color.BLACK,
        'stroke-width': 1,
        transform: `translate(${caliperPlotLinesGap / 2}, 0)`,
        zIndex: 8,
      })
      .add(caliperCenterLegGroup);

    // Render Caliper Interval
    const caliperInterval = renderer
      .label(
        `${caliperPlotLinesGapToMs.toLocaleString()}ms/${convertMsToBpm(
          caliperPlotLinesGapToMs
        )}bpm`,
        0,
        plotY + plotHeight + 6
      )
      .css({ color: theme.color.WHITE })
      .attr({
        class: CLASS_CONST_CALIPER_INTERVAL,
        fill: theme.color.COOL_GRAY_80,
        zIndex: 8,
        r: 5,
        baseline: true,
      })
      .add(caliperCenterLegGroup);

    chart.caliperCenterLegGroup = caliperCenterLegGroup;
    chart.caliperCenterUnderLine = caliperCenterUnderLine;
    chart.caliperCenterControlHandle = caliperCenterControlHandle;
    chart.caliperInterval = caliperInterval;

    // Show or Hide Center Control Handle
    showOrHideCenterControlHandle(chart);
  },
  /**
   * TickMark들을 render하는 메서드
   * @param {chartRef} chart
   * @param {number[a,b]} caliperPlotLines a : 캘리퍼의 왼쪽 플롯라인, b : 캘리퍼의 오른쪽 플롯라인
   * @param {useTheme} theme
   */
  renderTickMarks: function (chart, caliperPlotLines, theme) {
    const renderer = chart.renderer;
    // Move Tick MarksGroup X Coord to LeftGroup Absolute X Coord
    _moveTickMarksGroupXCoordToLeftLegGroup(chart);

    const boundary = {
      left: 0,
      right: chart.plotWidth,
    };
    const { y: plotY, height: plotHeight } = chart.plotBox;

    const [leftPlotLineXCoord, rightPlotLineXCoord] = caliperPlotLines;
    const caliperPlotLinesGap = rightPlotLineXCoord - leftPlotLineXCoord;
    const absCoordOfTickMarksGroup =
      chart.caliperTickMarksGroup.attr('translateX');

    if (caliperPlotLinesGap < 2) return;

    let relCoordProPagatedToLeftBasedOnLeftPlotLines =
      leftPlotLineXCoord - caliperPlotLinesGap - absCoordOfTickMarksGroup;
    let relCoordProPagatedToLeftBasedOnRightPlotLines =
      rightPlotLineXCoord + caliperPlotLinesGap - absCoordOfTickMarksGroup;

    const tickMarkPlotLines = [];
    // Tick Mark 배열 생성
    while (
      _isLeftTickMarkVisible(
        boundary,
        relCoordProPagatedToLeftBasedOnLeftPlotLines,
        absCoordOfTickMarksGroup
      )
    ) {
      tickMarkPlotLines.push(relCoordProPagatedToLeftBasedOnLeftPlotLines);
      relCoordProPagatedToLeftBasedOnLeftPlotLines =
        relCoordProPagatedToLeftBasedOnLeftPlotLines - caliperPlotLinesGap;
    }
    while (
      _isRightTickMarkVisible(
        boundary,
        relCoordProPagatedToLeftBasedOnRightPlotLines,
        absCoordOfTickMarksGroup
      )
    ) {
      tickMarkPlotLines.push(relCoordProPagatedToLeftBasedOnRightPlotLines);
      relCoordProPagatedToLeftBasedOnRightPlotLines =
        relCoordProPagatedToLeftBasedOnRightPlotLines + caliperPlotLinesGap;
    }

    // Render Tick Marks
    tickMarkPlotLines.forEach((plotLineCoord) => {
      renderer
        .rect({
          x: plotLineCoord,
          y: plotY,
          width: 1,
          height: plotHeight,
          zIndex: 8,
        })
        .attr({
          class: 'huinno-caliper-tick-mark',
          zIndex: 8,
          fill: theme.color.BLACK,
        })
        .add(chart.caliperTickMarksGroup);
    });
  },
  /**
   * TickMark를 지우는 메서드 (Tick Mark 버튼을 누르거나, Caliper 버튼을 눌러 껏을 때 발동)
   * @param {chartRef} chart
   */
  removeTickMarksGroup: function (chart) {
    chart.caliperTickMarksGroup?.element.replaceChildren();
  },
  /**
   * caliper의 구성요소들을 drag하는 메서드
   * @param {chartRef} chart
   * @param {number[a,b]} caliperPlotLines a : 캘리퍼의 왼쪽 플롯라인, b : 캘리퍼의 오른쪽 플롯라인
   * @param {dispatch} _setCaliperPlotLines Set Global State
   * @param {string} editModeType  init, add_beat, change_beat, caliper, tick_marks 5개의 모드타입이 있음 (TEN_SEC_STRIP_DETAIL.EDIT_MODE 참고)
   * @param {func} chartOriginFuncOfOnMouseMove chart의 origin onMouseMove func
   */
  drag: function (
    chart,
    caliperPlotLines,
    _setCaliperPlotLines,
    editModeType,
    chartOriginFuncOfOnMouseMove
  ) {
    const boundary = {
      left: 0,
      right: chart.plotWidth,
    };

    if (editModeType === CONST_TEN_SEC_STRIP_DETAIL_EDIT_MODE_ADD_BEAT) {
      // HighChart의 onMouseMove function을 origin prototype 으로 초기화
      _initMouseEvent(chart, chartOriginFuncOfOnMouseMove);
      return;
    }

    // Translate Caliper Elements
    chart.container.onmousemove = (e) =>
      _chartContainerOnMouseMove(chart, e, caliperPlotLines, boundary);
    // SetPlotLines X Coord
    chart.container.onmouseup = (e) =>
      _chartContainerOnMouseUp(
        chart,
        e,
        boundary,
        caliperPlotLines,
        _setCaliperPlotLines
      );

    // on Mouse [Down,Over,Out]  Events
    _managedVisibleCursorByMouseEvent(chart);
  },
  /**
   * Interval이 차트 범위 밖으로 넘어갔을 경우, 위치를 재조정하는 메서드
   * @param {chartRef} chart
   * @param {number[a,b]} caliperPlotLines a : 캘리퍼의 왼쪽 플롯라인, b : 캘리퍼의 오른쪽 플롯라인
   */
  adjustIntervalCoordWithinBoundary: function (chart, caliperPlotLines) {
    const boundary = {
      left: 0,
      right: chart.plotWidth,
    };

    const [leftPlotLineXCoord, rightPlotLineXCoord] = caliperPlotLines;
    const caliperPlotLinesGap = Math.abs(
      rightPlotLineXCoord - leftPlotLineXCoord
    );
    const relXCoordStartPointOfInterval =
      (caliperPlotLinesGap - chart.caliperInterval.width) / 2;
    const absoluteCoordOfStartPointOfInterval =
      leftPlotLineXCoord + relXCoordStartPointOfInterval;
    const absoluteCoordOfEndPointOfInterval =
      absoluteCoordOfStartPointOfInterval + chart.caliperInterval.width;

    chart.caliperCenterLegGroup.translate(leftPlotLineXCoord);

    if (absoluteCoordOfStartPointOfInterval <= boundary.left) {
      const translateX =
        relXCoordStartPointOfInterval - absoluteCoordOfStartPointOfInterval;

      chart.caliperInterval.attr('translateX', translateX);
      return;
    }
    if (absoluteCoordOfEndPointOfInterval >= boundary.right) {
      const translateX =
        boundary.right - chart.caliperInterval.width - leftPlotLineXCoord;

      chart.caliperInterval.attr('translateX', translateX);
      return;
    }
    chart.caliperInterval.attr('translateX', relXCoordStartPointOfInterval);
  },
  /**
   * Caliper를 Reset하는 메서드
   * @param {dispatch} setIsCaliperMode Set Global State
   * @param {dispatch} setIsTickMarksMode Set Global State
   * @param {dispatch} setCaliperPlotLines Set Global State
   */
  resetCaliper: function (
    setIsCaliperMode,
    setIsTickMarksMode,
    setCaliperPlotLines
  ) {
    setIsCaliperMode(false);
    setIsTickMarksMode(false);
    setCaliperPlotLines([]);
  },
};

/**
 * * Util Funcs * *
 * UnderBar 가 붙지 않은 함수들은 여러 함수에서 공통으로 사용되는 함수
 */

// Control UI
/**
 * CONTROL_HANDLE_WIDTH 보다 Caliper width가 작을 경우, centerControlHandle을 숨기는 함수
 * @param {chartRef} chart
 */
function showOrHideCenterControlHandle(chart) {
  const CONTROL_HANDLE_WIDTH = 10;
  const SHOW_HANDLE_THRESHOLD = CONTROL_HANDLE_WIDTH;
  const caliperCenterUnderLineWidth =
    chart.caliperCenterUnderLine.attr('width');

  if (SHOW_HANDLE_THRESHOLD <= caliperCenterUnderLineWidth) {
    chart.caliperCenterControlHandle.show();
  } else {
    chart.caliperCenterControlHandle.hide();
  }
}
/**
 * CenterUnderLine의 width를 결정하는 함수
 * 캘리퍼의 왼쪽,오른쪽 Leg를 drag할 때 실행
 * @param {{ chart: chartRef, leftPlotLineXCoord: caliperPlotLines[0], rightPlotLineXCoord: caliperPlotLines[1]}} param0
 */
function setUnderLineWidth({ chart, leftPlotLineXCoord, rightPlotLineXCoord }) {
  const caliperPlotLinesGap = Math.abs(
    rightPlotLineXCoord - leftPlotLineXCoord
  );

  chart.caliperCenterUnderLine.attr('width', caliperPlotLinesGap + 1);
}
/**
 * Interval의 Text를 변경하는 함수 (Ex. 1000ms/60bpm)
 * 캘리퍼의 왼쪽,오른쪽 Leg를 drag할 때 실행
 * @param {chartRef} chart
 * @param {number} caliperPlotLinesGapToMs 왼쪽 leg와 오른쪽 leg의 차이를 Ms로 변경한 변수
 */
function setIntervalText(chart, caliperPlotLinesGapToMs) {
  const bpm = convertMsToBpm(caliperPlotLinesGapToMs);
  const text = `${caliperPlotLinesGapToMs.toLocaleString()}ms/${bpm}bpm`;

  chart.caliperInterval.attr('text', text);
}
/**
 * Chart.Container 에 임의로 부여했던 showCursor 클래스를 제거하는 함수
 * @param {chartRef} chart
 */
function _resetChartContainerCursorClass(chart) {
  chart.container.classList.remove('showLegHoverCursor');
  chart.container.classList.remove('showIntervalHoverCursor');
}

// Create || Destroy Renderer
/**
 * SVG Group을 만드는 함수
 * @param {chartRef} chart
 * @param {string} className
 * @param {HighChartRenderer} parent
 * @returns {HighChartRenderer} renderer.g().attr({ class: className }).add(parent)
 */
function _createGroup(chart, className, parent) {
  const renderer = chart.renderer;

  return renderer.g().attr({ class: className }).add(parent);
}
/**
 * Leg를 destroy하는 함수
 * @param {chartRef} chart
 * @param {string} legClickAreaKey 하이차트 element 객체 chart[legClickAreaKey]
 * @param {string} legKey 하이차트 element 객체 chart[legKey]
 * @param {string} controlHandleKey 하이차트 element 객체 chart[controlHandleKey]
 */
function _destroyLeg(chart, legClickAreaKey, legKey, controlHandleKey) {
  chart[legClickAreaKey].destroy();
  chart[legKey].destroy();
  chart[controlHandleKey].destroy();
}

// Control Mouse Event
/**
 * 마우스 커서를 변경하는 함수
 * (Left, Right, Center Leg Group에 mouse down,over,out 할 경우)
 * @param {chartRef} chart
 */
function _managedVisibleCursorByMouseEvent(chart) {
  const caliperLeftLegGroup = chart.caliperLeftLegGroup.element;
  const caliperRightLegGroup = chart.caliperRightLegGroup.element;
  const caliperCenterLegGroup = chart.caliperCenterLegGroup.element;

  // on Mouse Down
  caliperLeftLegGroup.onmousedown = function () {
    caliperLeftLegGroup.drag = true;
    chart.container.classList.add('showLegHoverCursor');
  };
  caliperRightLegGroup.onmousedown = function () {
    caliperRightLegGroup.drag = true;
    chart.container.classList.add('showLegHoverCursor');
  };
  caliperCenterLegGroup.onmousedown = function () {
    caliperCenterLegGroup.drag = true;
    chart.container.classList.add('showIntervalHoverCursor');
  };

  // on Mouse Over
  caliperLeftLegGroup.onmouseover = function () {
    chart.container.classList.add('showLegHoverCursor');
  };
  caliperRightLegGroup.onmouseover = function () {
    chart.container.classList.add('showLegHoverCursor');
  };
  caliperCenterLegGroup.onmouseover = function () {
    chart.container.classList.add('showIntervalHoverCursor');
  };
  // on Mouse Out
  caliperLeftLegGroup.onmouseout = function () {
    if (caliperLeftLegGroup.drag) return;
    chart.container.classList.remove('showLegHoverCursor');
  };
  caliperRightLegGroup.onmouseout = function () {
    if (caliperRightLegGroup.drag) return;
    chart.container.classList.remove('showLegHoverCursor');
  };
  caliperCenterLegGroup.onmouseout = function () {
    if (caliperCenterLegGroup.drag) return;
    chart.container.classList.remove('showIntervalHoverCursor');
  };
}
/**
 * drag 과정에서, Mouse UP을 manage 하는 함수
 * @param {chartRef} chart
 * @param {event} e
 * @param {{left: number,right: number}} boundary 차트 영역, left: 차트의 시작 x좌표, right: 차트의 마지막 x좌표
 * @param {number[a,b]} caliperPlotLines a : 캘리퍼의 왼쪽 플롯라인, b : 캘리퍼의 오른쪽 플롯라인
 * @param {dispatch} setCaliperPlotLines Set Global State
 */
function _chartContainerOnMouseUp(
  chart,
  e,
  boundary,
  caliperPlotLines,
  setCaliperPlotLines
) {
  const caliperLeftLegGroup = chart.caliperLeftLegGroup.element;
  const caliperRightLegGroup = chart.caliperRightLegGroup.element;
  const caliperCenterLegGroup = chart.caliperCenterLegGroup.element;

  const [leftPlotLineXCoord, rightPlotLineXCoord] = caliperPlotLines;
  const caliperPlotLinesGap = rightPlotLineXCoord - leftPlotLineXCoord;
  const halfOfCaliperGroupWidth = caliperPlotLinesGap / 2;

  let newCaliperPlotLines;

  if (caliperLeftLegGroup.drag) {
    newCaliperPlotLines = [e.layerX, rightPlotLineXCoord].sort((a, b) => a - b);
  }
  if (caliperRightLegGroup.drag) {
    newCaliperPlotLines = [leftPlotLineXCoord, e.layerX].sort((a, b) => a - b);
  }
  if (caliperCenterLegGroup.drag) {
    if (_isCaliperGroupWithinBoundary(e, boundary, halfOfCaliperGroupWidth)) {
      newCaliperPlotLines = [
        e.layerX - halfOfCaliperGroupWidth,
        e.layerX + halfOfCaliperGroupWidth,
      ];
    } else if (e.layerX - halfOfCaliperGroupWidth <= boundary.left) {
      newCaliperPlotLines = [
        boundary.left,
        boundary.left + halfOfCaliperGroupWidth * 2,
      ];
    } else if (e.layerX + halfOfCaliperGroupWidth >= boundary.right) {
      newCaliperPlotLines = [
        boundary.right - halfOfCaliperGroupWidth * 2,
        boundary.right,
      ];
    }
  }

  if (newCaliperPlotLines) {
    setCaliperPlotLines(newCaliperPlotLines);
  }

  _resetChartContainerCursorClass(chart);
  _resetCaliperGroupDragStatus(chart);
}
/**
 * drag 과정에서, Mouse Move할 경우 실행되는 함수
 * @param {chartRef} chart
 * @param {event} e
 * @param {number[a,b]} caliperPlotLines a : 캘리퍼의 왼쪽 플롯라인, b : 캘리퍼의 오른쪽 플롯라인
 * @param {{left: number,right: number}} boundary 차트 영역, left: 차트의 시작 x좌표, right: 차트의 마지막 x좌표
 */
function _chartContainerOnMouseMove(chart, e, caliperPlotLines, boundary) {
  const caliperLeftLegGroup = chart.caliperLeftLegGroup.element;
  const caliperRightLegGroup = chart.caliperRightLegGroup.element;

  const msPerWidth = TEN_SEC_TO_MS / chart.plotWidth;
  const [leftPlotLineXCoord, rightPlotLineXCoord] = caliperPlotLines;
  const caliperPlotLinesGap = rightPlotLineXCoord - leftPlotLineXCoord;
  const halfOfCaliperGroupWidth = caliperPlotLinesGap / 2;

  // Move PlotLines
  if (_isMousePointWithinBoundary(e, boundary)) {
    if (caliperLeftLegGroup.drag) {
      CaliperUtil.removeTickMarksGroup(chart);
      _moveLeftLegGroup(chart, e, rightPlotLineXCoord, msPerWidth);
    }

    if (caliperRightLegGroup.drag) {
      CaliperUtil.removeTickMarksGroup(chart);
      _moveRightLegGroup(chart, e, leftPlotLineXCoord, msPerWidth);
    }
  }

  // Mover Caliper Group
  if (
    _isMousePointNCaliperGroupXCoordWithinBoundary(
      chart,
      e,
      boundary,
      halfOfCaliperGroupWidth
    )
  ) {
    // Move caliperCenterLegGroup, caliperLeftLegGroup, caliperRightLegGroup, tickMarksGroup
    _moveCaliperGroup(chart, e, boundary, halfOfCaliperGroupWidth);
  }
}
/**
 * overRide한 func를 reset하는 함수
 * @param {chartRef} chart
 * @param {func} chartOriginFuncOfOnMouseMove chart의 origin onMouseMove func
 */
function _initMouseEvent(chart, chartOriginFuncOfOnMouseMove) {
  const caliperLeftLegGroup = chart.caliperLeftLegGroup.element;
  const caliperRightLegGroup = chart.caliperRightLegGroup.element;

  chart.container.onmousemove = chartOriginFuncOfOnMouseMove;
  caliperLeftLegGroup.onmousedown = null;
  caliperRightLegGroup.onmousedown = null;
  caliperLeftLegGroup.onmouseover = null;
  caliperRightLegGroup.onmouseover = null;
}

// Move Caliper UI
/**
 * left, right leg를 drag할 때 실행되는 함수
 * RightLeg의 좌표에서 LeftLeg좌표를 뺀 값을 토대로 CenterControlHandle의 위치를 계산하는 함수
 * @param {{ chart: chartRef, leftPlotLineXCoord: caliperPlotLines[0], rightPlotLineXCoord: caliperPlotLines[1]}} param0
 */
function moveCenterControlHandle({
  chart,
  leftPlotLineXCoord,
  rightPlotLineXCoord,
}) {
  const caliperPlotLinesGap = Math.abs(
    rightPlotLineXCoord - leftPlotLineXCoord
  );

  chart.caliperCenterControlHandle.attr('translateX', caliperPlotLinesGap / 2);
}
/**
 * leftLeg를 drag할때 실행되는 함수
 * left Leg Group, center Leg Group을 translate 한 후 underLineWidth, interval 을 계산
 * @param {chartRef} chart
 * @param {event} e
 * @param {number} rightPlotLineXCoord Right Leg의 X좌표
 * @param {number} msPerWidth ms를 width로 나눈 값. 1ms 당 width를 의미
 */
function _moveLeftLegGroup(chart, e, rightPlotLineXCoord, msPerWidth) {
  const caliperPlotLinesGap = Math.abs(rightPlotLineXCoord - e.layerX);
  const caliperPlotLinesGapToMs = parseInt(caliperPlotLinesGap * msPerWidth);

  // translate Left Leg Group
  chart.caliperLeftLegGroup.translate(e.layerX);
  // translate Center Leg Group
  chart.caliperCenterLegGroup.translate(
    _adjustCenterLegGroupXCoordBasedRight(e, rightPlotLineXCoord)
  );

  // Set Under Line Width
  setUnderLineWidth({
    chart,
    leftPlotLineXCoord: e.layerX,
    rightPlotLineXCoord,
  });
  // Move Center Control Handle
  moveCenterControlHandle({
    chart,
    leftPlotLineXCoord: e.layerX,
    rightPlotLineXCoord,
  });
  // Show or Hide Center Control Handle
  showOrHideCenterControlHandle(chart);
  // set Interval Text
  setIntervalText(chart, caliperPlotLinesGapToMs);

  // adjust Interval to Center of Caliper Group
  adjustIntervalRelCoordToCenterOfCaliperGroup({
    chart,
    leftPlotLineXCoord: e.layerX,
    rightPlotLineXCoord,
  });
}
/**
 * rightLeg를 drag할때 실행되는 함수
 * right Leg Group, center Leg Group을 translate 한 후 underLineWidth, interval 을 계산
 * @param {chartRef} chart
 * @param {event} e
 * @param {number} leftPlotLineXCoord Left Leg의 X좌표
 * @param {number} msPerWidth ms를 width로 나눈 값. 1ms 당 width를 의미
 */
function _moveRightLegGroup(chart, e, leftPlotLineXCoord, msPerWidth) {
  const caliperPlotLinesGap = Math.abs(e.layerX - leftPlotLineXCoord);
  const caliperPlotLinesGapToMs = parseInt(caliperPlotLinesGap * msPerWidth);

  // translate Right Leg Group
  chart.caliperRightLegGroup.translate(e.layerX);
  // translate Center Leg Group
  chart.caliperCenterLegGroup.translate(
    _adjustCenterLegGroupXCoordBasedLeft(e, leftPlotLineXCoord)
  );

  // Set Under Line Width
  setUnderLineWidth({
    chart,
    leftPlotLineXCoord,
    rightPlotLineXCoord: e.layerX,
  });
  // Move Center Control Handle
  moveCenterControlHandle({
    chart,
    leftPlotLineXCoord,
    rightPlotLineXCoord: e.layerX,
  });
  // Show or Hide Center Control Handle
  showOrHideCenterControlHandle(chart);
  // set Interval Text
  setIntervalText(chart, caliperPlotLinesGapToMs);

  adjustIntervalRelCoordToCenterOfCaliperGroup({
    chart,
    leftPlotLineXCoord,
    rightPlotLineXCoord: e.layerX,
  });
}
/**
 * CaliperGroup 전체를 translate 하는 함수
 * CaliperGroup은 좌표가 없기 때문에, LeftLegGroup,RightLegGroup,CenterLegGroup,TickMarksGroup을 각각 이동 시킴
 * @param {chartRef} chart
 * @param {event} e
 * @param {{left: number,right: number}} boundary 차트 영역, left: 차트의 시작 x좌표, right: 차트의 마지막 x좌표
 * @param {number} halfOfCaliperGroupWidth (RightLegXCoord - LeftLegXCoord) / 2
 */
function _moveCaliperGroup(chart, e, boundary, halfOfCaliperGroupWidth) {
  const leftLegGroupXCoord = e.layerX - halfOfCaliperGroupWidth;
  const rightLegGroupXCoord = e.layerX + halfOfCaliperGroupWidth;

  const newLeftLegGroupXCoord = _getNewLeftLegGroupXCoord(
    boundary,
    leftLegGroupXCoord
  );
  const newRightLegGroupXCoord = _getNewRightLegGroupXCoord(
    boundary,
    rightLegGroupXCoord
  );

  chart.caliperCenterLegGroup.translate(newLeftLegGroupXCoord);
  chart.caliperLeftLegGroup.translate(newLeftLegGroupXCoord);
  chart.caliperRightLegGroup.translate(newRightLegGroupXCoord);
  chart.caliperTickMarksGroup.translate(leftLegGroupXCoord);
}
/**
 * TickMarkGroup을 이동하는 함수
 * TickMarkGroup의 좌표는 LeftLegGroup, CenterLegGroup과 동일
 * @param {chartRef} chart
 */
function _moveTickMarksGroupXCoordToLeftLegGroup(chart) {
  const leftGroupAbsXCoord = chart.caliperLeftLegGroup.attr('translateX');
  const tickMarksGroup = chart.caliperTickMarksGroup;

  // Move Tick MarksGroup X Coord to LeftGroup Absolute X Coord
  tickMarksGroup.translate(leftGroupAbsXCoord);
}
/**
 * Interval을 CaliperGroup의 가운데로 이동하도록 조정하는 함수
 * Interval안의 Text의 길이에 따라 위치할 좌표가 달라지기 때문에, CaliperGroup의 길이와 Interval의 길이를 기준으로 좌표를 재계산하여 이동시킴
 * @param {{ chart: chartRef, leftPlotLineXCoord: caliperPlotLines[0], rightPlotLineXCoord: caliperPlotLines[1]}} param0
 */
function adjustIntervalRelCoordToCenterOfCaliperGroup({
  chart,
  leftPlotLineXCoord,
  rightPlotLineXCoord,
}) {
  const caliperInterval = chart.caliperInterval;
  const caliperIntervalWidth = caliperInterval.width;
  const caliperPlotLinesGap = Math.abs(
    rightPlotLineXCoord - leftPlotLineXCoord
  );
  // Interval을 가운데에 위치시키기 위해 (CaliperGroup의 길이 - Interval의 길이) / 2 를 Interval의 시작좌표로 설정
  const relXCoordStartPointOfInterval =
    (caliperPlotLinesGap - caliperIntervalWidth) / 2;

  // translate Interval
  caliperInterval.attr('translateX', relXCoordStartPointOfInterval);
}
/**
 * Left Leg Group을 Drag할 때, CenterLegGroup의 시작 좌표를 계산하는 함수
 * Left Leg Group의 X Coord가 Right Leg Group의 X Coord보다 클 경우, CenterLegGroup의 시작 좌표는 RightLegGroup의 X Coord가 됨
 * @param {event} e
 * @param {number} rightPlotLineXCoord Right Leg Group의 X Coordinate
 * @returns {number} centerLegGroupStartXCoord
 */
function _adjustCenterLegGroupXCoordBasedRight(e, rightPlotLineXCoord) {
  // CenterLegGroup의 시작좌표는 LeftLegGroup의 좌표와 동일
  // 만약 centerLegGroupStartXCoord 가 rightPlotLineXCoord를 넘어가면, CenterLegGroup의 시작좌표를 기존의 rightPlotLineXCoord로 변경
  let centerLegGroupStartXCoord = e.layerX;

  if (e.layerX > rightPlotLineXCoord) {
    centerLegGroupStartXCoord = rightPlotLineXCoord;
  }

  return centerLegGroupStartXCoord;
}
/**
 * Right Leg Group을 Drag할 때, CenterLegGroup의 시작 좌표를 계산하는 함수
 * Right Leg Group의 X Coord가 Left Leg Group의 X Coord보다 작을 경우, CenterLegGroup의 시작 좌표는 LeftLegGroup의 X Coord가 됨
 * @param {event} e
 * @param {number} leftPlotLineXCoord Left Leg Group의 X Coordinate
 * @returns {number} centerLegGroupStartXCoord
 */
function _adjustCenterLegGroupXCoordBasedLeft(e, leftPlotLineXCoord) {
  // 만약 e.centerLegGroupStartXCoord 가 leftPlotLineXCoord보다 작아지면, CenterLegGroup의 시작좌표를 e.layerX로 변경
  let centerLegGroupStartXCoord = leftPlotLineXCoord;
  if (e.layerX < leftPlotLineXCoord) {
    centerLegGroupStartXCoord = e.layerX;
  }

  return centerLegGroupStartXCoord;
}

// Validation
/**
 * CaliperGroup의 시작,끝 좌표가 Boundary 내에 있는지에 대한 여부를 반환하는 함수
 * @param {event} e
 * @param {{left: number,right: number}} boundary 차트 영역, left: 차트의 시작 x좌표, right: 차트의 마지막 x좌표
 * @param {number} halfOfCaliperGroupWidth (RightLegXCoord - LeftLegXCoord) / 2
 * @returns {Boolean}
 */
function _isCaliperGroupWithinBoundary(e, boundary, halfOfCaliperGroupWidth) {
  const isLeftLegGroupWithinBoundaryLeft =
    e.layerX - halfOfCaliperGroupWidth >= boundary.left;
  const isRightLegGroupWithinBoundaryRight =
    e.layerX + halfOfCaliperGroupWidth <= boundary.right;

  return isLeftLegGroupWithinBoundaryLeft && isRightLegGroupWithinBoundaryRight;
}
/**
 * LeftLegGroup의 X Coord를 기준으로 왼쪽으로 전파된  TickMarks가 보이는 영역인지에 대한 여부를 반환하는 함수
 * @param {{left: number,right: number}} boundary 차트 영역, left: 차트의 시작 x좌표, right: 차트의 마지막 x좌표
 * @param {number} relCoordProPagatedToLeftBasedOnLeftPlotLines LeftPlotLine을 기준으로, 왼쪽방향으로 caliperGroupWidth만큼 작아지는 변수
 * @param {number} absCoordOfTickMarksGroup TickMarksGroup의 절대좌표
 * @returns {Boolean}
 */
function _isLeftTickMarkVisible(
  boundary,
  relCoordProPagatedToLeftBasedOnLeftPlotLines,
  absCoordOfTickMarksGroup
) {
  const tickMarkStartXCoord =
    relCoordProPagatedToLeftBasedOnLeftPlotLines + absCoordOfTickMarksGroup;
  const tickMarkVisibleLeftBoundary =
    boundary.left - (boundary.right - absCoordOfTickMarksGroup);

  return tickMarkStartXCoord >= tickMarkVisibleLeftBoundary;
}
/**
 * LeftLegGroup의 X Coord를 기준으로 오른쪽으로 전파된  TickMarks가 보이는 영역인지에 대한 여부를 반환하는 함수
 * @param {{left: number,right: number}} boundary 차트 영역, left: 차트의 시작 x좌표, right: 차트의 마지막 x좌표
 * @param {number} relCoordProPagatedToLeftBasedOnLeftPlotLines LeftPlotLine을 기준으로, 오른쪽방향으로 caliperGroupWidth만큼 커지는 변수
 * @param {number} absCoordOfTickMarksGroup TickMarksGroup의 절대좌표
 * @returns {Boolean}
 */
function _isRightTickMarkVisible(
  boundary,
  relCoordProPagatedToLeftBasedOnRightPlotLines,
  absCoordOfTickMarksGroup
) {
  const tickMarkStartXCoord =
    relCoordProPagatedToLeftBasedOnRightPlotLines + absCoordOfTickMarksGroup;
  const tickMarkVisibleRightBoundary =
    boundary.right + (boundary.left + absCoordOfTickMarksGroup);

  return tickMarkStartXCoord <= tickMarkVisibleRightBoundary;
}
/**
 * 움직인 마우스 포인트가 차트영역 내인지에 대한 여부를 반환하는 함수
 * @param {event} e
 * @param {{left: number,right: number}} boundary 차트 영역, left: 차트의 시작 x좌표, right: 차트의 마지막 x좌표
 * @returns {Boolean}
 */
function _isMousePointWithinBoundary(e, boundary) {
  const isMousePointOverLeftBoundary = e.layerX >= boundary.left;
  const isMousePointBelowRightBoundary = e.layerX <= boundary.right;

  return isMousePointOverLeftBoundary && isMousePointBelowRightBoundary;
}
/**
 * 움직인 마우스 포인트와, caliperGroup의 시작,끝 좌표가 차트영역 내인지에 대한 여부를 반환하는 함수
 * @param {chartRef} chart
 * @param {event} e
 * @param {{left: number,right: number}} boundary 차트 영역, left: 차트의 시작 x좌표, right: 차트의 마지막 x좌표
 * @param {number} halfOfCaliperGroupWidth (RightLegXCoord - LeftLegXCoord) / 2
 * @returns {Boolean}
 */
function _isMousePointNCaliperGroupXCoordWithinBoundary(
  chart,
  e,
  boundary,
  halfOfCaliperGroupWidth
) {
  const xWithinLeftExtreme =
    e.layerX - halfOfCaliperGroupWidth >= boundary.left;
  const xWithinRightExtreme =
    e.layerX + halfOfCaliperGroupWidth <= boundary.right;

  const caliperCenterLegGroup = chart.caliperCenterLegGroup.element;
  const isCaliperCenterLegGroupDragged = caliperCenterLegGroup.drag;

  return (
    xWithinLeftExtreme && xWithinRightExtreme && isCaliperCenterLegGroupDragged
  );
}

// ETC
/**
 * CaliperGroup들의 drag 를 false로 초기화 하는 함수
 * @param {chartRef} chart
 */
function _resetCaliperGroupDragStatus(chart) {
  const caliperLeftLegGroup = chart.caliperLeftLegGroup.element;
  const caliperRightLegGroup = chart.caliperRightLegGroup.element;
  const caliperCenterLegGroup = chart.caliperCenterLegGroup.element;

  caliperLeftLegGroup.drag = false;
  caliperRightLegGroup.drag = false;
  caliperCenterLegGroup.drag = false;
}
/**
 * 차트의 시작부분보다 leftLegGroup이 작아지지 않도록 하는 함수
 * @param {{left: number,right: number}} boundary 차트 영역, left: 차트의 시작 x좌표, right: 차트의 마지막 x좌표
 * @param {*} leftLegGroupXCoord
 * @returns {number} newLeftLegGroupXCoord
 */
function _getNewLeftLegGroupXCoord(boundary, leftLegGroupXCoord) {
  let newLeftLegGroupXCoord = boundary.left;

  if (leftLegGroupXCoord >= boundary.left) {
    newLeftLegGroupXCoord = leftLegGroupXCoord;
  }

  return newLeftLegGroupXCoord;
}
/**
 * 차트의 끝 부분 보다 rightLegGroup이 커지지 않도록 하는 함수
 * @param {{left: number,right: number}} boundary 차트 영역, left: 차트의 시작 x좌표, right: 차트의 마지막 x좌표
 * @param {*} rightLegGroupXCoord
 * @returns {number} newRightLegGroupXCoord
 */
function _getNewRightLegGroupXCoord(boundary, rightLegGroupXCoord) {
  let newRightLegGroupXCoord = boundary.right;

  if (rightLegGroupXCoord <= boundary.right) {
    newRightLegGroupXCoord = rightLegGroupXCoord;
  }

  return newRightLegGroupXCoord;
}
