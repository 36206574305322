import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { DialogWrapper, DialogButtonWrapper } from 'component/ui/dialog/Dialog';
import TextButton from 'component/ui/button/TextButton';

const TitleText = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.color.BLACK};
`;

const MessageContainer = styled.div`
  margin-bottom: 16px;
  font-size: 12px;
`;

const AlertMessageText = styled.div`
  font-weight: bold;
  color: ${(props) => props.theme.color.RED};
`;

const MessageText = styled.div`
  color: ${(props) => props.theme.color.BLACK};
`;

function ReturnPatchDialog(props) {
  const intl = useIntl();

  const { open, params, callback, onClose } = props;
  const { patientName, remainingDayToReturn, cancelButtonText, onSubmit } =
    params;

  return (
    <DialogWrapper open={open} maxWidth={360}>
      <TitleText>
        {intl.formatMessage(
          {
            id: '99-ReturnPatchDialog-TitleText-children-01',
            description: '3.2 검사 반납 처리 팝업의 타이틀',
            defaultMessage: '{patientName}님의 패치를 반납 처리하시겠습니까?',
          },
          { patientName }
        )}
      </TitleText>

      <MessageContainer>
        {remainingDayToReturn > 0 && (
          <AlertMessageText>
            {intl.formatMessage(
              {
                id: '99-ReturnPatchDialog-AlertMessageText-children-01',
                description: '3.2 검사 반납 처리 팝업의 경고 메시지',
                defaultMessage:
                  '[주의] 종료 예정일까지 {remainingDayToReturn}일 남은 검사입니다!',
              },
              { remainingDayToReturn }
            )}
          </AlertMessageText>
        )}

        <MessageText>
          {intl.formatMessage({
            id: '99-ReturnPatchDialog-MessageText-children-01',
            description: '3.2 검사 반납 처리 팝업의 메시지',
            defaultMessage: '반납 확인 이후에는 취소할 수 없습니다.',
          })}
        </MessageText>
      </MessageContainer>

      <DialogButtonWrapper>
        <TextButton
          textColor="#575b5d"
          title={
            cancelButtonText ||
            intl.formatMessage({
              id: '99-Dialog-Button-title-cancel',
              description: '팝업 Dialog의 취소 버튼',
              defaultMessage: '취소',
            })
          }
          onClick={onClose}
        />
        <TextButton
          title={intl.formatMessage({
            id: '99-ReturnPatchDialog-TextButton-title-01',
            description: '3.2 검사 반납 처리 팝업의 버튼',
            defaultMessage: '반납 확인',
          })}
          onClick={() => {
            onClose();
            if (typeof onSubmit === 'function') {
              onSubmit();
            }
          }}
        />
      </DialogButtonWrapper>
    </DialogWrapper>
  );
}

export default ReturnPatchDialog;
