import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import LocalStorageKey from 'constant/LocalStorageKey';
import LocalStorageManager from 'manager/LocalStorageManager';

import { showDialog } from 'redux/duck/dialogDuck';
import { authLogoutRequested } from 'redux/duck/authDuck';

import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';

import Header from 'component/base/Header';

function HeaderContainer(props) {
  // CustomHooks
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  // Selectors
  const authState = useShallowEqualSelector((state) => state.authReducer);

  // Dispatches
  const handleShowDialog = (dialogKey, params, callback) =>
    dispatch(showDialog(dialogKey, params, callback));
  const handleLogout = () => dispatch(authLogoutRequested());

  // 공지사항 dialog
  useEffect(() => {
    if (location.pathname !== '/') return;

    const showNoticeDialog = process.env.REACT_APP_SHOW_NOTICE_DIALOG || false;
    const currentDate = new Date();

    if (!JSON.parse(showNoticeDialog)) return;

    if (
      LocalStorageManager.getItem(
        LocalStorageKey.DO_NOT_SHOW_NOTICE_POPUP_TIL_THIS_DATE
      ) <= currentDate
    ) {
      handleShowDialog('NoticeDialog', {
        showCloseIcon: true,
        handleOnclick: () => {
          if (!process.env.REACT_APP_NOTICE_DIALOG_CLICKED_URL) return;

          window.open(process.env.REACT_APP_NOTICE_DIALOG_CLICKED_URL);
        },
      });
    }
  }, [location]);

  return (
    <Header
      history={history}
      // Selectors
      authState={authState}
      // Dispatches
      handleShowDialog={handleShowDialog}
      handleLogout={handleLogout}
    />
  );
}

export default HeaderContainer;
