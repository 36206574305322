interface BlackBox {
  showExpireAlert: null | (() => void);
  showNetworkAlert:
    | null
    | (({
        errorData,
        shouldLogout,
        showTitle,
        showErrorCode,
        callback,
      }: ShowNetworkAlertParams) => void);
  callback: null | (() => void);
}

interface ShowNetworkAlertParams {
  errorData: any;
  shouldLogout: boolean;
  showTitle: boolean;
  showErrorCode: boolean;
  callback: null | (() => void);
}

const _blackBox: BlackBox = {
  showExpireAlert: null,
  showNetworkAlert: null,
  callback: null,
};

const TrickUtil = {
  showExpireAlert: () => {
    if (typeof _blackBox.showExpireAlert === 'function')
      return _blackBox.showExpireAlert();
  },
  setExpireAlert: (dispatchFunction: never) =>
    (_blackBox.showExpireAlert = dispatchFunction),
  showNetworkAlert: ({
    errorData,
    shouldLogout,
    showTitle,
    showErrorCode,
    callback,
  }: ShowNetworkAlertParams) => {
    if (typeof _blackBox.showNetworkAlert === 'function')
      return _blackBox.showNetworkAlert({
        errorData,
        shouldLogout,
        showTitle,
        showErrorCode,
        callback,
      });
  },
  setNetworkAlert: (dispatchFunction: never) =>
    (_blackBox.showNetworkAlert = dispatchFunction),
  callback: () => {
    if (typeof _blackBox.callback === 'function') return _blackBox.callback();
  },
  setCallback: (dispatchFunction: never) =>
    (_blackBox.callback = dispatchFunction),
};

export default TrickUtil;
