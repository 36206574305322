import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  min-height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.color.LIGHT_GREY_EE};
`;

const CopyrightText = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.color.DARK};
`;

function Footer(props) {
  return (
    <Wrapper>
      <CopyrightText>ⓒ2021 HUINNO Co., Ltd. All right reserved.</CopyrightText>
    </Wrapper>
  );
}

export default Footer;
