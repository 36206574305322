import styled, { CSSProperties } from 'styled-components';

const Wrapper = styled.label<{
  width?: number;
  height?: number;
  disabled?: boolean;
}>`
  position: relative;
  display: inline-block;
  width: ${({ width }) => width || 30}px;
  height: ${({ height }) => height || 16}px;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;

const Input = styled.input.attrs({
  type: 'checkbox',
})`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + .slider {
    background-color: ${({ theme }) => theme.color.PRIMARY};
  }

  &:checked + .slider:before {
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
  }
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    width: 40%;
    height: 75%;
    left: 10%;
    bottom: 12%;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

interface SwitchProps {
  onChange?: (e?: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  disabled?: boolean;
  width?: number;
  height?: number;
  style?: CSSProperties; // width와 height는 style을 사용하지 않고 props로 받아야합니다.
}
function Switch({
  onChange,
  checked,
  disabled,
  width,
  height,
  style,
}: SwitchProps) {
  return (
    <Wrapper style={style} disabled={disabled} width={width} height={height}>
      <Input onChange={onChange} checked={checked} disabled={disabled} />
      <Slider className="slider" />
    </Wrapper>
  );
}

export default Switch;
