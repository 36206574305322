import { connect } from 'react-redux';
import { showDialog, hideDialog } from 'redux/duck/dialogDuck';
import NewDoctorDialog from 'component/dialog/NewDoctorDialog';
import { createMedicalStaffRequested } from 'redux/duck/medicalStaffsDuck';
import Const from 'constant/Const';

const mapStateToProps = (state, ownProps) => {
  return {
    medicalStaffCUState: state.medicalStaffsReducer.cu,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    // Dialog
    showDialog: (dialogKey, params, callback) => {
      dispatch(showDialog(dialogKey, params, callback));
    },
    hideDialog: (dialogKey) => {
      dispatch(hideDialog(dialogKey));
    },
    // Medical Staff
    createDoctor: (firstName, email) =>
      dispatch(
        createMedicalStaffRequested(
          firstName,
          email,
          Const.STAFF_TYPE.DOCTOR.value,
          Const.ACCESS_LEVEL.READ_WRITE.value,
          false
        )
      ),
  };
};

const NewDoctorDialogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewDoctorDialog);

export default NewDoctorDialogContainer;
