import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import ApiManager from 'network/ApiManager';
// Actions
// Fetch
const FETCH_ECG_TOTAL_REQUESTED = 'memo-web/FETCH_ECG_TOTAL_REQUESTED';
const FETCH_ECG_TOTAL_SUCCEED = 'memo-web/FETCH_ECG_TOTAL_SUCCEED';
const FETCH_ECG_TOTAL_FAILED = 'memo-web/FETCH_ECG_TOTAL_FAILED';

// Fetch
const BULK_CONFIRM_REQUESTED = 'memo-web/BULK_CONFIRM_REQUESTED';
const BULK_CONFIRM_SUCCEED = 'memo-web/BULK_CONFIRM_SUCCEED';
const BULK_CONFIRM_FAILED = 'memo-web/BULK_CONFIRM_FAILED';

const INITIALIZE_ERROR_VALUE = 'memo-web/INITIALIZE_ERROR_VALUE';

// Reducer
const initialState = {
  explore: {
    pending: false,
    data: {},
    error: null,
  },
  confirm: {
    pending: false,
    error: null,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    // Fetch
    case FETCH_ECG_TOTAL_REQUESTED:
      return {
        ...state,
        explore: {
          pending: true,
          data: {},
          error: null,
        },
      };
    case FETCH_ECG_TOTAL_SUCCEED:
      return {
        ...state,
        explore: {
          pending: false,
          data: action.data,
          error: null,
        },
      };
    case FETCH_ECG_TOTAL_FAILED:
      return {
        ...state,
        explore: {
          ...state.explore,
          pending: false,
          error: action.error,
        },
      };
    case BULK_CONFIRM_REQUESTED:
      return {
        ...state,
        confirm: {
          pending: true,
          error: null,
        },
      };
    case BULK_CONFIRM_SUCCEED:
      return {
        ...state,
        explore: {
          pending: false,
          data: {
            ...state.data,
            results: state.explore.data.results.map((ecg) => {
              for (let i = 0; i < action.data.results.length; i++) {
                if (
                  ecg.startTimestamp === action.data.results[i].eventTimestamp
                ) {
                  ecg.diagnosis = action.data.results[i].finalDiagnosis;
                  break;
                }
              }
              return ecg;
            }),
          },
          error: null,
        },
        confirm: {
          pending: false,
          error: null,
        },
      };
    case BULK_CONFIRM_FAILED:
      return {
        ...state,
        confirm: {
          pending: false,
          error: action.error,
        },
      };
    case INITIALIZE_ERROR_VALUE:
      return {
        ...state,
        explore: {
          ...state.explore,
          error: null,
        },
        confirm: {
          ...state.confirm,
          error: null,
        },
      };
    default:
      return state;
  }
}
// Fetch
export function fetchEcgTotalRequested(
  tid,
  atTime,
  secStep,
  backward,
  forward
) {
  return {
    type: FETCH_ECG_TOTAL_REQUESTED,
    tid,
    atTime,
    secStep,
    backward,
    forward,
  };
}
export function fetchEcgTotalSucceed(data) {
  return {
    type: FETCH_ECG_TOTAL_SUCCEED,
    data,
  };
}
export function fetchEcgTotalFailed(error) {
  return { type: FETCH_ECG_TOTAL_FAILED, error: error };
}
export function bulkConfirmRequested(tid, payload) {
  return {
    type: BULK_CONFIRM_REQUESTED,
    tid,
    payload,
  };
}
export function bulkConfirmSucceed(data) {
  return {
    type: BULK_CONFIRM_SUCCEED,
    data,
  };
}
export function bulkConfirmFailed(error) {
  return {
    type: BULK_CONFIRM_FAILED,
    error,
  };
}
export function initializeErrorValue() {
  return {
    type: INITIALIZE_ERROR_VALUE,
  };
}

// Sagas
function* getEcgTotal(action) {
  try {
    const { data } = yield call(
      ApiManager.getEcgTotal,
      action.tid,
      action.atTime,
      action.secStep,
      action.backward,
      action.forward
    );

    yield put(fetchEcgTotalSucceed(data));
  } catch (error) {
    yield put(
      fetchEcgTotalFailed({
        name: error.name,
        status: error.status,
        message: error.message,
      })
    );
  }
}

function* bulkConfirm(action) {
  try {
    const { data } = yield call(
      ApiManager.bulkConfirm,
      action.tid,
      action.payload
    );

    yield put(bulkConfirmSucceed(data));
  } catch (error) {
    yield put(
      bulkConfirmFailed({
        name: error.name,
        status: error.status,
        message: error.message,
      })
    );
  }
}

export function* saga() {
  yield debounce(1000, FETCH_ECG_TOTAL_REQUESTED, getEcgTotal);
  yield takeLatest(BULK_CONFIRM_REQUESTED, bulkConfirm);
}
