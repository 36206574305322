import React, { useState } from 'react';
import styled from 'styled-components';
import UploadingFileListItem from 'component/ui/item/UploadingFileListItem';

const Wrapper = styled.div`
  max-height: 500px;
  padding: 8px 0px;
  overflow-y: scroll;

  & > * {
    :not(:last-child) {
      margin-bottom: 4px;
    }
  }
`;

function UploadingFileList(props) {
  const { files, onClickDelete, handleProgressesDone } = props;

  return (
    <Wrapper>
      {files.map((file) => {
        return (
          <UploadingFileListItem
            key={file.name}
            file={file}
            onClickDelete={() => {
              onClickDelete(file);
            }}
            handleProgressesDone={() => {
              handleProgressesDone(file.name);
            }}
          />
        );
      })}
    </Wrapper>
  );
}

export default UploadingFileList;
