import ForgotPasswordDialogContainer from 'redux/container/dialog/ForgotPasswordDialogContainer';
import UserManagementDialogContainer from 'redux/container/dialog/UserManagementDialogContainer';
import NewDoctorDialogContainer from 'redux/container/dialog/NewDoctorDialogContainer';
import AddNewTestDialogContainer from 'redux/container/dialog/AddNewTestDialogContainer';
import UploadDataDialogContainer from 'redux/container/dialog/UploadDataDialogContainer';
import AlertExpireDialogContainer from 'redux/container/dialog/AlertExpireDialogContainer';
import FinalConfirmReportDialogContainer from 'redux/container/dialog/FinalConfirmReportDialogContainer';
import AlertNetworkDialogContainer from 'redux/container/dialog/AlertNetworkDialogContainer';

import AlertDialog from './AlertDialog';
import AlertTimerDialog from './AlertTimerDialog';
import ConfirmDialog from './ConfirmDialog';
import ReturnPatchDialog from './ReturnPatchDialog';
import AlertExceedBpmLimitDialog from './AlertExceedBpmLimitDialog';
import ConfirmDoctorDialog from './ConfirmDoctorDialog';
import NoticeDialog from './NoticeDialog';

export default {
  AlertDialog: {
    Name: 'AlertDialog',
    Description: 'AlertDialog description',
    Component: AlertDialog,
  },
  AlertTimerDialog: {
    Name: 'AlertTimerDialog',
    Description: 'AlertTimerDialog description',
    Component: AlertTimerDialog,
  },
  ConfirmDialog: {
    Name: 'ConfirmDialog',
    Description: 'ConfirmDialog description',
    Component: ConfirmDialog,
  },
  ForgotPasswordDialog: {
    Name: 'ForgotPasswordDialog',
    Description: 'ForgotPasswordDialog description',
    Component: ForgotPasswordDialogContainer,
  },
  ReturnPatchDialog: {
    Name: 'ReturnPatchDialog',
    Description: 'ReturnPatchDialog description',
    Component: ReturnPatchDialog,
  },
  UserManagementDialog: {
    Name: 'UserManagementDialog',
    Description: 'UserManagementDialog description',
    Component: UserManagementDialogContainer,
  },
  NewDoctorDialog: {
    Name: 'NewDoctorDialog',
    Description: 'NewDoctorDialog description',
    Component: NewDoctorDialogContainer,
  },
  AddNewTestDialog: {
    Name: 'AddNewTestDialog',
    Description: 'AddNewTestDialog description',
    Component: AddNewTestDialogContainer,
  },
  UploadDataDialog: {
    Name: 'UploadDataDialog',
    Description: 'UploadDataDialog description',
    Component: UploadDataDialogContainer,
  },
  AlertExpireDialog: {
    Name: 'AlertExpireDialog',
    Description: 'AlertExpireDialog description',
    Component: AlertExpireDialogContainer,
  },
  AlertNetworkDialog: {
    Name: 'AlertNetworkDialog',
    Description: 'AlertNetworkDialog description',
    Component: AlertNetworkDialogContainer,
  },
  AlertExceedBpmLimitDialog: {
    Name: 'AlertExceedBpmLimitDialog',
    Description: 'AlertExceedBpmLimitDialog description',
    Component: AlertExceedBpmLimitDialog,
  },
  ConfirmDoctorDialog: {
    Name: 'ConfirmDoctorDialog',
    Description: 'ConfirmDoctorDialog description',
    Component: ConfirmDoctorDialog,
  },
  FinalConfirmReportDialog: {
    Name: 'FinalConfirmReportDialog',
    Description: 'FinalConfirmReportDialog description',
    Component: FinalConfirmReportDialogContainer,
  },
  NoticeDialog: {
    Name: 'NoticeDialog',
    Description: 'NoticeDialog description',
    Component: NoticeDialog,
  },
};
