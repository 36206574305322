import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 8px 16px;
  display: flex;
  background-color: ${(props) => props.theme.color.LIGHT};
  :hover {
    background-color: ${(props) => props.theme.color.LIGHT_GREY_EE};
  }
  transition: background-color 0.1s ease-in-out;
`;

const TitleText = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.color.DARK};
`;

function DropdownItem(props) {
  const { style, title, onClick } = props;

  return (
    <Wrapper style={style} onClick={onClick}>
      <TitleText>{title}</TitleText>
    </Wrapper>
  );
}

export default DropdownItem;
