export const events = {
  data: [],

  subscribe: function (data) {
    this.data.push(data);
  },

  publish: function (callback, type) {
    callback(() => {
      this.data.forEach((v) => v());
    });
    this.data = [];
  },
};

export const publishType = {
  CLICK: 'click',
  KEYUP_ARROW_UP_ARROW_DOWN: 'keyupArrowUpArrowDown',
  KEYUP_ARROW_LEFT_ARROW_RIGHT: 'keyupArrowLeftArrowRight',
  KEYUP_ESCAPE: 'keyupEscape',
  DBL_CLICK: 'dblClick',
};
