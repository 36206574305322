import { useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import Const from 'constant/Const';

import DateUtil from 'util/DateUtil';

import useAuthority from 'component/hook/useAuthority';
import usePrevious from 'component/hook/usePrevious';
import useValidateReportAndStatistics from 'component/hook/useValidateReportAndStatistics';
import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';

import {
  confirmEcgTestReviewRequested,
  editDoneEcgTestReviewRequested,
  patchEcgTestRequested,
} from 'redux/duck/ecgTestsDuck';
import { showDialog as showDialogRequested } from 'redux/duck/dialogDuck';
import FinalConfirmReportDialog from 'component/dialog/FinalConfirmReportDialog';

const IS_CUSTOMER_WEB =
  process.env.REACT_APP_CLIENT_NAME === Const.CLIENT_NAME.WEB_APP;

function FinalConfirmReportDialogContainer(props) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { open, params, callback, onClose } = props;
  const {
    ecgTestId,
    // patientName,
    patientNumber,
    report,
    initConfirmedBy,
    userTestNotification,
    // PrintReportDialogCallback,
  } = params;
  const {
    // rid: reportId,
    updatedAt,
    createdAt,
    reportFile,
    deIdReportFile,
    // validReport,
  } = report;

  // Store States
  const {
    ecgTestsValidReportState,
    ecgTestsPatchState,
    ecgTestsConfirmState,
    ecgTestsEditDoneState,
  } = useShallowEqualSelector(({ ecgTestsReducer: state }) => ({
    ecgTestsValidReportState: state.validReport,
    ecgTestsPatchState: state.patch,
    ecgTestsConfirmState: state.confirm,
    ecgTestsEditDoneState: state.editDone,
  }));

  // Dispatches
  const showDialog = (dialogKey, params, callback) => {
    dispatch(showDialogRequested(dialogKey, params, callback));
  };
  const patchEcgTestConfirmedBy = ({ confirmedBy, callback }) => {
    dispatch(
      patchEcgTestRequested({ ecgTestId, form: { confirmedBy }, callback })
    );
  };
  const confirmEcgTestReview = ({ ecgTestId, reGenerateReport, callback }) => {
    dispatch(
      confirmEcgTestReviewRequested({
        ecgTestId,
        reGenerateReport,
        callback,
      })
    );
  };
  const editDoneEcgTestReview = ({ ecgTestId, isSendToHospitalUser }) => {
    dispatch(
      editDoneEcgTestReviewRequested({ ecgTestId, isSendToHospitalUser })
    );
  };

  // local state
  const [initPending, setInitPending] = useState(true);
  const [submitPending, setSubmitPending] = useState(false);

  // custom hook
  const { relationType } = useAuthority();
  const { validateStt } = useValidateReportAndStatistics({
    ecgTestId,
    callback: () => onClose(),
  });

  // ..etc
  const shortTid = ecgTestId.split('-')[0];
  const [
    //
    reportFileName,
    reportFileUrl,
  ] = useMemo(() => {
    const isCustomerUser = relationType === Const.USER_RELATION_TYPE.CUSTOMER;
    return [
      `${isCustomerUser ? patientNumber : shortTid}_${DateUtil.format(
        updatedAt || createdAt,
        'yyyy-MM-dd_HH-mm'
      )}.pdf`,
      isCustomerUser ? reportFile : deIdReportFile,
    ];
  }, [patientNumber, createdAt, updatedAt]);

  const isMainEcgTest = useShallowEqualSelector(
    (state) => state.testResultReducer.ecgTest.data?.isMainEcgTest
  );

  /**
   * 0: 행위 명사만,
   * 1: 행위 명사 + 조사,
   * 2: 다음 단계
   */
  const purposeText = useMemo(
    () =>
      relationType === Const.USER_RELATION_TYPE.PARTNER
        ? [
            intl.formatMessage({
              id: '99-purposeText-partner-01',
              description: '',
              defaultMessage: '편집',
            }),
            intl.formatMessage({
              id: '99-purposeText-partner-02',
              description: '',
              defaultMessage: '편집을',
            }),
            intl.formatMessage({
              id: '99-purposeText-partner-03',
              description: '',
              defaultMessage: '휴이노로',
            }),
          ]
        : [
            intl.formatMessage({
              id: '99-purposeText-notPartner-01',
              description: '',
              defaultMessage: '검토',
            }),
            intl.formatMessage({
              id: '99-purposeText-notPartner-02',
              description: '',
              defaultMessage: '검토를',
            }),
            intl.formatMessage({
              id: '99-purposeText-notPartner-03',
              description: '',
              defaultMessage: '담당 병원으로',
            }),
          ],
    [relationType]
  );

  const doneRequest = () => {
    const isPartnerUser = relationType === Const.USER_RELATION_TYPE.PARTNER;

    if (IS_CUSTOMER_WEB) {
      confirmEcgTestReview({ ecgTestId, reGenerateReport: true });
    } else {
      isPartnerUser || userTestNotification == null
        ? editDoneEcgTestReview({ ecgTestId })
        : showDialog('ConfirmDialog', {
            title: `${shortTid} 검사를 Care 웹으로 알림 발송할까요?`,
            message: `알림 발송을 클릭하시면 알림을 발송합니다.
        알림 미발송하여 검토 완료만 진행을 클릭하시면
        알림이 발송되지 않고 검토완료만 진행합니다.`,
            cancelButtonText: '알림 미발송하여 검토 완료만 진행',
            confirmButtonText: '알림 발송',
            onCancel: () => {
              editDoneEcgTestReview({ ecgTestId });
            },
            onSubmit: () =>
              editDoneEcgTestReview({
                ecgTestId,
                isSendToHospitalUser: true,
              }),
          });
    }
  };

  const reportOnClick = useCallback(() => {
    reportFileUrl && window.open(reportFileUrl);
  }, [reportFileUrl, reportFileName]);

  const onSubmit = useCallback(
    (newConfirmedBy) => {
      try {
        setSubmitPending(true);
        if (newConfirmedBy?.value === initConfirmedBy?.value) {
          // 검토완료 또는 편집완료 처리, 요청자 계정 유형에 따라 결과 달라짐
          doneRequest();
        } else {
          // 변경된 확인의 저장 처리
          patchEcgTestConfirmedBy({ confirmedBy: newConfirmedBy.value });
        }
      } catch (error) {
        console.error('확인의 처리시 오류가 발생했습니다.', error);
      }
    },
    [ecgTestId, initConfirmedBy]
  );

  useEffect(() => {
    if (open) {
      validateStt('showDialog', purposeText[0]);
    }
  }, [open]);

  // 리포트 유효성 검증 요청의 응답 처리
  const prevEcgTestsValidReportState = usePrevious(ecgTestsValidReportState);
  useEffect(() => {
    // PA 임상에서는 리포트 검증을 하지 않는다.
    if (!isMainEcgTest) return;

    if (
      prevEcgTestsValidReportState &&
      prevEcgTestsValidReportState.pending &&
      !ecgTestsValidReportState.pending
    ) {
      if (!ecgTestsValidReportState.error) {
        if (ecgTestsValidReportState.data) {
          // 검토 완료 Dialog 팝업
          setInitPending(false);
        } else {
          // 리포트 생성 Dialog 팝업
          showDialog('ConfirmDialog', {
            title: intl.formatMessage({
              id: '99-FinalConfirmReportDialog-ConfirmDialog-title-01',
              description:
                '리포트를 다시 생성해 주세요. / There is a history of correcting patient and examination information that was not reflected in the report.',
              defaultMessage: '리포트를 다시 생성해 주세요.',
            }),
            message: intl.formatMessage(
              {
                id: '99-FinalConfirmReportDialog-ConfirmDialog-message-01',
                description:
                  '리포트 변경 사항이 있습니다. 리포트를 다시 생성한 뒤에 검토 완료를 진행해 주세요. ',
                defaultMessage:
                  '리포트 변경 사항이 있습니다. 리포트를 다시 생성한 뒤에 {purposeText} 완료를 진행해 주세요.',
              },
              { purposeText: purposeText[0] }
            ),
            cancelButtonText: intl.formatMessage({
              id: '99-FinalConfirmReportDialog-ConfirmDialog-cancelButtonText-01',
              description: '취소 / Cancel',
              defaultMessage: '취소',
            }),
            confirmButtonText: intl.formatMessage({
              id: '99-FinalConfirmReportDialog-ConfirmDialog-confirmButtonText-01',
              description: '다시 생성하기',
              defaultMessage: '다시 생성하기',
            }),
            onCancel: () => {
              onClose();
            },
            onSubmit: () => {
              validateStt('showDialog');
            },
          });
        }
      } else {
        onClose();
      }
    }
  }, [ecgTestsValidReportState.pending]);

  // 검사 정보에 확인의 수정 요청에 대한 응답 처리
  const prevEcgTestsPatchState = usePrevious(ecgTestsPatchState);
  useEffect(() => {
    if (
      prevEcgTestsPatchState &&
      prevEcgTestsPatchState.pending &&
      !ecgTestsPatchState.pending
    ) {
      if (!ecgTestsPatchState.error) {
        // 검토완료 또는 편집완료 처리, 요청자 계정 유형에 따라 결과 달라짐
        doneRequest();
      } else {
        // showDialog('AlertNetworkDialog');
      }
    }
  }, [ecgTestsPatchState.pending]);

  // 검토 완료 요청의 응답 처리
  const prevEcgTestsConfirmState = usePrevious(ecgTestsConfirmState);
  useEffect(() => {
    async function ecgTestConfirmReviewCallback() {
      if (
        prevEcgTestsConfirmState &&
        prevEcgTestsConfirmState.pending &&
        !ecgTestsConfirmState.pending
      ) {
        if (!ecgTestsConfirmState.error) {
          setSubmitPending(false);

          onClose();
          if (typeof callback === 'function') {
            callback();
          }
        } else {
          // showDialog('AlertNetworkDialog');
        }
      }
    }

    ecgTestConfirmReviewCallback();
  }, [ecgTestsConfirmState.pending]);

  // 편집 완료 요청의 응답 처리
  const prevEcgTestsEditDoneState = usePrevious(ecgTestsEditDoneState);
  useEffect(() => {
    async function confirmEcgTestReviewRequestedCallback() {
      if (
        prevEcgTestsEditDoneState &&
        prevEcgTestsEditDoneState.pending &&
        !ecgTestsEditDoneState.pending
      ) {
        if (!ecgTestsEditDoneState.error) {
          setSubmitPending(false);

          onClose();
          if (typeof callback === 'function') {
            callback();
          }
        } else {
          // showDialog('AlertNetworkDialog');
        }
      }
    }

    confirmEcgTestReviewRequestedCallback();
  }, [ecgTestsEditDoneState.pending]);

  // const parseInitConfirmedBy = (initConfirmedBy) => {
  //   let result = null;
  //   if (!!initConfirmedBy) {
  //     result = {
  //       label: initConfirmedBy?.firstName,
  //       value: initConfirmedBy?.username,
  //     };
  //   }
  //   return result;
  // };

  useEffect(() => {
    //sub test는 리포트 검증을 하지 않는다. (for PA 임상)
    if (!isMainEcgTest) {
      setInitPending(false);
    }
  }, [isMainEcgTest]);
  return !initPending ? (
    <FinalConfirmReportDialog
      {...{
        // Dialog Props
        open,
        params: {
          purposeText,
          reportFileName,
          reportOnClick,
          initConfirmedBy,
          onSubmit,
        },
        onClose,
        // Store States
        // Local States
        submitPending,
      }}
    />
  ) : null;
}

export default FinalConfirmReportDialogContainer;
