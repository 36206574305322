/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { MessageDescriptor } from '@formatjs/intl/src/types';

type IntlMapType = Record<string, ExtendedMessageDescriptor>;

type FormattedMessages<T> = {
  [K in keyof T]: string | ((...args: any[]) => string);
};

type ValueFunction<T = any> = (...args: any[]) => T;

interface ExtendedMessageDescriptor extends MessageDescriptor {
  value?: ValueFunction;
}

export function useIntlMsg<T extends IntlMapType>(
  INTL_MAP: T
): FormattedMessages<T> {
  const intl = useIntl();

  const messages = useMemo(() => defineMessages(INTL_MAP), []);

  const t = (
    messageDescriptor: ExtendedMessageDescriptor,
    values: Record<string, any> = {}
  ): string => intl.formatMessage(messageDescriptor, values);

  const formattedMessages: FormattedMessages<T> = {} as FormattedMessages<T>;

  (Object.keys(messages) as Array<keyof T>).forEach((key) => {
    const messageDescriptor = messages[key];

    if (messageDescriptor.value) {
      formattedMessages[key] = (...args: any[]): string => {
        const valueObj = messageDescriptor.value!(...args);
        return t(messageDescriptor, valueObj);
      };
    } else {
      formattedMessages[key] = t(messageDescriptor);
    }
  });

  return formattedMessages;
}
