import { call, put, select } from 'redux-saga/effects';

import { TIME_EVENT_TYPE } from 'constant/EventConst';

import {
  getBeatsNEctopicListRequested,
  selectEcgRawList,
} from 'redux/duck/testResultDuck';

const CONST_TIME_EVENT_TYPE = TIME_EVENT_TYPE;

/**
 * @typedef {Number} WaveformIndex Waveform Index
 *
 * @typedef SectionType 구간 정보 타입
 * @property {WaveformIndex} onsetWaveformIndex
 * @property {WaveformIndex} terminationWaveformIndex
 *
 * @typedef TimeEventsPostRequestBodyType Time Events API 요청 데이터 타입
 * @property {TIME_EVENT_TYPE} eventType
 * @property {Boolean} isRemove
 * @property {WaveformIndex} onsetWaveformIndex
 * @property {WaveformIndex} terminationWaveformIndex
 * @property {String} tid
 *
 */

/**
 * 구간 정보의 값이 올바른지 검증
 * @param {SectionType} section
 */
const validSectionInfo = ({ onsetWaveformIndex, terminationWaveformIndex }) =>
  typeof (onsetWaveformIndex + terminationWaveformIndex) === 'number' &&
  !Number.isNaN(onsetWaveformIndex + terminationWaveformIndex);
/**
 * 두 구간에 교차 구간이 존재하는 지 검사
 * @param {SectionType} sectionLeft
 * @param {SectionType} sectionRight
 * @returns
 */
const isIntersect = (sectionLeft, sectionRight) =>
  validSectionInfo(sectionLeft) &&
  validSectionInfo(sectionRight) &&
  !(
    sectionLeft.terminationWaveformIndex <= sectionRight.onsetWaveformIndex ||
    sectionRight.terminationWaveformIndex <= sectionLeft.onsetWaveformIndex
  );
/**
 * ECG Raw 정보로 부터 구간 정보만 반환
 * @param {SectionType & any} ecgRawInfo
 * @returns
 */
const extractSectionInfo = ({
  onsetWaveformIndex,
  terminationWaveformIndex,
}) => ({ onsetWaveformIndex, terminationWaveformIndex });
/**
 * `sectionList` 중 연속하는 Section 을 하나의 정보로 병합
 *
 * 두 Section 의 연속은 서로 다른 Section 의 terminationWaveformIndex 과 onsetWaveformIndex 이 같은 경우
 * @param {SectionType[]} sectionList
 * @returns {SectionType[]}
 */
const mergeContinuousSections = (sectionList) => {
  return sectionList.reduce((acc, cur) => {
    let lastSection = acc.at(-1);
    if (!lastSection) {
      return [{ ...cur }];
    }

    if (lastSection.terminationWaveformIndex === cur.onsetWaveformIndex) {
      lastSection.terminationWaveformIndex = cur.terminationWaveformIndex;
    } else {
      acc.push({ ...cur });
    }
    return [...acc];
  }, []);
};

/**
 * @param {TimeEventsPostRequestBodyType} requestBody
 */
function* postProcessForAf({ onsetWaveformIndex, terminationWaveformIndex }) {
  const intersectionList = yield select((state) =>
    selectEcgRawList(state)
      .filter((ecgRawInfo) =>
        isIntersect(
          { onsetWaveformIndex, terminationWaveformIndex },
          {
            onsetWaveformIndex: ecgRawInfo.onsetWaveformIndex,
            terminationWaveformIndex: ecgRawInfo.terminationWaveformIndex,
          }
        )
      )
      .map(extractSectionInfo)
  );

  const mergedSectionList = mergeContinuousSections(intersectionList);
  for (const {
    onsetWaveformIndex,
    terminationWaveformIndex,
  } of mergedSectionList) {
    yield put(
      getBeatsNEctopicListRequested(
        onsetWaveformIndex,
        terminationWaveformIndex
      )
    );
  }
}

/**
 * @param {TimeEventsPostRequestBodyType} requestBody
 */
export function* postProcessEditedTimeEvent({ eventType, ...rest }) {
  switch (eventType) {
    case CONST_TIME_EVENT_TYPE.AF:
      yield call(postProcessForAf, rest);
      return;
    case CONST_TIME_EVENT_TYPE.PAUSE:
    case CONST_TIME_EVENT_TYPE.OTHERS:
    default:
      return;
  }
}
