import AppColors from 'theme/AppColors';

export interface ITheme {
  color: {
    [key: string]: string;
  };
  layout: {
    maxWidth: number;
  };
  breakpoint: {
    [key: string]: number;
  };
  size?: {
    [key: string]: number;
  };
}

const StyledComponentsTheme: ITheme = {
  color: AppColors,
  layout: {
    maxWidth: 1140,
  },
  breakpoint: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
};

export default StyledComponentsTheme;
