import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { DialogWrapper, DialogButtonWrapper } from 'component/ui/dialog/Dialog';
import TextInput from 'component/ui/input/TextInput';
import TextButton from 'component/ui/button/TextButton';

const TitleText = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.color.BLACK};
`;

const MessageText = styled.div`
  margin: 8px 0 16px 0;
  font-size: 12px;
  color: ${(props) => props.theme.color.BLACK};
`;

function ForgotPasswordDialog(props) {
  const intl = useIntl();

  const { open, params, onClose } = props;
  const { onSubmit } = params;

  // TODO: 이메일 포멧 검증 필요?
  const [email, setEmail] = useState('');

  return (
    <DialogWrapper open={open} maxWidth={480}>
      <TitleText>
        {intl.formatMessage({
          id: '99-ForgotPasswordDialog-TitleText-children-01',
          description: '비밀번호 찾기',
          defaultMessage: '비밀번호 찾기',
        })}
      </TitleText>

      <MessageText>
        {intl.formatMessage(
          {
            id: '99-ForgotPasswordDialog-MessageText-children-01',
            description: '비밀번호 찾기 안내 메시지',
            defaultMessage:
              '등록된 이메일 주소를 입력하시면 해당 주소로 비밀번호 재설정 링크를 보내드립니다. 이메일 주소를 잊으신 경우에는 {email}으로 문의해주세요.',
          },
          { email: 'help@huinno.com' }
        )}
      </MessageText>

      <TextInput
        style={{ width: '100%', marginBottom: '6px' }}
        inputType="email"
        value={email}
        onChange={(text) => {
          setEmail(text);
        }}
      />
      <DialogButtonWrapper>
        <TextButton
          textColor="#575b5d"
          title={intl.formatMessage({
            id: '99-Dialog-Button-title-cancel',
            description: '팝업 Dialog의 취소 버튼',
            defaultMessage: '취소',
          })}
          onClick={onClose}
        />
        <TextButton
          disabled={!email || email.length === 0}
          title={intl.formatMessage({
            id: '99-Dialog-Button-title-confirm',
            description: '팝업 Dialog의 확인 버튼',
            defaultMessage: '확인',
          })}
          onClick={() => {
            onSubmit(email);
          }}
        />
      </DialogButtonWrapper>
    </DialogWrapper>
  );
}

export default ForgotPasswordDialog;
