/**
 * ❗️❗️❗️
 * *주의*
 * ❗️❗️❗️
 *
 * 하기 코드는 파트너 웹과 병원 웹 간 차이가 있습니다.
 */
import Const from 'constant/Const';
import useShallowEqualSelector from './useShallowEqualSelector';

const IS_CUSTOMER_WEB =
  process.env.REACT_APP_CLIENT_NAME === Const.CLIENT_NAME.WEB_APP;

function useAuthority(args) {
  // FIXME(준호): relationType 기본값으로 internal 로 하는것이 적합?
  // const { isHospitalAdmin, relationType, accessLevel } = useSelector(
  //   (state) => ({
  //     isHospitalAdmin: state.authReducer.user?.isHospitalAdmin ?? false,
  //     relationType:
  //       state.authReducer.user?.relationType ??
  //       Const.USER_RELATION_TYPE.INTERNAL,
  //     accessLevel:
  //       state.authReducer.user?.accessLevel ??
  //       Const.ACCESS_LEVEL.READ_ONLY.value,
  //   })
  // );
  const {
    isHospitalAdmin,
    relationType,
    accessLevel,
    ecgTestStatus,
    cloudStatus,
  } = useShallowEqualSelector((state) => ({
    isHospitalAdmin: state.authReducer.me?.isHospitalAdmin ?? false,
    relationType:
      state.authReducer.me?.relationType ?? Const.USER_RELATION_TYPE.INTERNAL,
    accessLevel:
      state.authReducer.me?.accessLevel ?? Const.ACCESS_LEVEL.READ_ONLY.value,
    ecgTestStatus: state.testResultReducer.ecgTest.data?.ecgTestStatus,
    cloudStatus: state.testResultReducer.ecgTest.data?.cloudStatus,
  }));

  const isDone = () => {
    switch (relationType) {
      case 10:
        return IS_CUSTOMER_WEB ? ecgTestStatus === 200 : cloudStatus === 200;
      case 20:
        return cloudStatus >= 60;
      case 30:
        return ecgTestStatus === 200;

      default:
        return;
    }
  };

  return {
    // XXX: 병원 어드민에 인터널 병원 사용자가 로그인한 경우, read only?
    isReadOnly:
      (isHospitalAdmin
        ? false
        : accessLevel === Const.ACCESS_LEVEL.READ_ONLY.value) || isDone(),
    isEditable: accessLevel === Const.ACCESS_LEVEL.READ_WRITE.value,
    isHospitalAdmin,
    relationType,
  };
}

export default useAuthority;
