import {
  format,
  addDays,
  subDays,
  addMinutes,
  subMinutes,
  differenceInDays,
  isWithinInterval,
  intervalToDuration,
  isValid,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import LocalStorageKey from 'constant/LocalStorageKey';

import LocalStorageManager from 'manager/LocalStorageManager';

const validationDateUtil = (date) => {
  let result;
  try {
    if (typeof date === 'object') {
      result = new Date(date);
    } else if (!isNaN(date) && typeof date === 'number') {
      if (`${date}`.length === 13) {
        result = new Date(date);
      } else {
        result = new Date(date * 1000);
      }
    } else if (typeof date === 'string') {
      result = new Date(date);
    } else {
      result = new Date(date).toString();
      throw 'type should be string, date or number object';
    }
  } catch (error) {
    console.error(error);
  }
  return result;
};

export const FORMAT_TYPE = {
  'MM/dd': 'MM/dd',
  'yyyy-MM-dd': 'yyyy-MM-dd',
  'MM-dd': 'MM-dd',
};

const DateUtil = {
  formatDate: (date, formatType = 'yyyy-MM') => {
    return format(validationDateUtil(date), formatType);
  },
  formatDateOnly: (date, formatType = 'yyyy-MM-dd') => {
    return format(validationDateUtil(date), formatType);
  },

  formatTimeOnly: (date) => {
    return format(validationDateUtil(date), 'HH:mm:ss');
  },

  formatHourOnly: (date) => {
    return format(validationDateUtil(date), 'ha').toLowerCase();
  },

  formatDateTime: (date) => {
    return format(validationDateUtil(date), 'yyyy-MM-dd (HH:mm:ss)');
  },

  formatDateToYYYYMMDD: (date) => {
    return format(validationDateUtil(date), 'yyyyMMdd');
  },

  formatFullDateTime: (date) => {
    const result =
      date === 0 || date === undefined
        ? ''
        : DateUtil.format(validationDateUtil(date), 'yyyy-MM-dd, HH:mm:ss');
    return result;
  },

  formatDateHourMinute: (date) => {
    return format(validationDateUtil(date), 'yy-MM-dd, HH:mm');
  },

  format: (date, dateFormatStr) => {
    const validCheckDate = validationDateUtil(date);
    return isValid(validCheckDate)
      ? format(validCheckDate, dateFormatStr)
      : validCheckDate;
  },

  // Date Object -> 20XX년 X월 X일
  formatDateToKorean: (date) => {
    const currentDate = new Date();

    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    const month = months[date.getMonth()];
    const day = date.getDate();
    let hour = date.getHours();
    const minute = date.getMinutes().toString().padStart(2, '0');
    const meridiem = hour < 12 ? '오전' : '오후';

    if (hour > 12) hour -= 12; // 24시간 형식을 12시간 형식으로 변환
    if (hour === 0) hour = 12; // 0시를 12시로 표시

    // 오늘 날짜와 date가 같다면 시간만 반환
    if (
      date.getDate() === currentDate.getDate() &&
      date.getMonth() === currentDate.getMonth() &&
      date.getFullYear() === currentDate.getFullYear()
    ) {
      return `${meridiem} ${hour}:${minute}`;
    }

    return `${month}월 ${day}일 ${meridiem} ${hour}:${minute}`;
  },

  addMinutes: (date, period) => {
    return addMinutes(date, period);
  },

  subMinutes: (date, period) => {
    return subMinutes(date, period);
  },

  addDays: (date, period) => {
    return addDays(date, period);
  },

  subDays: (date, period) => {
    return subDays(date, period);
  },

  isDayDifferent: (dateLeft, dateRight) => {
    if (
      dateLeft.getFullYear() !== dateRight.getFullYear() ||
      dateLeft.getMonth() !== dateRight.getMonth() ||
      dateLeft.getDate() !== dateRight.getDate()
    ) {
      return true;
    }

    return false;
  },

  calculateRemaingDay: (date) => {
    return differenceInDays(date, new Date());
  },

  calculateElapsedDay: (date) => {
    /* 
        # 시간 기준으로 현재와 차이를 일자로 반환
    */
    /* 
        # 주의 
            * 일자가 달라도 24시간 차이가 나지 않으면 두 날짜의 차이는 0일이다.
            * 예시
                - 아래 두시간의 차이는 23시간
                - 2022-01-01 10:00    
                - 2022-01-02 09:00
                ```
                    // 결과 값: 0
                    differenceInDays(
                        new Date(new Date('2022-01-02').setHours(9, 0)),
                        new Date(new Date('2022-01-01').setHours(10, 0))
                    )
                ```
        )
    */
    return differenceInDays(new Date(), date);
  },

  calcDday: (date, option = { prefixSign: false }) => {
    /* 
        # 날짜 기준으로 현재와 차이를 일자로 반환
            * 24시간 차이가 나지 않아도 일자가 바뀌면 일자가 바뀐다.
    */
    let result;
    const prefixSign = option?.prefixSign;
    const currentDate = new Date(new Date().toDateString());
    const targetDate = new Date(new Date(date).toDateString());
    const elapsedDay = (+currentDate - +targetDate) / 1000 / 60 / 60 / 24;

    if (prefixSign) {
      result = elapsedDay >= 0 ? `+${elapsedDay}` : elapsedDay;
    } else {
      result = Math.abs(elapsedDay);
    }

    return result;
  },

  // 검사 종료일 및 예정일을 구하는 함수
  getEndDateTime: ({
    returnDateTime,
    estimatedEndDateTime,
    dateFormat = 'yyyy-MM-dd',
  }) => {
    let endDateTime;
    if (returnDateTime) {
      endDateTime = DateUtil.formatDate(new Date(returnDateTime), dateFormat);
    } else if (estimatedEndDateTime) {
      endDateTime = `${DateUtil.formatDate(
        new Date(estimatedEndDateTime),
        dateFormat
      )}(예정)`;
    } else {
      endDateTime = '-';
    }
    return endDateTime;
  },

  intervalToDuration: ({ start, end }) => {
    const diff = end - start;

    const days = Math.floor(diff / (24 * 60 * 60 * 1000)); // 하루의 밀리초 수로 나눔
    const hours = Math.floor((diff % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)); // 남은 밀리초를 시간으로 변환
    const minutes = Math.floor((diff % (60 * 60 * 1000)) / (60 * 1000)); // 남은 밀리초를 분으로 변환
    const seconds = Math.floor((diff % (60 * 1000)) / 1000); // 남은 밀리초를 초로 변환

    return { days, hours, minutes, seconds };
  },

  /**
   * millisecond 단위인 duration을 아래와 같은 상황에 맞게 Return 해주는 유틸함수 입니다.
   * - 0 s 초과, 60 s 이하일 경우: ≤ {nn.n} sec
   * - 60 s 초과 1h 이하일 경우: {nn}m {nn.n}s
   * - 1h 초과일 경우: {nn}h {nn}m {nn.n}s
   * - 1d 초과일 경우: {dd}d {nn}h {nn}m (초 단위 비노출)
   */
  convertDuration: (duration, isPause) => {
    if (isNaN(Number(duration))) return '-';
    const date = DateUtil.intervalToDuration({
      start: 0,
      end: duration,
    });
    /* {nn.n}s 인 경우 */
    const dotMilliseconds = isPause
      ? duration.toString().padStart(3, '0').slice(-3)
      : duration.toString().padStart(3, '0').slice(-3, -2);

    if (date.days) {
      return `${date.days}d ${date.hours}h ${date.minutes}m`;
    } else if (date.hours) {
      return `${date.hours}h ${date.minutes}m ${
        Number(dotMilliseconds)
          ? `${date.seconds}.${dotMilliseconds}s`
          : date.seconds + '.0 s'
      }`;
    } else if (date.minutes) {
      return `${date.minutes}m ${
        Number(dotMilliseconds)
          ? `${date.seconds}.${dotMilliseconds}s`
          : date.seconds + '.0 s'
      }`;
    } else {
      return Number(dotMilliseconds)
        ? `${date.seconds}.${dotMilliseconds} sec`
        : date.seconds + '.0 sec';
    }
  },

  isWithinInterval: (firstDate, secondDate, target) => {
    return isWithinInterval(target, {
      start: firstDate,
      end: secondDate,
    });
  },

  getAge: (birth) => {
    // birth를 new Date()객체로 반환하면 Timezone에 따라 달라집니다.
    // 아래는 윤년까지 고려하여 fixed된 birth와 new Date()를 비교하는 로직입니다.
    try {
      const [birthYear, birthMonth, birthDate] = birth
        .split('-')
        .map((el) => +el);
      let now = new Date();
      // 현재 연도에서 생일 연도를 뺀 값을 age 로 한다.
      let age = now.getFullYear() - birthYear;
      // getMonth는 메서드는 0부터 시작하기 때문에 + 1을 해줘야 합니다. (https://developer.mozilla.org/ko/docs/Web/JavaScript/Reference/Global_Objects/Date/getMonth)
      let thisMonth = now.getMonth() + 1;
      let thisDate = now.getDate();

      // 현재 월일 < 태어난 월일 이면 -1
      if (thisMonth < birthMonth) {
        return age - 1;
      }
      if (thisMonth === birthMonth && thisDate < birthDate) {
        return age - 1;
      }
      return age;
    } catch (error) {
      window.location.href = '/';
    }
  },

  formatMs: (date) => parseInt(date.toString().padEnd(13, 0)),

  getMsFromWaveformIndex: (recordingStartMs, waveformIndex) =>
    recordingStartMs + waveformIndex * 4,

  formatWaveformToDate: (recordingStartMs, waveformIndex) => {
    return DateUtil.format(
      this.getMsFromWaveformIndex(recordingStartMs, waveformIndex),
      'yy-MM-dd, HH:mm:ss'
    );
  },

  /**
   * 사용자의 로컬 타임존을 고려하여 현재 시간을 Date 객체로 반환
   * @param {{timeZone : string}} timeZone = Asia/Seoul
   * @returns {Date} new Date() 객체
   */
  getUserLocationTime: ({ timeZone } = {}) => {
    try {
      const serverTimestamp = Number(
        LocalStorageManager.getItem(LocalStorageKey.SERVER_TIME_STAMP)
      );
      const timeDiffBtwServer = Number(
        LocalStorageManager.getItem(LocalStorageKey.TIME_DIFF_BTW_SERVER)
      );
      const hospitalTimezone = LocalStorageManager.getItem(
        LocalStorageKey.HOSPITAL_TIMEZONE
      );

      if (!serverTimestamp) return new Date();

      // 사용자 PC시간을 서버시간으로 동기화
      const userLocationTimestamp = new Date().getTime() + timeDiffBtwServer;

      // 타임존이 고려된 Date 객체 반환
      return utcToZonedTime(
        userLocationTimestamp,
        timeZone ?? hospitalTimezone
      );
    } catch (error) {
      console.error('Error in getUserFormattedTimestamp:', error.message);
      return new Date();
    }
  },

  isWithinDateRange: ({ currentDate, startDate, endDate }) => {
    return (
      currentDate >= new Date(startDate) && currentDate <= new Date(endDate)
    );
  },
  convertDateToMs: (date) => {
    return date * 24 * 60 * 60 * 1000;
  },

  // 처방일수 대비 실제 측정기간 비율
  // 분자(numerator), 분모(denominator)
  calculatePercentage: (RoundingMethods, { numerator, denominator }) => {
    const acceptableRoundingMethods = ['round', 'ceil', 'floor'];

    if (!acceptableRoundingMethods.includes(RoundingMethods.name)) {
      return;
    }

    return RoundingMethods((numerator / denominator) * 100);
  },
};

export default DateUtil;
