import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import usePrevious from 'component/hook/usePrevious';
import useDoctorsDropdown from 'component/hook/useDoctorsDropdown';
import DateUtil from 'util/DateUtil';
import Const from 'constant/Const';
import {
  DialogWrapper,
  DialogContentContainer,
  DialogButtonWrapper,
} from 'component/ui/dialog/Dialog';
import DialogRowItem from 'component/ui/dialog/DialogRowItem';
import TextInput from 'component/ui/input/TextInput';
import { RadioContainer, RadioButton } from 'component/ui/radio/Radio';
import TextButton from 'component/ui/button/TextButton';

const TitleText = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.color.BLACK};
`;

function AddNewTestDialog(props) {
  const intl = useIntl();

  const {
    open,
    params,
    callback,
    onClose,
    // Redux state
    ecgTestsCreateState,
    // Redux dispatch
    showDialog,
    hideDialog,
    createEcgTest,
  } = props;

  const { title } = params;
  const GENDER_OBJS = [
    {
      value: 'M',
      label: (
        <FormattedMessage {...Const.PATIENT_SEX_LABEL_DICT[`patientSex_M`]} />
      ),
    },
    {
      value: 'F',
      label: (
        <FormattedMessage {...Const.PATIENT_SEX_LABEL_DICT[`patientSex_F`]} />
      ),
    },
  ];

  const [patientName, setPatientName] = useState('');
  const [patientId, setPatientId] = useState('');
  const [gender, setGender] = useState(GENDER_OBJS[0].value);
  const [birthDate, setBirthDate] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [testPeriod, setTestPeriod] = useState('0');

  const prevEcgTestsCreateState = usePrevious(ecgTestsCreateState);
  useEffect(() => {
    async function createEcgTestCallback() {
      if (
        prevEcgTestsCreateState &&
        prevEcgTestsCreateState.pending &&
        !ecgTestsCreateState.pending
      ) {
        if (ecgTestsCreateState.error) {
        } else {
          onClose();
          showDialog(
            'AlertDialog',
            {
              message: intl.formatMessage({
                id: '99-AddNewTestDialog-AlertDialog-message-success',
                description: '3.4 검사 추가 팝업의 성공 결과 Alert 메시지',
                defaultMessage: '검사가 추가되었습니다.',
              }),
            },
            callback
          );
        }
      }
    }

    createEcgTestCallback();
  }, [ecgTestsCreateState.pending]);

  const [referredBy, DoctorsDropdown] = useDoctorsDropdown(
    intl.formatMessage({
      id: '99-AddNewTestDialog-Dropdown-placeholder-referredBy',
      description: '3.4 검사 추가 팝업의 referredBy 필드 placeholder',
      defaultMessage: '처방의 선택',
    })
  );

  /* 
        # validationReturnType
            * result: '', // boolean
            * type: '', // string
            * msg: '', // string
    */
  let validationList = [];
  let validationBirthDay = () => {
    try {
      const birthDateSplitedArr = birthDate.split('-');
      const year = parseInt(birthDateSplitedArr[0]);
      const month = parseInt(birthDateSplitedArr[1]);
      const date = parseInt(birthDateSplitedArr[2]);

      // 각 분기별로 다른 메세지가 들어갈 것을 대비
      if (birthDateSplitedArr.length != 3) {
        return {
          result: true,
          type: 'birthDay',
          msg: intl.formatMessage({
            id: '99-AddNewTestDialog-AlertDialog-message-01',
            description: '3.4 검사 추가 팝업의 생년월일 검증 실패',
            defaultMessage: '생년월일을 다시 확인하세요.',
          }),
        };
      }

      if (year < 1800 || year > new Date().getFullYear()) {
        return {
          result: true,
          type: 'birthDay',
          msg: intl.formatMessage({
            id: '99-AddNewTestDialog-AlertDialog-message-01',
            description: '3.4 검사 추가 팝업의 생년월일 검증 실패',
            defaultMessage: '생년월일을 다시 확인하세요.',
          }),
        };
      }

      if (month < 1 || month > 12) {
        return {
          result: true,
          type: 'birthDay',
          msg: intl.formatMessage({
            id: '99-AddNewTestDialog-AlertDialog-message-01',
            description: '3.4 검사 추가 팝업의 생년월일 검증 실패',
            defaultMessage: '생년월일을 다시 확인하세요.',
          }),
        };
      }

      const lastDay = new Date(year, month, 0).getDate();

      if (date < 1 || date > lastDay) {
        return {
          result: true,
          type: 'birthDay',
          msg: intl.formatMessage({
            id: '99-AddNewTestDialog-AlertDialog-message-01',
            description: '3.4 검사 추가 팝업의 생년월일 검증 실패',
            defaultMessage: '생년월일을 다시 확인하세요.',
          }),
        };
      }

      const toDay = new Date();
      if (toDay < new Date(birthDate)) {
        return {
          result: true,
          type: 'birthDay',
          msg: intl.formatMessage({
            id: '99-AddNewTestDialog-AlertDialog-message-01',
            description: '3.4 검사 추가 팝업의 생년월일 검증 실패',
            defaultMessage: '생년월일을 다시 확인하세요.',
          }),
        };
      }
      return {
        result: false,
      };
    } catch (error) {
      console.error(error);
    }
  };

  let validationPhoneNumber = () => {
    const replacedPhoneNumber = phoneNumber.replace(/[^\d]/g, '');

    // 각 분기별로 다른 메세지가 들어갈 것을 대비
    if (replacedPhoneNumber.length !== 11) {
      return {
        result: true,
        type: 'birthDay',
        msg: intl.formatMessage({
          id: '99-AddNewTestDialog-AlertDialog-message-02',
          description: '3.4 검사 추가 팝업의 전화번호 검증 실패',
          defaultMessage: '전화번호 형식이 올바르지 않습니다.',
        }),
      };
    }

    if (!!replacedPhoneNumber.match(/[^\d]/g, '')) {
      return {
        result: true,
        type: 'birthDay',
        msg: intl.formatMessage({
          id: '99-AddNewTestDialog-AlertDialog-message-02',
          description: '3.4 검사 추가 팝업의 전화번호 검증 실패',
          defaultMessage: '전화번호 형식이 올바르지 않습니다.',
        }),
      };
    }
    return {
      result: false,
    };
  };

  let validationPrescribedBy = () => {
    // 각 분기별로 다른 메세지가 들어갈 것을 대비
    if (referredBy === null) {
      return {
        result: true,
        type: 'referredBy',
        msg: intl.formatMessage({
          id: '99-AddNewTestDialog-AlertDialog-message-03',
          description: '3.4 검사 추가 팝업의 처방의 검증 실패',
          defaultMessage: '처방의를 선택하세요.',
        }),
      };
    }

    return {
      result: false,
    };
  };

  validationList.push(validationBirthDay);
  validationList.push(validationPhoneNumber);
  validationList.push(validationPrescribedBy);

  const onClickCreate = () => {
    for (let validation of validationList) {
      let validationResult = validation();
      if (validationResult.result === true) {
        showDialog('AlertDialog', {
          message: validationResult.msg,
        });
        return;
      }
    }

    createEcgTest(
      patientName,
      patientId,
      gender,
      phoneNumber.replace(/[^\d]/g, ''),
      birthDate,
      testPeriod,
      referredBy.value
    );
  };

  const isAllInfoEntered =
    patientName &&
    patientId &&
    gender &&
    birthDate &&
    phoneNumber &&
    testPeriod > 0;

  return (
    <DialogWrapper open={open}>
      <TitleText>{title}</TitleText>

      <DialogContentContainer>
        <DialogRowItem
          label={intl.formatMessage({
            id: '99-AddNewTestDialog-DialogRowItem-label-patientName',
            description: '3.4 검사 추가 팝업의 patientName 필드이름',
            defaultMessage: '환자명',
          })}>
          <TextInput
            inputType="text"
            value={patientName}
            onChange={(text) => {
              setPatientName(text);
            }}
          />
        </DialogRowItem>

        <DialogRowItem
          label={intl.formatMessage({
            id: '99-AddNewTestDialog-DialogRowItem-label-patientId',
            description: '3.4 검사 추가 팝업의 patientId 필드이름',
            defaultMessage: '환자번호',
          })}>
          <TextInput
            inputType="text"
            value={patientId}
            onChange={(text) => {
              setPatientId(text);
            }}
          />
        </DialogRowItem>

        <DialogRowItem
          label={intl.formatMessage({
            id: '99-AddNewTestDialog-DialogRowItem-label-gender',
            description: '3.4 검사 추가 팝업의 gender 필드이름',
            defaultMessage: '성별',
          })}>
          <RadioContainer>
            {GENDER_OBJS.map((genderObj) => {
              return (
                <RadioButton
                  key={genderObj.value}
                  id={genderObj.value}
                  value={genderObj.value}
                  label={genderObj.label}
                  checked={gender == genderObj.value}
                  onChange={() => {
                    setGender(genderObj.value);
                  }}
                />
              );
            })}
          </RadioContainer>
        </DialogRowItem>

        <DialogRowItem
          label={intl.formatMessage({
            id: '99-AddNewTestDialog-DialogRowItem-label-birthDate',
            description: '3.4 검사 추가 팝업의 birthDate 필드이름',
            defaultMessage: '생년월일',
          })}>
          <TextInput
            inputType="text"
            placeholder="YYYY-MM-DD"
            value={birthDate}
            onChange={(text, { nativeEvent: { inputType } }) => {
              const regex = /^[0-9\b -]{0,10}$/;
              if (regex.test(text)) {
                setBirthDate(() => {
                  if (inputType === 'insertFromPaste') {
                    return text
                      .replace(/-/g, '')
                      .replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
                  } else {
                    return text
                      .replace(/[^0-9]/g, '')
                      .replace(/^(\d{0,4})(\d{0,2})(\d{0,2})$/g, '$1-$2-$3')
                      .replace(/(\-{1,2})$/g, '');
                  }
                });
              }
            }}
          />
        </DialogRowItem>

        <DialogRowItem
          label={intl.formatMessage({
            id: '99-AddNewTestDialog-DialogRowItem-label-phoneNumber',
            description: '3.4 검사 추가 팝업의 phoneNumber 필드이름',
            defaultMessage: '전화번호',
          })}>
          <TextInput
            inputType="text"
            value={phoneNumber}
            onChange={(text, { nativeEvent: { inputType } }) => {
              const regex = /^[0-9\b -]{0,13}$/;
              if (regex.test(text)) {
                setPhoneNumber(() => {
                  if (inputType === 'insertFromPaste') {
                    return text
                      .replace(/-/g, '')
                      .replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
                  } else {
                    return text
                      .replace(/[^0-9]/g, '')
                      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
                      .replace(/(\-{1,2})$/g, '');
                  }
                });
              }
            }}
          />
        </DialogRowItem>

        <DialogRowItem
          label={intl.formatMessage({
            id: '99-AddNewTestDialog-DialogRowItem-label-testPeriod',
            description: '3.4 검사 추가 팝업의 testPeriod 필드이름',
            defaultMessage: '처방기간',
          })}>
          <TextInput
            inputType="number"
            value={testPeriod}
            adornment={intl.formatMessage({
              id: '99-AddNewTestDialog-DialogRowItem-label-testPeriodDays',
              description: '3.4 검사 추가 팝업의 testPeriod value 몇 일',
              defaultMessage: '일',
            })}
            onChange={(text) => {
              const regex = /^[0-9]{1}\d{0,}$/g;
              if (regex.test(text) || text === '') {
                setTestPeriod(() => {
                  if (text[0] === '0') {
                    text = text.slice(1);
                  }
                  return text;
                });
              }
            }}
          />
        </DialogRowItem>

        <DialogRowItem
          label={intl.formatMessage({
            id: '99-AddNewTestDialog-DialogRowItem-label-referredBy',
            description: '3.4 검사 추가 팝업의 referredBy 필드이름',
            defaultMessage: '처방의',
          })}>
          <DoctorsDropdown dropToTop={true} />
        </DialogRowItem>
      </DialogContentContainer>

      <DialogButtonWrapper>
        <TextButton
          outline
          title={intl.formatMessage({
            id: '99-Dialog-Button-title-cancel',
            description: '팝업 Dialog의 취소 버튼',
            defaultMessage: '취소',
          })}
          onClick={onClose}
        />
        <TextButton
          disabled={!isAllInfoEntered}
          title={intl.formatMessage({
            id: '99-AddNewTestDialog-TextButton-title-01',
            description: '3.4 검사 추가 팝업의 추가 버튼',
            defaultMessage: '추가',
          })}
          onClick={onClickCreate}
        />
      </DialogButtonWrapper>
    </DialogWrapper>
  );
}

export default AddNewTestDialog;
