import { AMPLITUDE_OPTION } from './ChartEditConst';

export const EIGHT_SEC_MS = 8000;
export const TEN_SEC_MS = 10000;
export const EIGHTEEN_SEC_MS = 18000;
export const TWENTY_SEC_MS = 20000;

export const REPORT_EVENT_EDITOR_STEP_MAP = {
  CANCEL: 0, // report 담기 과정 아님
  SECTION: 1, // report page 선택 단계
  TITLE: 2, // report title 입력 단계
  SUBMIT: 3,
};

export const STRIP_TYPE_MAP = {
  MAIN: 'main',
  SUB: 'sub',
};

export const EDITABLE_REPORT_EDITOR_STATE = {
  EDITOR_STEP: 'editor_step',
  SELECTED_REPORT_SECTION: 'selected_report_section',
  ANNOTATION: 'annotation',
  AMPLITUDE_RATE: 'amplitude_rate',
  SELECTED_STRIP_TYPE: 'selected_strip_type',
  DELETE_SUB_STRIP: 'delete_sub_strip',
};

// 10mm/mV => rate: 1
export const BASE_AMPLITUDE_RATIO = 10;

export const AMPLITUDE_RATE_OPTION_LIST = [
  {
    amplitude: AMPLITUDE_OPTION.FIVE_MV.LABEL,
    rate: AMPLITUDE_OPTION.FIVE_MV.RATE,
    basisOption: false,
  },
  {
    amplitude: AMPLITUDE_OPTION.TEN_MV.LABEL,
    rate: AMPLITUDE_OPTION.TEN_MV.RATE,
    basisOption: false,
  },
  {
    amplitude: AMPLITUDE_OPTION.TWENTY_MV.LABEL,
    rate: AMPLITUDE_OPTION.TWENTY_MV.RATE,
    basisOption: true,
  },
  {
    amplitude: AMPLITUDE_OPTION.THIRTY_MV.LABEL,
    rate: AMPLITUDE_OPTION.THIRTY_MV.RATE,
    basisOption: false,
  },
  {
    amplitude: AMPLITUDE_OPTION.FORTY_MV.LABEL,
    rate: AMPLITUDE_OPTION.FORTY_MV.RATE,
    basisOption: false,
  },
];

export const BASIS_AMPLITUDE_RATE = AMPLITUDE_RATE_OPTION_LIST.filter(
  (amplitudeRateOption) => amplitudeRateOption.basisOption
)[0].rate;

function getAmplitude(rate) {
  return rate * BASE_AMPLITUDE_RATIO;
}
function getAmplitudeRate(amplitude) {
  return amplitude / BASE_AMPLITUDE_RATIO;
}

/**
 * @typedef ReportEventEditorState ReportEventEditor 모듈의 Global State 구조
 * @prop {string | null} reportEventId PTE 를 제외한 다른 Event 의 ReportEvent 데이터 식별자; 수정 시 사용
 * @prop {string | null} prevSelectedReportSection PTE 를 제외한 다른 Event 의 ReportEvent 정보 중 이전에 선택된 Report Section; 수정 시 사용
 * @prop {string | null} prevAnnotation PTE 를 제외한 다른 Event 의 ReportEvent 정보 중 이전에 입력된 Annotation; 수정 시 사용
 *
 * @prop {object} prevMainRepresentativeInfo PTE 의 Report 편집 시 기존의 90초 대표 Strip 정보, 그 외 Event 의 ReportEvent 편집 시 기존의 대표 Strip 정보
 * @prop {number | null} prevMainRepresentativeInfo.selectedMs 기존 대표 Strip 구간의 가운데 시점(Timestamp); 수정 시 사용
 * @prop {number | null} prevMainRepresentativeInfo.representativeOnsetIndex 기존 대표 Strip 구간의 시작 시점(Waveform Index); 수정 시 사용
 * @prop {number | null} prevMainRepresentativeInfo.representativeTerminationIndex 기존 대표 Strip 구간의 종료 시점(Waveform Index); 수정 시 사용
 * @prop {number | null} prevMainRepresentativeInfo.amplitudeRate 기존 대표 Strip 신호의 증폭 수준
 * @prop {boolean} prevMainRepresentativeInfo.isRemoved 기존 대표 Strip 구간 데이터가 없다면 true
 *
 * @prop {object} prevSubRepresentativeInfo 사용처 없음
 *
 * @prop {string | null} eventType Report 포함되는 ReportEvent 의 Event Type
 * @prop {0 | 1 | 2 | 3} editorStep ReportEvent 편집 단계; 0: 편집중 아님, 1: Report Section 선택단계, 2: Annotation, 대표 Strip 선택단계; 3: 편집완료(0으로 리셋)
 * @prop {string | null} selectedReportSection 편집중인 Report Section
 * @prop {'main' | 'sub'} selectedStripType 편집중인 Strip 종류; 'sub' 은 PTE Section 에서만 사용
 *
 * @prop {object} mainRepresentativeInfo PTE 의 Report 편집 시 90초 대표 Strip 정보, 그 외 Event 의 ReportEvent 편집 시 대표 Strip 정보
 * @prop {number | null} mainRepresentativeInfo.selectedMs 편집중인 대표 Strip 구간의 가운데 시점(Timestamp)
 * @prop {number | null} mainRepresentativeInfo.representativeOnsetIndex 편집중인 대표 Strip 구간의 가운데 시점(Timestamp)
 * @prop {number | null} mainRepresentativeInfo.representativeTerminationIndex 편집중인 대표 Strip 구간의 가운데 시점(Timestamp)
 * @prop {number} mainRepresentativeInfo.amplitudeRate 편집중인 대표 Strip 신호의 증폭 수준
 *
 * @prop {object} subRepresentativeInfo PTE 의 Report 편집 시 10초 보조 Strip 정보(PTE 의 ReportEvent 데이터)
 * @prop {number | null} subRepresentativeInfo.selectedMs 편집중인 PTE 보조 Strip 구간의 가운데 시점(Timestamp)
 * @prop {number | null} subRepresentativeInfo.representativeOnsetIndex 편집중인 PTE 보조 Strip 구간의 시작 시점(Waveform Index)
 * @prop {number | null} subRepresentativeInfo.representativeTerminationIndex 편집중인 PTE 보조 Strip 구간의 종료 시점(Waveform Index)
 * @prop {number} subRepresentativeInfo.amplitudeRate 편집중인 PTE 보조 Strip 신호의 증폭 수준
 * @prop {boolean} subRepresentativeInfo.isRemoved PTE 의 보조 Strip 이 없다면 true
 * @prop {boolean} subRepresentativeInfo.isMainChanged PTE 의 보조 Strip 이 없는 이유가 90초 Strip 정보 변경에 의한것이라면 true; 기본값 false
 *
 * @prop {number} selectedAmplitudeRate 대표 Strip 수정중 TenSecStripDetailChart 에서 보여지는 신호의 증폭 수준
 *
 */
