import { call, put, takeEvery, select } from 'redux-saga/effects';
import { BEATS_API_MANAGER_MAP } from 'constant/ApiManagerConst';
import { NOTHING_REDUX_ACTION } from 'constant/Const';

const CONST_BEATS_API_MANAGER_MAP = BEATS_API_MANAGER_MAP;

/**
 * @typedef {Object} BeatsRequestStatementType Beats API 요청 정보 구조
 * @property {String} requestType Beats API 요청 종류
 * @property {String} ecgTestId Beats API 요청 종류
 * @property {any} reqBody Beats API 요청 종류
 *
 * @typedef RequestInfoType API 요청 처리 정보 구조
 * @property {BeatsRequestStatementType} requestStatement API 요청 정보
 * @property {GeneratorFunction} succeedCallback API 요청 후 성공 응답 받을 시 응답 값을 Parameter 로 전달하여 실행
 * @property {GeneratorFunction} failedCallback API 요청 후 실패 응답 받을 시 에러 명세를 Parameter 로 전달하여 실행
 */

// :: Selectors
/** dequeue 를 통해 할당된 beats request 정보, API 요청 중일때만 값이 있음 */
const selectRequestedInfo = ({ beatsRequestQueueReducer: state }) =>
  state.requestedInfo;
const selectQueueLength = ({ beatsRequestQueueReducer: state }) =>
  state.requestQueue.length;

// :: Actions
// Queue Management
const ENQUEUE_REQUEST = 'memo-web/beat-relay/ENQUEUE_REQUEST';
const DEQUEUE_REQUEST = 'memo-web/beat-relay/DEQUEUE_REQUEST';
const RELEASE_REQUESTED_INFO = 'memo-web/beat-relay/RELEASE_REQUESTED_INFO';

// :: Reducer
const initialState = {
  requestQueue: [],
  // diffQueue: [],
  requestedInfo: null,
};
export default function reducer(
  state = initialState,
  { type = '', payload = {} } = {}
) {
  switch (type) {
    case ENQUEUE_REQUEST:
      return {
        ...state,
        requestQueue: [...state.requestQueue, payload.newRequestInfo],
      };
    case DEQUEUE_REQUEST:
      return {
        ...state,
        requestedInfo: state.requestQueue.at(0) ?? null,
      };
    case RELEASE_REQUESTED_INFO:
      return {
        ...state,
        requestQueue: [...state.requestQueue.slice(1)],
        requestedInfo: initialState.requestedInfo,
      };
    default:
      return state;
  }
}

// :: Action Creators
// Queue Management
/**
 *
 * @param {RequestInfoType} newRequestInfo
 * @returns
 */
export function enqueueRequest(newRequestInfo) {
  const { waveformIndexes } = newRequestInfo.requestStatement.reqBody;
  if (waveformIndexes && waveformIndexes.length === 0) {
    console.error('Beat 편집 오류', newRequestInfo);
    return { type: NOTHING_REDUX_ACTION };
  }
  return { type: ENQUEUE_REQUEST, payload: { newRequestInfo } };
}
function releaseRequestedInfo() {
  return { type: RELEASE_REQUESTED_INFO };
}

// :: Saga Action Handlers
// Queue Management
/** pop 되어 API 요청 중인 건이 없다면 새로운 요청을 pop */
function* _relayDequeueHandler({ payload = {} } = {}) {
  const requestedInfo = yield select(selectRequestedInfo);
  const queueLength = yield select(selectQueueLength);
  if (isPendingApiResponse(requestedInfo) || isEmptyQueue(queueLength)) return;

  // DEQUEUE_REQUEST Action 을 Dispatch 할 수 있는 유일 지점!
  yield put({ type: DEQUEUE_REQUEST });
}
/** pop 된 API 요청 건을 실행, 완료 된 후 해당 요청(requestedInfo)은 state 에서 해제 */
function* _dequeueHandler({ payload = {} } = {}) {
  const requestedInfo = yield select(selectRequestedInfo);
  if (!requestedInfo) return;

  const {
    requestStatement: { requestType, ecgTestId, reqBody },
    succeedCallback,
    failedCallback,
  } = requestedInfo;

  try {
    const responseBody = yield call(
      getBeatsApi(requestType),
      ecgTestId,
      reqBody
    );
    yield put(releaseRequestedInfo());
    yield call(succeedCallback, responseBody);
  } catch (error) {
    yield put(releaseRequestedInfo());
    yield call(failedCallback, error);
  }
}

// :: Saga
export function* saga() {
  yield takeEvery(ENQUEUE_REQUEST, _relayDequeueHandler);
  yield takeEvery(DEQUEUE_REQUEST, _dequeueHandler);
  yield takeEvery(RELEASE_REQUESTED_INFO, _relayDequeueHandler);
}

function getBeatsApi(type) {
  return CONST_BEATS_API_MANAGER_MAP[type];
}
function isPendingApiResponse(requestedInfo) {
  return requestedInfo ? true : false;
}
function isEmptyQueue(queueLength) {
  return queueLength === 0;
}
