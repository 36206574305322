import Const from 'constant/Const';
import React from 'react';
import styled from 'styled-components';
import AppColors from 'theme/AppColors';

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
  :not(:last-child) {
    margin-bottom: 22px;
  }
`;

export const RadioButtonWrapper = styled.div`
  position: relative;
  flex: 1;
  padding: 8px;
  border-width: 1px;
  border-radius: 32px;
  border-style: solid;
  background-color: ${(props) =>
    props.checked && props.theme.color.LIGHT_PRIMARY_BLUE};
  border-color: ${(props) =>
    props.borderChecked || props.checked
      ? props.theme.color.PRIMARY_BLUE
      : props.theme.color.MEDIUM};
  transition: border-color 0.2s ease-in-out;
  :hover {
    border-color: ${(props) =>
      !props.disabled && props.theme.color.PRIMARY_BLUE};
  }
  :not(:last-child) {
    ${(props) => {
      if (props.direction === 'row') {
        return `margin-right: 10px;`;
      } else {
        return `margin-bottom: 10px;`;
      }
    }}
  }
  ${(props) => {
    let disabledResult = '';
    if (props.disabled) {
      disabledResult = `
        opacity: 0.5;
        & > label {
          cursor: default !important;
        }
      `;
    }
    return disabledResult;
  }};
`;

const Label = styled.label`
  white-space: nowrap;
  font-size: 14px;
  color: #242626;
  text-underline-offset: 1px;
`;

export function OnlyRadio(props) {
  const { id, value, checked, onChange, label } = props;
  return (
    <>
      <input
        type="radio"
        id={id}
        value={value}
        checked={checked}
        onChange={onChange}
        readOnly={typeof onChange !== 'function'}
      />
      <Label htmlFor={id}>{label}</Label>
    </>
  );
}

export function RadioButton(props) {
  const { id, value, checked, direction, onChange, label } = props;
  return (
    <RadioButtonWrapper
      disabled={true}
      direction={direction || 'row'}
      onClick={onChange}>
      <input
        type="radio"
        id={id}
        value={value}
        checked={checked}
        onChange={() => {}}
      />
      <Label htmlFor={id}>{label}</Label>
    </RadioButtonWrapper>
  );
}

const ClassLabelContainer = styled.div`
  position: absolute;
  top: -15px;
  left: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1.5px;
`;

const ClassLabel = styled.div`
  border-radius: 6px 6px 0 0;
  color: ${(props) => `${props.borderColor}`};
  border: ${(props) => `1px solid ${props.borderColor}`};
  border-bottom: none;
  background-color: ${(props) => props.bgColor || ''};

  height: 13px;
  width: 17px;
  &:before {
    content: ${(props) => `'${props.classification}'`};
    font-size: 9px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export function RadioButtonWithShortcut(props) {
  const {
    id,
    value,
    checked,
    direction,
    onChange,
    label,
    shortcut,
    disabled,
    borderChecked,
    loginUserReltionType,
    classificationDiagnosisList: {
      aiPrediction,
      partnerDiagnosis,
      internalDiagnosis,
      customerDiagnosis,
    },
  } = props;
  /* 
    # 버튼의 종류 
      * border, 배경색 여부에 따라 종류가 세가지가 있다.
        - activate button
        - border-colored button
        - deactivate button
      * 버튼 종류를 code value level에서설명
        - activate button
          - borderChecked value = 상관없음
          - checked value = true
        - border-colored button
          - checked value = false
          - borderChecked value = true
        - deactivate button
          - checked value = false
          - borderChecked value = false
      * checked, borderChckec value 기준
          - checked - init class diagnosis -> change class diagnosis
          - borderchecked - init class diagnosis
  */
  /*
    # 라벨 종류
      * 참고 클래스 diagnosis - refLabel
        - 클래스 어드민의 참고 클래스 diagnosis - aiPrediction
        - 인터널 어드민의 참고 클래스 diagnosis - aiPrediction, partnerDiagnosis
      * 비활성화 클래스 - activateLabel
        - 활성화 이전 단계 클래스 
        - 클래스 단계
          - aiPrediction, parterDiagnosis, internalDiagnosis
        - customerDiagnosis는 항상 비활성화
      * 활성화 클래스 - deActivateLabel
        - 파트너 유저: parterDiagnosis || internalDiagnosis
        - 인터널 유저: internalDiagnosis

    # 색으로 알아보는 라벨
      * LIGHT_PRIMARY_BLUE 
        -> 선택 된 클래스
        -> bg-color로 사용 
        - EventListFragment.jsx > getInitSelectedClass FN과 관련있다.
      * PRIMARY_BLUE
        -> 선택 된 클래스 
        -> border-color로 사용
      * MEDIUM 
        -> 선택된 이전 단계 클래스
        -> border-color로 사용
        - 단계: Ai -> P -> In   
   */

  let classificationLabelElList = [];
  // 파트너 계정으로 보여줄 라벨
  // - 보여줄 label 종류 3개: ai, parnter, internal label
  if (loginUserReltionType === Const.RELATION_TYPE.PARTNER) {
    if (aiPrediction == value) {
      classificationLabelElList.push(
        <ClassLabel
          key="0"
          classification="Ai"
          borderColor={
            partnerDiagnosis != null || internalDiagnosis != null
              ? AppColors.MEDIUM
              : AppColors.PRIMARY_BLUE
          }
          bgColor={''}
        />
      );
    }
    if (partnerDiagnosis == value) {
      classificationLabelElList.push(
        <ClassLabel
          key="1"
          classification="P"
          borderColor={
            internalDiagnosis == null
              ? AppColors.PRIMARY_BLUE
              : AppColors.MEDIUM
          }
          bgColor={
            internalDiagnosis == null ? AppColors.LIGHT_PRIMARY_BLUE : ''
          }
        />
      );
    }
    if (internalDiagnosis == value) {
      classificationLabelElList.push(
        <ClassLabel
          key="2"
          classification="In"
          borderColor={AppColors.PRIMARY_BLUE}
          bgColor={AppColors.LIGHT_PRIMARY_BLUE}
        />
      );
    }
  }

  // 인터널 계정으로 보여줄 라벨
  // - label 종류 4개: ai, parnter, internal, customer label
  if (loginUserReltionType === Const.RELATION_TYPE.INTERNAL) {
    if (aiPrediction == value) {
      classificationLabelElList.push(
        <ClassLabel
          key="0"
          classification="Ai"
          borderColor={
            partnerDiagnosis != null || internalDiagnosis != null
              ? AppColors.MEDIUM
              : AppColors.PRIMARY_BLUE
          }
          bgColor={''}
        />
      );
    }
    if (partnerDiagnosis == value) {
      classificationLabelElList.push(
        <ClassLabel
          key="1"
          classification="P"
          borderColor={
            internalDiagnosis == null
              ? AppColors.PRIMARY_BLUE
              : AppColors.MEDIUM
          }
          bgColor={''}
        />
      );
    }
    if (internalDiagnosis == value) {
      classificationLabelElList.push(
        <ClassLabel
          key="2"
          classification="In"
          borderColor={AppColors.PRIMARY_BLUE}
          bgColor={AppColors.LIGHT_PRIMARY_BLUE}
        />
      );
    }
    if (customerDiagnosis == value) {
      classificationLabelElList.push(
        <ClassLabel
          key="3"
          classification="C"
          borderColor={AppColors.MEDIUM}
          bgColor={''}
        />
      );
    }
  }

  return (
    <RadioButtonWrapper
      direction={direction || 'row'}
      onClick={disabled ? null : onChange}
      checked={checked}
      borderChecked={borderChecked}
      disabled={disabled}>
      <ClassLabelContainer>{classificationLabelElList}</ClassLabelContainer>
      <input
        type="radio"
        id={id}
        value={value}
        checked={checked}
        onChange={() => {}}
      />
      <Label htmlFor={id}>
        {`${label} (`}
        <Label style={{ textDecoration: 'underline' }}>{shortcut}</Label>
        {')'}
      </Label>
    </RadioButtonWrapper>
  );
}
