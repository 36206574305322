import { ECG_CHART_UNIT } from 'constant/ChartEditConst';
import {
  BIN_SIZE_LOOKUP,
  MIN_AVG_MAX_BADGE_TYPE,
  HR_REVIEW_HISTOGRAM_TYPE,
  RR_MAX_BIN_KEY,
} from 'constant/HrReviewConst';

import { getStringFloat } from './NumberUtil';

/** 1 Waveform 의 Millie Sec */
const MS_PER_WAVEFORM = ECG_CHART_UNIT.MS_UNIT_PER_CHART_POINT;

export const getSagaFunctionError = (errorMessage: string) => {
  const sagaFunctionError = new Error();
  sagaFunctionError.name = 'HrReviewDuck Saga Function Error';
  sagaFunctionError.message = errorMessage;

  return sagaFunctionError;
};

/**
 * Bin Key 를 선택 가능한 값으로 변환합니다.
 *
 * R-R Interval Hist. 의 경우 Bin 구간 크기가 0.02(Waveform 기준 5)이므로,
 * 이 단위로 Bin Key 가 설정되어야 Hist. 및 Bin Detail 데이터 조회 가능
 */
export const getRefinedBinKey = (
  rawBinKey: number,
  histType: HR_REVIEW_HISTOGRAM_TYPE
) => {
  if (typeof rawBinKey !== 'number') return null;

  const binSize = BIN_SIZE_LOOKUP[histType];
  let result = rawBinKey - (rawBinKey % binSize);
  if (histType === HR_REVIEW_HISTOGRAM_TYPE.RR && RR_MAX_BIN_KEY < result) {
    result = RR_MAX_BIN_KEY;
  }

  return result;
};

/**
 * Histogram 의 Bin 대표값을 변환합니다.
 *
 * R-R Interval 값 단위인 Waveform 기준을 초단위로 변환
 */
export const convertPresentText = (
  value: number,
  histType: HR_REVIEW_HISTOGRAM_TYPE,
  fractionDigits: number = 3
) => {
  if (value === null) return '-';
  if (histType === HR_REVIEW_HISTOGRAM_TYPE.HR) {
    return getStringFloat(value);
  }

  return getStringFloat(
    Math.floor(value * MS_PER_WAVEFORM) / 1000,
    fractionDigits
  );
};

/**
 * 현재 조회중인 Strip(Episode) 의 최대, 평균, 최소 뱃지 타입을 반환한다.
 *
 * @param minData Histogram 데이터의 최소값
 * @param avgData Histogram 데이터의 평균값
 * @param maxData Histogram 데이터의 최대값
 * @param selectedBinGapWI 현재 선택된 Episode의 HR|RR 값
 * @returns 현재 조회중인 Strip(Episode) 의 최대, 평균, 최소 뱃지 타입
 */
export function getMinAvgMaxBadgeType(
  selectedBinGapWI: number,
  minData: number,
  avgData: number,
  maxData: number
): MIN_AVG_MAX_BADGE_TYPE {
  const flooredBinGapWI = Math.floor(selectedBinGapWI);

  switch (flooredBinGapWI) {
    case minData: {
      return MIN_AVG_MAX_BADGE_TYPE.MIN;
    }
    case avgData: {
      return MIN_AVG_MAX_BADGE_TYPE.AVG;
    }
    case maxData: {
      return MIN_AVG_MAX_BADGE_TYPE.MAX;
    }
    default: {
      return MIN_AVG_MAX_BADGE_TYPE.NONE;
    }
  }
}

/**
 * 10초 Strip 에서 제공할 ECG 데이터 조회에 사용되는 가운데 지점의 Waveform Index 를 반환
 * @param binData 선택된 Bin 데이터, Beat 의 R-Peak Waveform Index 와 histType 의 값으로 구성
 * @param histType 현재 조회 중인 Histogram 종류
 * @returns 조회할 10초의 가운데 Waveform Index
 */
export function getCenterWaveformIndex(
  binData: [number, number],
  histType: HR_REVIEW_HISTOGRAM_TYPE
): number {
  // HR Histogram 은 해당 Beat 위치가 중심 위치
  if (histType === HR_REVIEW_HISTOGRAM_TYPE.HR) return binData[0];

  // R-R Histogram 은 R-R Interval 이 Strip 의 가운데 위치
  const halfRRInterval = Math.floor(binData[1] / 2);
  const result = binData[0] - halfRRInterval;

  return result;
}

/**
 * onset 과 termination 사이의 가운데 value를 반환하는 함수
 *
 * @param onset onsetWaveformIndex | onsetMs
 * @param termination terminationWaveformIndex | terminationMs
 * @returns onset 과 termination 사이의 가운데 WaveformIndex | Ms
 */
export function getMiddleValueBtwOnsetNTerm({
  onset,
  termination,
}: Record<string, number>) {
  return termination - (termination - onset) / 2;
}

export function getValueOfBadgeType({
  minAvgMaxBadgeType,
  minData,
  avgData,
  maxData,
}: {
  minAvgMaxBadgeType: MIN_AVG_MAX_BADGE_TYPE;
  minData: number;
  avgData: number;
  maxData: number;
}): number {
  switch (minAvgMaxBadgeType) {
    case MIN_AVG_MAX_BADGE_TYPE.MIN:
      return minData;
    case MIN_AVG_MAX_BADGE_TYPE.AVG:
      return avgData;
    case MIN_AVG_MAX_BADGE_TYPE.MAX:
      return maxData;
    default:
      throw Error('minAvgMaxBadgeType is not valid');
  }
}
