import AppColors from 'theme/AppColors';

export const CONTENT_WRAPPER_WIDTH = 492;
export const INVERSE_ECG_WRAPPER_HEIGHT = 220;
export const BULK_UPDATE_WRAPPER_HEIGHT = 400;
export const MIN_BPM = 1;
export const MAX_BPM = 400;
export const MIN_RRI = 150;
export const MAX_RRI = 60_000;
export const CLASS_NAME = {
  EVENT_REVIEW_FRAGMENT_WRAPPER: 'event-review-fragment-wrapper',
};
export const HELPER_TEXT = {
  INVALID_FORMAT: '올바른 형식이 아닙니다.',
  DUPLICATE_INTERVAL: '중복된 구간이 있습니다.',
  BEFORE_START_TIME: '시작 시점보다 이전 시간입니다.',
  SAME_AS_START_TIME: '시작 시점과 동일한 시간입니다.',
  OUT_OF_RANGE_TIME: '측정 범위를 벗어난 시간입니다.',
  PLEASE_ENTER_DATE_TIME: '구간을 입력해주세요.',
};

export const ECTOPIC_TYPE = {
  ALL_TYPES: {
    text: 'All types',
    value: 'ALL_TYPES',
    color: AppColors.COOL_GRAY_80,
  },
  ISOLATED: {
    text: 'Isolated',
    value: 'ISOLATE',
    color: AppColors.COOL_GRAY_80,
  },
  COUPLET: { text: 'Couplet', value: 'COUPLET', color: AppColors.COOL_GRAY_80 },
  ISOLATED_AND_COUPLET: {
    text: 'Isolated & Couplet',
    value: 'ISOLATE_AND_COUPLET',
    color: AppColors.COOL_GRAY_80,
  },
  RUN: { text: 'Run', value: 'RUN', color: AppColors.COOL_GRAY_80 },
};
export const ECTOPIC_TYPE_LIST = Object.keys(ECTOPIC_TYPE).map(
  (key) => ECTOPIC_TYPE[key]
);

export const BEAT_TYPE = {
  NORMAL_BEAT: { text: 'Normal beat', value: 0, color: AppColors.COOL_GRAY_90 },
  S_BEAT: { text: 'S beat', value: 1, color: AppColors.SVE_70 },
  V_BEAT: { text: 'V beat', value: 2, color: AppColors.VE_70 },
  QUESTIONABLE_BEAT: {
    text: 'Questionable beat',
    value: 3,
    color: AppColors.COOL_GRAY_80,
  },
};
export const BEAT_TYPE_LIST = Object.keys(BEAT_TYPE).map(
  (key) => BEAT_TYPE[key]
);

export const METRIC = {
  BEAT_HR: 'Beat HR',
  RRI: 'RRI',
};
export const METRIC_LIST = Object.keys(METRIC).map((key) => METRIC[key]);

export const ADJACENT_TO = {
  BEHIND_OF: '바로 뒤에',
  AHEAD_OF: '바로 앞에',
};
export const ADJACENT_TO_LIST = Object.keys(ADJACENT_TO).map(
  (key) => ADJACENT_TO[key]
);
