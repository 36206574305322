import { call, put, takeLatest } from 'redux-saga/effects';
import ApiManager from 'network/ApiManager';

// Actions
// Fetch explorer data
const FETCH_EXPLORER_DATA_REQUESTED =
  'memo-web/patch-ecgs/FETCH_EXPLORER_DATA_REQUESTED';
const FETCH_EXPLORER_DATA_SUCCEED =
  'memo-web/patch-ecgs/FETCH_EXPLORER_DATA_SUCCEED';
const FETCH_EXPLORER_DATA_FAILED =
  'memo-web/patch-ecgs/FETCH_EXPLORER_DATA_FAILED';
// Get Event Counts
const GET_EVENT_COUNTS_REQUESTED =
  'memo-web/patch-ecgs/GET_EVENT_COUNTS_REQUESTED';
const GET_EVENT_COUNTS_SUCCEED = 'memo-web/patch-ecgs/GET_EVENT_COUNTS_SUCCEED';
const GET_EVENT_COUNTS_FAILED = 'memo-web/patch-ecgs/GET_EVENT_COUNTS_FAILED';

// Reducer
const initialState = {
  explore: {
    pending: false,
    startTimestamp: null,
    endTimestamp: null,
    data: null,
    error: null,
  },
  counts: {
    pending: false,
    data: null,
    error: null,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    // Fetch explore data
    case FETCH_EXPLORER_DATA_REQUESTED:
      return {
        ...state,
        explore: {
          ...state.explore,
          pending: true,
          data: null,
          error: null,
        },
      };
    case FETCH_EXPLORER_DATA_SUCCEED:
      return {
        ...state,
        explore: {
          ...state.explore,
          pending: false,
          startTimestamp: action.startTimestamp,
          endTimestamp: action.endTimestamp,
          data: action.data,
        },
      };
    case FETCH_EXPLORER_DATA_FAILED:
      return {
        ...state,
        explore: {
          ...state.explore,
          pending: false,
          error: action.error,
        },
      };
    // Get Event Counts
    case GET_EVENT_COUNTS_REQUESTED:
      return {
        ...state,
        counts: {
          ...state.counts,
          pending: true,
          data: null,
          error: null,
        },
      };
    case GET_EVENT_COUNTS_SUCCEED:
      return {
        ...state,
        counts: {
          ...state.counts,
          pending: false,
          data: action.data,
        },
      };
    case GET_EVENT_COUNTS_FAILED:
      return {
        ...state,
        counts: {
          ...state.counts,
          pending: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}

// Action Creators
// Fetch explorer data
export function fetchExplorerDataRequested(
  ecgTestId,
  atTime,
  secStep,
  backward,
  forward
) {
  return {
    type: FETCH_EXPLORER_DATA_REQUESTED,
    ecgTestId: ecgTestId,
    atTime: atTime,
    secStep: secStep,
    backward: backward,
    forward: forward,
  };
}
export function fetchExplorerDataSucceed(startTimestamp, endTimestamp, data) {
  return {
    type: FETCH_EXPLORER_DATA_SUCCEED,
    startTimestamp: startTimestamp,
    endTimestamp: endTimestamp,
    data: data,
  };
}
export function fetchExplorerDataFailed(error) {
  return { type: FETCH_EXPLORER_DATA_FAILED, error: error };
}
// Get Event Counts
export function getEventCountsRequested(tid, callback) {
  return {
    type: GET_EVENT_COUNTS_REQUESTED,
    tid,
    callback,
  };
}
function getEventCountsSucceed(data) {
  return {
    type: GET_EVENT_COUNTS_SUCCEED,
    data,
  };
}
function getEventCountsFailed(error) {
  return { type: GET_EVENT_COUNTS_FAILED, error };
}

// Sagas
function* readExplorerData(action) {
  try {
    const { data } = yield call(
      ApiManager.readExplorerData,
      action.ecgTestId,
      action.atTime,
      action.secStep,
      action.backward,
      action.forward
    );
    const { ecgMeta, results } = data;

    const { startTimestamp, endTimestamp } = ecgMeta;
    yield put(fetchExplorerDataSucceed(startTimestamp, endTimestamp, results));
  } catch (error) {
    yield put(
      fetchExplorerDataFailed({
        name: error.name,
        status: error.status,
        message: error.message,
      })
    );
  }
}

function* getEventCounts(action) {
  try {
    const {
      data: { result },
    } = yield call(
      ApiManager.getEventCounts,
      { tid: action.tid },
      action.callback
    );

    yield put(getEventCountsSucceed(result));
  } catch (error) {
    yield put(
      getEventCountsFailed({
        name: error.name,
        status: error.status,
        message: error.message,
      })
    );
  }
}

export function* saga() {
  yield takeLatest(FETCH_EXPLORER_DATA_REQUESTED, readExplorerData);
  yield takeLatest(GET_EVENT_COUNTS_REQUESTED, getEventCounts);
}
