import { connect } from 'react-redux';
import { showDialog, hideDialog } from 'redux/duck/dialogDuck';
import { createEcgTestRequested } from 'redux/duck/ecgTestsDuck';
import AddNewTestDialog from 'component/dialog/AddNewTestDialog';

const mapStateToProps = (state, ownProps) => {
  return {
    ecgTestsCreateState: state.ecgTestsReducer.create,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    // Dialog
    showDialog: (dialogKey, params, callback) => {
      dispatch(showDialog(dialogKey, params, callback));
    },
    hideDialog: (dialogKey) => {
      dispatch(hideDialog(dialogKey));
    },
    // ECG Tests
    createEcgTest: (
      patientName,
      patientNumber,
      patientSex,
      patientPhoneNumber,
      patientBirth,
      testDuration,
      referredBy
    ) => {
      dispatch(
        createEcgTestRequested(
          patientName,
          patientNumber,
          patientSex,
          patientPhoneNumber,
          patientBirth,
          testDuration,
          referredBy
        )
      );
    },
  };
};

const AddNewTestDialogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewTestDialog);

export default AddNewTestDialogContainer;
