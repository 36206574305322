export const INFINITY_SCROLL = {
  TYPE: {
    INIT: 'INIT',
    INIT_EXTRA: 'INIT_EXTRA',
    INIT_EXTRA_BACKWARD: 'INIT_EXTRA_BACKWARD',
    INIT_EXTRA_FORWARD: 'INIT_EXTRA_FORWARD',
    BACKWARD: 'BACKWARD',
    FORWARD: 'FORWARD',
    TIME_JUMP: 'TIME_JUMP',
  },
  EXPLORER_UNIT_MS: {
    '5MIN': 5 * 60 * 1000, // = chart 10 line(300,000)
    '10MIN': 10 * 60 * 1000, // = chart 20 line(600,000)
    '20MIN': 20 * 60 * 1000, // = chart 40 line(1,200,000)
    OFFSET_ROW: 2 * 30 * 1000, // = chart 2 line
  },
  getExplorerUnitMs: (min) => {
    // return 10 * 60 * 1000;
    // return 5 * 60 * 1000;
    return min * 60 * 1000;
  },
};
