import { ECG_CHART_UNIT } from 'constant/ChartEditConst';

const HALF_TEN_SEC_WAVEFORM_LENGTH = ECG_CHART_UNIT.HALF_TEN_SEC_WAVEFORM_IDX;
const TEN_SEC_WAVEFORM_LENGTH = ECG_CHART_UNIT.TEN_SEC_WAVEFORM_IDX;

/**
 * 중심위치로부터 반경 5초인 총 10초 구간의 R-R HR 평균을 계산
 *
 * 10s Avg HR = (HR 합계)/(비트 수 - 1) ← 단, 첫번째 비트의 HR 및 R-R 제외
 *
 * reference MC-643
 * @param withExtraBeats 중심위치로부터 반경 6초(5초 + 1초) + a초 안의 Beats 데이터
 * @param centerWaveformIndex 중심위치 Waveform Index
 * @returns HR 계산이 불가능할 경우 null
 */
export const getTenSecAvgHrByCenter = (
  withExtraBeats: Beats,
  centerWaveformIndex: WaveformIndex
): number | null => {
  const fromWaveformIndex = centerWaveformIndex - HALF_TEN_SEC_WAVEFORM_LENGTH;
  const toWaveformIndex = centerWaveformIndex + HALF_TEN_SEC_WAVEFORM_LENGTH;

  return getTenSecAvgHrByFromTo(
    withExtraBeats,
    fromWaveformIndex,
    toWaveformIndex
  );
};

export const getTenSecAvgHrByFromTo = (
  withExtraBeats: Beats,
  fromWaveformIndex: WaveformIndex,
  toWaveformIndex: WaveformIndex
): number | null => {
  if (toWaveformIndex - fromWaveformIndex !== TEN_SEC_WAVEFORM_LENGTH) {
    debugger;
    return null;
  }

  const { waveformIndex, beatType, hr } = withExtraBeats;
  const beatCount = beatType.length;

  let hrList = Array<number>();
  for (let i = 0; i < beatCount; i++) {
    if (
      waveformIndex[i] < fromWaveformIndex ||
      toWaveformIndex <= waveformIndex[i]
    ) {
      continue;
    }
    hrList.push(hr[i]);
  }
  if (hrList.length < 2) return null;

  // 10초 구간의 첫 번째 Beat 의 R-R HR 은 계산에서 제외
  hrList = hrList.splice(1).filter((value) => typeof value === 'number');
  if (hrList.length === 0) return null;

  const avg = hrList.reduce((acc, cur) => acc + cur, 0) / hrList.length;
  // 소수점 자리 버림
  const result = Math.floor(avg);

  return result;
};

type Beats = {
  waveformIndex: Array<WaveformIndex>;
  beatType: Array<BeatType>;
  hr: Array<number>;
};
type BeatType = null | 0 | 1 | 2 | 3;
type WaveformIndex = number;
