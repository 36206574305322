import React, { useState } from 'react';
import styled from 'styled-components';

const Progress = styled.div`
  width: ${(props) => props.percentage}%;
  position: absolute;
  left: 0;
  background-color: ${(props) =>
    props.percentage < 100 ? props.inProgressColor : props.finishedColor};
  transition: all 0.2s ease-in-out;
`;

const Wrapper = styled.div`
  width: 100%;
  height: ${(props) => props.height}px;
  position: relative;
  background-color: #e6eaec;
  border-radius: ${(props) => props.borderRadius}px;
  ${Progress} {
    height: ${(props) => props.height}px;
    border-radius: ${(props) => props.borderRadius}px;
  }
`;

function FileUploadProgress(props) {
  return (
    <Wrapper height={props.height || 4} borderRadius={props.borderRadius || 4}>
      <Progress
        percentage={props.percentage || 0}
        inProgressColor={props.inProgressColor || '#3656ff'}
        finishedColor={props.finishedColor || '#21C7B7'}
      />
    </Wrapper>
  );
}

export default FileUploadProgress;
