import { useIntl } from 'react-intl';

import AlertDialog from 'component/dialog/AlertDialog';

// TODO: 준호 - API 에러 구조 변경에 따라 재수정 필요.
interface AlertNetworkDialogProps {
  open: boolean;
  params: {
    errorData: {
      error: {
        errorCode: string;
        errorMessage: string;
      };
    };
    shouldLogout?: boolean;
    showTitle?: boolean;
    showErrorCode?: boolean;
  };
  callback?: () => void;
  onClose: () => void;
  logout: () => void;
}

const AlertNetworkDialog = (props: AlertNetworkDialogProps) => {
  const intl = useIntl();

  const { open, params, callback, onClose, logout } = props;
  const { errorData, shouldLogout, showTitle = true, showErrorCode } = params;

  const message = (() => {
    let result;
    try {
      if (showErrorCode) {
        result = `${errorData.error.errorMessage}\n(Error Code : ${errorData.error.errorCode})`;
      } else {
        result = errorData.error.errorMessage;
      }
    } catch (error) {
      result = intl.formatMessage({
        id: '99-AlertNetworkDialog-AlertDialog-message-01',
        description: 'API 실패 응답 기본 안내',
        defaultMessage:
          '서버에 연결할 수 없습니다. 네트워크 상태를 확인해주세요.',
      });
    }
    return result;
  })();

  return (
    <AlertDialog
      open={open}
      params={{
        title: showTitle
          ? intl.formatMessage({
              id: '99-AlertNetworkDialog-AlertDialog-title-01',
              description: 'API 실패 응답 기본 안내 시 제공하는 고정된 제목',
              defaultMessage: '잘못된 요청입니다.',
            })
          : '',
        message,
      }}
      callback={callback}
      onClose={() => {
        onClose();
        if (shouldLogout) logout();
      }}
      zIndex={13}
    />
  );
};

export default AlertNetworkDialog;
