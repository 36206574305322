import {
  call,
  put,
  takeLatest,
  takeEvery,
  select,
  debounce,
} from 'redux-saga/effects';
import rfdc from 'rfdc';

import {
  CheckBoxAll,
  PoolingState,
  ShapeReviewSectionArea,
  ShapeReviewState,
  editType,
  shapeReviewFetchingOption,
} from 'constant/ShapeReviewConst';
import {
  EVENT_GROUP_TYPE,
  SIDE_PANEL_EVENT_GROUP,
  ECTOPIC_TYPE,
  EVENT_CONST_TYPES,
  SUFFIX_LIST,
} from 'constant/EventConst';
import {
  ECG_CHART_UNIT,
  POSITION_MOVE_TYPE,
  TEN_SEC_STRIP_EDIT,
  rawAndEventCalledCase,
} from 'constant/ChartEditConst';

import {
  findIndexOfBeatEventDetailInFromList,
  getBeDisplayedFormData,
  getIndexOfNextPooling,
  getIndexOfNextPoolingInPrevCase,
  getNewCurrentPageDependOnPoolingData,
} from 'util/reduxDuck/ShapeReviewDuckUtil';
import { unionArrays } from 'util/Utility';
import {
  getIsAllEventEdited,
  getIsIndeterminate,
} from 'util/shapeReview/shapeReviewUtil';

import ApiManager from 'network/ApiManager';
import { axiosSourceManager } from 'network/RestClient';

import { enqueueRequest } from './beatsRequestQueueDuck';
import {
  ArrangeRequiredStatus,
  BeatPostprocess,
  ClickedInfo,
  EventsPanelSelectedInfo,
  FormPanelSelectedInfo,
  PatchBeatByFormIds,
  PatchBeatByWaveFormIndexes,
  SelectedInfo,
  PanelSizeInfo,
  SelectAllInfo,
} from './shapeReview/shapeReviewDuckType';

const rfdcClone = rfdc();

// Constants
// Selector
// Actions
// etc function
// Reducer
// Action Creators
// Saga functions
// Saga

// Constants
const shapeReviewSidePanelEventsList =
  SIDE_PANEL_EVENT_GROUP[EVENT_GROUP_TYPE.SHAPE];
const ectopicType = ECTOPIC_TYPE;

/**
 * @typedef {Object} Beats
 * @property {number[]} waveformIndex - 비트의 웨이브폼 인덱스 배열
 * @property {number[]} beatType - 비트의 타입 배열
 * @property {number[]} hr - 비트의 심박수 배열
 */

/**
 * @typedef {Object} MainECG
 * @property {number[]} rawECG - 주요 ECG의 원시 ECG 배열
 * @property {number} onsetWaveformIndex - 시작 웨이브폼 인덱스
 * @property {number} terminationWaveformIndex - 종료 웨이브폼 인덱스
 * @property {number} onsetMs - 시작 시간(ms)
 * @property {number} terminationMs - 종료 시간(ms)
 */

/**
 * @typedef {Object} EctopicHR
 * @property {number|null} hrMin - 최소 이완심박수
 * @property {number|null} hrMax - 최대 이완심박수
 * @property {number|null} hrAvg - 평균 이완심박수
 */

/**
 * @typedef {Object} BeatEventDetail
 * @property {number} originWaveformIndex - 원본 웨이브폼 인덱스
 * @property {boolean} exists - 존재 여부
 * @property {number|null} rrHR - RR HR 값
 * @property {number} beatType - 비트 타입
 * @property {Beats} beats - 비트 정보
 * @property {number} sampleSize - 샘플 크기
 * @property {MainECG} mainECG - 주요 ECG 정보
 * @property {EctopicHR} ectopicHR - 이완심박수 정보
 */

/**
 * @typedef {Object} BeatEventDetailReturn
 * @property {BeatEventDetail} result - 결과 객체
 */

// Selector
const selectEcgTestId = (state) => state.testResultReducer.ecgTestId;

// shapeReviewReducer > poolingData
const selectPoolingDataOfEventPanelState = (state) =>
  state.shapeReviewReducer.poolingData[ShapeReviewSectionArea.EVENTS]
    .pauseState;
export const selectProgressPercentage = (state) =>
  state.shapeReviewReducer.poolingData[ShapeReviewSectionArea.EVENTS]
    .progressPercentage;

// shapeReviewReducer > shapeReviewState
export const selectActivePanel = (state) =>
  state.shapeReviewReducer.shapeReviewState[ShapeReviewState.ACTIVE_PANEL];
export const selectPaginationInfo: (state: any) => PanelSizeInfo = (state) =>
  state.shapeReviewReducer.shapeReviewState[ShapeReviewState.PAGINATION_INFO];
const selectPanelSizeInfo = (state) =>
  state.shapeReviewReducer.shapeReviewState[ShapeReviewState.PANEL_SIZE_INFO];
export const selectClickedInfo: (state: any) => ClickedInfo = (state) =>
  state.shapeReviewReducer.shapeReviewState[ShapeReviewState.CLICKED_INFO];
export const selectSelectedInfo: (state: any) => SelectedInfo = (state) =>
  state.shapeReviewReducer.shapeReviewState[ShapeReviewState.SELECTED_INFO];
export const selectSelectAllInfo: (state: any) => SelectAllInfo = (state) =>
  state.shapeReviewReducer.shapeReviewState[ShapeReviewState.SELECT_ALL];

// shapeReviewReducer > selectedInfo
export const selectSelectedInfoOfFormsPanel: (
  state: any
) => FormPanelSelectedInfo = (state) =>
  state.shapeReviewReducer.selectedInfo[ShapeReviewSectionArea.FORMS];
export const selectSelectedInfoOfEventsPanel: (
  state: any
) => EventsPanelSelectedInfo = (state) =>
  state.shapeReviewReducer.selectedInfo[ShapeReviewSectionArea.EVENTS];

// shapeReviewReducer > form, event panel에 보여질 데이터
export const selectFormDataOfSelectedEvent = (state) =>
  state.shapeReviewReducer.clusterFormDetail;
export const selectEventDataOfSelectedForm = (state) =>
  state.shapeReviewReducer.clusterEventDetail;

// shapeReviewReducer > 수정된 정보
export const selectPatchBeatByFormIds: (state: any) => PatchBeatByFormIds = (
  state
) => state.shapeReviewReducer.patchBeatByFormIds;
export const selectPatchBeatByWaveFormIndexes: (
  state: any
) => PatchBeatByWaveFormIndexes = (state) =>
  state.shapeReviewReducer.patchBeatByWaveFormIndexes;

// shapeReviewReducer > etc
export const selectTenSecStripDetail = (state) =>
  state.shapeReviewReducer.tenSecStripDetail;
export const selectArrangeRequiredStatus: (
  state: any
) => ArrangeRequiredStatus = (state) =>
  state.shapeReviewReducer.arrangeRequiredStatus;
export const selectBeatPostprocessState: (state: any) => BeatPostprocess = (
  state
) => state.shapeReviewReducer.beatPostprocess;

/**
 *
 * # shapeReviewState 정보
 * # focus, select chart 정보
 * # 선택된 정보 (이벤트 타입, 차트(focused, selected), 선택된 데이터 중 첫번째 데이터)
 * # pooling 조회 대상 list, pooling data
 * # edited data
 * # 기타 select
 */

/**
 * # shapeReviewState 정보
 *   - PAGINATION_INFO 정보
 *   - PANEL_SIZE_INFO 정보
 */
// - PAGINATION_INFO 정보
export const selectPanelPaginationInfo = (state, panelType) =>
  selectPaginationInfo(state)[panelType][selectClickedInfo(state).eventType];
export const selectFormPanelPaginationInfo = (state) =>
  selectPaginationInfo(state)[ShapeReviewSectionArea.FORMS][
    selectClickedInfo(state).eventType
  ];
export const selectEventPanelPaginationInfo = (state) =>
  selectPaginationInfo(state)[ShapeReviewSectionArea.EVENTS][
    selectClickedInfo(state).eventType
  ]?.[
    selectFormPanelDetailOfFirstIndexOfLastSelectedSection(state)?.formInfo.id
  ];

// - PANEL_SIZE_INFO 정보
const selectFormPanelSizeInfo = (state) =>
  state.shapeReviewReducer.shapeReviewState[ShapeReviewState.PANEL_SIZE_INFO][
    ShapeReviewSectionArea.FORMS
  ];
const selectEventPanelSizeInfo = (state) =>
  state.shapeReviewReducer.shapeReviewState[ShapeReviewState.PANEL_SIZE_INFO][
    ShapeReviewSectionArea.EVENTS
  ];

/*
 * # 선택된 정보 (이벤트 타입, 차트(focused, selected), 선택된 데이터 중 첫번째 데이터)
 *   1. 선택된 "이벤트타입" 정보
 *   2. 선택된 "차트(focused, selected)" 정보(lastSelectedSectionInfo, selectedItemList)
 *     - form panel의 선택된 차트 정보
 *     - event panel의 선택된 차트 정보
 *   3. 선택된 데이터 중 첫번째 데이터
 *     - Form panel에서 선택된 데이터 중 첫번째 데이터
 *     - Event panel에서 선택된 데이터 중 첫번째 데이터
 */
/**
 * 1. 선택된 "이벤트타입" 정보
 * */
export const selectSelectedEventType = (state) =>
  selectClickedInfo(state).eventType;

/**
 * 2. 선택된 "차트(focused, selected)" 정보(lastSelectedSectionInfo, selectedItemList)
 *   - form panel의 선택된 차트 정보
 *   - event panel의 선택된 차트 정보
 */
export const selectFirstSelectedInfoOfFormPanel = (state) =>
  selectSelectedInfoOfFormsPanel(state).lastSelectedSectionInfo[0];
export const selectFirstSelectedInfoOfEventPanel = (state) =>
  selectSelectedInfoOfEventsPanel(state).lastSelectedSectionInfo[0];

/**
 * 3. 선택된 데이터 중 첫번째 데이터
 *   - Form panel에서 선택된 데이터 중 첫번째 데이터
 *   - Event panel에서 선택된 데이터 중 첫번째 데이터
 */
export const selectFormPanelDetailOfFirstIndexOfLastSelectedSection = (state) =>
  selectFormDataOfSelectedEvent(state)[
    selectFirstSelectedInfoOfFormPanel(state)?.index
  ] || { formInfo: {} };
export const selectEventPanelDetailOfFirstIndexOfLastSelectedSection = (
  state
) =>
  selectEventDataOfSelectedForm(state)[
    selectFirstSelectedInfoOfEventPanel(state)?.index
  ];

/**
 * 4. 선택된 정보
 */
export const selectClickedSelectedInfoOfFormsPanel = (state) => {
  return state.shapeReviewReducer.shapeReviewState[
    ShapeReviewState.SELECTED_INFO
  ][ShapeReviewSectionArea.FORMS][selectClickedInfo(state).eventType];
};
export const selectClickedSelectedInfoOfEventPanel = (state) =>
  state.shapeReviewReducer.shapeReviewState[ShapeReviewState.SELECTED_INFO][
    ShapeReviewSectionArea.EVENTS
  ][selectFirstSelectedInfoOfFormPanel(state)?.index];

/**
 *  # pooling 조회 대상 list, pooling data
 *  ## 1. 선택된 이벤트의 form panel 정보
 *    - 1.1 form info list (선택된 event type의 form panel에서 조회될 대상)
 *    - 1.2 form pooling data (선택된 이벤트의 form panel event 정보)
 *
 *  ## 2. 선택된 forms의 event panel 정보
 *    - 2.1 event waveformIndexList list (선택된 form의 event panel에서 조회될 대상)
 *    - 2.2 event pooling data (선택된 form의 event panel)
 */
// # 1. 선택된 이벤트의 form panel 정보
//   - 1.1 form info list (선택된 event type의 form panel에서 조회될 대상)
export const selectFormListInfoOfSelectedEvent = (state) =>
  state.shapeReviewReducer.clusterFormInfoList.data[
    selectClickedInfo(state).eventType
  ];
//   - 1.2 form pooling data (선택된 이벤트의 form panel event 정보)
export const selectFormPoolingStateOfSelectedEvent = (state) => ({
  pending:
    state.shapeReviewReducer.poolingData[ShapeReviewSectionArea.FORMS].pending,
  calledType:
    state.shapeReviewReducer.poolingData[ShapeReviewSectionArea.FORMS]
      .calledType,
});
export const selectFormPoolingDataOfSelectedEvent = (state) =>
  state.shapeReviewReducer.poolingData[ShapeReviewSectionArea.FORMS].data[
    selectClickedInfo(state).eventType
  ];
// # 2. 선택된 forms의 event panel 정보
//   - 2.1 event waveformIndexList list (선택된 form의 event panel에서 조회될 대상)
const selectWaveformIndexListInfoOfSelectedForm = (state) =>
  state.shapeReviewReducer.waveformIndexListInfoOfForms.data[
    selectClickedInfo(state).eventType
  ][selectFormPanelDetailOfFirstIndexOfLastSelectedSection(state)?.formInfo.id];
//   - 2.2 event pooling data (선택된 form의 event panel)
export const selectEventPoolingStateOfSelectedEvent = (state) => {
  const eventPanelPoolingDataState =
    state.shapeReviewReducer.poolingData[ShapeReviewSectionArea.EVENTS];
  return {
    pending: eventPanelPoolingDataState.pending,
    calledType: eventPanelPoolingDataState.calledType,
    pauseState: eventPanelPoolingDataState.pauseState,
  };
};
const selectEventPoolingDataOfSelectedForm = (state) => {
  return state.shapeReviewReducer.poolingData[ShapeReviewSectionArea.EVENTS]
    .data[selectClickedInfo(state).eventType]?.[
    selectFormPanelDetailOfFirstIndexOfLastSelectedSection(state)?.formInfo.id
  ];
};

// # 기타 select
/**
 * # edited data
 * - 선택한 event의 수정된 form list
 * - 선택한 form의 수정된 event list
 */
export const editedFormListByClickedEventType = (state) =>
  state.shapeReviewReducer.patchBeatByFormIds.patchedList[
    selectSelectedEventType(state)
  ];
export const editedEventListByClickedForm = (state) =>
  state.shapeReviewReducer.patchBeatByWaveFormIndexes.patchedList[
    selectSelectedEventType(state)
  ]?.[
    selectFormPanelDetailOfFirstIndexOfLastSelectedSection(state)?.formInfo.id
  ] || [];
export const selectFormTotalCount = (state) => {
  const { totalFormsCount = 0 } =
    selectFormListInfoOfSelectedEvent(state) || {};
  const totalEventsCount = selectNumberOfClickedEventsType(state) ?? 0;

  return {
    totalEventsCount,
    totalFormsCount,
  };
};

export const selectNumberOfClickedForms = (state) =>
  [...selectSelectedInfoOfFormsPanel(state).selectedItemList].length;
export const selectNumberOfClickedEvents = (state) =>
  [...selectSelectedInfoOfEventsPanel(state).selectedItemList].length;
/**
 * @returns {boolean} form panel이 전체 선택 되어 있는 케이스
 */
export const selectIsFormPanelCheckedAll = (state) =>
  state.shapeReviewReducer.shapeReviewState[ShapeReviewState.SELECT_ALL][
    ShapeReviewSectionArea.FORMS
  ] === CheckBoxAll.CheckedAll;
/**
 * @returns {boolean} event panel이 전체 선택 되어 있는 케이스
 */
export const selectIsEventsPanelCheckedAll = (state) =>
  state.shapeReviewReducer.shapeReviewState[ShapeReviewState.SELECT_ALL][
    ShapeReviewSectionArea.EVENTS
  ] === CheckBoxAll.CheckedAll;
/**
 * @returns {boolean} 클릭한 이벤트타입이 Couplet인지에 대한 여부
 */
export const selectIsCoupletEctopicTypeClicked = (state) =>
  selectClickedInfo(state)?.ectopicType === ectopicType.COUPLET;
/**
 * @returns {boolean} 클릭한 Form이  한개이고, Edited 인 상태인지에 대한 여부
 */
export const selectIsClickedFormEdited = (state) => {
  const selectedFormsInfo = selectSelectedInfoOfFormsPanel(state);
  const formPanelDetailOfFirstIndexOfLastSelectedSection =
    selectFormPanelDetailOfFirstIndexOfLastSelectedSection(state);

  if (!formPanelDetailOfFirstIndexOfLastSelectedSection) return;

  const firstIndexOfLastSelectedSectionInfoFormId =
    formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id;

  const selectEditedFormListByClickedEventType =
    editedFormListByClickedEventType(state);
  const selectedItemListLength = [...selectedFormsInfo.selectedItemList].length;

  return (
    selectEditedFormListByClickedEventType?.includes(
      firstIndexOfLastSelectedSectionInfoFormId
    ) && selectedItemListLength === 1
  );
};
export const selectIsClickedEventEdited = (state) => {
  const eventPanelDetailOfFirstIndexOfLastSelectedSection =
    selectEventPanelDetailOfFirstIndexOfLastSelectedSection(state);

  if (!eventPanelDetailOfFirstIndexOfLastSelectedSection) return;

  const originIndexOfEventPanelDetailOfFirstIndexOfLastSelectedSection =
    eventPanelDetailOfFirstIndexOfLastSelectedSection.originWaveformIndex;

  const selectedEventsInfo = selectSelectedInfoOfEventsPanel(state);
  const selectEditedEventListByClickedForm =
    editedEventListByClickedForm(state);
  const selectedItemListLength = [...selectedEventsInfo.selectedItemList]
    .length;

  return (
    selectEditedEventListByClickedForm === editType.ALL ||
    (selectEditedEventListByClickedForm?.includes(
      originIndexOfEventPanelDetailOfFirstIndexOfLastSelectedSection
    ) &&
      selectedItemListLength === 1)
  );
};
/**
 * @returns {boolean} Form을 선택했는지에 대한 여부 (단일/복수 구분 X)
 */
export const selectIsSelectedForms = (state) => {
  const activePanel = selectActivePanel(state);
  const { FORMS: selectAllInfoOfForms } = selectSelectAllInfo(state);

  return (
    selectAllInfoOfForms !== CheckBoxAll.None &&
    activePanel === ShapeReviewSectionArea.FORMS
  );
};
/**
 * @returns {boolean} Events를 선택했는지에 대한 여부 (단일/복수 구분 X)
 */
export const selectIsSelectedEvents = (state) => {
  const activePanel = selectActivePanel(state);
  const { EVENTS: selectAllInfoOfEvents } = selectSelectAllInfo(state);

  return (
    selectAllInfoOfEvents !== CheckBoxAll.None &&
    activePanel === ShapeReviewSectionArea.EVENTS
  );
};
/**
 * @returns {boolean} Form을 단일 선택했는지에 대한 여부
 */
export const selectIsMultiSelectedForms = (state) => {
  const selectedInfoOfFormsPanel = selectSelectedInfoOfFormsPanel(state);
  const selectedItemListLength = [...selectedInfoOfFormsPanel.selectedItemList]
    .length;

  return selectedItemListLength > 1 && selectedItemListLength !== 0;
};
/**
 * @returns {boolean} Event를 복수 선택했는지에 대한 여부
 */
export const selectIsMultiSelectedEvents = (state) => {
  const selectedInfoOfEventsPanel = selectSelectedInfoOfEventsPanel(state);
  const selectedItemListLength = [...selectedInfoOfEventsPanel.selectedItemList]
    .length;

  return selectedItemListLength > 1 && selectedItemListLength !== 0;
};
/**
 * @returns {number} 선택한 Event Type의 총 비트 수
 */
export const selectNumberOfClickedEventsType = (state) => {
  const clusteringStatistics =
    state.shapeReviewReducer.clusteringStatistics.data;
  const clickedEventType = selectClickedInfo(state)?.eventType;

  const filteredEvent = shapeReviewSidePanelEventsList.find(
    (v) => v.type === clickedEventType
  );
  const eventSection = filteredEvent?.eventSection;
  const numberOfClickedEventsType = clusteringStatistics[eventSection];

  return numberOfClickedEventsType;
};
/**
 * @returns  {number[]} 선택한 Events의 [가운데 비트의 이전 Bpm, 가운데 비트 Bpm,가운데 비트의 다음 Bpm]
 */
export const selectSurroundingBeatHrBpm = (state) => {
  const firstSelectedInfoOfEventPanel =
    selectFirstSelectedInfoOfEventPanel(state);

  if (!firstSelectedInfoOfEventPanel) return ['N/A', 'N/A', 'N/A'];

  const eventPanelDetailOfFirstIndexOfLastSelectedSection =
    selectEventPanelDetailOfFirstIndexOfLastSelectedSection(state);

  if (!eventPanelDetailOfFirstIndexOfLastSelectedSection)
    return ['N/A', 'N/A', 'N/A'];

  const waveformIndex =
    eventPanelDetailOfFirstIndexOfLastSelectedSection.beats.waveformIndex;
  const originWaveformIndex =
    eventPanelDetailOfFirstIndexOfLastSelectedSection.originWaveformIndex;
  const indexOfCenterWaveformIndex = waveformIndex?.findIndex(
    (v) => v === originWaveformIndex
  );

  const prevHrBpm =
    eventPanelDetailOfFirstIndexOfLastSelectedSection.beats.hr[
      indexOfCenterWaveformIndex - 1
    ];
  const beatHrBpm =
    eventPanelDetailOfFirstIndexOfLastSelectedSection.beats.hr[
      indexOfCenterWaveformIndex
    ];
  const nextHrBpm =
    eventPanelDetailOfFirstIndexOfLastSelectedSection.beats.hr[
      indexOfCenterWaveformIndex + 1
    ];

  return [prevHrBpm, beatHrBpm, nextHrBpm].map((bpm) =>
    isNaN(bpm) || bpm === null || bpm === undefined ? 'N/A' : Math.floor(bpm)
  );
};
/**
 * @returns {number} 선택한 체크박스의 개수
 */
export const selectNumberOfChecked = (state) => {
  const isSelectedForms = selectIsSelectedForms(state);
  const isSelectedEvents = selectIsSelectedEvents(state);
  const activePanel = selectActivePanel(state);

  if (!isSelectedForms && !isSelectedEvents) return;

  const isCoupletEctopicTypeClicked = selectIsCoupletEctopicTypeClicked(state);

  let numberOfChecked = 0;
  if (
    (!isSelectedEvents && isSelectedForms) ||
    (isSelectedEvents && isSelectedEvents && activePanel === 'FORMS')
  ) {
    const selectedInfo = selectFilterSelectedItemListOfFormPanel(state) || {};
    numberOfChecked += sumBeatCount(selectedInfo.onset);

    if (isCoupletEctopicTypeClicked) {
      numberOfChecked += sumBeatCount(selectedInfo.termination);
    }

    return numberOfChecked;
  }

  const selectedInfo = selectSelectedInfoOfEventsPanel(state) || {};
  const selectedItemListArray = [...selectedInfo.selectedItemList];
  selectedItemListArray.forEach(([, v]) => {
    if (isCoupletEctopicTypeClicked) {
      numberOfChecked += v.checkbox.filter(Boolean).length;
    } else numberOfChecked += Number(v.checkbox[0]);
  });

  return numberOfChecked;

  function sumBeatCount(list = []) {
    return list.reduce((sum, item) => sum + item.formInfo.beatCount, 0);
  }
};

export const selectFilterSelectedItemListOfFormPanel = (state) => {
  const clickedInfo = selectClickedInfo(state);
  const formDataOfSelectedEvent = selectFormDataOfSelectedEvent(state);
  const { selectedItemList: selectedItemListOfFormPanel } =
    selectSelectedInfoOfFormsPanel(state);
  const filterSelectedItemList = formDataOfSelectedEvent.filter((_, i) =>
    [...selectedItemListOfFormPanel.keys()].includes(i)
  );

  let onset = [];
  let termination = [];
  let rst = { filterSelectedItemList, onset, termination: [] };
  for (let filterSelectedItem of filterSelectedItemList) {
    const checkBoxOfFilterSelectedItem = selectedItemListOfFormPanel.get(
      filterSelectedItem.index
    )?.checkbox;

    if (!Array.isArray(checkBoxOfFilterSelectedItem)) return rst;

    if (checkBoxOfFilterSelectedItem[0] === true) {
      onset.push(filterSelectedItem);
    }

    if (
      checkBoxOfFilterSelectedItem[1] === true &&
      selectClickedInfo(state).ectopicType === ECTOPIC_TYPE.COUPLET
    ) {
      termination.push(filterSelectedItem);
    }
  }

  if (clickedInfo.ectopicType === ECTOPIC_TYPE.COUPLET) {
    rst.termination = termination;
  }

  return rst;
};
export const selectFilterSelectedItemListOfEventPanel = (state) => {
  const clickedInfo = selectClickedInfo(state);
  const eventDataOfSelectedForm = selectEventDataOfSelectedForm(state);
  const { selectedItemList: selectedItemListOfEventPanel } =
    selectSelectedInfoOfEventsPanel(state);
  const filterSelectedItemList = eventDataOfSelectedForm.filter((_, i) =>
    [...selectedItemListOfEventPanel.keys()].includes(i)
  );

  let onset = [];
  let termination = [];
  let rst = { filterSelectedItemList, onset, termination: [] };
  for (let filterSelectedItem of filterSelectedItemList) {
    const checkBoxOfFilterSelectedItem = selectedItemListOfEventPanel.get(
      filterSelectedItem.index
    )?.checkbox;

    if (!Array.isArray(checkBoxOfFilterSelectedItem)) return rst;

    if (checkBoxOfFilterSelectedItem[0] === true) {
      onset.push(filterSelectedItem);
    }

    if (
      checkBoxOfFilterSelectedItem[1] === true &&
      selectClickedInfo(state).ectopicType === ECTOPIC_TYPE.COUPLET
    ) {
      termination.push(filterSelectedItem);
    }
  }

  if (clickedInfo.ectopicType === ECTOPIC_TYPE.COUPLET) {
    rst.termination = termination;
  }
  return rst;
};

export const selectCheckExistTargetPageData =
  ({ activePanelType, setPageType }) =>
  (state) => {
    let result;
    const selectedEventType = selectSelectedEventType(state);
    const formPanelDetailOfFirstIndexOfLastSelectedSection =
      selectFormPanelDetailOfFirstIndexOfLastSelectedSection(state);
    const clickedEventPaginationInfo =
      selectPaginationInfo(state)[activePanelType][selectedEventType];
    const clickedEventPanelSizeInfo =
      selectPanelSizeInfo(state)[activePanelType];

    if (!clickedEventPaginationInfo) return;

    let clickedEventCurrentPage, clickedTargetPoolingData;
    if (activePanelType === ShapeReviewSectionArea.FORMS) {
      clickedEventCurrentPage = clickedEventPaginationInfo.currentPage;
      clickedTargetPoolingData =
        state.shapeReviewReducer.poolingData[activePanelType].data[
          selectedEventType
        ];
    } else if (activePanelType === ShapeReviewSectionArea.EVENTS) {
      clickedEventCurrentPage =
        clickedEventPaginationInfo?.[
          formPanelDetailOfFirstIndexOfLastSelectedSection?.formInfo.id
        ]?.currentPage;
      clickedTargetPoolingData =
        state.shapeReviewReducer.poolingData[activePanelType].data[
          selectedEventType
        ][formPanelDetailOfFirstIndexOfLastSelectedSection?.formInfo.id];
    }

    let newCurrenPage;
    let waveFormIndexOfNextPageFirstItemIndex;
    if (setPageType === POSITION_MOVE_TYPE.PREV) {
      newCurrenPage = clickedEventCurrentPage - 1;
      if (newCurrenPage < 1) {
        newCurrenPage = 1;
      }
    } else if (setPageType === POSITION_MOVE_TYPE.NEXT) {
      newCurrenPage = clickedEventCurrentPage + 1;
      const nextPageFirstItemIndex =
        clickedEventPanelSizeInfo.pageSize * (newCurrenPage - 1);
      if (activePanelType === ShapeReviewSectionArea.FORMS) {
        waveFormIndexOfNextPageFirstItemIndex =
          state.shapeReviewReducer.clusterFormInfoList.data[selectedEventType]
            .formsThumbNailWaveFormIndex[nextPageFirstItemIndex];
      } else if (activePanelType === ShapeReviewSectionArea.EVENTS) {
        waveFormIndexOfNextPageFirstItemIndex =
          state.shapeReviewReducer.waveformIndexListInfoOfForms.data[
            selectedEventType
          ][formPanelDetailOfFirstIndexOfLastSelectedSection.id]
            .onsetWaveformIndexListOfForm[nextPageFirstItemIndex];
      }
    }
    result = clickedTargetPoolingData.get(
      waveFormIndexOfNextPageFirstItemIndex
    );
    return !!result;
  };

// Action
// # SET
const RESET_SHAPE_REVIEW_STATE = 'shape-review/RESET_SHAPE_REVIEW_STATE';
const SET_PANEL_SIZE = 'shape-review/SET_PANEL_SIZE';
const SET_SELECT_EVENT = 'shape-review/SET_SELECT_EVENT';

const SET_PAGE = 'shape-review/SET_PAGE';
const SET_PAGINATION = 'shape-review/SET_PAGINATION';
const SET_CLUSTERING_STATISTICS = 'shape-review/SET_CLUSTERING_STATISTICS';
const SET_TENSEC_STRIP_DETAIL = 'shape-review/SET_TENSEC_STRIP_DETAIL';
const RESET_TENSEC_STRIP_DETAIL = 'shape-review/RESET_TENSEC_STRIP_DETAIL';

// ## SET - 선택 관련
const SET_SELECT_ALL = 'shape-review/SET_SELECT_ALL';
const SET_ACTIVE_PANEL = 'shape-review/SET_ACTIVE_PANEL';
const SET_LAST_SELECTED_SECTION_INFO =
  'shape-review/SET_LAST_SELECTED_SECTION_INFO';
const SET_SELECTED_ITEM_LIST = 'shape-review/SET_SELECTED_ITEM_LIST';

// ## SET - form panel에 보여질 데이터
const SET_FORM_DATA = 'shape-review/SET_FORM_DATA';
const SET_EVENT_DATA = 'shape-review/SET_EVENT_DATA';

/**
 * # api action list
 * ## api - fetching(clustering statistics)
 * ## api - fetching(form list)
 * ## api - fetching(form detail pooling Data)
 * ## api - fetching(event detail - raw and event)
 *
 * ## api - fetching(waveformIndexList of forms)
 * ## api - fetching(pooling data of event of form)
 *
 * ## api - patch beat update by formId
 * ## api - patch beat update by waveformIndex
 */
// ## api - fetching(clustering statistics)
const GET_CLUSTERING_STATISTICS_REQUESTED =
  'shape-review/GET_CLUSTERING_STATISTICS_REQUESTED';
const GET_CLUSTERING_STATISTICS_SUCCEED =
  'shape-review/GET_CLUSTERING_STATISTICS_SUCCEED';
const GET_CLUSTERING_STATISTICS_FAILED =
  'shape-review/GET_CLUSTERING_STATISTICS_FAILED';
// ## api - fetching(form list)
const GET_FORM_LIST_REQUESTED = 'shape-review/GET_FORM_LIST_REQUESTED';
const GET_FORM_LIST_SUCCEED = 'shape-review/GET_FORM_LIST_SUCCEED';
const GET_FORM_LIST_FAILED = 'shape-review/GET_FORM_LIST_FAILED';
// ## api - fetching(form detail pooling Data)
const GET_FORM_DETAIL_POOLING_DATA_REQUESTED =
  'shape-review/GET_FORM_DETAIL_POOLING_DATA_REQUESTED';
const GET_FORM_DETAIL_POOLING_DATA_SUCCEED =
  'shape-review/GET_FORM_DETAIL_POOLING_DATA_SUCCEED';
const GET_FORM_DETAIL_POOLING_DATA_FAILED =
  'shape-review/GET_FORM_DETAIL_POOLING_DATA_FAILED';
// ## api - fetching(form detail pooling Data - event detail(raw and event)
const GET_RAW_AND_EVENT_REQUESTED = 'shape-review/GET_RAW_AND_EVENT_REQUESTED';
const GET_RAW_AND_EVENT_SUCCEED = 'shape-review/GET_RAW_AND_EVENT_SUCCEED';
const GET_RAW_AND_EVENT_FAILED = 'shape-review/GET_RAW_AND_EVENT_FAILED';

// ## api - fetching(waveformIndexList of forms)
const DEBOUNCE_GET_WAVEFORM_INDEX_LIST_OF_FORM_REQUESTED =
  'shape-review/DEBOUNCE_GET_WAVEFORM_INDEX_LIST_OF_FORM_REQUESTED';
const GET_WAVEFORM_INDEX_LIST_OF_FORM_REQUESTED =
  'shape-review/GET_WAVEFORM_INDEX_LIST_OF_FORM_REQUESTED';
const GET_WAVEFORM_INDEX_LIST_OF_FORM_SUCCEED =
  'shape-review/GET_WAVEFORM_INDEX_LIST_OF_FORM_SUCCEED';
const GET_WAVEFORM_INDEX_LIST_OF_FORM_FAILED =
  'shape-review/GET_WAVEFORM_INDEX_LIST_OF_FORM_FAILED';
// ## api - fetching(pooling data of event of form)
const GET_POOLING_DATA_OF_EVENT_OF_FORM_REQUESTED =
  'shape-review/GET_POOLING_DATA_OF_EVENT_OF_FORM_REQUESTED';
const GET_POOLING_DATA_OF_EVENT_OF_FORM_SUCCEED =
  'shape-review/GET_POOLING_DATA_OF_EVENT_OF_FORM_SUCCEED';
const GET_POOLING_DATA_OF_EVENT_OF_FORM_FAILED =
  'shape-review/GET_POOLING_DATA_OF_EVENT_OF_FORM_FAILED';
const SET_PROGRESS_PERCENTAGE_POOLING_EVENT_LIST =
  'shape-review/SET_PROGRESS_PERCENTAGE_POOLING_EVENT_LIST';
const SET_PAUSE_POOLING_EVENT_LIST =
  'shape-review/SET_PAUSE_POOLING_EVENT_LIST';
const SET_FINISH_POOLING_EVENT_LIST =
  'shape-review/SET_FINISH_POOLING_EVENT_LIST';

// ## api - patch beat 방법1 (by formIds)
const PATCH_BEATS_BY_FORM_ID_REQUESTED =
  'shape-review/PATCH_BEATS_BY_FORM_ID_REQUESTED';
const PATCH_BEATS_BY_FORM_ID_SUCCEED =
  'shape-review/PATCH_BEATS_BY_FORM_ID_SUCCEED';
const PATCH_BEATS_BY_FORM_ID_FAILED = 'shape-review/PATCH_BEATS_FAILED';
// ## api - patch beat 방법2 (by waveformIndex)
const PATCH_BEATS_BY_WAVEFORM_INDEX_REQUESTED =
  'shape-review/PATCH_BEATS_BY_WAVEFORM_INDEX_REQUESTED';
const PATCH_BEATS_BY_WAVEFORM_INDEX_SUCCEED =
  'shape-review/PATCH_BEATS_BY_WAVEFORM_INDEX_SUCCEED';
const PATCH_BEATS_BY_WAVEFORM_INDEX_FAILED =
  'shape-review/PATCH_BEATS_BY_WAVEFORM_INDEX_FAILED';

// Beat Postprocess
const PATCH_BEAT_POSTPROCESS_REQUESTED =
  'shape-review/PATCH_BEAT_POSTPROCESS_REQUESTED';
const PATCH_BEAT_POSTPROCESS_SUCCEED =
  'shape-review/PATCH_BEAT_POSTPROCESS_SUCCEED';
const PATCH_BEAT_POSTPROCESS_FAILED =
  'shape-review/PATCH_BEAT_POSTPROCESS_FAILED';

// Arrange - (event-statistic) 재계산 + (clustering) 재정렬
const GET_ARRANGE_REQUIRED_STATUS_REQUESTED =
  'shape-review/GET_ARRANGE_REQUIRED_STATUS_REQUESTED';
const GET_ARRANGE_REQUIRED_STATUS_SUCCEED =
  'shape-review/GET_ARRANGE_REQUIRED_STATUS_SUCCEED';
const GET_ARRANGE_REQUIRED_STATUS_FAILED =
  'shape-review/GET_ARRANGE_REQUIRED_STATUS_FAILED';
const SET_IS_ARRANGE_REQUIRED = 'shape-review/SET_IS_ARRANGE_REQUIRED';

// Caliper
const SET_CALIPER_PLOT_LINES = 'shape-review/SET_CALIPER_PLOT_LINES';
const SET_IS_CALIPER_MODE = 'shape-review/SET_IS_CALIPER_MODE';
const SET_IS_TICK_MARKS_MODE = 'shape-review/SET_IS_TICK_MARKS_MODE';

/**
 * @typedef {Object} ShapeReviewEventType
 * @property {formsListObject} EVENT-TYPE-ISOLATE-2
 * @property {formsListObject} EVENT-TYPE-COUPLET-2
 * @property {formsListObject} EVENT-TYPE-ISOLATE-1
 * @property {formsListObject} EVENT-TYPE-COUPLET-1
 */

/**
 * @typedef {Object} formsListObject
 * @property {formsObject[]} forms
 * @property {WaveformIndex[]} formsThumbNailWaveFormIndex
 * @property {number} totalEditedBeatCount
 * @property {number} totalFormsCount
 */

/**
 * @typedef {Object} formsObject
 * @property {number} id
 * @property {number} beatCount
 * @property {number} editedBeatCount
 * @property {number} formBeatType
 * @property {string} formEctopicType
 * @property {number} formRankNumber
 * @property {number} representativeWaveformIndex
 */

/**
 * @typedef {Number} WaveformIndex Waveform Index
 */

/**
 * @typedef {Object} FormList
 * @property {BeatType} beatType
 * @property {EctopicType} ectopicType
 * @property {EventSection} eventSection
 * @property {string} label
 * @property {number} qty
 * @property {BeatReviewEvent} type // EventConstTypes에서 filter된 data
 */

/**
 * @type {FormList[]}
 */

let initFormPanelPagination = {};
let initEventPanelPagination = {};

let initFormPanelSelectedInfo = {};
let initEventPanelSelectedInfo = {};
/**
 * @type {ShapeReviewEventType}
 */
let initClusterFormInfoList = {};
let initWaveformIndexListInfoOfForm = {};

let initClusterFormPoolingData = {};
let initEventOfFormPoolingData = {};
for (const shapeReviewEvent of shapeReviewSidePanelEventsList) {
  const shapeReviewEventType = shapeReviewEvent.type;
  initFormPanelPagination[shapeReviewEventType] = {
    eventType: null,
    panelType: null,
    totalItemCount: null,
    pageSize: null,
    totalPageSize: null,
    currentPage: 1,
  };
  // type => [form.id]:{eventType:undefined,panelType:undefined,formId:undefined,totalItemCount:undefined,pageSize:undefined,totalPageSize:undefined,currentPage:1,};
  initEventPanelPagination[shapeReviewEventType] = {};
  initClusterFormInfoList[shapeReviewEventType] = {
    forms: [],
    formsThumbNailWaveFormIndex: [],
    totalEditedBeatCount: 0,
    totalFormsCount: 0,
  };
  // type: [shapeReviewEventType]: { [formsId]: {onsetWaveformIndexListOfForm: number[], totalBeatCount: number}}
  initWaveformIndexListInfoOfForm[shapeReviewEventType] = {};

  // type: [shapeReviewEventType]: { lastSelectedSectionInfo, selectedItemList }
  initFormPanelSelectedInfo[shapeReviewEventType] = {};
  // type: [shapeReviewEventType]: { [formsId]: { lastSelectedSectionInfo, selectedItemList }}
  initEventPanelSelectedInfo[shapeReviewEventType] = {};

  // pooling data
  initClusterFormPoolingData[shapeReviewEventType] = new Map(); // [shapeReviewEventType]: new Map() }
  initEventOfFormPoolingData[shapeReviewEventType] = {}; // [shapeReviewEventType]: { [formsId]: new Map() }
} // end of for

const initSelectedInfo = {
  [ShapeReviewSectionArea.FORMS]: {
    lastSelectedSectionInfo: [null, null],
    selectedItemList: new Map(),
  },
  [ShapeReviewSectionArea.EVENTS]: {
    lastSelectedSectionInfo: [null, null],
    selectedItemList: new Map(),
  },
};

// 데이터 기준(화면 기준 아님)
const initialState = {
  clusteringStatistics: {
    data: {},
    pending: false,
    error: null,
  },
  shapeReviewState: {
    [ShapeReviewState.ACTIVE_PANEL]: ShapeReviewSectionArea.FORMS, // SectionAreaEnum.EVENTS
    [ShapeReviewState.CLICKED_INFO]: {
      eventType: null, // BeatReviewEvent (EVENT-TYPE-ISOLATE-2 | EVENT-TYPE-COUPLET-2 | EVENT-TYPE-ISOLATE-1 | EVENT-TYPE-COUPLET-1)
      beatType: null, // BeatType
      ectopicType: null, // EctopicType
    },
    [ShapeReviewState.PANEL_SIZE_INFO]: {
      [ShapeReviewSectionArea.FORMS]: {
        columnNumber: null,
        rowNumber: null,
        pageSize: null,
      },
      [ShapeReviewSectionArea.EVENTS]: {
        columnNumber: null,
        rowNumber: null,
        pageSize: null,
      },
    },
    [ShapeReviewState.PAGINATION_INFO]: {
      [ShapeReviewSectionArea.FORMS]: initFormPanelPagination,
      [ShapeReviewSectionArea.EVENTS]: initEventPanelPagination,
    },
    [ShapeReviewState.SELECTED_INFO]: {
      [ShapeReviewSectionArea.FORMS]: initFormPanelSelectedInfo,
      [ShapeReviewSectionArea.EVENTS]: initEventPanelSelectedInfo,
    },
    [ShapeReviewState.SELECT_ALL]: {
      [ShapeReviewSectionArea.FORMS]: '', // enum CheckBoxAll
      [ShapeReviewSectionArea.EVENTS]: '', // enum CheckBoxAll
    },
  },
  selectedInfo: initSelectedInfo,
  // Info List (form, event of form)
  clusterFormInfoList: {
    data: initClusterFormInfoList,
    pending: false,
    error: null,
  },
  waveformIndexListInfoOfForms: {
    data: initWaveformIndexListInfoOfForm,
    pending: false,
    error: null,
  },
  // pooling data(form, event of form)
  poolingData: {
    [ShapeReviewSectionArea.FORMS]: {
      data: initClusterFormPoolingData,
      pending: false,
      calledType: '',
      error: null,
    },
    [ShapeReviewSectionArea.EVENTS]: {
      data: initEventOfFormPoolingData,
      pending: false,
      pauseState: false,
      progressPercentage: 0,
      calledType: '',
      error: null,
    },
  },
  // data to be displayed
  clusterFormDetail: [],
  clusterEventDetail: [],
  // patch beat by form ids(edited label에 사용)
  patchBeatByFormIds: {
    patchedList: {
      [EVENT_CONST_TYPES.ISO_VPC]: [], // 'EVENT-TYPE-ISOLATE-1'
      [EVENT_CONST_TYPES.COUPLET_VPC]: [], // 'EVENT-TYPE-COUPLET-1'
      [EVENT_CONST_TYPES.ISO_APC]: [], // 'EVENT-TYPE-ISOLATE-2'
      [EVENT_CONST_TYPES.COUPLET_APC]: [], // 'EVENT-TYPE-COUPLET-2'
    },
    pending: false,
    error: null,
  },
  // patch beat by waveformIndex list(edited label에 사용)
  patchBeatByWaveFormIndexes: {
    patchedList: {
      // * [number: chartId]: number[] | editType.ALL('ALL'),
      //   - 'ALL': form에서 전체 선택 이후 편집 했을 경우
      [EVENT_CONST_TYPES.ISO_VPC]: {}, // 'EVENT-TYPE-ISOLATE-1'
      [EVENT_CONST_TYPES.COUPLET_VPC]: {}, // 'EVENT-TYPE-COUPLET-1'
      [EVENT_CONST_TYPES.ISO_APC]: {}, // 'EVENT-TYPE-ISOLATE-2'
      [EVENT_CONST_TYPES.COUPLET_APC]: {}, // 'EVENT-TYPE-COUPLET-2'
    },
    pending: false,
    error: null,
    responseValidationResult: {
      succeedWaveformIndexes: [],
      failedWaveformIndexes: [],
    },
  },
  tenSecStripDetail: {
    onsetMs: null,
    terminationMs: null,
    onsetWaveformIdx: null,
    terminationWaveformIdx: null,
    hrAvg: null,
    ecgRaw: [],
    beatLabelButtonDataList: null,
    beatsOrigin: {},
    responseValidationResult: {
      requestAt: null,
      validResult: null,
      editTargetBeatType: null,
    },
    pending: false,
    error: null,
  },
  // patch beat update by waveformIndexes
  caliper: {
    caliperPlotLines: [],
    isCaliperMode: false,
    isTickMarksMode: false,
  },
  // Beat Postprocess
  beatPostprocess: {
    pending: false,
    error: null,
    data: {
      beatPostprocessedMs: null,
    },
  },
  //  Arrange Status
  arrangeRequiredStatus: {
    pending: false,
    error: null,
    isArrangeRequired: false,
  },
};

// Reducer
const getClickedEventType = (shapeReviewReducer) =>
  shapeReviewReducer.shapeReviewState[ShapeReviewState.CLICKED_INFO].eventType;
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_SHAPE_REVIEW_STATE: {
      const updatedState = action.updatedState;
      return {
        ...initialState,
        ...updatedState,
      };
    }
    case SET_PANEL_SIZE: {
      return {
        ...state,
        shapeReviewState: {
          ...state.shapeReviewState,
          [ShapeReviewState.PANEL_SIZE_INFO]: {
            ...state.shapeReviewState[ShapeReviewState.PANEL_SIZE_INFO],
            [action.panelType]: {
              columnNumber: action.columnNumber,
              rowNumber: action.rowNumber,
              pageSize: action.columnNumber * action.rowNumber,
            },
          },
        },
      };
    }
    case SET_SELECT_EVENT: {
      let initSelectedInfoState;
      if (!!action?.selectedFormPanel) {
        initSelectedInfoState = {
          ...state.selectedInfo,
          [ShapeReviewSectionArea.FORMS]: action.selectedFormPanel,
        };
      } else {
        initSelectedInfoState = initSelectedInfo;
      }

      return {
        ...state,
        shapeReviewState: {
          ...state.shapeReviewState,
          [ShapeReviewState.CLICKED_INFO]: {
            ...state.shapeReviewState[ShapeReviewState.CLICKED_INFO],
            eventType: action.eventType,
            beatType: action.beatType,
            ectopicType: action.ectopicType,
          },
        },
        selectedInfo: { ...initSelectedInfoState },
        tenSecStripDetail: { ...initialState.tenSecStripDetail },
      };
    }

    case SET_PAGE: {
      const clickedEventType = selectSelectedEventType({
        shapeReviewReducer: state,
      });
      const formPanelDetailOfFirstIndexOfLastSelectedSection =
        selectFormPanelDetailOfFirstIndexOfLastSelectedSection({
          shapeReviewReducer: state,
        });
      const clickedEventPaginationInfo = selectPaginationInfo({
        shapeReviewReducer: state,
      })[action.panelType][clickedEventType];
      const clickedEventPanelSizeInfo = selectPanelSizeInfo({
        shapeReviewReducer: state,
      })[action.panelType];

      let clickedEventCurrentPage, clickedTargetPoolingData;
      const poolingDataOfClickedPanel =
        state.poolingData[action.panelType].data[clickedEventType];
      if (action.panelType === ShapeReviewSectionArea.FORMS) {
        clickedEventCurrentPage = clickedEventPaginationInfo.currentPage;
        clickedTargetPoolingData = poolingDataOfClickedPanel;
      } else if (action.panelType === ShapeReviewSectionArea.EVENTS) {
        clickedEventCurrentPage =
          clickedEventPaginationInfo[
            formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id
          ].currentPage;
        clickedTargetPoolingData =
          poolingDataOfClickedPanel[
            formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id
          ];
      }

      let newCurrenPage;
      if (action.setPageType === POSITION_MOVE_TYPE.PREV) {
        newCurrenPage = clickedEventCurrentPage - 1;

        newCurrenPage = getNewCurrentPageDependOnPoolingData({
          clickedEventPanelSizeInfo,
          newCurrenPage,
          action,
          state,
          clickedEventType,
          formPanelDetailOfFirstIndexOfLastSelectedSection,
          clickedTargetPoolingData,
          clickedEventCurrentPage,
        });

        if (newCurrenPage < 1) newCurrenPage = 1;
      } else if (action.setPageType === POSITION_MOVE_TYPE.NEXT) {
        newCurrenPage = clickedEventCurrentPage + 1;

        newCurrenPage = getNewCurrentPageDependOnPoolingData({
          clickedEventPanelSizeInfo,
          newCurrenPage,
          action,
          state,
          clickedEventType,
          formPanelDetailOfFirstIndexOfLastSelectedSection,
          clickedTargetPoolingData,
          clickedEventCurrentPage,
        });

        if (newCurrenPage > clickedEventPaginationInfo.totalPageSize) {
          newCurrenPage = clickedEventPaginationInfo.totalPageSize;
        }
      } else if (action.setPageType === POSITION_MOVE_TYPE.JUMP) {
        newCurrenPage = action.value;
      }

      let setPageStateResult;
      let setSelectedInfoResult;
      const paginationInfoState =
        state.shapeReviewState[ShapeReviewState.PAGINATION_INFO];
      if (action.panelType === ShapeReviewSectionArea.FORMS) {
        setSelectedInfoResult = {
          [ShapeReviewSectionArea.FORMS]: {
            lastSelectedSectionInfo: [undefined, undefined],
            selectedItemList: new Map(),
          },
        };
        setPageStateResult = {
          ...paginationInfoState,
          [action.panelType]: {
            ...paginationInfoState[action.panelType],
            [clickedEventType]: {
              ...paginationInfoState[action.panelType][clickedEventType],
              currentPage: Number(newCurrenPage),
            },
          },
        };
      } else if (action.panelType === ShapeReviewSectionArea.EVENTS) {
        setSelectedInfoResult = {
          [ShapeReviewSectionArea.EVENTS]: {
            lastSelectedSectionInfo: [undefined, undefined],
            selectedItemList: new Map(),
          },
        };
        setPageStateResult = {
          ...paginationInfoState,
          [action.panelType]: {
            ...paginationInfoState[action.panelType],
            [clickedEventType]: {
              ...paginationInfoState[action.panelType][clickedEventType],
              [formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id]: {
                ...paginationInfoState[action.panelType][clickedEventType][
                  formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id
                ],
                currentPage: Number(newCurrenPage),
              },
            },
          },
        };
      }

      return {
        ...state,
        shapeReviewState: {
          ...state.shapeReviewState,
          [ShapeReviewState.PAGINATION_INFO]: setPageStateResult,
        },
        selectedInfo: { ...state.selectedInfo, ...setSelectedInfoResult },
      }; // end of SET_PAGE
    }
    case SET_PAGINATION: {
      let setPaginationResult;
      if (action.panelType === ShapeReviewSectionArea.FORMS) {
        setPaginationResult = {
          [action.panelType]: {
            ...state.shapeReviewState[ShapeReviewState.PAGINATION_INFO][
              action.panelType
            ],
            [action.eventType]: {
              eventType: action.eventType,
              panelType: action.panelType,
              totalItemCount: action.totalItemCount,
              totalPageSize: action.totalPageSize,
              currentPage: Number(action.currentPage),
            },
          },
        };
      } else if (action.panelType === ShapeReviewSectionArea.EVENTS) {
        setPaginationResult = {
          [action.panelType]: {
            ...state.shapeReviewState[ShapeReviewState.PAGINATION_INFO][
              action.panelType
            ],
            [action.eventType]: {
              ...state.shapeReviewState[ShapeReviewState.PAGINATION_INFO][
                action.panelType
              ][action.eventType],
              [action.formId]: {
                eventType: action.eventType,
                panelType: action.panelType,
                totalItemCount: action.totalItemCount,
                totalPageSize: action.totalPageSize,
                currentPage: Number(action.currentPage),
              },
            },
          },
        };
      }
      return {
        ...state,
        shapeReviewState: {
          ...state.shapeReviewState,
          [ShapeReviewState.PAGINATION_INFO]: {
            ...state.shapeReviewState[ShapeReviewState.PAGINATION_INFO],
            ...setPaginationResult,
          },
        },
      };
    }
    case SET_TENSEC_STRIP_DETAIL: {
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: false,
          ...action.data,
        },
      };
    }
    case RESET_TENSEC_STRIP_DETAIL: {
      return {
        ...state,
        tenSecStripDetail: { ...initialState.tenSecStripDetail },
      };
    }
    case SET_SELECT_ALL: {
      return {
        ...state,
        shapeReviewState: {
          ...state.shapeReviewState,
          [ShapeReviewState.SELECT_ALL]: {
            ...state.shapeReviewState[ShapeReviewState.SELECT_ALL],
            [action.panelType]: action.selectAllState,
          },
        },
      };
    }
    case SET_ACTIVE_PANEL: {
      return {
        ...state,
        shapeReviewState: {
          ...state.shapeReviewState,
          [ShapeReviewState.ACTIVE_PANEL]: action.activePanel,
        },
      };
    }
    case SET_LAST_SELECTED_SECTION_INFO: {
      const clickedEventType = getClickedEventType(state);

      let updateSetLastSelectedSectionInfoState;
      if (action.panelType === ShapeReviewSectionArea.FORMS) {
        updateSetLastSelectedSectionInfoState = {
          ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO],
          // FORMS, EVENTS
          [action.panelType]: {
            ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO][
              action.panelType
            ],
            [clickedEventType]: {
              ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO][
                action.panelType
              ][clickedEventType],
              lastSelectedSectionInfo: action.lastSelectedSectionInfo,
            },
          },
        };
      } else if (action.panelType === ShapeReviewSectionArea.EVENTS) {
        const formPanelDetailOfFirstIndexOfLastSelectedSection =
          selectFormPanelDetailOfFirstIndexOfLastSelectedSection({
            shapeReviewReducer: state,
          });
        const selectedFormId =
          formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id;

        if (!selectedFormId) {
          updateSetLastSelectedSectionInfoState = {
            ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO],
          };
        } else {
          updateSetLastSelectedSectionInfoState = {
            ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO],
            // FORMS, EVENTS
            [action.panelType]: {
              ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO][
                action.panelType
              ],
              [clickedEventType]: {
                ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO][
                  action.panelType
                ][clickedEventType],
                [selectedFormId]: {
                  ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO][
                    action.panelType
                  ][clickedEventType][selectedFormId],
                  lastSelectedSectionInfo: action.lastSelectedSectionInfo,
                },
              },
            },
          };
        }
      }

      return {
        ...state,
        shapeReviewState: {
          ...state.shapeReviewState,
          [ShapeReviewState.SELECTED_INFO]: {
            ...updateSetLastSelectedSectionInfoState,
          },
        },
        selectedInfo: {
          ...state.selectedInfo,
          [action.panelType]: {
            ...state.selectedInfo[action.panelType],
            lastSelectedSectionInfo: action.lastSelectedSectionInfo,
          },
        },
      };
    }
    case SET_SELECTED_ITEM_LIST: {
      const clickedEventType = getClickedEventType(state);

      let updateSelectAllState;
      let updateSetSelectedItemState;

      if (action.panelType === ShapeReviewSectionArea.FORMS) {
        const isIndeterminate = getIsIndeterminate({
          selectedItemList: [...action.selectedItemList],
          panelList: state.clusterFormDetail,
        });

        updateSelectAllState = {
          ...state.shapeReviewState[ShapeReviewState.SELECT_ALL],
          [action.panelType]: isIndeterminate
            ? CheckBoxAll.Indeterminate
            : state.shapeReviewState[ShapeReviewState.SELECT_ALL][
                action.panelType
              ],
        };

        updateSetSelectedItemState = {
          ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO],
          // FORMS, EVENTS
          [action.panelType]: {
            ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO][
              action.panelType
            ],
            [clickedEventType]: {
              ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO][
                action.panelType
              ][clickedEventType],
              selectedItemList: action.selectedItemList,
            },
          },
        };
      } else if (action.panelType === ShapeReviewSectionArea.EVENTS) {
        const isIndeterminate = getIsIndeterminate({
          selectedItemList: [...action.selectedItemList],
          panelList: state.clusterEventDetail,
        });

        updateSelectAllState = {
          ...state.shapeReviewState[ShapeReviewState.SELECT_ALL],
          [action.panelType]: isIndeterminate
            ? CheckBoxAll.Indeterminate
            : state.shapeReviewState[ShapeReviewState.SELECT_ALL][
                action.panelType
              ],
        };

        const formPanelDetailOfFirstIndexOfLastSelectedSection =
          selectFormPanelDetailOfFirstIndexOfLastSelectedSection({
            shapeReviewReducer: state,
          });
        const selectedFormId =
          formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id;

        if (!selectedFormId) {
          updateSetSelectedItemState = {
            ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO],
          };
        } else {
          updateSetSelectedItemState = {
            ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO],
            // FORMS, EVENTS
            [action.panelType]: {
              ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO][
                action.panelType
              ],
              [clickedEventType]: {
                ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO][
                  action.panelType
                ][clickedEventType],
                [selectedFormId]: {
                  ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO][
                    action.panelType
                  ][clickedEventType][selectedFormId],
                  selectedItemList: action.selectedItemList,
                },
              },
            },
          };
        }
      }

      return {
        ...state,
        shapeReviewState: {
          ...state.shapeReviewState,
          [ShapeReviewState.SELECT_ALL]: {
            ...updateSelectAllState,
          },
          [ShapeReviewState.SELECTED_INFO]: {
            ...updateSetSelectedItemState,
          },
        },
        selectedInfo: {
          ...state.selectedInfo,
          [action.panelType]: {
            ...state.selectedInfo[action.panelType],
            selectedItemList: action.selectedItemList,
          },
        },
      };
    }
    case SET_FORM_DATA: {
      return {
        ...state,
        clusterFormDetail: action.formData,
      };
    }
    case SET_EVENT_DATA: {
      return {
        ...state,
        clusterEventDetail: action.eventData,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.EVENTS]: {
            ...state.poolingData[ShapeReviewSectionArea.EVENTS],
            pending: false,
            pauseState: false,
          },
        },
      };
    }

    // # api
    // ## api - fetching(clustering statistics)
    case GET_CLUSTERING_STATISTICS_REQUESTED: {
      return {
        ...state,
        clusteringStatistics: {
          ...state.clusteringStatistics,
          pending: true,
          error: null,
        },
      };
    }
    case GET_CLUSTERING_STATISTICS_SUCCEED: {
      return {
        ...state,
        clusteringStatistics: {
          ...state.clusteringStatistics,
          data: action.data,
          pending: false,
          error: null,
        },
      };
    }
    case GET_CLUSTERING_STATISTICS_FAILED: {
      return {
        ...state,
        clusteringStatistics: {
          ...state.clusteringStatistics,
          pending: false,
          error: action.error,
        },
      };
    }
    // ## api - fetching(form list)
    case GET_FORM_LIST_REQUESTED: {
      return {
        ...state,
        clusterFormInfoList: {
          ...state.clusterFormInfoList,
          pending: true,
        },
        clusterFormDetail: [],
      };
    }
    case GET_FORM_LIST_SUCCEED: {
      const clickedEventType = getClickedEventType(state);

      return {
        ...state,
        clusterFormInfoList: {
          ...state.clusterFormInfoList,
          pending: false,
          data: {
            ...state.clusterFormInfoList.data,
            [state.shapeReviewState[ShapeReviewState.CLICKED_INFO].eventType]: {
              forms: action.forms.map((v, i) => {
                // v.formIndex = i;
                return v;
              }),
              formsThumbNailWaveFormIndex: action.formsThumbNailWaveFormIndex,
              totalEditedBeatCount: action.totalEditedBeatCount,
              totalFormsCount: action.totalFormsCount,
            },
          },
        },
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.FORMS]: {
            ...state.poolingData[ShapeReviewSectionArea.FORMS],
            data: {
              ...state.poolingData[ShapeReviewSectionArea.FORMS].data,
              [clickedEventType]: new Map(
                ...[
                  action.formsThumbNailWaveFormIndex.map((v) => [v, undefined]),
                ]
              ),
            },
          },
        },
      };
    }
    case GET_FORM_LIST_FAILED: {
      return {
        ...state,
        clusterFormInfoList: {
          ...state.clusterFormInfoList,
          pending: false,
          error: action.error,
        },
      };
    }
    // ## api - fetching(form detail pooling Data)
    case GET_FORM_DETAIL_POOLING_DATA_REQUESTED: {
      return {
        ...state,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.FORMS]: {
            ...state.poolingData[ShapeReviewSectionArea.FORMS],
            pending: true,
          },
        },
      };
    }
    case GET_FORM_DETAIL_POOLING_DATA_SUCCEED: {
      const clickedEventType = getClickedEventType(state);
      const serverStoredEditedFormIdList = [
        ...action.mergeFormAndEventDetailInfo.values(),
      ]
        .filter((v) => v?.formInfo.isEdited)
        .map((v) => v.formInfo.id);
      return {
        ...state,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.FORMS]: {
            ...state.poolingData[ShapeReviewSectionArea.FORMS],
            pending: false,
            data: {
              ...state.poolingData[ShapeReviewSectionArea.FORMS].data,
              [clickedEventType]: new Map([
                ...state.poolingData[ShapeReviewSectionArea.FORMS].data[
                  clickedEventType
                ],
                ...action.mergeFormAndEventDetailInfo,
              ]),
            },
          },
        },
        patchBeatByFormIds: {
          ...state.patchBeatByFormIds,
          patchedList: {
            ...state.patchBeatByFormIds.patchedList,
            [clickedEventType]: [
              ...state.patchBeatByFormIds.patchedList[clickedEventType],
              ...serverStoredEditedFormIdList,
            ],
          },
        },
      };
    }
    case GET_FORM_DETAIL_POOLING_DATA_FAILED: {
      return {
        ...state,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.FORMS]: {
            ...state.poolingData[ShapeReviewSectionArea.FORMS],
            pending: false,
          },
        },
      };
    }
    // ## api - fetching(form detail pooling Data - event detail(raw and event)
    case GET_RAW_AND_EVENT_REQUESTED: {
      const clickedEventType = getClickedEventType(state);
      const fetchingTargetOfWaveformIndexList = state.clusterFormInfoList.data[
        clickedEventType
      ].formsThumbNailWaveFormIndex.slice(
        action.sliceInfoOfPooling.begin,
        action.sliceInfoOfPooling.end
      );
      const clickedEventPoolingMap =
        state.poolingData[ShapeReviewSectionArea.FORMS].data[clickedEventType];
      for (let poolingItem of clickedEventPoolingMap) {
        if (fetchingTargetOfWaveformIndexList.includes(poolingItem[0])) {
          clickedEventPoolingMap.set(poolingItem[0], PoolingState.PENDING);
        }
      }
      return {
        ...state,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.FORMS]: {
            ...state.poolingData[ShapeReviewSectionArea.FORMS],
            pending: true,
            calledType: action.calledType,
            data: {
              ...state.poolingData[ShapeReviewSectionArea.FORMS].data,
              [clickedEventType]: new Map([...clickedEventPoolingMap]),
            },
          },
        },
      };
    }
    case GET_RAW_AND_EVENT_SUCCEED: {
      return {
        ...state,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.FORMS]: {
            ...state.poolingData[ShapeReviewSectionArea.FORMS],
            pending: false,
            calledType: '',
          },
        },
      };
    }
    case GET_RAW_AND_EVENT_FAILED: {
      return {
        ...state,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.FORMS]: {
            ...state.poolingData[ShapeReviewSectionArea.FORMS],
            pending: false,
            calledType: '',
            error: action.error,
          },
        },
      };
    }

    // ## api - fetching(waveformIndexList of forms)
    case DEBOUNCE_GET_WAVEFORM_INDEX_LIST_OF_FORM_REQUESTED:
    case GET_WAVEFORM_INDEX_LIST_OF_FORM_REQUESTED: {
      return {
        ...state,
        waveformIndexListInfoOfForms: {
          ...state.waveformIndexListInfoOfForms,
          pending: true,
        },
        clusterEventDetail: [], //
        selectedInfo: {
          ...state.selectedInfo,
          [ShapeReviewSectionArea.EVENTS]: {
            ...initSelectedInfo[ShapeReviewSectionArea.EVENTS],
          },
        },
        tenSecStripDetail: initialState.tenSecStripDetail,
      };
    }
    case GET_WAVEFORM_INDEX_LIST_OF_FORM_SUCCEED: {
      const clickedEventType = getClickedEventType(state);
      const formPanelDetailOfFirstIndexOfLastSelectedSection =
        selectFormPanelDetailOfFirstIndexOfLastSelectedSection({
          shapeReviewReducer: state,
        });

      return {
        ...state,
        waveformIndexListInfoOfForms: {
          ...state.waveformIndexListInfoOfForms,
          data: {
            ...state.waveformIndexListInfoOfForms.data,
            [clickedEventType]: {
              ...state.waveformIndexListInfoOfForms.data[clickedEventType],
              [formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id]: {
                onsetWaveformIndexListOfForm: action.onsetWaveformIndexesOfForm,
                totalBeatCount: action.totalBeatCount,
              },
            },
          },
          pending: false,
        },
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.EVENTS]: {
            ...state.poolingData[ShapeReviewSectionArea.EVENTS],
            data: {
              ...state.poolingData[ShapeReviewSectionArea.EVENTS].data,
              [clickedEventType]: {
                ...state.poolingData[ShapeReviewSectionArea.EVENTS].data[
                  clickedEventType
                ],
                [formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id]:
                  new Map(
                    ...[
                      action.onsetWaveformIndexesOfForm.map((v) => [
                        v,
                        undefined,
                      ]),
                    ]
                  ),
              },
            },
            pending: false,
          },
        },
      };
    }
    case GET_WAVEFORM_INDEX_LIST_OF_FORM_FAILED: {
      return {
        ...state,
        waveformIndexListInfoOfForms: {
          ...state.waveformIndexListInfoOfForms,
          pending: false,
          error: action.error,
        },
      };
    }
    // ## api - fetching(pooling data of event of form)
    case GET_POOLING_DATA_OF_EVENT_OF_FORM_REQUESTED: {
      const clickedEventType = getClickedEventType(state);

      const onsetWaveformIndexListOfSelectedForm =
        state.waveformIndexListInfoOfForms.data[clickedEventType][action.formId]
          .onsetWaveformIndexListOfForm;
      const clickedEventOfFormPoolingMap =
        state.poolingData[ShapeReviewSectionArea.EVENTS].data[clickedEventType][
          action.formId
        ];
      const hasPoolingDataMap = [...clickedEventOfFormPoolingMap].filter(
        (v) => v[1] !== undefined
      );

      return {
        ...state,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.EVENTS]: {
            ...state.poolingData[ShapeReviewSectionArea.EVENTS],
            pending: true,
            pauseState: false,
            progressPercentage: Math.round(
              ([...hasPoolingDataMap].length /
                onsetWaveformIndexListOfSelectedForm.length) *
                100
            ),
            calledType: action.calledType,
            data: {
              ...state.poolingData[ShapeReviewSectionArea.EVENTS].data,
              [clickedEventType]: {
                ...state.poolingData[ShapeReviewSectionArea.EVENTS].data[
                  clickedEventType
                ],
                [action.formId]: new Map([...clickedEventOfFormPoolingMap]),
              },
            },
          },
        },
      };
    }
    case GET_POOLING_DATA_OF_EVENT_OF_FORM_SUCCEED: {
      const { poolingDataMap, formId } = action;
      const clickedEventType = getClickedEventType(state);
      const isCoupletEctopicTypeClicked = selectIsCoupletEctopicTypeClicked({
        shapeReviewReducer: {
          ...state,
        },
      });

      const serverStoredPatchEventListOfClickedForm = _getEditedWaveformIndexes(
        poolingDataMap,
        isCoupletEctopicTypeClicked
      );
      const allEvents = [...poolingDataMap.values()];
      const isAllEventOfClickedFormEdited =
        allEvents.filter(Boolean).length ===
        serverStoredPatchEventListOfClickedForm.length;

      const updatedEventData = new Map([
        ...state.poolingData[ShapeReviewSectionArea.EVENTS].data[
          clickedEventType
        ][formId],
        ...poolingDataMap,
      ]);

      return {
        ...state,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.EVENTS]: {
            ...state.poolingData[ShapeReviewSectionArea.EVENTS],
            data: {
              ...state.poolingData[ShapeReviewSectionArea.EVENTS].data,
              [clickedEventType]: {
                ...state.poolingData[ShapeReviewSectionArea.EVENTS].data[
                  clickedEventType
                ],
                [formId]: updatedEventData,
              },
            },
          },
        },
        patchBeatByWaveFormIndexes: {
          ...state.patchBeatByWaveFormIndexes,
          patchedList: {
            ...state.patchBeatByWaveFormIndexes.patchedList,
            [clickedEventType]: {
              ...state.patchBeatByWaveFormIndexes.patchedList[clickedEventType],
              [formId]: isAllEventOfClickedFormEdited
                ? editType.ALL
                : serverStoredPatchEventListOfClickedForm,
            },
          },
        },
      };
      function _getEditedWaveformIndexes(dataMap, isCoupletEctopicTypeClicked) {
        // 수정된 WI만 추출하는 함수
        return [...dataMap.values()]
          .filter((v) => {
            const centerWI = v.beats.waveformIndex.indexOf(
              v.originWaveformIndex
            );

            // lastModifierId 가 -1 이거나 0일 경우는 수정이 안된것으로 판단합니다.
            const isCenterWIEdited = ![-1, 0].includes(
              v.beats.lastModifierId[centerWI]
            );
            const isNextToCenterWIEdited = ![-1, 0].includes(
              v.beats.lastModifierId[centerWI + 1]
            );

            return isCoupletEctopicTypeClicked
              ? isCenterWIEdited || isNextToCenterWIEdited
              : isCenterWIEdited;
          })
          .map((v) => v.originWaveformIndex);
      }
    }
    case GET_POOLING_DATA_OF_EVENT_OF_FORM_FAILED: {
      return {
        ...state,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.EVENTS]: {
            ...state.poolingData[ShapeReviewSectionArea.EVENTS],
            pending: false,
            calledType: '',
          },
        },
      };
    }
    case SET_PROGRESS_PERCENTAGE_POOLING_EVENT_LIST: {
      return {
        ...state,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.EVENTS]: {
            ...state.poolingData[ShapeReviewSectionArea.EVENTS],
            progressPercentage: action.progressPercentage,
          },
        },
      };
    }
    case SET_PAUSE_POOLING_EVENT_LIST: {
      return {
        ...state,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.EVENTS]: {
            ...state.poolingData[ShapeReviewSectionArea.EVENTS],
            pending: !action.state,
            pauseState: action.state,
            calledType: '',
          },
        },
      };
    }
    case SET_FINISH_POOLING_EVENT_LIST: {
      return {
        ...state,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.EVENTS]: {
            ...state.poolingData[ShapeReviewSectionArea.EVENTS],
            pending: false,
            pauseState: false,
            progressPercentage: 0,
            calledType: '',
          },
        },
      };
    }

    // ## api - patch beat 방법1 (by formIds)
    case PATCH_BEATS_BY_FORM_ID_REQUESTED: {
      return {
        ...state,
        patchBeatByFormIds: {
          ...state.patchBeatByFormIds,
          pending: true,
        },
      };
    }
    case PATCH_BEATS_BY_FORM_ID_SUCCEED: {
      // form 선택이후 event update 시 form, event관련 데이터 모두 업데이트 해줘야 합니다.
      const {
        reqBody: { beatType: reqBeatType, onsetFormIds, terminationFormIds },
        filterSelectedItemList,
        succeedWaveformIndexList,
      } = action;
      const clickedEventType = getClickedEventType(state);
      const millisecondTime = new Date().getTime();

      const updatedEventPoolingData = {};
      const updatedPatchBeatByFormIds = [];
      const updatedPatchBeatByWaveFormIndexes = {};

      const selectedFormId =
        state.clusterFormDetail[
          state.selectedInfo.FORMS.lastSelectedSectionInfo[0]?.index
        ]?.formInfo.id;

      for (let filterSelectItem of filterSelectedItemList) {
        const formId = filterSelectItem.formInfo.id;
        updatedPatchBeatByFormIds.push(formId);

        if (selectedFormId !== formId) {
          updatedPatchBeatByWaveFormIndexes[formId] = editType.ALL;
        }

        const poolingEventOfClickedForm =
          state.poolingData[ShapeReviewSectionArea.EVENTS].data[
            clickedEventType
          ][formId];

        if (!poolingEventOfClickedForm) continue;

        const updatedPoolingDataOfClickedForm = [
          ...poolingEventOfClickedForm,
        ].map((poolingDataItem) => {
          if (!poolingDataItem[1]) return poolingDataItem;

          const poolingData = poolingDataItem[1];
          updatePoolingBeatType({
            poolingData,
            succeedWaveformIndexList,
            reqBeatType,
            formId,
          });

          return poolingDataItem;
        });

        const updatePoolingDataMap = new Map(updatedPoolingDataOfClickedForm);

        updatedEventPoolingData[formId] = updatePoolingDataMap;
      }

      // redux state(clusterEventDetail) update
      let updateEditTypeOfClusterEventDetail = [];

      if (isFormIdInList(onsetFormIds)) {
        updateEditTypeOfClusterEventDetail.push(editType.ONSET);
      }

      if (isFormIdInList(terminationFormIds)) {
        updateEditTypeOfClusterEventDetail.push(editType.TERMINATION);
      }

      return {
        ...state,
        patchBeatByFormIds: {
          ...state.patchBeatByFormIds,
          pending: false,
          patchedList: {
            ...state.patchBeatByFormIds.patchedList,
            [clickedEventType]: [
              ...new Set([
                ...state.patchBeatByFormIds.patchedList[clickedEventType],
                ...updatedPatchBeatByFormIds,
              ]),
            ],
          },
        },
        patchBeatByWaveFormIndexes: {
          ...state.patchBeatByWaveFormIndexes,
          patchedList: {
            ...state.patchBeatByWaveFormIndexes.patchedList,
            [clickedEventType]: {
              ...state.patchBeatByWaveFormIndexes.patchedList[clickedEventType],
              ...updatedPatchBeatByWaveFormIndexes,
            },
          },
        },
        clusterFormDetail: [
          ...state.clusterFormDetail.map((formDetail) => {
            const isIncludeReqOnsetFormIdInSelectItem = onsetFormIds.includes(
              formDetail.formInfo.id
            );
            const isIncludeReqTerminationFormIdInSelectItem =
              terminationFormIds.includes(formDetail.formInfo.id);

            if (isIncludeReqOnsetFormIdInSelectItem) {
              const indexOfOnsetOriginWaveformIndex =
                formDetail.beats.waveformIndex.indexOf(
                  formDetail.originWaveformIndex
                );
              formDetail.beatType = reqBeatType;
              formDetail.beats.beatType[indexOfOnsetOriginWaveformIndex] =
                reqBeatType;
            }

            if (isIncludeReqTerminationFormIdInSelectItem) {
              const indexOfTerminationOriginWaveformIndex =
                formDetail.beats.waveformIndex.indexOf(
                  formDetail.originWaveformIndex
                ) + 1;
              formDetail.beats.beatType[indexOfTerminationOriginWaveformIndex] =
                reqBeatType;
            }
            formDetail.millisecondTime = millisecondTime;

            return formDetail;
          }),
        ],
        clusterEventDetail: [
          ...state.clusterEventDetail.map((eventDetail) => {
            if (updateEditTypeOfClusterEventDetail.includes(editType.ONSET)) {
              if (
                !succeedWaveformIndexList.includes(
                  eventDetail.originWaveformIndex
                )
              )
                return eventDetail;

              const originWaveformIndex =
                eventDetail.beats.waveformIndex.indexOf(
                  eventDetail.originWaveformIndex
                );
              eventDetail.beats.beatType[originWaveformIndex] = reqBeatType;
            }
            if (
              updateEditTypeOfClusterEventDetail.includes(editType.TERMINATION)
            ) {
              if (
                !succeedWaveformIndexList.includes(
                  eventDetail.beats.waveformIndex[
                    eventDetail.beats.waveformIndex.indexOf(
                      eventDetail.originWaveformIndex
                    ) + 1
                  ]
                )
              )
                return eventDetail;

              const originWaveformIndex =
                eventDetail.beats.waveformIndex.indexOf(
                  eventDetail.originWaveformIndex
                ) + 1;
              eventDetail.beats.beatType[originWaveformIndex] = reqBeatType;
            }

            eventDetail.beatType = reqBeatType;
            eventDetail.millisecondTime = millisecondTime;

            return eventDetail;
          }),
        ],
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.EVENTS]: {
            ...state.poolingData[ShapeReviewSectionArea.EVENTS],
            data: {
              ...state.poolingData[ShapeReviewSectionArea.EVENTS].data,
              [clickedEventType]: {
                ...state.poolingData[ShapeReviewSectionArea.EVENTS].data[
                  clickedEventType
                ],
                ...updatedEventPoolingData,
              },
            },
          },
        },
      };

      // funcs
      function updatePoolingBeatType({
        poolingData,
        succeedWaveformIndexList,
        reqBeatType,
        formId,
      }) {
        const indexOfOnsetOriginWaveformIndex =
          poolingData.beats.waveformIndex.indexOf(
            poolingData.originWaveformIndex
          );
        const indexOfTerminationOriginWaveformIndex =
          indexOfOnsetOriginWaveformIndex + 1;

        if (
          succeedWaveformIndexList.includes(poolingData.originWaveformIndex)
        ) {
          poolingData.beatType = reqBeatType;
          poolingData.beats.beatType[indexOfOnsetOriginWaveformIndex] =
            reqBeatType;
          updatedPatchBeatByWaveFormIndexes[formId] = [
            ...new Set(
              [
                ...(updatedPatchBeatByWaveFormIndexes?.[formId] || []),
                poolingData.originWaveformIndex,
              ].filter(Number)
            ),
          ];
        }

        if (
          succeedWaveformIndexList.includes(
            poolingData.beats.waveformIndex[
              indexOfTerminationOriginWaveformIndex
            ]
          )
        ) {
          poolingData.beatType = reqBeatType;
          poolingData.beats.beatType[indexOfTerminationOriginWaveformIndex] =
            reqBeatType;
          updatedPatchBeatByWaveFormIndexes[formId] = [
            ...new Set(
              [
                ...(updatedPatchBeatByWaveFormIndexes?.[formId] || []),
                poolingData.originWaveformIndex,
              ].filter(Number)
            ),
          ];
        }
      }

      function isFormIdInList(formIds) {
        return formIds.includes(selectedFormId);
      }
    }
    case PATCH_BEATS_BY_FORM_ID_FAILED: {
      return {
        ...state,
        patchBeatByFormIds: {
          ...state.patchBeatByFormIds,
          pending: false,
        },
      };
    }
    // ## api - patch beat 방법2 (by waveformIndex)
    case PATCH_BEATS_BY_WAVEFORM_INDEX_REQUESTED: {
      return {
        ...state,
        patchBeatByWaveFormIndexes: {
          ...state.patchBeatByWaveFormIndexes,
          pending: true,
          responseValidationResult: {
            succeedWaveformIndexes: [],
            failedWaveformIndexes: [],
          },
        },
      };
    }
    case PATCH_BEATS_BY_WAVEFORM_INDEX_SUCCEED: {
      const {
        updatedWaveformIndexMap,
        resBeatType,
        responseValidationResult: {
          succeedWaveformIndexes,
          failedWaveformIndexes,
        },
      } = action;

      const clickedEventType = getClickedEventType(state);
      const millisecondTime = new Date().getTime();
      const formPanelDetailOfFirstIndexOfLastSelectedSection =
        selectFormPanelDetailOfFirstIndexOfLastSelectedSection({
          shapeReviewReducer: state,
        });

      const isEditedAll =
        state.patchBeatByWaveFormIndexes.patchedList[clickedEventType][
          formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id
        ] === editType.ALL;
      const poolingDataOfClickedEvent = [
        ...state.poolingData[ShapeReviewSectionArea.EVENTS].data[
          clickedEventType
        ][formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id],
      ];
      const updatedPoolingDataOfClickedEvent = poolingDataOfClickedEvent.map(
        (poolingDataItem) => {
          // updatedWaveformIndexMap: {[originWaveformIndex]: [onset || termination waveformIndex]}
          if (updatedWaveformIndexMap[poolingDataItem[0]]) {
            for (let waveformIndex of updatedWaveformIndexMap[
              poolingDataItem[0]
            ]) {
              const indexOfWaveformIndex =
                poolingDataItem[1].beats.waveformIndex.indexOf(waveformIndex);
              poolingDataItem[1].beats.beatType[indexOfWaveformIndex] =
                resBeatType;
            }
          }
          return poolingDataItem;
        }
      );

      // 선택한 폼의 Event 중, 마지막 Event가 변경되었다면 Form의 대표이미지도 변경
      let updatedClickedFormPoolingData = [];
      const { representativeWaveformIndex } =
        formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo || {};
      const isLastEventEdited = !!(
        updatedWaveformIndexMap[representativeWaveformIndex] ?? []
      ).length;

      if (isLastEventEdited) {
        const copiedPoolingData = getRepresentativeWaveformValue(
          state.poolingData.FORMS.data[clickedEventType],
          representativeWaveformIndex
        );

        const updatedPoolingData = updateBeatsAndTime(
          copiedPoolingData,
          updatedPoolingDataOfClickedEvent,
          representativeWaveformIndex
        );

        updatedClickedFormPoolingData = createUpdatedDataMap(
          representativeWaveformIndex,
          updatedPoolingData
        );

        function getRepresentativeWaveformValue(
          data,
          representativeWaveformIndex
        ) {
          return [...data].find(
            (v) => v[0] === representativeWaveformIndex
          )?.[1];
        }
        function updateBeatsAndTime(
          data,
          updatedData,
          representativeWaveformIndex
        ) {
          data.beats = updatedData.find(
            (v) => v[0] === representativeWaveformIndex
          )[1].beats;
          data.millisecondTime = new Date();

          return data;
        }
        function createUpdatedDataMap(representativeWaveformIndex, data) {
          return new Map([[representativeWaveformIndex, data]]);
        }
      }

      // updateState
      let updateStateOfPatchBeatsByWaveformIndexSucceed = {
        patchBeatByWaveFormIndexes: {
          ...state.patchBeatByWaveFormIndexes,
          pending: false,
          responseValidationResult: {
            succeedWaveformIndexes: succeedWaveformIndexes ?? [],
            failedWaveformIndexes: failedWaveformIndexes ?? [],
          },
        },
        clusterEventDetail: rfdcClone(
          state.clusterEventDetail.map((eventDetail) => {
            const updatedWaveformIndexList =
              updatedWaveformIndexMap[eventDetail.originWaveformIndex] ?? [];

            if (!!updatedWaveformIndexList.length) {
              for (let updatedWaveformIndex of updatedWaveformIndexList) {
                const onsetWaveformIndexIndex =
                  eventDetail.beats.waveformIndex.indexOf(updatedWaveformIndex);
                eventDetail.beats.beatType[onsetWaveformIndexIndex] =
                  resBeatType;
              }
            }

            eventDetail.millisecondTime = millisecondTime;
            return eventDetail;
          })
        ),
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.FORMS]: {
            ...state.poolingData[ShapeReviewSectionArea.FORMS],
            data: {
              ...state.poolingData[ShapeReviewSectionArea.FORMS].data,
              [clickedEventType]: new Map(
                [...state.poolingData.FORMS.data[clickedEventType]],
                [...updatedClickedFormPoolingData]
              ),
            },
          },
          [ShapeReviewSectionArea.EVENTS]: {
            ...state.poolingData[ShapeReviewSectionArea.EVENTS],
            data: {
              ...state.poolingData[ShapeReviewSectionArea.EVENTS].data,
              [clickedEventType]: {
                ...state.poolingData[ShapeReviewSectionArea.EVENTS].data[
                  clickedEventType
                ],
                [formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id]:
                  new Map(updatedPoolingDataOfClickedEvent),
              },
            },
          },
        },
      };

      // 폼 패널안의 모든 이벤트가 edited면, Form도 edited로 변경
      const patchedEventList =
        state.patchBeatByWaveFormIndexes.patchedList[clickedEventType][
          formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id
        ] || [];
      const validPatchedEventList =
        patchedEventList === editType.ALL
          ? [...poolingDataOfClickedEvent.map((v) => v[0])]
          : patchedEventList;
      const newPatchedEventListOfClickedForm = unionArrays(
        validPatchedEventList,
        succeedWaveformIndexes
      );
      const isAllEventEdited = getIsAllEventEdited({
        poolingDataOfClickedEvent,
        newPatchedEventListOfClickedForm,
      });
      const patchBeatByFormId = isAllEventEdited
        ? [formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id]
        : [];

      if (!isEditedAll) {
        updateStateOfPatchBeatsByWaveformIndexSucceed.patchBeatByWaveFormIndexes.patchedList =
          {
            ...state.patchBeatByWaveFormIndexes.patchedList,
            [clickedEventType]: {
              ...state.patchBeatByWaveFormIndexes.patchedList[clickedEventType],
              [formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id]:
                isAllEventEdited
                  ? editType.ALL
                  : [
                      ...(state.patchBeatByWaveFormIndexes.patchedList[
                        clickedEventType
                      ][
                        selectFormPanelDetailOfFirstIndexOfLastSelectedSection({
                          shapeReviewReducer: state,
                        }).formInfo.id
                      ] ?? []),
                      ...Object.keys(action.updatedWaveformIndexMap).map(
                        Number
                      ),
                    ],
            },
          };
      }

      return {
        ...state,
        ...updateStateOfPatchBeatsByWaveformIndexSucceed,
        patchBeatByFormIds: {
          ...state.patchBeatByFormIds,
          patchedList: {
            ...state.patchBeatByFormIds.patchedList,
            [clickedEventType]: [
              ...state.patchBeatByFormIds.patchedList[clickedEventType],
              ...patchBeatByFormId,
            ],
          },
        },
      };
    }
    case PATCH_BEATS_BY_WAVEFORM_INDEX_FAILED: {
      return {
        ...state,
      };
    }
    // Beat Postprocess
    case PATCH_BEAT_POSTPROCESS_REQUESTED: {
      return {
        ...state,
        beatPostprocess: {
          ...state.beatPostprocess,
          pending: true,
          error: null,
        },
      };
    }
    case PATCH_BEAT_POSTPROCESS_SUCCEED: {
      return {
        ...state,
        beatPostprocess: {
          ...state.beatPostprocess,
          pending: false,
          data: {
            ...action.payload,
          },
        },
      };
    }
    case PATCH_BEAT_POSTPROCESS_FAILED: {
      return {
        ...state,
        beatPostprocess: {
          ...state.beatPostprocess,
          pending: false,
          error: action.payload.error,
        },
      };
    }
    // Arrange
    case GET_ARRANGE_REQUIRED_STATUS_REQUESTED: {
      return {
        ...state,
        arrangeRequiredStatus: {
          ...state.arrangeRequiredStatus,
          pending: true,
          error: null,
        },
      };
    }
    case GET_ARRANGE_REQUIRED_STATUS_SUCCEED: {
      return {
        ...state,
        arrangeRequiredStatus: {
          ...state.arrangeRequiredStatus,
          pending: false,
          error: null,
          isArrangeRequired: action.payload.isArrangeRequired,
        },
      };
    }
    case GET_ARRANGE_REQUIRED_STATUS_FAILED: {
      return {
        ...state,
        arrangeRequiredStatus: {
          ...state.arrangeRequiredStatus,
          pending: false,
          error: action.error,
        },
      };
    }
    // Caliper
    case SET_CALIPER_PLOT_LINES: {
      return {
        ...state,
        caliper: {
          ...state.caliper,
          caliperPlotLines: action.caliperPlotLines,
        },
      };
    }
    case SET_IS_CALIPER_MODE: {
      return {
        ...state,
        caliper: {
          ...state.caliper,
          isCaliperMode: action.isCaliperMode,
        },
      };
    }
    case SET_IS_TICK_MARKS_MODE: {
      return {
        ...state,
        caliper: {
          ...state.caliper,
          isTickMarksMode: action.isTickMarksMode,
        },
      };
    }
    case SET_IS_ARRANGE_REQUIRED: {
      return {
        ...state,
        arrangeRequiredStatus: {
          ...state.arrangeRequiredStatus,
          isArrangeRequired: action.payload.isArrangeRequired,
        },
      };
    }
    default:
      return state;
  }
}

// Action Creators
export function resetShapeReviewState(updatedState = {}) {
  return { type: RESET_SHAPE_REVIEW_STATE, updatedState };
}
export function setPanelSize({ panelType, columnNumber, rowNumber }) {
  return {
    type: SET_PANEL_SIZE,
    panelType,
    columnNumber,
    rowNumber,
  };
}
// Set 10s Strip Detail
function setTenSecStripDetail(data) {
  return { type: SET_TENSEC_STRIP_DETAIL, data };
}
function resetTenSecStripDetail() {
  return { type: RESET_TENSEC_STRIP_DETAIL };
}

export function setSelectEvent({
  beatType,
  ectopicType,
  eventType,
  selectedFormPanel,
}) {
  return {
    type: SET_SELECT_EVENT,
    beatType,
    ectopicType,
    eventType,
    selectedFormPanel,
  };
}

export function setPagination({
  eventType,
  formId,
  panelType,
  totalItemCount,
  totalPageSize,
  currentPage,
}) {
  return {
    type: SET_PAGINATION,
    eventType,
    formId,
    panelType,
    totalItemCount,
    totalPageSize,
    currentPage,
  };
}

/**
 * 페이지를 설정하는 액션 생성자 함수입니다.
 * @param {Object} options - 페이지 설정 옵션
 * @param {ShapeReviewSectionArea.FORMS || ShapeReviewSectionArea.EVENTS} options.panelType - 패널 타입
 * @param {POSITION_MOVE_TYPE} options.setPageType - 페이지 설정 타입
 * @param {number} options.value - panel current page
 * @returns {Object} - 페이지 설정 액션 객체
 */
export function setPage({ panelType, setPageType, value }) {
  return {
    type: SET_PAGE,
    panelType,
    setPageType,
    value,
  };
}
export function setLastSelectedSectionInfo({
  triggerType,
  panelType,
  lastSelectedSectionInfo,
}) {
  return {
    type: SET_LAST_SELECTED_SECTION_INFO,
    triggerType,
    panelType,
    lastSelectedSectionInfo,
  };
}
export function setSelectedItemList({ panelType, selectedItemList }) {
  return { type: SET_SELECTED_ITEM_LIST, panelType, selectedItemList };
}
export function setSelectAll({ panelType, selectAllState }) {
  return { type: SET_SELECT_ALL, panelType, selectAllState };
}
export function setActivePanel({ activePanel }) {
  return { type: SET_ACTIVE_PANEL, activePanel };
}
export function setFormData(formData) {
  return { type: SET_FORM_DATA, formData };
}
export function setEventData(eventData) {
  return { type: SET_EVENT_DATA, eventData };
}
export function getClusteringStatisticsRequested() {
  return { type: GET_CLUSTERING_STATISTICS_REQUESTED };
}
function getClusteringStatisticsSucceed(data) {
  return { type: GET_CLUSTERING_STATISTICS_SUCCEED, data };
}
function getClusteringStatisticsFailed(error) {
  return { type: GET_CLUSTERING_STATISTICS_FAILED, error };
}
export function getFormListRequested({ formBeatType, formEctopicType }) {
  return {
    type: GET_FORM_LIST_REQUESTED,
    formBeatType,
    formEctopicType,
  };
}
export function getFormListSucceed({
  forms,
  formsThumbNailWaveFormIndex,
  totalEditedBeatCount,
  totalFormsCount,
}) {
  return {
    type: GET_FORM_LIST_SUCCEED,
    forms,
    formsThumbNailWaveFormIndex,
    totalEditedBeatCount,
    totalFormsCount,
  };
}
export function getFormListFailed(error) {
  return { type: GET_FORM_LIST_FAILED, error };
}

export function getFormDetailPoolingDataRequested(param) {
  return { type: GET_FORM_DETAIL_POOLING_DATA_REQUESTED, param };
}
export function getFormDetailPoolingDataSucceed({
  mergeFormAndEventDetailInfo,
}) {
  return {
    type: GET_FORM_DETAIL_POOLING_DATA_SUCCEED,
    mergeFormAndEventDetailInfo,
  };
}
export function getFormDetailPoolingDataFailed(error) {
  return { type: GET_FORM_DETAIL_POOLING_DATA_FAILED, error };
}

export function getRawAndEventRequested({
  calledType,
  sliceInfoOfPooling,
} = {}) {
  return {
    type: GET_RAW_AND_EVENT_REQUESTED,
    calledType,
    sliceInfoOfPooling,
  };
}
function getRawAndEventSucceed() {
  return {
    type: GET_RAW_AND_EVENT_SUCCEED,
  };
}
function getRawAndEventFailed(error) {
  return { type: GET_RAW_AND_EVENT_FAILED, error };
}
export function getPoolingDataOfEventOfFormRequested({
  calledType,
  sliceInfoOfPooling,
  formId,
} = {}) {
  return {
    type: GET_POOLING_DATA_OF_EVENT_OF_FORM_REQUESTED,
    calledType,
    sliceInfoOfPooling,
    formId,
  };
}
function getPoolingDataOfEventOfFormSucceed({ formId, poolingDataMap }) {
  return {
    type: GET_POOLING_DATA_OF_EVENT_OF_FORM_SUCCEED,
    formId,
    poolingDataMap,
  };
}
function getPoolingDataOfEventOfFormFailed(error) {
  return { type: GET_POOLING_DATA_OF_EVENT_OF_FORM_FAILED, error };
}
function setProgressPercentagePoolingEventList(progressPercentage) {
  return {
    type: SET_PROGRESS_PERCENTAGE_POOLING_EVENT_LIST,
    progressPercentage,
  };
}
export function setPausePoolingEventList(state) {
  return {
    type: SET_PAUSE_POOLING_EVENT_LIST,
    state,
  };
}
function setFinishPoolingEventList() {
  return {
    type: SET_FINISH_POOLING_EVENT_LIST,
  };
}
export function debounceGetWaveformIndexListOfFormRequested({ formId } = {}) {
  return {
    type: DEBOUNCE_GET_WAVEFORM_INDEX_LIST_OF_FORM_REQUESTED,
    formId,
  };
}
export function getWaveformIndexListOfFormRequested({ formId } = {}) {
  return {
    type: GET_WAVEFORM_INDEX_LIST_OF_FORM_REQUESTED,
    formId,
  };
}
function getWaveformIndexListOfFormSucceed({
  onsetWaveformIndexesOfForm,
  totalBeatCount,
}) {
  return {
    type: GET_WAVEFORM_INDEX_LIST_OF_FORM_SUCCEED,
    onsetWaveformIndexesOfForm,
    totalBeatCount,
  };
}
function getWaveformIndexListOfFormFailed(error) {
  return { type: GET_WAVEFORM_INDEX_LIST_OF_FORM_FAILED, error };
}

// patch beat update by formId
export function patchBeatsByFormIdRequested(reqBody) {
  return {
    type: PATCH_BEATS_BY_FORM_ID_REQUESTED,
    reqBody,
  };
}
function patchBeatsByFormIdSucceed({
  reqBody,
  filterSelectedItemList,
  succeedWaveformIndexList,
}) {
  return {
    type: PATCH_BEATS_BY_FORM_ID_SUCCEED,
    reqBody,
    filterSelectedItemList,
    succeedWaveformIndexList,
  };
}
function patchBeatsByFormIdFailed(error) {
  return {
    type: PATCH_BEATS_BY_FORM_ID_FAILED,
    error,
  };
}
// patch beat update by waveformIndex
export function patchBeatsByWaveformIndexRequested(reqBody) {
  return {
    type: PATCH_BEATS_BY_WAVEFORM_INDEX_REQUESTED,
    reqBody,
  };
}
function patchBeatsByWaveformIndexSucceed({
  updatedWaveformIndexMap,
  resBeatType,
  responseValidationResult,
}) {
  return {
    type: PATCH_BEATS_BY_WAVEFORM_INDEX_SUCCEED,
    updatedWaveformIndexMap,
    resBeatType,
    responseValidationResult,
  };
}
function patchBeatsByWaveformIndexFailed(error) {
  return {
    type: PATCH_BEATS_BY_WAVEFORM_INDEX_FAILED,
    error,
  };
}

// Caliper
export function setCaliperPlotLines(caliperPlotLines) {
  return { type: SET_CALIPER_PLOT_LINES, caliperPlotLines };
}
export function setIsCaliperMode(isCaliperMode) {
  return { type: SET_IS_CALIPER_MODE, isCaliperMode };
}
export function setIsTickMarksMode(isTickMarksMode) {
  return { type: SET_IS_TICK_MARKS_MODE, isTickMarksMode };
}

// Arrange 필요 여부 확인
export function getArrangeRequiredStatusRequested(payload) {
  return { type: GET_ARRANGE_REQUIRED_STATUS_REQUESTED, payload };
}
function getArrangeRequiredStatusSucceed(payload) {
  return { type: GET_ARRANGE_REQUIRED_STATUS_SUCCEED, payload };
}
function getArrangeRequiredStatusFailed(payload) {
  return { type: GET_ARRANGE_REQUIRED_STATUS_FAILED, payload };
}
export function setIsArrangeRequired(payload) {
  return { type: SET_IS_ARRANGE_REQUIRED, payload };
}

// Arrange: beat(Ectopic), Clustering  재정렬
export function patchBeatPostprocessRequested(payload) {
  return { type: PATCH_BEAT_POSTPROCESS_REQUESTED, payload };
}
function patchBeatPostprocessSucceed(payload) {
  return { type: PATCH_BEAT_POSTPROCESS_SUCCEED, payload };
}
function patchBeatPostprocessFailed(payload) {
  return { type: PATCH_BEAT_POSTPROCESS_FAILED, payload };
}

// Saga Functions
function* _setPage(action) {
  try {
    /**
     *
     * step1. setting form data(be displayed form panel)
     *
     * step2. pooling
     * step2.1 validate pooling
     * step2.2 request pooling
     */
    const { setPageType } = action;
    const formListInfoOfSelectedEvent = yield select(
      selectFormListInfoOfSelectedEvent
    );
    const formsThumbNailWaveFormIndexOfSelectedEvent =
      formListInfoOfSelectedEvent.formsThumbNailWaveFormIndex;
    const formPoolingDataOfSelectedEvent = yield select(
      selectFormPoolingDataOfSelectedEvent
    );
    const waveformIndexListInfoOfSelectedForm = yield select(
      selectWaveformIndexListInfoOfSelectedForm
    );
    const onsetWaveformIndexListOfForm =
      waveformIndexListInfoOfSelectedForm?.onsetWaveformIndexListOfForm;
    const eventPoolingDataOfSelectedForm = yield select(
      selectEventPoolingDataOfSelectedForm
    );
    //
    const { pageSize: formPanelSize } = yield select(selectFormPanelSizeInfo);
    const { pageSize: eventPanelSize } = yield select(selectEventPanelSizeInfo);
    const { currentPage: formPanelCurrentPage } = yield select(
      selectFormPanelPaginationInfo
    );
    const eventPanelPaginationInfo = yield select(
      selectEventPanelPaginationInfo
    );
    const eventPanelCurrentPage = eventPanelPaginationInfo?.currentPage;

    // step1. setting form data(be displayed form panel)
    let beDisplayedFormData;
    let panelCurrentPage, panelSize;
    let waveformIndexOfSelectedTarget,
      sliceInfoOfBeDisplayedFormData,
      poolingDataOfSelectedTarget;
    if (action.panelType === ShapeReviewSectionArea.FORMS) {
      panelCurrentPage = formPanelCurrentPage;
      panelSize = formPanelSize;
      waveformIndexOfSelectedTarget =
        formsThumbNailWaveFormIndexOfSelectedEvent;
      poolingDataOfSelectedTarget = formPoolingDataOfSelectedEvent;
    } else if (action.panelType === ShapeReviewSectionArea.EVENTS) {
      panelCurrentPage = eventPanelCurrentPage;
      panelSize = eventPanelSize;
      waveformIndexOfSelectedTarget = onsetWaveformIndexListOfForm;
      poolingDataOfSelectedTarget = eventPoolingDataOfSelectedForm;
    }

    sliceInfoOfBeDisplayedFormData = {
      begin: (panelCurrentPage - 1) * panelSize,
      end: panelCurrentPage * panelSize,
    };

    beDisplayedFormData = getBeDisplayedFormData({
      waveformIndexOfSelectedTarget,
      sliceInfoOfBeDisplayedFormData,
      poolingDataOfSelectedTarget,
    });

    if (action.panelType === ShapeReviewSectionArea.FORMS) {
      yield put(setFormData(beDisplayedFormData));
    } else if (action.panelType === ShapeReviewSectionArea.EVENTS) {
      yield put(setEventData(beDisplayedFormData));
    }

    // step2. pooling
    const basisMultipleFetching =
      shapeReviewFetchingOption[ShapeReviewSectionArea.FORMS]
        .BASIS_MULTIPLE_FETCHING;
    let calledType;
    if (setPageType === POSITION_MOVE_TYPE.JUMP) {
      calledType = rawAndEventCalledCase.POSITION_JUMP;
    } else {
      calledType = rawAndEventCalledCase.DATA_POLLING;
    }

    // step2.1 validate pooling
    // step2.2 request pooling
    const beginIndexOfCurrentPage = sliceInfoOfBeDisplayedFormData.begin;
    const endIndexOfCurrentPage = sliceInfoOfBeDisplayedFormData.end;
    let cursorLimit, indexOfNextPooling, sliceInfoOfPooling;
    if (setPageType === POSITION_MOVE_TYPE.PREV) {
      cursorLimit = (formPanelCurrentPage - basisMultipleFetching) * panelSize;
      indexOfNextPooling = getIndexOfNextPoolingInPrevCase(
        beginIndexOfCurrentPage,
        waveformIndexOfSelectedTarget,
        poolingDataOfSelectedTarget,
        cursorLimit
      );

      if (!indexOfNextPooling) return;

      sliceInfoOfPooling = {
        begin: indexOfNextPooling - panelSize * basisMultipleFetching + 1,
        end: indexOfNextPooling + 1,
      };
    } else if (
      setPageType === POSITION_MOVE_TYPE.NEXT ||
      setPageType === POSITION_MOVE_TYPE.JUMP
    ) {
      cursorLimit = (formPanelCurrentPage + basisMultipleFetching) * panelSize;
      indexOfNextPooling = getIndexOfNextPooling(
        beginIndexOfCurrentPage,
        waveformIndexOfSelectedTarget,
        poolingDataOfSelectedTarget,
        cursorLimit
      );
      if (!indexOfNextPooling) return;

      sliceInfoOfPooling = {
        begin: indexOfNextPooling,
        end: indexOfNextPooling + panelSize * basisMultipleFetching,
      };
    }

    if (action.panelType === ShapeReviewSectionArea.FORMS) {
      yield put(getRawAndEventRequested({ calledType, sliceInfoOfPooling }));
    } else if (action.panelType === ShapeReviewSectionArea.EVENTS) {
      const firstSelectedInfoOfFormPanel = yield select(
        selectFirstSelectedInfoOfFormPanel
      );
      const formIdOfSelectedItem = firstSelectedInfoOfFormPanel?.formInfo.id;

      yield put(
        getPoolingDataOfEventOfFormRequested({
          calledType,
          sliceInfoOfPooling,
          formId: formIdOfSelectedItem,
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

function* _getClusteringStatistics(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);

    const {
      data: { result },
    } = yield call(ApiManager.getClusteringStatistics, {
      ecgTestId,
    });

    yield put(getClusteringStatisticsSucceed(result));
  } catch (error) {
    console.error(error);
    yield put(getClusteringStatisticsFailed(error));
  }
}

function* _getFormListRequested(action) {
  try {
    const { formBeatType, formEctopicType } = action;
    const ecgTestId = yield select(selectEcgTestId);
    const selectedEventType = yield select(selectSelectedEventType);
    const formListInfoOfSelectedEvent = yield select(
      selectFormListInfoOfSelectedEvent
    );
    const { pageSize: formPanelSize } = yield select(selectFormPanelSizeInfo);

    // 이미 forms 데이터가 있는 경우
    if (formListInfoOfSelectedEvent.forms.length !== 0) {
      const formPoolingDataOfSelectedEvent = yield select(
        selectFormPoolingDataOfSelectedEvent
      );
      const beDisplayedFormData = getBeDisplayedFormData({
        waveformIndexOfSelectedTarget:
          formListInfoOfSelectedEvent.formsThumbNailWaveFormIndex,
        sliceInfoOfBeDisplayedFormData: { begin: 0, end: formPanelSize },
        poolingDataOfSelectedTarget: formPoolingDataOfSelectedEvent,
      });

      yield put(setFormData(beDisplayedFormData));
      return;
    }
    // step1. fetching forms list
    //   ㄴ [call - api] getClusteringForms
    //   ㄴ [put] getFormListSucceed
    //   ㄴ [put] setPagination

    // step2. fetching event list (forms의 thumbnail image 때문에 호출)
    //   ㄴ [put] getRawAndEventRequested

    // step1. fetching forms list
    //   ㄴ [call - api] getClusteringForms
    const {
      data: {
        result: { forms, totalEditedBeatCount, totalFormsCount },
      },
    } = yield call(ApiManager.getClusteringForms, {
      ecgTestId,
      formBeatType,
      formEctopicType,
    });

    const formsThumbNailWaveFormIndex = forms.map(
      (v) => v.representativeWaveformIndex
    );

    //   ㄴ [put] getFormListSucceed
    yield put(
      getFormListSucceed({
        forms,
        formsThumbNailWaveFormIndex,
        totalEditedBeatCount,
        totalFormsCount,
      })
    );

    if (formListInfoOfSelectedEvent.forms.length === 0) {
      const paginationInfo = {
        eventType: selectedEventType,
        panelType: ShapeReviewSectionArea.FORMS,
        totalItemCount: totalFormsCount,
        totalPageSize: Math.ceil(totalFormsCount / formPanelSize),
        currentPage: 1,
      };
      //   ㄴ [put] setPagination
      yield put(setPagination({ ...paginationInfo }));
    }

    // step2. fetching event list (forms의 thumbnail image 때문에 호출)
    //   ㄴ [put] getRawAndEventRequested
    const calledType = rawAndEventCalledCase.CLICK_EVENT;
    const basisMultipleFetching =
      shapeReviewFetchingOption[ShapeReviewSectionArea.FORMS]
        .BASIS_MULTIPLE_FETCHING;
    const sliceInfoOfPooling = {
      begin: 0,
      end: formPanelSize * basisMultipleFetching,
    };
    yield put(getRawAndEventRequested({ calledType, sliceInfoOfPooling }));
  } catch (error) {
    console.error(error);
    yield put(getFormListFailed(error));
  }
}

function* _getRawAndEvent({ calledType, sliceInfoOfPooling }) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const formListInfoOfSelectedEvent = yield select(
      selectFormListInfoOfSelectedEvent
    );
    const panelSizeInfo = yield select(selectPanelSizeInfo);
    const { pageSize: formPanelSize } =
      panelSizeInfo[ShapeReviewSectionArea.FORMS];

    /* 
      # STEP LIST
        step1. fetching
        step2. setting pooling
        step3. post process(호출 방법에 따른 후처리)
    */
    const slicedFormsWaveformIndexes =
      formListInfoOfSelectedEvent.formsThumbNailWaveFormIndex.slice(
        sliceInfoOfPooling.begin,
        sliceInfoOfPooling.end
      );
    const chartSampleSize =
      shapeReviewFetchingOption.fetchingOption.chartSampleSizeOf4s;

    if (slicedFormsWaveformIndexes.length === 0) return;

    // step1. fetching
    /** @type {BeatEventDetailReturn} */
    const {
      data: { results: beatEventDetailList },
    } = yield call(ApiManager.getBeatsFilterWaveformIndexWithSampleSize, {
      ecgTestId,
      waveformIndexes: slicedFormsWaveformIndexes,
      sampleSize: chartSampleSize,
      withRegisteredStrip: false,
      withRaw: true,
    });
    yield put(getRawAndEventSucceed());

    // step2. setting pooling
    const mergeFormAndEventDetailInfo = new Map();
    for (let i = 0; i < beatEventDetailList.length; i++) {
      const beatEventDetail = beatEventDetailList[i];
      const indexOfBeatEventDetailInFromList =
        findIndexOfBeatEventDetailInFromList(
          formListInfoOfSelectedEvent,
          beatEventDetail
        );

      Object.assign(beatEventDetail, {
        formInfo:
          formListInfoOfSelectedEvent.forms[indexOfBeatEventDetailInFromList],
      });

      mergeFormAndEventDetailInfo.set(
        beatEventDetail.originWaveformIndex,
        beatEventDetail
      );
    }
    yield put(getFormDetailPoolingDataSucceed({ mergeFormAndEventDetailInfo }));

    // step3. post process(호출 방법에 따른 후처리)
    if (calledType === rawAndEventCalledCase.CLICK_EVENT) {
      const beDisplayedFormData = getBeDisplayedFormData({
        waveformIndexOfSelectedTarget:
          formListInfoOfSelectedEvent.formsThumbNailWaveFormIndex,
        sliceInfoOfBeDisplayedFormData: { begin: 0, end: formPanelSize },
        poolingDataOfSelectedTarget: mergeFormAndEventDetailInfo,
      });

      yield put(setFormData(beDisplayedFormData));
    } else if (
      calledType === rawAndEventCalledCase.DATA_POLLING ||
      calledType === rawAndEventCalledCase.POSITION_JUMP
    ) {
      const selectedEventFormData = yield select(selectFormDataOfSelectedEvent);
      if (selectedEventFormData.length !== 0) return;

      const selectedEventType = yield select(selectSelectedEventType);
      const paginationInfo = yield select(selectPaginationInfo);
      const formPoolingDataOfSelectedEvent = yield select(
        selectFormPoolingDataOfSelectedEvent
      );
      const formPanelPaginationInfo =
        paginationInfo[ShapeReviewSectionArea.FORMS];
      const { currentPage: formPanelCurrentPage } =
        formPanelPaginationInfo[selectedEventType];

      const beDisplayedFormData = getBeDisplayedFormData({
        waveformIndexOfSelectedTarget:
          formListInfoOfSelectedEvent.formsThumbNailWaveFormIndex,
        sliceInfoOfBeDisplayedFormData: {
          begin: (formPanelCurrentPage - 1) * formPanelSize,
          end: formPanelCurrentPage * formPanelSize,
        },
        poolingDataOfSelectedTarget: formPoolingDataOfSelectedEvent,
      });

      yield put(setFormData(beDisplayedFormData));
    }
  } catch (error) {
    console.error(error);
    yield put(getRawAndEventFailed(error));
  }
}

function* _getWaveformIndexListOfForm({ formId }) {
  try {
    const selectedEventType = yield select(selectSelectedEventType);
    const waveformIndexListInfoOfSelectedForm = yield select(
      selectWaveformIndexListInfoOfSelectedForm
    );
    const { pageSize: formPanelSize } = yield select(selectEventPanelSizeInfo);

    if (!waveformIndexListInfoOfSelectedForm) {
      const {
        data: {
          result: { onsetWaveformIndexes, totalBeatCount },
        },
      } = yield call(ApiManager.getWaveformIndexesOfForm, {
        formId,
      });

      if (onsetWaveformIndexes.length === 0) return;

      yield put(
        getWaveformIndexListOfFormSucceed({
          onsetWaveformIndexesOfForm: onsetWaveformIndexes,
          totalBeatCount,
        })
      );

      const paginationInfo = {
        eventType: selectedEventType,
        formId,
        panelType: ShapeReviewSectionArea.EVENTS,
        totalItemCount: totalBeatCount,
        totalPageSize: Math.ceil(totalBeatCount / formPanelSize),
        currentPage: 1,
      };
      yield put(setPagination({ ...paginationInfo }));
    }

    const eventPoolingDataOfSelectedForm = yield select(
      selectEventPoolingDataOfSelectedForm
    );
    const hasAllPoolingData = [...eventPoolingDataOfSelectedForm].every(
      (v) => v[1] !== undefined
    );

    const panelSizeInfo = yield select(selectPanelSizeInfo);
    const { pageSize: eventPanelSize } =
      panelSizeInfo[ShapeReviewSectionArea.EVENTS];
    if (hasAllPoolingData) {
      const originWaveformIndexListOfSelectedForm =
        waveformIndexListInfoOfSelectedForm.onsetWaveformIndexListOfForm;

      const eventPanelPaginationInfo = yield select(
        selectEventPanelPaginationInfo
      );
      const eventPanelCurrentPage = eventPanelPaginationInfo?.currentPage;
      const panelCurrentPage = eventPanelCurrentPage;
      const panelSize = eventPanelSize;
      const sliceInfoOfBeDisplayedFormData = {
        begin: (panelCurrentPage - 1) * panelSize,
        end: panelCurrentPage * panelSize,
      };

      const beDisplayedFormData = getBeDisplayedFormData({
        waveformIndexOfSelectedTarget: originWaveformIndexListOfSelectedForm,
        // sliceInfoOfBeDisplayedFormData: { begin: 0, end: eventPanelSize },
        sliceInfoOfBeDisplayedFormData,
        poolingDataOfSelectedTarget: eventPoolingDataOfSelectedForm,
      });

      yield put(setEventData(beDisplayedFormData));
    } else {
      const eventPoolingDataOfSelectedForm = yield select(
        selectEventPoolingDataOfSelectedForm
      );
      const undefinedBeginIndexOfPoolingData = [
        ...eventPoolingDataOfSelectedForm,
      ].findIndex((v) => v[1] === undefined);

      const calledType = rawAndEventCalledCase.CLICK_EVENT;
      const basisMultipleFetching =
        shapeReviewFetchingOption[ShapeReviewSectionArea.EVENTS]
          .BASIS_MULTIPLE_FETCHING;
      const sliceInfoOfPooling = {
        begin: 0 + undefinedBeginIndexOfPoolingData,
        end:
          eventPanelSize * basisMultipleFetching +
          undefinedBeginIndexOfPoolingData,
      };

      yield put(
        getPoolingDataOfEventOfFormRequested({
          calledType,
          sliceInfoOfPooling,
          formId,
        })
      );
    }
  } catch (error) {
    console.error(error);
    yield put(getWaveformIndexListOfFormFailed(error));
  }
}
function* _getPoolingDataOfEventOfForm({
  calledType,
  sliceInfoOfPooling,
  formId,
}) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const waveformIndexListInfoOfSelectedForm = yield select(
      selectWaveformIndexListInfoOfSelectedForm
    );
    const originWaveformIndexListOfSelectedForm =
      waveformIndexListInfoOfSelectedForm.onsetWaveformIndexListOfForm;
    const waveformIndexListOfSelectedForm = [
      ...waveformIndexListInfoOfSelectedForm.onsetWaveformIndexListOfForm,
    ];

    const panelSizeInfo = yield select(selectPanelSizeInfo);
    const { pageSize: eventPanelSize } =
      panelSizeInfo[ShapeReviewSectionArea.EVENTS];

    /* 
      # STEP LIST
        step1. fetching
        step2. setting pooling
        step3. post process(호출 방법에 따른 후처리)
    */
    let poolingDataMap = new Map();
    const slicedFormsWaveformIndexes = waveformIndexListOfSelectedForm.slice(
      sliceInfoOfPooling.begin
    );
    const chartSampleSize = 2500; // 10초차트 에서 사용하기 위해 2500개 호출

    if (slicedFormsWaveformIndexes.length === 0) return;

    // step1. fetching
    let beatEventDetailList = [];

    while (slicedFormsWaveformIndexes.length > 0) {
      let fetchingTargetWaveformIndexList = [];
      let j = 0;
      const poolingDataOfEventPanelState = yield select(
        selectPoolingDataOfEventPanelState
      );

      if (poolingDataOfEventPanelState) break;

      while (j < shapeReviewFetchingOption.fetchingOption.fetchingSize) {
        j++;
        if (slicedFormsWaveformIndexes.length !== 0) {
          fetchingTargetWaveformIndexList.push(
            slicedFormsWaveformIndexes.shift()
          );
        } else {
          break;
        }
      }

      /** @type {BeatEventDetailReturn} */
      const axiosSource = axiosSourceManager.createSource(
        fetchingTargetWaveformIndexList[0]
      );
      const result = yield call(
        ApiManager.getBeatsFilterWaveformIndexWithSampleSize,
        {
          ecgTestId,
          waveformIndexes: fetchingTargetWaveformIndexList,
          sampleSize: chartSampleSize,
          withRegisteredStrip: false,
          withRaw: true,
          axiosSource,
        }
      );

      const partOfBeatEventDetailList = result?.data?.results.map((v) => {
        const crossOverZeroWaveformIndex =
          ECG_CHART_UNIT.HALF_TEN_SEC_WAVEFORM_IDX - v.originWaveformIndex;
        const isCrossOverZeroWaveformIndex = crossOverZeroWaveformIndex > 0;

        if (!isCrossOverZeroWaveformIndex) return v;

        const halfOfExtraFetchingSampleSize = 1250;
        const startWaveFormIndexOfRawEcg = isCrossOverZeroWaveformIndex
          ? 0
          : halfOfExtraFetchingSampleSize;

        const ecgRaw = [
          ...Array.from({ length: crossOverZeroWaveformIndex }, () => 0),
        ].concat(v.mainECG.rawECG.slice(startWaveFormIndexOfRawEcg));

        return {
          ...v,
          mainECG: {
            ...v.mainECG,
            onsetWaveformIndex:
              v.originWaveformIndex - ECG_CHART_UNIT.HALF_TEN_SEC_WAVEFORM_IDX,
            terminationWaveformIndex:
              v.originWaveformIndex -
              ECG_CHART_UNIT.HALF_TEN_SEC_WAVEFORM_IDX +
              2500,
            rawECG: ecgRaw,
          },
        };
      });

      if (!partOfBeatEventDetailList) return;

      beatEventDetailList.push(...partOfBeatEventDetailList);

      // step2. setting pooling
      for (let i = 0; i < beatEventDetailList.length; i++) {
        const beatEventDetail = beatEventDetailList[i];
        beatEventDetail.totalIndex =
          originWaveformIndexListOfSelectedForm.indexOf(
            beatEventDetail.originWaveformIndex
          );
        poolingDataMap.set(
          beatEventDetail.originWaveformIndex,
          beatEventDetail
        );
      }
      yield put(getPoolingDataOfEventOfFormSucceed({ formId, poolingDataMap }));

      const eventPoolingDataOfSelectedForm = yield select(
        selectEventPoolingDataOfSelectedForm
      );
      const hasPoolingDataMap = [...eventPoolingDataOfSelectedForm].filter(
        (v) => v[1] !== undefined
      );

      yield put(
        setProgressPercentagePoolingEventList(
          Math.round(
            ([...hasPoolingDataMap].length /
              originWaveformIndexListOfSelectedForm.length) *
              100
          )
        )
      );
    }

    yield put(setFinishPoolingEventList());
    // step3. post process(호출 방법에 따른 후처리)
    if (
      calledType === rawAndEventCalledCase.CLICK_EVENT ||
      calledType === rawAndEventCalledCase.KEY_EVENT
    ) {
      const eventPoolingDataOfSelectedForm = yield select(
        selectEventPoolingDataOfSelectedForm
      );

      const beDisplayedFormData = getBeDisplayedFormData({
        waveformIndexOfSelectedTarget: originWaveformIndexListOfSelectedForm,
        sliceInfoOfBeDisplayedFormData: { begin: 0, end: eventPanelSize },
        poolingDataOfSelectedTarget: eventPoolingDataOfSelectedForm,
      });

      yield put(setEventData(beDisplayedFormData));
    } else if (
      calledType === rawAndEventCalledCase.DATA_POLLING ||
      calledType === rawAndEventCalledCase.POSITION_JUMP
    ) {
      const { currentPage: eventPanelCurrentPage } = yield select(
        selectEventPanelPaginationInfo
      );
      const eventPoolingDataOfSelectedForm = yield select(
        selectEventPoolingDataOfSelectedForm
      );

      const beDisplayedFormData = getBeDisplayedFormData({
        waveformIndexOfSelectedTarget: originWaveformIndexListOfSelectedForm,
        sliceInfoOfBeDisplayedFormData: {
          begin: (eventPanelCurrentPage - 1) * eventPanelSize,
          end: eventPanelCurrentPage * eventPanelSize,
        },
        poolingDataOfSelectedTarget: eventPoolingDataOfSelectedForm,
      });

      yield put(setEventData(beDisplayedFormData));
    }
  } catch (error) {
    console.error(error);
    yield put(getPoolingDataOfEventOfFormFailed(error));
  }
}

function* _setPausePoolingEventList(action) {
  try {
    if (action.state === true) {
      axiosSourceManager.cancel();
    } else if (action.state === false) {
      const eventPoolingDataOfSelectedForm = yield select(
        selectEventPoolingDataOfSelectedForm
      );
      const undefinedBeginIndexOfPoolingData = [
        ...eventPoolingDataOfSelectedForm,
      ].findIndex((v) => v[1] === undefined);

      const calledType = rawAndEventCalledCase.CLICK_EVENT;
      const panelSizeInfo = yield select(selectPanelSizeInfo);
      const { pageSize: eventPanelSize } =
        panelSizeInfo[ShapeReviewSectionArea.EVENTS];
      const basisMultipleFetching =
        shapeReviewFetchingOption[ShapeReviewSectionArea.EVENTS]
          .BASIS_MULTIPLE_FETCHING;
      const sliceInfoOfPooling = {
        begin: 0 + undefinedBeginIndexOfPoolingData,
        end:
          eventPanelSize * basisMultipleFetching +
          undefinedBeginIndexOfPoolingData,
      };
      const formPanelDetailOfFirstIndexOfLastSelectedSection = yield select(
        selectFormPanelDetailOfFirstIndexOfLastSelectedSection
      );

      const firstIndexOfLastSelectedSectionInfoFormId =
        formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id;
      yield put(
        getPoolingDataOfEventOfFormRequested({
          calledType,
          sliceInfoOfPooling,
          formId: firstIndexOfLastSelectedSectionInfoFormId,
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

function* _patchBeatsByFormIds(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const filterSelectedItemListOfFormPanel = yield select(
      selectFilterSelectedItemListOfFormPanel
    );

    const reqBeatType = action.reqBody.beatType;
    const reqBody = {
      beatType: reqBeatType,
      onsetFormIds: filterSelectedItemListOfFormPanel.onset.map(
        (v) => v.formInfo.id
      ),
      terminationFormIds: filterSelectedItemListOfFormPanel.termination.map(
        (v) => v.formInfo.id
      ),
    };

    const {
      data: {
        result: { beatType: resBeatType, waveformIndex: resWaveformIndex },
      },
      status,
    } = yield call(ApiManager.updateBeatsByFormIds, ecgTestId, reqBody);

    const filterSelectedItemList =
      filterSelectedItemListOfFormPanel.filterSelectedItemList;

    const failedBeatTypeIndexList = resBeatType.reduce((acc, beatType, idx) => {
      if (beatType === reqBeatType) return acc;

      acc.push(idx);
      return acc;
    }, []);

    const succeedWaveformIndexList = resWaveformIndex.filter(
      (_, idx) => !failedBeatTypeIndexList.includes(idx)
    );

    if (status === 200) {
      yield put(
        patchBeatsByFormIdSucceed({
          reqBody,
          filterSelectedItemList,
          succeedWaveformIndexList,
        })
      );
      yield put(setIsArrangeRequired({ isArrangeRequired: true }));
    }
  } catch (error) {
    console.error(error);
    yield put(patchBeatsByFormIdFailed(error));
  }
}
function* _patchBeatsByWaveformIndexes(action) {
  try {
    const { reqBody } = action;

    const ecgTestId = yield select(selectEcgTestId);
    const filterSelectedItemListOfEventPanel = yield select(
      selectFilterSelectedItemListOfEventPanel
    );

    let reqWaveformIndexList = [];
    for (let onset of filterSelectedItemListOfEventPanel.onset) {
      const onsetWaveformIndex =
        onset.beats.waveformIndex[
          onset.beats.waveformIndex.indexOf(onset.originWaveformIndex)
        ];

      reqWaveformIndexList.push(onsetWaveformIndex);
    }

    for (let termination of filterSelectedItemListOfEventPanel.termination) {
      const terminationWaveformIndex =
        termination.beats.waveformIndex[
          termination.beats.waveformIndex.indexOf(
            termination.originWaveformIndex
          ) + 1
        ];

      reqWaveformIndexList.push(terminationWaveformIndex);
    }

    action.reqBody.waveformIndexes = reqWaveformIndexList.sort((a, b) => a - b);

    const requestStatement = {
      requestType: SUFFIX_LIST.EDIT_BEAT_SUFFIX.UPDATE_BEATS_BY_INDEXES, // api
      ecgTestId: ecgTestId,
      reqBody: action.reqBody,
    };

    yield put(
      enqueueRequest({
        requestStatement,
        succeedCallback,
        failedCallback,
      })
    );

    function* succeedCallback({ data }) {
      const {
        waveformIndex: succeedWaveformIndexes,
        beatType: succeedBeatType,
      } = data.result;

      // API에 수정 요청했지만 실패한 WI List (AF => S 변경했거나, 삭제된 WI를 편집 요청한 경우) 실패처리
      const failedWaveformIndexes = reqBody.waveformIndexes.filter(
        (v) => !succeedWaveformIndexes.includes(v)
      );

      let updatedWaveformIndexMap = {};

      for (let onset of filterSelectedItemListOfEventPanel.onset) {
        const waveformIndexes = onset.beats.waveformIndex;
        const originIndex = onset.originWaveformIndex;
        const onsetWaveformIndex =
          waveformIndexes[waveformIndexes.indexOf(originIndex)];
        const terminationWaveformIndex =
          waveformIndexes[waveformIndexes.indexOf(originIndex) + 1];

        if (
          succeedWaveformIndexes.includes(onsetWaveformIndex) &&
          !failedWaveformIndexes.includes(terminationWaveformIndex)
        ) {
          updatedWaveformIndexMap[originIndex] = [onsetWaveformIndex];
        } else {
          delete updatedWaveformIndexMap[originIndex];
        }
      }

      for (let termination of filterSelectedItemListOfEventPanel.termination) {
        const waveformIndexes = termination.beats.waveformIndex;
        const originIndex = termination.originWaveformIndex;
        const onsetWaveformIndex =
          waveformIndexes[waveformIndexes.indexOf(originIndex)];
        const terminationWaveformIndex =
          waveformIndexes[waveformIndexes.indexOf(originIndex) + 1];

        if (
          succeedWaveformIndexes.includes(terminationWaveformIndex) &&
          !failedWaveformIndexes.includes(onsetWaveformIndex)
        ) {
          updatedWaveformIndexMap[originIndex] = [
            ...(updatedWaveformIndexMap[originIndex] ?? []),
            terminationWaveformIndex,
          ];
        } else {
          delete updatedWaveformIndexMap[originIndex];
        }
      }

      yield put(
        patchBeatsByWaveformIndexSucceed({
          updatedWaveformIndexMap,
          resBeatType: succeedBeatType[0],
          responseValidationResult: {
            succeedWaveformIndexes,
            failedWaveformIndexes,
          },
        })
      );
      yield put(setIsArrangeRequired({ isArrangeRequired: true }));
    }
    function* failedCallback(error) {
      yield put(patchBeatsByWaveformIndexFailed(error));
    }
  } catch (error) {
    console.error(error);
    yield put(patchBeatsByWaveformIndexFailed(error));
  }
}

function* _setLastSelectedSectionInfo({
  triggerType,
  panelType,
  lastSelectedSectionInfo,
}) {
  try {
    const numberOfClickedForms = yield select(selectNumberOfClickedForms);
    const { FORMS: selectAllInfoOfForms } = yield select(selectSelectAllInfo);
    const isOverClickedFormsMoreThanOne = numberOfClickedForms > 1;
    const isClickEventPanel = panelType === ShapeReviewSectionArea.EVENTS;
    const isOnlyClickCase = !lastSelectedSectionInfo?.[0];
    const doesSelectSection =
      lastSelectedSectionInfo.filter((v) => !v).length !== 1; // shift + click case

    if (
      isClickEventPanel ||
      isOnlyClickCase ||
      isOverClickedFormsMoreThanOne ||
      doesSelectSection ||
      selectAllInfoOfForms === CheckBoxAll.None
    ) {
      return;
    }

    const formDataOfSelectedEvent = yield select(selectFormDataOfSelectedEvent);
    const formIdOfFocusedItem =
      formDataOfSelectedEvent[lastSelectedSectionInfo[0].index]?.formInfo?.id;

    if (!formIdOfFocusedItem) return;

    if (triggerType === rawAndEventCalledCase.CLICK_EVENT) {
      yield put(
        getWaveformIndexListOfFormRequested({ formId: formIdOfFocusedItem })
      );
    } else if (triggerType === rawAndEventCalledCase.KEY_EVENT) {
      yield put(
        debounceGetWaveformIndexListOfFormRequested({
          formId: formIdOfFocusedItem,
        })
      );
    }
  } catch (error) {
    console.error('error: ', error);
  }
}
function* _tenSecStripHandler(action) {
  try {
    if (action.panelType === ShapeReviewSectionArea.FORMS) return;

    const focusedSectionInfo = yield select(
      (state) =>
        selectEventDataOfSelectedForm(state)[
          (
            selectSelectedInfoOfEventsPanel(state).lastSelectedSectionInfo[1] ??
            selectSelectedInfoOfEventsPanel(state).lastSelectedSectionInfo[0]
          )?.index
        ]
    ) ?? {};

    if (!focusedSectionInfo) {
      yield put(resetTenSecStripDetail());
      return;
    }

    const { beats, mainECG, ectopicHR } = focusedSectionInfo;
    let tenSecStripDetail = {
      onsetMs: mainECG.onsetMs,
      terminationMs: mainECG.terminationMs,
      onsetWaveformIdx: mainECG.onsetWaveformIndex,
      terminationWaveformIdx: mainECG.terminationWaveformIndex,
      hrAvg: ectopicHR.hrAvg,
      ecgRaw: mainECG.rawECG,
      beatLabelButtonDataList: [],
      beatsOrigin: beats,
    };

    const beatType = TEN_SEC_STRIP_EDIT.BEAT_TYPE;
    const beatColorType = TEN_SEC_STRIP_EDIT.BEAT_COLOR_TYPE;
    const waveformIndexList = beats.waveformIndex
      .slice(
        beats.waveformIndex.findIndex((v) => v >= mainECG.onsetWaveformIndex),
        beats.waveformIndex.findLastIndex(
          (v) => v <= mainECG.terminationWaveformIndex
        ) + 1
      )
      .map((waveformIdx) => waveformIdx - mainECG.onsetWaveformIndex);
    const beatTypeList = beats.beatType.slice(
      beats.waveformIndex.findIndex((v) => v >= mainECG.onsetWaveformIndex),
      beats.waveformIndex.findLastIndex(
        (v) => v <= mainECG.terminationWaveformIndex
      ) + 1
    );
    for (let index in waveformIndexList) {
      tenSecStripDetail.beatLabelButtonDataList.push({
        xAxisPoint: waveformIndexList[index],
        isSelected:
          waveformIndexList[index] ===
          beats.waveformIndex[0] - mainECG.onsetWaveformIndex,
        beatType: beatTypeList[index],
        title: beatType[beatTypeList[index]],
        color: beatColorType[beatTypeList[index]],
        isEventReview: '',
      });
    }

    yield put(setTenSecStripDetail(tenSecStripDetail));
  } catch (error) {
    console.error(error);
  }
}

// Beat Postprocess
function* _patchBeatPostprocess(action) {
  let payload = {};
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const {
      data: { result },
    } = yield call(ApiManager.patchBeatPostprocess, {
      ecgTestId,
      doRearrange: true,
    });

    payload.beatPostprocessedMs = result.beatPostprocessedMs;
    const clickedInfo = yield select(selectClickedInfo);
    const updatedState = {
      shapeReviewState: {
        ...initialState.shapeReviewState,
        [ShapeReviewState.CLICKED_INFO]: {
          ...clickedInfo,
        },
      },
    };
    yield put(resetShapeReviewState(updatedState));
    yield put(getClusteringStatisticsRequested());
    yield put(patchBeatPostprocessSucceed(payload));
    yield put(setIsArrangeRequired({ isArrangeRequired: false }));
  } catch (error) {
    payload.error = error;
    yield put(patchBeatPostprocessFailed(payload));
  }
}

// Arrange
function* _getIsArrangeRequiredRequested(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);

    const {
      data: {
        result: { isArrangeRequired },
      },
    } = yield call(ApiManager.getIsArrangeRequired, {
      ecgTestId,
    });

    yield put(getArrangeRequiredStatusSucceed({ isArrangeRequired }));
  } catch (error) {
    console.error(error);
    yield put(getArrangeRequiredStatusFailed(error));
  }
}

// Saga
export function* saga() {
  /**
   * setting
   * setting: event panel data
   * setting: tensec Strip
   *
   * fetching: clustering statistics
   * fetching: Form panel data
   * fetching: Event panel data
   * fetching: stop pooling event panel data
   *
   * edit: event
   * edit: Beat Postprocess
   * edit: Arrange
   */

  // setting
  yield takeLatest(SET_PAGE, _setPage);
  // setting: event panel data
  yield takeLatest(SET_LAST_SELECTED_SECTION_INFO, _setLastSelectedSectionInfo);
  // setting: tensec Strip
  yield takeLatest(SET_LAST_SELECTED_SECTION_INFO, _tenSecStripHandler);
  yield takeLatest(PATCH_BEATS_BY_WAVEFORM_INDEX_SUCCEED, _tenSecStripHandler);

  // fetching: clustering statistics
  yield takeLatest(
    GET_CLUSTERING_STATISTICS_REQUESTED,
    _getClusteringStatistics
  );
  // fetching: Form panel data
  yield takeLatest(GET_FORM_LIST_REQUESTED, _getFormListRequested);
  yield takeEvery(GET_RAW_AND_EVENT_REQUESTED, _getRawAndEvent);

  // fetching: Event panel data
  yield debounce(
    1000,
    DEBOUNCE_GET_WAVEFORM_INDEX_LIST_OF_FORM_REQUESTED,
    _getWaveformIndexListOfForm
  );
  yield takeEvery(
    GET_WAVEFORM_INDEX_LIST_OF_FORM_REQUESTED,
    _getWaveformIndexListOfForm
  );
  yield takeEvery(
    GET_POOLING_DATA_OF_EVENT_OF_FORM_REQUESTED,
    _getPoolingDataOfEventOfForm
  );
  // fetching: stop pooling event panel data
  yield takeLatest(SET_PAUSE_POOLING_EVENT_LIST, _setPausePoolingEventList);

  // edit: event
  yield takeLatest(PATCH_BEATS_BY_FORM_ID_REQUESTED, _patchBeatsByFormIds);
  yield takeLatest(
    PATCH_BEATS_BY_WAVEFORM_INDEX_REQUESTED,
    _patchBeatsByWaveformIndexes
  );

  // edit: Beat Postprocess
  yield takeLatest(PATCH_BEAT_POSTPROCESS_REQUESTED, _patchBeatPostprocess);

  // edit: Arrange
  yield takeLatest(
    GET_ARRANGE_REQUIRED_STATUS_REQUESTED,
    _getIsArrangeRequiredRequested
  );
}
