import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { DialogWrapper, DialogButtonWrapper } from 'component/ui/dialog/Dialog';
import TextButton from 'component/ui/button/TextButton';

const TitleText = styled.div`
  font-size: 14px;
  line-height: 130%;
  font-weight: 700;
  margin-bottom: 8px;
  color: ${(props) => props.theme.color.BLACK};
`;

const MessageText = styled.div`
  white-space: pre-wrap;
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 130%;
  color: ${(props) => props.theme.color.BLACK};
`;

function AlertDialog(props) {
  const intl = useIntl();

  const { open, params, callback, onClose, zIndex } = props;
  const { title, message, messageStyle, confirmButtonText } = params;

  return (
    <DialogWrapper zIndex={zIndex ?? 12} open={open} maxWidth={360}>
      {title && <TitleText>{title}</TitleText>}

      {message && <MessageText style={messageStyle}>{message}</MessageText>}

      <DialogButtonWrapper>
        <TextButton
          title={
            confirmButtonText ||
            intl.formatMessage({
              id: '99-Dialog-Button-title-close',
              description: '팝업 Dialog의 닫기 버튼',
              defaultMessage: '닫기',
            })
          }
          onClick={() => {
            onClose();
            if (typeof callback === 'function') {
              callback();
            }
          }}
        />
      </DialogButtonWrapper>
    </DialogWrapper>
  );
}

export default AlertDialog;
