import styled, { keyframes } from 'styled-components';

/**
 * @usecase 
 * animation: ${(props) => (props.visible ? showMenuAnim : hideMenuAnim)} 0.2s ease-in-out;
 * animation: ${(props) =>
            props.visible
                ? showMenuAnim('translateX(50%)')
                : hideMenuAnim('translateX(50%)')}
        0.2s ease-in-out;
 * @param {*} transformOpt 
 * @returns 
 */
export const showMenuAnim = (transformOpt) => keyframes`
    from {
        transform: scale(1, 0.3) ${transformOpt ?? ''};
        opacity: 0;
    }
    to {
        transform: scale(1, 1) ${transformOpt ?? ''};
        opacity: 1;
    }
`;

export const hideMenuAnim = (transformOpt) => keyframes`
    from {
        transform: scale(1, 1) ${transformOpt ?? ''};
        opacity: 1;
    }
    to {
        transform: scale(1, 0.3) ${transformOpt ?? ''};
        opacity: 0;
    }
`;

export const showDissolveAnim = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

export const hideDissolveAnim = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;
