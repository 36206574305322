import { connect } from 'react-redux';
import { showDialog, hideDialog } from 'redux/duck/dialogDuck';
import UploadDataDialog from 'component/dialog/UploadDataDialog';

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showDialog: (dialogKey, params, callback) => {
      dispatch(showDialog(dialogKey, params, callback));
    },
  };
};

const UploadDataDialogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadDataDialog);

export default UploadDataDialogContainer;
