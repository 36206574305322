import { useDispatch } from 'react-redux';

import { authLogoutRequested } from 'redux/duck/authDuck';
import AlertNetworkDialog from 'component/dialog/AlertNetworkDialog';

function AlertNetworkDialogContainer(props) {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(authLogoutRequested());
  };

  return <AlertNetworkDialog {...props} logout={handleLogout} />;
}
export default AlertNetworkDialogContainer;
