/** 웨이브폼 인덱스 */
export type WaveformIndex = number;
/** 밀리세컨드(타임스탬프) */
export type Ms = number;
/** 웨이브폼 인덱스 기반 구간 데이터 구조 */
export type Range = {
  onsetWaveformIndex: WaveformIndex;
  terminationWaveformIndex: WaveformIndex;
} & { [x: string]: any };
/** */
export type OpenableRange =
  | Range
  | {
      onsetWaveformIndex: WaveformIndex;
      terminationWaveformIndex: undefined;
    }
  | {
      onsetWaveformIndex: undefined;
      terminationWaveformIndex: WaveformIndex;
    };
/** Beats API 의 응답 데이터 구조 */
export interface IBeatsResponseResult {
  waveformIndex: Array<WaveformIndex>;
  beatType: Array<number>;
  hr: Array<number>;
}
/** Ectopic API 의 응답 데이터 구조 */
export interface IEctopicResponseResult {
  waveformIndex: Array<WaveformIndex>;
  ectopicType: string;
  beatType: number;
  hrMin: number;
  hrMax: number;
  hrAvg: number;
  position: number;
  beatCount: number;
  isFastest: boolean;
  durationMs: Ms;
  registeredReport: Array<any>;
}
/** 단일 Beat 데이터 구조 */
export interface IBeat {
  waveformIndex: WaveformIndex;
  beatType: number;
  hr: number;
}
/** Beat Event 의 소스 데이터 구조 */
export type BeatTypeGroup = {
  prevBeatWaveformIndex?: WaveformIndex;
  nextBeatWaveformIndex?: WaveformIndex;
  beatType: number;
  onsetRPeakIndex: WaveformIndex;
  waveformIndexList: Array<WaveformIndex>;
};
/** Beat Event 데이터 구조 */
export interface IBeatEvent {
  onsetRPeakIndex: WaveformIndex;
  ectopicType: string;
  beatType: number;
  waveformIndex: Array<WaveformIndex>;
  onsetWaveformIndex: WaveformIndexOrUndefined;
  hasOnsetMarker: boolean;
  prevExclusive: RangeOrUndefined;
  terminationWaveformIndex: WaveformIndexOrUndefined;
  hasTerminationMarker: boolean;
  nextExclusive: RangeOrUndefined;
  type: string;
}
/** 30초 구간에 대한 Beat 정보와 Beat Event 정보 목록 */
export type BeatsNBeatEvents = {
  createAt: Ms;
  onsetWaveformIndex: WaveformIndex;
  terminationWaveformIndex: WaveformIndex;
  beats: IBeatsResponseResult;
  noises: Array<IBeatEvent>;
  ectopics: Array<IBeatEvent>;
  beatEvents: Array<IBeatEvent>;
};
export type WaveformIndexOrUndefined = WaveformIndex | undefined;
/** Beat Event 데이터 구조 또는 undefined */
export type BeatEventOrUndefined = IBeatEvent | undefined;
/** Range 데이터 구조 또는 undefined */
export type RangeOrUndefined = Range | undefined;
/** 대상 Waveform Index 이 포함된 Range 배열을 필터하는 함수 */
export type InRangeFinder = (
  typeChangedRange: OpenableRange,
  isBeatTypeS: boolean
) => Array<Range>;
/** 정규 30초 구간의 BeatsNBeatEvents 관리 구조  */
export type BeatsNBeatEventsList = { [x: WaveformIndex]: BeatsNBeatEvents };
export enum EventUpdateDirection {
  PREV,
  NEXT,
  BI,
}
