// src/mocks/handlers.js
import { rest } from 'msw';

export const handlers = [
  // 모든 Tid 조회
  rest.get('/alarms', (req, res, ctx) => {
    const currentPage = req.url.searchParams.get('currentPage') || 1;
    const itemsPerPage = req.url.searchParams.get('itemsPerPage') || 10;

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = currentPage * itemsPerPage;
    const pageData = mockData.slice(startIndex, endIndex);
    const totalPage = Math.ceil(mockData.length / itemsPerPage);
    console.log(
      `currentPage: ${currentPage}, itemsPerPage: ${itemsPerPage}, totalPage: ${totalPage}`
    );

    if (currentPage >= totalPage) {
      return res(ctx.status(400));
    }

    return res(ctx.delay(500), ctx.json({ pageData, totalPage }));
  }),

  // 하나의 Tid 읽음 처리
  rest.patch('/alarms/:tid/read', (req, res, ctx) => {
    const { tid } = req.params;

    return res(ctx.status(200), ctx.json({ tid }));
  }),

  //  모두 읽음 처리
  rest.patch('/alarms/readAll', (req, res, ctx) => {
    return res(ctx.status(200));
  }),
];

const mockData = [
  {
    shortTid: 'e528a607',
    tid: 'e528a607-a2b4-42ac-9f82-3751d68d4f76',
    content: 'A.fib : 25% burdenㅣPause : 5 episodesㅣAV block',
    time: new Date(),
    // time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'd44ed6ec',
    tid: 'd44ed6ec-0315-4b34-9611-7a84e2a1e6da',
    content: 'A.fib : 35% burdenㅣPause : 4 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'f6a9a4ec',
    tid: 'f6a9a4ec-019b-478e-b292-cfe6173909fb',
    content: 'A.fib : 20% burdenㅣPause : 6 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'ee970e68',
    tid: 'ee970e68-f4b2-4f49-a3fc-1ce962d6b940',
    content: 'A.fib : 30% burdenㅣPause : 3 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'b7cb6ae2',
    tid: 'b7cb6ae2-8f4e-4033-b7c9-85cd68fa8f2f',
    content: 'A.fib : 28% burdenㅣPause : 7 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: '9380668b',
    tid: '9380668b-d2d9-4f76-9e76-f7986cc0135e',
    content: 'A.fib : 32% burdenㅣPause : 2 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: '439f9bd5',
    tid: '439f9bd5-4ac8-4f20-a1cb-c5039745145a',
    content: 'A.fib : 23% burdenㅣPause : 8 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: '6b2baac8',
    tid: '6b2baac8-23f3-477b-aea2-d301be687379',
    content: 'A.fib : 40% burdenㅣPause : 1 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'd445455b',
    tid: 'd445455b-11c7-4d68-bf19-771b1ce8adca',
    content: 'A.fib : 19% burdenㅣPause : 9 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: '174c40d0',
    tid: '174c40d0-ec8f-4d33-b56e-71869b43ae4a',
    content: 'A.fib : 29% burdenㅣPause : 5 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  // 아래는 없는 Tid
  {
    shortTid: 'a8c3d7f1',
    tid: 'a8c3d7f1-23f8-4abc-8d23-5178cdef4567',
    content: 'A.fib : 33% burdenㅣPause : 4 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'b7d9e5f2',
    tid: 'b7d9e5f2-31a9-4ef1-82ba-7158d9ef2345',
    content: 'A.fib : 27% burdenㅣPause : 6 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'c8e1f3g3',
    tid: 'c8e1f3g3-45bc-5de2-93cd-8259e1fgh567',
    content: 'A.fib : 24% burdenㅣPause : 7 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'd9f2g4h4',
    tid: 'd9f2g4h4-56cd-6ef3-a4de-936f2gh4i678',
    content: 'A.fib : 36% burdenㅣPause : 3 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'e0g3h5i5',
    tid: 'e0g3h5i5-67de-7fg4-b5ef-a47g3hi5j789',
    content: 'A.fib : 38% burdenㅣPause : 2 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'f1h4i6j6',
    tid: 'f1h4i6j6-78ef-8gh5-c6fg-b58h4ij6k890',
    content: 'A.fib : 31% burdenㅣPause : 8 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'g2i5j7k7',
    tid: 'g2i5j7k7-89fg-9hi6-d7gh-c69i5jk7l901',
    content: 'A.fib : 21% burdenㅣPause : 9 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'h3j6k8l8',
    tid: 'h3j6k8l8-90gh-0ij7-e8hi-d70j6kl8m012',
    content: 'A.fib : 34% burdenㅣPause : 5 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'i4k7l9m9',
    tid: 'i4k7l9m9-01hi-1jk8-f9ij-e81k7lm9n123',
    content: 'A.fib : 26% burdenㅣPause : 4 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'j5l8m0n0',
    tid: 'j5l8m0n0-12ij-2kl9-g0jk-f92l8mn0o234',
    content: 'A.fib : 37% burdenㅣPause : 3 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'jbsnn5',
    tid: 'jbsnn5-o1gmrt-rkmjj3-tdyrt2-th92ezgi2tgz',
    content: 'A.fib : 3% burdenㅣPause : 7 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 's42q5z',
    tid: 's42q5z-xxz99x-m80247-4qen5e-rya303i2x6cn',
    content: 'A.fib : 23% burdenㅣPause : 4 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'dw8z5d',
    tid: 'dw8z5d-4r7nqi-q2gz6p-41s8su-oifpah38owwg',
    content: 'A.fib : 21% burdenㅣPause : 0 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: '0xfl4p',
    tid: '0xfl4p-dz9t88-497nuo-vymo15-mrs0kd8xshvb',
    content: 'A.fib : 51% burdenㅣPause : 9 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: '4a8s1m',
    tid: '4a8s1m-nmg1v4-drp2xy-ejxudt-i8r3hz2tvtil',
    content: 'A.fib : 2% burdenㅣPause : 8 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: '2qsv4d',
    tid: '2qsv4d-8yu6ly-jgwk9f-yw05li-rnatsvmr6e4y',
    content: 'A.fib : 73% burdenㅣPause : 7 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'gewclp',
    tid: 'gewclp-lks7o6-0l9rob-9zbtet-uq21h2wix2v1',
    content: 'A.fib : 96% burdenㅣPause : 1 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'ri749v',
    tid: 'ri749v-hljcm2-fu91b9-q5fwh1-47ouw9mg59xq',
    content: 'A.fib : 30% burdenㅣPause : 1 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'tllb9r',
    tid: 'tllb9r-tpbanq-v45f8i-6vionk-yikciforitnm',
    content: 'A.fib : 0% burdenㅣPause : 2 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'oz7uz2',
    tid: 'oz7uz2-0frca6-d8p9ld-ej5ryp-t2qquixt0m7v',
    content: 'A.fib : 99% burdenㅣPause : 6 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'my0j50',
    tid: 'my0j50-ejwkr4-heb58s-ya1i7y-7f24wy3b37fs',
    content: 'A.fib : 71% burdenㅣPause : 2 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: '6ew5hm',
    tid: '6ew5hm-19r6zc-sezubt-3mvih3-xxdy5wbendeh',
    content: 'A.fib : 66% burdenㅣPause : 7 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'q91fk8',
    tid: 'q91fk8-0x94vp-78lhmt-slzs64-5cp61p2xh7z4',
    content: 'A.fib : 62% burdenㅣPause : 1 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'i7fmya',
    tid: 'i7fmya-j4t585-asrrqd-bbc5bz-991ca6cdodx0',
    content: 'A.fib : 25% burdenㅣPause : 8 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'lbo50a',
    tid: 'lbo50a-ht4fak-yndnjy-2fs289-0l35lnxwgmte',
    content: 'A.fib : 30% burdenㅣPause : 4 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'n5o4mx',
    tid: 'n5o4mx-ots7he-7frzfy-aenlel-wxszfvaj9rz4',
    content: 'A.fib : 19% burdenㅣPause : 6 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'ppqgf6',
    tid: 'ppqgf6-yrclrn-yk4ci9-ds4ivk-igfu1o06dppa',
    content: 'A.fib : 32% burdenㅣPause : 1 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: '5ypyzn',
    tid: '5ypyzn-dls4hx-m8jtjx-4notag-49huw8odkuan',
    content: 'A.fib : 1% burdenㅣPause : 3 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'w32ve8',
    tid: 'w32ve8-orpu0q-pkr8o7-rc7xth-ltczrovn5wai',
    content: 'A.fib : 92% burdenㅣPause : 2 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'nkc553',
    tid: 'nkc553-h9qryv-62l8if-28e6dy-dir0cmrpa3ab',
    content: 'A.fib : 43% burdenㅣPause : 2 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'bhl2o2',
    tid: 'bhl2o2-9ixbgy-jtw6n5-58al7v-yx5ao4ghn9a3',
    content: 'A.fib : 5% burdenㅣPause : 6 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'pq09l5',
    tid: 'pq09l5-0nehv5-rwrfdn-lw8g96-dare0rxoeynl',
    content: 'A.fib : 44% burdenㅣPause : 0 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'gr64nv',
    tid: 'gr64nv-h40nn9-enoklx-qx9gxp-uyckvvf3v3dx',
    content: 'A.fib : 25% burdenㅣPause : 9 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: '59tn7x',
    tid: '59tn7x-jas481-5gzzv9-hfblxv-k14vgw4nlv5u',
    content: 'A.fib : 65% burdenㅣPause : 7 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: '84ilpm',
    tid: '84ilpm-pk8bj7-fc5398-jlgfg8-b5aoioki4xmf',
    content: 'A.fib : 8% burdenㅣPause : 6 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'rfxaef',
    tid: 'rfxaef-raxr7o-01mayv-yj3wj3-5z284xgzo4mj',
    content: 'A.fib : 83% burdenㅣPause : 9 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'e930zm',
    tid: 'e930zm-xddu7u-0oyi6x-b16ubc-0zjh0fxoq204',
    content: 'A.fib : 55% burdenㅣPause : 1 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'bujxdc',
    tid: 'bujxdc-ltf2c6-9jtzp7-kl7d7y-i07twvye93ki',
    content: 'A.fib : 76% burdenㅣPause : 8 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'wlb4uc',
    tid: 'wlb4uc-or6d63-9m5w7n-enrikj-izj4o5laons1',
    content: 'A.fib : 13% burdenㅣPause : 8 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'sww7gt',
    tid: 'sww7gt-xzuycg-iy9t1j-amitsi-e4ajpvnxrkz0',
    content: 'A.fib : 14% burdenㅣPause : 5 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: '0w41p0',
    tid: '0w41p0-tqqgt6-f2l89n-y35bm5-f37c06xeqdcx',
    content: 'A.fib : 82% burdenㅣPause : 2 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'o2k2ex',
    tid: 'o2k2ex-vh68lq-6xriqp-7c9rv3-fdkl1w70aigp',
    content: 'A.fib : 17% burdenㅣPause : 6 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'hlklb9',
    tid: 'hlklb9-s000ga-50ds6v-kc3lme-d6u5o4n1q94i',
    content: 'A.fib : 82% burdenㅣPause : 7 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'fubtxg',
    tid: 'fubtxg-euo5xo-n9jklw-0d51ro-bhqgczuwil3q',
    content: 'A.fib : 34% burdenㅣPause : 1 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'he1ioo',
    tid: 'he1ioo-kadkz4-694i87-s2xuez-jz8z86b8fs6m',
    content: 'A.fib : 67% burdenㅣPause : 5 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'hdc36u',
    tid: 'hdc36u-oyjbqh-4syde7-g5yi1h-vxsi82pj1deu',
    content: 'A.fib : 58% burdenㅣPause : 0 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'qzuca9',
    tid: 'qzuca9-1o549l-2t0y7c-4r3n5c-4jzjiols5cqe',
    content: 'A.fib : 83% burdenㅣPause : 5 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'xj7ilv',
    tid: 'xj7ilv-byjtit-w1bzj1-7fsdqh-gygq2jdavw7r',
    content: 'A.fib : 86% burdenㅣPause : 3 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'ikut53',
    tid: 'ikut53-aphfnn-83dxx1-3p8h1h-d654kdm595jf',
    content: 'A.fib : 7% burdenㅣPause : 6 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'vs2fuu',
    tid: 'vs2fuu-wj2qjl-ll4b28-6rvbbz-fff98ctvxtmz',
    content: 'A.fib : 66% burdenㅣPause : 4 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'x4h3y3',
    tid: 'x4h3y3-b0y1uk-4p7wpn-nyaslo-1z6z64mw7iko',
    content: 'A.fib : 22% burdenㅣPause : 3 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'fx39g1',
    tid: 'fx39g1-wilv1k-c9t9k7-67f4wh-a59okb93bb9u',
    content: 'A.fib : 40% burdenㅣPause : 4 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'rt8vyg',
    tid: 'rt8vyg-oalb44-zart1d-la0wvd-mjd9rcm7r8k6',
    content: 'A.fib : 37% burdenㅣPause : 3 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'zd2rn6',
    tid: 'zd2rn6-qp6soe-xmr9j4-6q5ngr-y5k6x80usxzx',
    content: 'A.fib : 2% burdenㅣPause : 7 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'gvncm4',
    tid: 'gvncm4-6tkdyt-6dmhbt-ilu2u5-fms86xbf9kpd',
    content: 'A.fib : 83% burdenㅣPause : 6 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'xwdh9k',
    tid: 'xwdh9k-hrr3c9-jo1skv-qgbloz-uevz7sloniwh',
    content: 'A.fib : 35% burdenㅣPause : 1 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'mto14g',
    tid: 'mto14g-z1obr6-a54kqc-mt2w0e-nme7wnbgdmum',
    content: 'A.fib : 45% burdenㅣPause : 0 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'gqdjp2',
    tid: 'gqdjp2-5hnbrx-aso9k4-xiihar-xjozkvihgyfv',
    content: 'A.fib : 48% burdenㅣPause : 7 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'mmytlk',
    tid: 'mmytlk-adgvex-bytp3g-okf8q5-ss2hal8ey2hu',
    content: 'A.fib : 42% burdenㅣPause : 5 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'k0mf56',
    tid: 'k0mf56-lp1fa1-rdnnqb-6874eo-glgq1t5rlrju',
    content: 'A.fib : 19% burdenㅣPause : 7 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: '1yf83v',
    tid: '1yf83v-2m4fw2-vr3bae-j1g8fm-l6i6k9ie3dil',
    content: 'A.fib : 38% burdenㅣPause : 0 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'tjqglp',
    tid: 'tjqglp-8qdttd-m7tytr-m9hq0f-b6x30mz9a96m',
    content: 'A.fib : 52% burdenㅣPause : 9 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'jgh4df',
    tid: 'jgh4df-9omurc-n4pgob-24f63e-s6kd3hxeoqu3',
    content: 'A.fib : 81% burdenㅣPause : 9 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'rmrkr6',
    tid: 'rmrkr6-a6ldow-cu79ky-zcevpc-7rg3e67s7hs8',
    content: 'A.fib : 82% burdenㅣPause : 3 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'b5h3o6',
    tid: 'b5h3o6-u2whew-7l6nnq-03ofco-eg4yystet089',
    content: 'A.fib : 22% burdenㅣPause : 2 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'gjrrrv',
    tid: 'gjrrrv-sanfil-zrluzf-apc2jv-4waxmco8nsqu',
    content: 'A.fib : 14% burdenㅣPause : 1 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'z00ne1',
    tid: 'z00ne1-mz5o58-64vryh-pq3s5u-crhrt6m8qd54',
    content: 'A.fib : 67% burdenㅣPause : 4 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'ykpt3f',
    tid: 'ykpt3f-uuy0xw-od3vlg-7o3btm-r3sk0kwyytg1',
    content: 'A.fib : 22% burdenㅣPause : 8 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'iheazd',
    tid: 'iheazd-o8su3c-llo0oi-b2v2xc-ei4kcf0lqq7t',
    content: 'A.fib : 37% burdenㅣPause : 4 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: '2ibdo3',
    tid: '2ibdo3-ubxe09-u0d2m1-722la8-919ridm1uqs9',
    content: 'A.fib : 69% burdenㅣPause : 1 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: '2oees0',
    tid: '2oees0-ns8bgc-raxj2l-p77so8-jh2dd4wjtqv5',
    content: 'A.fib : 29% burdenㅣPause : 7 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: '167vn8',
    tid: '167vn8-7fystm-bumui7-qee3dv-w011rhc4dxw2',
    content: 'A.fib : 67% burdenㅣPause : 4 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'hf1c21',
    tid: 'hf1c21-gsgexg-87f1go-cqgdcr-56ht9absy0sf',
    content: 'A.fib : 72% burdenㅣPause : 1 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'qxhkz7',
    tid: 'qxhkz7-trbk1o-hbgnr1-etw1yc-80skkkxia7eo',
    content: 'A.fib : 84% burdenㅣPause : 5 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'byucmk',
    tid: 'byucmk-04f4on-4y6bc4-3gltdx-12a278b5msxw',
    content: 'A.fib : 99% burdenㅣPause : 1 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'nz325z',
    tid: 'nz325z-r1dtgm-hpairl-rascv9-gpirf1sm0zpx',
    content: 'A.fib : 1% burdenㅣPause : 3 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'yltlvy',
    tid: 'yltlvy-syow90-simt8x-sn4b6d-2v1ftz4hborg',
    content: 'A.fib : 89% burdenㅣPause : 2 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'vv3a5v',
    tid: 'vv3a5v-kk3j14-nzip01-1653my-a9os62nbsdpy',
    content: 'A.fib : 96% burdenㅣPause : 2 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'zv5jzx',
    tid: 'zv5jzx-rldceq-7i84eo-8qp4rj-vs40kaolaqcq',
    content: 'A.fib : 44% burdenㅣPause : 8 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: '6hoqay',
    tid: '6hoqay-0rq97t-kwpsf0-xbslvd-ovr4pe7wc0hv',
    content: 'A.fib : 11% burdenㅣPause : 4 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'n6olz1',
    tid: 'n6olz1-fm8rs2-azstuv-a3de8c-5f3eeu6koyn6',
    content: 'A.fib : 24% burdenㅣPause : 9 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'iisx26',
    tid: 'iisx26-tz4wd7-k157vy-f6y2j3-talfdcwqlyvg',
    content: 'A.fib : 88% burdenㅣPause : 4 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: '6s04by',
    tid: '6s04by-qk13rx-amlaqa-hdp5pm-dma6x9e60ask',
    content: 'A.fib : 70% burdenㅣPause : 3 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: '7pwpp7',
    tid: '7pwpp7-k2u3it-3aubnh-12z8we-3ao42hihfa6o',
    content: 'A.fib : 85% burdenㅣPause : 8 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'm3ijwu',
    tid: 'm3ijwu-msebmg-pthpdd-r36xfl-rky9rk6gztep',
    content: 'A.fib : 93% burdenㅣPause : 1 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: '2bond3',
    tid: '2bond3-s3tb0h-9gbudu-a37g28-aw9vgfcfibsu',
    content: 'A.fib : 8% burdenㅣPause : 4 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'la2d0q',
    tid: 'la2d0q-eaomq3-abpghb-0w5f11-6567deqxsuat',
    content: 'A.fib : 36% burdenㅣPause : 9 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'qpg3c3',
    tid: 'qpg3c3-kf5pap-m4fxx6-9e1fdt-jc52mwxn42jo',
    content: 'A.fib : 29% burdenㅣPause : 6 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: '6l9vgf',
    tid: '6l9vgf-8f2aie-3kr2er-jc3tng-32j8vis6t5ng',
    content: 'A.fib : 30% burdenㅣPause : 8 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'w1c0tb',
    tid: 'w1c0tb-c2b5bw-2i5139-r3tvi2-wz3qgwrho97t',
    content: 'A.fib : 12% burdenㅣPause : 3 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'ltxcww',
    tid: 'ltxcww-koh1jw-ls1erm-9xa9wz-zszrzh4y9n3m',
    content: 'A.fib : 76% burdenㅣPause : 4 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'pmn1n3',
    tid: 'pmn1n3-3iczrx-ui8xfk-pahmz9-xfnj4bdro6l5',
    content: 'A.fib : 52% burdenㅣPause : 0 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: '069x2e',
    tid: '069x2e-pkh4h7-ge4eqy-r0eu7b-t9y1glu8d7g9',
    content: 'A.fib : 92% burdenㅣPause : 7 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'dq1rdu',
    tid: 'dq1rdu-bupka7-ied6cb-0zbk6x-488guhe80r4t',
    content: 'A.fib : 26% burdenㅣPause : 0 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 't0hru8',
    tid: 't0hru8-29b1gh-roisny-laolxp-gumecfylt4uo',
    content: 'A.fib : 5% burdenㅣPause : 7 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: '92p8m8',
    tid: '92p8m8-bo24gd-o3p22w-aameab-1rgcc7u94a3z',
    content: 'A.fib : 87% burdenㅣPause : 8 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'cik06x',
    tid: 'cik06x-igg5e3-oeyrwg-099crt-dja6mucw53hs',
    content: 'A.fib : 10% burdenㅣPause : 6 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'jjd9nf',
    tid: 'jjd9nf-ukjsge-a4vodk-plaxxa-oj89pg3sb994',
    content: 'A.fib : 31% burdenㅣPause : 0 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'zemt8w',
    tid: 'zemt8w-hoi3rl-iaj7ef-uuuozr-qi968nrb3gqy',
    content: 'A.fib : 71% burdenㅣPause : 7 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'a0i0jr',
    tid: 'a0i0jr-k0prtu-4dqbbs-2kxyyl-xj4m9r3o77f5',
    content: 'A.fib : 59% burdenㅣPause : 3 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'grn088',
    tid: 'grn088-earh8j-owuq30-oumxsm-q6pa4dth9lnk',
    content: 'A.fib : 85% burdenㅣPause : 3 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: '1692hb',
    tid: '1692hb-lg2qcj-24dndc-d5gyqn-azlfgk0m2ao4',
    content: 'A.fib : 38% burdenㅣPause : 7 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'preajs',
    tid: 'preajs-dz4k2m-jajaw9-tlyll8-flgfqlo23rtt',
    content: 'A.fib : 46% burdenㅣPause : 2 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'wfx0qm',
    tid: 'wfx0qm-lkfb4p-ldzo3j-q9immw-d2n18vsyxo4a',
    content: 'A.fib : 98% burdenㅣPause : 7 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'yxa4sd',
    tid: 'yxa4sd-xc4wms-4xkxf1-bwvem6-i18sm1q3oj1l',
    content: 'A.fib : 37% burdenㅣPause : 1 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'hklc90',
    tid: 'hklc90-rg6ol6-c8q7lu-zoolqo-9buxpt0mrfm9',
    content: 'A.fib : 55% burdenㅣPause : 7 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'pio3yh',
    tid: 'pio3yh-jsr36y-shk12p-fhkl1t-3zhtp1d6tmwe',
    content: 'A.fib : 78% burdenㅣPause : 6 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'udnnhq',
    tid: 'udnnhq-q8uxsk-meq0t3-c292g2-cjt4c50bzrxy',
    content: 'A.fib : 23% burdenㅣPause : 7 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'wcelzi',
    tid: 'wcelzi-7g56hw-tbppyw-lfojvw-6pp2kp92azyo',
    content: 'A.fib : 88% burdenㅣPause : 0 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
  {
    shortTid: 'vzs6hl',
    tid: 'vzs6hl-pnys20-kbim8e-8o6fca-svodlm7pg3i9',
    content: 'A.fib : 46% burdenㅣPause : 9 episodesㅣAV block',
    time: makeRandomDate(),
    isRead: makeRandomBoolean(),
  },
].sort((a, b) => b.time - a.time);

function makeRandomDate() {
  const currentDate = new Date().getTime();

  // 1일의 밀리초
  const oneDayMillis = 24 * 60 * 60 * 1000;

  // 45일의 밀리초 (한 달 반으로 가정)
  const oneAndHalfMonthMillis = 45 * oneDayMillis;

  const randomMillis = Math.floor(Math.random() * oneAndHalfMonthMillis);
  const randomTimestamp = currentDate - randomMillis;

  return randomTimestamp;
}

function makeRandomBoolean() {
  return Math.random() < 0.5;
}
