import styled from 'styled-components';

import Const from 'constant/Const';

import TestNotificationDropdownContainer from 'redux/container/fragment/main/TestNotificationDropdownContainer';

import Avatar from 'component/ui/image/Avatar';
import Menu from 'component/ui/menu/Menu';
import MenuItem from 'component/ui/menu/MenuItem';

import { ReactComponent as LogoMemoSmall } from 'static/image/logo-memo-small.svg';

function Header(props) {
  const {
    history,
    // Redux state
    authState,
    // Redux dispatch
    handleLogout,
  } = props;

  const profile = authState.me;
  const isInternalUser =
    profile?.relationType === Const.USER_RELATION_TYPE.INTERNAL;

  return (
    <Wrapper>
      <LogoImage
        onClick={() => {
          history.push('/');
        }}
      />
      <Filler />

      {profile?.username && (
        <>
          {isInternalUser && <TestNotificationDropdownContainer />}
          <Avatar size={24} src={null} />
          <Menu
            style={{ marginLeft: 8 }}
            title={`${profile.hospital?.affiliation ?? ''} ${
              profile.hospital?.hospitalName ?? ''
            } ${profile.lastName}${profile.firstName}님`}>
            <MenuItem
              title={'내 계정'}
              onClick={() => {
                history.push('/my-account');
              }}
            />
            <MenuItem
              title={'비밀번호 변경'}
              onClick={() => {
                history.push('/change-password');
              }}
            />
            {isInternalUser && (
              <MenuItem
                title={'알림 목록'}
                onClick={() => {
                  history.push('/test-notification-history');
                }}
              />
            )}
            <MenuItem
              title={'로그아웃'}
              onClick={() => {
                handleLogout();
              }}
            />
            <VersionWrapper>
              <Divider />
              <VersionText>{`Version ${process.env.REACT_APP_VERSION}`}</VersionText>
            </VersionWrapper>
          </Menu>
        </>
      )}
    </Wrapper>
  );
}

export default Header;

const Wrapper = styled.div`
  height: 36px; // 36 + 14 = 50
  width: calc(100% - 40px);
  padding: 7px 20px;
  position: fixed;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 4px 0px ${({ theme }) => theme.color.BLACK_BOX_SHADOW};
  z-index: 10;
`;

const Filler = styled.div`
  flex: 1;
`;

const LogoImage = styled(LogoMemoSmall)`
  cursor: pointer;
`;

const VersionWrapper = styled.div`
  gap: 1px;
`;
const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.color.COOL_GRAY_30};
`;

const VersionText = styled.div`
  padding: 6px 12px 3px;
  font-weight: 500;
  font-size: 10px;
  line-height: 130%;
  color: ${({ theme }) => theme.color.COOL_GRAY_70};
`;
