import { SUFFIX_LIST } from 'constant/EventConst';

import ApiManager from 'network/ApiManager';

export const BEATS_API_MANAGER_MAP = {
  [SUFFIX_LIST.EDIT_BEAT_SUFFIX.ADD_BEATS]: ApiManager.postAddBeats,
  [SUFFIX_LIST.EDIT_BEAT_SUFFIX.UPDATE_BEATS_BY_BETWEEN_INDEX]:
    ApiManager.patchUpdateBeatsByBetweenIndex,
  [SUFFIX_LIST.EDIT_BEAT_SUFFIX.UPDATE_BEATS_BY_INDEXES]:
    ApiManager.patchUpdateBeatsByIndexes,
  [SUFFIX_LIST.EDIT_BEAT_SUFFIX.REMOVE_BEATS]: ApiManager.deleteRemoveBeats,
};
