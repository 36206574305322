import styled, { useTheme } from 'styled-components';
import { PhotoshopPicker } from 'react-color';
import { useState } from 'react';

import { useDarkMode } from 'component/page/MyAccountPage';

function ColorPalette() {
  const [color, setColor] = useState(() => {
    return sessionStorage.getItem('color') || '';
  });

  const handleChangeComplete = (color) => {
    setColor(color.hex);
    sessionStorage.setItem('color', color.hex);
  };

  return (
    <Wrapper>
      <PhotoshopPicker color={color} onChangeComplete={handleChangeComplete} />
      <ColorBoxContainer />
    </Wrapper>
  );
}

function ColorBoxContainer() {
  const { onClick } = useDarkMode();
  const { color: colors } = useTheme();

  return (
    <ColorBoxContainerWrapper onClick={onClick}>
      {Object.entries(colors).map(([name, color]) => (
        <ColorBox key={name} color={color} name={name} />
      ))}
    </ColorBoxContainerWrapper>
  );
}

function ColorBox(props) {
  const { color, name } = props;
  const textColor = getTextColor(color); // 배경색에 따라 글자색을 결정

  return (
    <ColorBoxWrapper bgColor={color} textColor={textColor}>
      <ColorBoxText textColor={textColor}>{name}</ColorBoxText>
      <ColorBoxText textColor={textColor}>{color}</ColorBoxText>
    </ColorBoxWrapper>
  );
}

function hexToRgb(hex) {
  let r = 0;
  let g = 0;
  let b = 0;
  // 3자리 HEX
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  }
  // 6자리 HEX
  else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return [r, g, b];
}

function rgbaToRgb(rgba) {
  // 백분율 포함한 rgba 정규 표현식
  const result = rgba.match(
    /rgba?\((\d{1,3}), (\d{1,3}), (\d{1,3})(?:, (\d+\.?\d*%?))?\)/
  );
  if (result) {
    return [
      parseInt(result[1], 10),
      parseInt(result[2], 10),
      parseInt(result[3], 10),
    ];
  }
  return null;
}

// rgba 색상에서 백그라운드와의 대조를 위해 텍스트 색상을 결정하는 함수
const getTextColor = (backgroundColor) => {
  // HEX 색상인 경우 또는 알파 값이 100%인 rgba 색상인 경우
  if (backgroundColor.startsWith('#')) {
    const rgb = hexToRgb(backgroundColor);
    // 명도 계산
    const brightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
    return brightness > 128 ? 'black' : 'white';
  }
  // rgba 색상에서 알파 값이 백분율로 주어진 경우
  else if (backgroundColor.startsWith('rgba')) {
    // 알파 채널을 무시하고 기본 색상으로만 명도 계산
    const rgb = rgbaToRgb(backgroundColor);
    if (rgb) {
      const brightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
      return brightness > 128 ? 'black' : 'white';
    }
  }
  // 기본적으로 검은색 텍스트를 반환
  return 'black';
};

const Wrapper = styled.div`
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

const ColorBoxContainerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
`;

const ColorBoxWrapper = styled.div`
  flex: 0 0 calc(10% - 10px); /* flex-basis를 계산하여 각 박스가 10%의 너비를 차지하도록 합니다. */
  height: 100px;
  margin-bottom: 10px;
  border: 1px solid #000;
  background-color: ${({ bgColor }) => bgColor};
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  margin: 10px;
  border: 1px solid black;
  position: relative;
  color: ${({ textColor }) => textColor};
`;

const ColorBoxText = styled.div`
  width: 100%;
  text-align: center;
  background-color: ${({ textColor }) =>
    textColor === 'black' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'};
`;
export default ColorPalette;
