import React, { useCallback, useEffect, useRef } from 'react';
// Dialog
import DialogDict from 'component/dialog';

function CentralizedDialog(props) {
  const { dialog, hideDialog, onSubmit } = props;

  return (
    <>
      {Object.keys(DialogDict).map((dialogKey, index) => {
        const DialogObject = DialogDict[dialogKey];

        if (DialogObject) {
          const dialogState = dialog[dialogKey];
          if (!dialogState.isOpen) return;

          return (
            <DialogObject.Component
              key={dialogKey}
              dialogKey={dialogKey}
              hideDialog={hideDialog}
              open={dialogState.isOpen}
              params={dialogState.params}
              callback={dialogState.callback}
              onClose={() => {
                hideDialog(dialogKey);
              }}
            />
          );
        } else {
          return null;
        }
      })}
    </>
  );
}

export default CentralizedDialog;
