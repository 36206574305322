const DialogUtil = {
  holdBody: () => {
    document.body.style.overflowY = 'hidden';
  },
  unHoldBody: () => {
    document.body.style.overflowY = 'auto';
  },
};

export default DialogUtil;
