import { useEffect, useState } from 'react';
import styled from 'styled-components';

import LocalStorageKey from 'constant/LocalStorageKey';

import { BlackModeIcon, LightModeIcon } from 'component/ui/icons';

function MyAccountPage(props) {
  const {
    // Redux state
    authState,
  } = props;

  const profile = authState.me;

  return (
    <Wrapper>
      <InnerHeaderContainer>
        <TitleText>내 계정</TitleText>
      </InnerHeaderContainer>

      <ContentContainer>
        <ProfileContainer>
          <InfoContainer>
            <InfoItem
              name={'이름'}
              value={`${profile.lastName}${profile.firstName}`}
            />
            <InfoItem name={'소속 기관'} value={profile.hospital.affiliation} />
          </InfoContainer>

          <InfoContainer>
            <InfoItem name={'이메일'} value={profile.email} />
            <InfoItem
              name={'사용 권한'}
              value={profile.isHospitalAdmin ? '전체' : '파트너'}
            />

            <GuideText>
              변경이 필요한 경우 휴이노 고객센터로 문의하세요.
            </GuideText>
          </InfoContainer>
        </ProfileContainer>
        <ThemeSetting />
      </ContentContainer>
    </Wrapper>
  );
}

function InfoItem(props) {
  const { name, value } = props;
  return (
    <InfoItemWrapper>
      <ItemName>{name}</ItemName>
      <ItemValue>{value}</ItemValue>
    </InfoItemWrapper>
  );
}

function ThemeSetting(props) {
  return (
    <ThemeSettingWrapper>
      <ThemeSettingTextWrapper>
        <ThemeSettingText>테마 설정</ThemeSettingText>
        <ThemeSettingBetaText>Beta</ThemeSettingBetaText>
      </ThemeSettingTextWrapper>
      <ThemeSwitch />
    </ThemeSettingWrapper>
  );
}

function ThemeSwitch() {
  const { isDarkMode, onClick } = useDarkMode();

  return (
    <SwitchContainer isDarkMode={isDarkMode} onClick={onClick}>
      <SwitchButton isDarkMode={isDarkMode}>
        <Icon as={isDarkMode ? BlackModeIcon : LightModeIcon} />
      </SwitchButton>
    </SwitchContainer>
  );
}

export function useDarkMode() {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    return localStorage.getItem(LocalStorageKey.IS_DARK_MODE) === 'true';
  });

  useEffect(() => {
    document.documentElement.classList.toggle('dark-mode', isDarkMode);
  }, []);

  const onClick = () => {
    setIsDarkMode((prev) => {
      localStorage.setItem(LocalStorageKey.IS_DARK_MODE, !prev);
      return !prev;
    });

    setTimeout(() => {
      // 동적으로 css를 변경하기 위해 페이지를 새로고침
      window.location.reload();
    }, 200);
  };

  return { isDarkMode, onClick };
}

const SwitchContainer = styled.div`
  cursor: pointer;
  position: relative;
  width: 56px;
  height: 32px;
  border-radius: 100px;
  background-color: ${({ isDarkMode, theme }) =>
    isDarkMode ? theme.color.COOL_GRAY_60 : theme.color.COOL_GRAY_30};
  transition: background-color 0.2s;
`;

const SwitchButton = styled.div`
  position: absolute;
  top: 4px;
  left: ${({ isDarkMode }) => (isDarkMode ? '28px' : '5px')};
  width: 24px;
  height: 24px;
  border-radius: 20px;
  transition: left 0.2s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.WHITE};
`;

const Icon = styled.svg`
  width: 16px;
  height: 16px;
  fill: ${({ theme }) => theme.color.BLACK};
`;

const InfoItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const ItemName = styled.div`
  font-size: 11px;
  color: ${(props) => props.theme.color.BLACK};
`;

const ItemValue = styled.div`
  margin-top: 2px;
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => props.theme.color.BLACK};
`;

const Wrapper = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
`;

const InnerHeaderContainer = styled.div`
  padding: 12px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.COOL_GRAY_40};
`;

const TitleText = styled.div`
  flex: 5;
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.color.BLACK};
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ProfileContainer = styled.div`
  max-width: 596px;
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.color.COOL_GRAY_40};
`;

const ThemeSettingWrapper = styled.div`
  width: 100%;
  max-width: 596px;
  height: 64px;
  padding: 24px 48px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.color.COOL_GRAY_40};
`;

const ThemeSettingTextWrapper = styled.div`
  display: flex;
  gap: 4px;
  font-size: 12px;
  font-weight: 500;
`;

const ThemeSettingText = styled.div`
  color: ${({ theme }) => theme.color.COOL_GRAY_90};
`;

const ThemeSettingBetaText = styled.div`
  font-family: Albert Sans;
  font-style: italic;
  color: ${({ theme }) => theme.color.BLUE_70};
`;

const InfoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & > * {
    margin-left: 16px;
    :not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

const GuideText = styled.span`
  font-size: 11px;
  margin-top: 22px;
  color: ${(props) => props.theme.color.DARK};
`;

export default MyAccountPage;
