import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
`;

const Content = styled.div`
  flex: 1;
  overflow: scroll;
`;

function FullScreenLayout(props) {
  return (
    <Wrapper>
      <Content>{props.children}</Content>
    </Wrapper>
  );
}

export default FullScreenLayout;
