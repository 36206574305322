import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import DropdownItem from 'component/ui/dropdown/DropdownItem';
import { showMenuAnim, hideMenuAnim } from 'component/ui/animation/Animation';
import { ReactComponent as DropdownArrowIcon } from 'static/icon/icon-dropdown-arrow.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  :hover {
    cursor: pointer;
  }
  z-index: 1;
  border-radius: 16px;
`;

const AnchorContainer = styled.div`
  padding: 4px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.color.LIGHT};
  color: ${(props) => props.theme.color.LIGHT_GREY_BB};
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.color.LIGHT_GREY_BB};
  :hover {
    border-color: ${(props) => props.theme.color.PRIMARY};
  }
  transition: border-color 0.2s ease-in-out;
`;

const AnchorValueText = styled.div`
  color: ${(props) => props.theme.color.DARK};
`;

const ItemContainer = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  box-shadow: 0 8px 16px 0 rgba(48, 48, 48, 0.1);
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  animation: ${(props) => (props.visible ? showMenuAnim : hideMenuAnim)} 0.2s
    ease-in-out;
  -webkit-transition: -webkit-visibility 0.2s ease-in-out;
  transition: visibility 0.2s ease-in-out;
  ${(props) =>
    props.dropToTop
      ? `
          transform-origin: bottom;
          bottom: 40px;`
      : `
          transform-origin: top;
          top: 40px;
    `};
`;

function Dropdown(props) {
  const { items, value, dropToTop, onChange } = props;

  const [show, _setShow] = useState(false);
  const anchorRef = useRef(null);
  const showStateRef = useRef(show);
  const setShow = (data) => {
    showStateRef.current = data;
    _setShow(data);
  };

  useEffect(() => {
    document.addEventListener('click', onMouseClick);

    return () => {
      document.removeEventListener('click', onMouseClick);
    };
  }, []);

  const onMouseClick = (event) => {
    if (
      showStateRef.current &&
      anchorRef.current &&
      anchorRef.current !== event.target
    ) {
      setShow(false);
    }
  };

  return (
    <Wrapper>
      {/* Dropdown anchor */}
      <AnchorContainer
        ref={anchorRef}
        onClick={(event) => {
          event.stopPropagation();
          setShow(!show);
        }}>
        <AnchorValueText>{value}</AnchorValueText>
        <DropdownArrowIcon />
      </AnchorContainer>

      {/* Dropdown list */}
      <ItemContainer visible={show} dropToTop={dropToTop}>
        {items.map((item) => {
          return (
            <DropdownItem
              key={item}
              title={item}
              onClick={() => {
                onChange(item);
              }}
            />
          );
        })}
      </ItemContainer>
    </Wrapper>
  );
}

export default Dropdown;
