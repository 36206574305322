const ResultCode = {
  AUTH_STATUS: {
    /**
     * @description 로그인 성공
     */
    NORMAL: 1000,
    CHANGE_PASSWORD_REQUIRED: 1001,
    DORMANT: 1002,
    INITIAL_PASSWORD_REQUIRED: 1003,
    NON_EXISTENT: 1004,
  },
};

export default ResultCode;
