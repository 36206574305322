import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { REPORT_SECTION_TITLE_SHORT } from 'constant/EventConst';

import { getReportSectionValidationMap } from 'util/EventConstUtil';

import usePrevious from 'component/hook/usePrevious';
import useShallowEqualSelector from 'component/hook//useShallowEqualSelector';

import {
  getAllStatisticsRequest,
  requestPrintReportRequested,
} from 'redux/duck/testResultDuck';
import {
  hideDialog,
  showDialog as showDialogRequested,
} from 'redux/duck/dialogDuck';
import { getValidReportRequested } from 'redux/duck/ecgTestsDuck';

import { useIntlMsg } from './useIntlMsg';

/**
 * 서버로부터 ecgStatistics와 reportStatistics를 불러와 두개를 비교하여 validation하거나 리포트를 생성하는데 사용하는 커스텀훅입니다.
 *
 * @param {string} ecgTestId report 생성시 필요한 tid
 * @param {function} callback hook이 끝나고 나서 실행할 callback function
 * validateStt는 현재 훅의 trigger입니다.
 * validateStt의 param에 boolean값이 들어갑니다. param(isShowDialog)이 true인 경우만 dialog를 보여줍니다.(리포트생성을 위해 검증하는 경우)
 * result에 report그룹탭에 있는 항목들의 validation 결과값이 들어갑니다.
 * isAllValidated는 createReport나 validateReport를 하기 위해 검증할 때 필요한 boolean값이 들어갑니다.
 * @returns {validateStt: function, result : {ADDITIONAL: boolean, AF: boolean, PAUSE:boolean, PTE: boolean, SUMMARY: boolean, SVE: boolean, SVT: boolean, VE: boolean, VT: boolean}, isAllValidated: boolean}
 */
function useValidateReportAndStatistics({ ecgTestId, callback = () => {} }) {
  const dispatch = useDispatch();
  const {
    리포트를_생성하시겠습니까,
    리포트_생성에_N분_정도소요됩니다,
    닫기,
    리포트_생성하기,
    리포트_생성시_일부_스트립이_누락되어_페이지에서_제외됩니다,
    누락된_스트립_STRING,
    다시_생성하기,
    취소,
    리포트에_스트립을_담아주세요,
    STRING_완료를_위해_누락된_스트립을_최소_1개씩_담아주세요,
    확인,
  } = useIntlMsg(INTL_MAP);

  // Store State
  const { pending: allStatisticsPending, data: allStatisticsData } =
    useShallowEqualSelector(
      ({ testResultReducer: state }) => state.allStatistics
    );

  /** PA 임상을 위한 속성
   * @type {boolean} PA임상용 ECG Test인지 여부를 나타내는 속성
   *
   * isMainEcgTest {boolean} PA임상용 ECG Test인지 여부를 나타내는 속성
   * isMainEcgTest가 false인 경우에는 리포트 생성시 validation을 무시한다.
   *
   * true  : 메인 ECG Test
   * false : PA임상용 ECG Test
   */
  const isMainEcgTest = useShallowEqualSelector(
    (state) => state.testResultReducer.ecgTest.data?.isMainEcgTest
  );
  const isMedicalCheckUpTest = useShallowEqualSelector(
    (state) => state.testResultReducer.ecgTest.data?.isMedicalCheckUpTest
  );

  // Dispatches
  const getAllStatistics = (_isShowDialog, purposeText) => {
    setIsShowDialog(_isShowDialog);
    setPurposeText(purposeText);
    dispatch(getAllStatisticsRequest(ecgTestId));
  };
  const showDialog = (dialogKey, params, callback) => {
    dispatch(showDialogRequested(dialogKey, params, callback));
  };
  const handleHideDialog = (dialogKey) => {
    dispatch(hideDialog(dialogKey));
  };
  const requestPrintReport = (tid, request) => {
    dispatch(requestPrintReportRequested(tid, request));
  };
  const getValidReport = () => {
    dispatch(getValidReportRequested({ ecgTestId }));
  };

  // States
  const [isAllValidated, setIsAllValidated] = useState(false);
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [purposeText, setPurposeText] = useState('');

  // Effects
  const prevAllStatisticsPending = usePrevious(allStatisticsPending);
  useEffect(() => {
    if (!prevAllStatisticsPending) return;
    if (allStatisticsPending) return;
    if (!allStatisticsData) return;

    if (!isShowDialog) {
      callback();
      return;
    }

    let _isAllValidated = false;

    //sub test는 리포트 검증을 하지 않는다. (for PA 임상)
    const overrideReportValidationForSubTest =
      !isMainEcgTest || isMedicalCheckUpTest;
    if (overrideReportValidationForSubTest) {
      _isAllValidated = true;
      // 임상 검사는 리포트 검증을 하지 않는다.
      purposeText ? getValidReport() : dialogAskSkipValidation();
    } else {
      const { ecgStatistics, reportStatistics, afMinInfoList } =
        allStatisticsData;
      const validationResult = getReportSectionValidationMap(
        ecgStatistics,
        reportStatistics,
        afMinInfoList
      );
      const invalidSectionsToString = Object.entries(validationResult)
        .filter(([, value]) => value === false)
        .map(([key]) => `[${REPORT_SECTION_TITLE_SHORT[key]}]`)
        .join(', '); //  ===  '[Pause], [VE], [SVT], [SVE], [VT], [AF], ...'
      const _isAllValidated = Object.values(validationResult).every(Boolean);

      if (_isAllValidated && !purposeText) dialogAskGenerate();
      else if (_isAllValidated && purposeText) getValidReport();
      else if (!_isAllValidated && purposeText)
        dialogAlertValidationError(purposeText, invalidSectionsToString);
      else if (!_isAllValidated && !purposeText)
        dialogAskSkipValidation(invalidSectionsToString);
    }

    setIsAllValidated(_isAllValidated);
    setIsShowDialog(false);
    setPurposeText('');
  }, [allStatisticsPending]);

  // Functions
  function dialogAskGenerate() {
    showDialog('ConfirmDialog', {
      title: 리포트를_생성하시겠습니까,
      message: 리포트_생성에_N분_정도소요됩니다({
        minute: '1~2',
      }),
      cancelButtonText: 닫기,
      confirmButtonText: 리포트_생성하기,
      onSubmit: () => {
        handleHideDialog('FinalConfirmReportDialog');
        requestPrintReport(ecgTestId);
      },
    });
  }
  function dialogAskSkipValidation(invalidSectionsToString) {
    showDialog('ConfirmDialog', {
      title: 리포트를_생성하시겠습니까,
      message: (
        <ValidErrorMessage
          message01={리포트_생성시_일부_스트립이_누락되어_페이지에서_제외됩니다}
          message02={누락된_스트립_STRING({
            invalidSectionsToString,
          })}
        />
      ),
      cancelButtonText: 취소,
      confirmButtonText: 다시_생성하기,
      onSubmit: () => {
        handleHideDialog('FinalConfirmReportDialog');
        requestPrintReport(ecgTestId);
      },
    });
  }
  function dialogAlertValidationError(purposeText, invalidSectionsToString) {
    showDialog(
      'AlertDialog',
      {
        title: 리포트에_스트립을_담아주세요,
        message: (
          <ValidErrorMessage
            message01={STRING_완료를_위해_누락된_스트립을_최소_1개씩_담아주세요(
              {
                purposeText,
              }
            )}
            message02={누락된_스트립_STRING({
              invalidSectionsToString,
            })}
          />
        ),
        confirmButtonText: 확인,
      },
      callback
    );
  }

  return {
    validateStt: getAllStatistics,
    isAllValidated,
    validateSttPending: allStatisticsPending,
  };
}

function ValidErrorMessage(props) {
  const { message01, message02 } = props;
  return (
    <>
      <DialogMessage>{message01}</DialogMessage>
      <DialogMessage style={{ paddingTop: 10, fontWeight: 500 }}>
        {message02}
      </DialogMessage>
    </>
  );
}

const INTL_MAP = {
  리포트를_생성하시겠습니까: {
    id: '99-AlertDialog-ReportAskGenerate-title',
    description: '리포트를 생성하시겠습니까?',
    defaultMessage: '리포트를 생성하시겠습니까?',
  },
  리포트_생성에_N분_정도소요됩니다: {
    id: '99-AlertDialog-ReportAskGenerate-message',
    description: '리포트 생성에 {minute}분 정도 소요됩니다.',
    defaultMessage: '리포트 생성에 {minute}분 정도 소요됩니다.',
    value: (value) => value,
  },
  닫기: {
    id: '99-AlertDialog-ReportAskGenerate-cancelButtonText-01',
    description: '닫기',
    defaultMessage: '닫기',
  },
  리포트_생성하기: {
    id: '99-AlertDialog-ReportAskGenerate-confirmButtonText-01',
    description: '리포트 생성하기',
    defaultMessage: '리포트 생성하기',
  },
  리포트_생성시_일부_스트립이_누락되어_페이지에서_제외됩니다: {
    id: '99-AlertDialog-dialogAskSkipValidation-message-01',
    description:
      '리포트 생성시 일부 스트립이 누락되어 페이지에서 제외됩니다. / To generate the report, you must have at least one strip on each report page.',
    defaultMessage:
      '리포트 생성시 일부 스트립이 누락되어 페이지에서 제외됩니다.',
  },
  누락된_스트립_STRING: {
    id: '99-AlertDialog-ReportValidError-message-02',
    description:
      '- 누락된 스트립: {invalidSectionsToString} / - Pages with no strips: {invalidSectionsToString}',
    defaultMessage: '- 누락된 스트립: {invalidSectionsToString}',
    value: (value) => value,
  },
  다시_생성하기: {
    id: '99-AlertDialog-ReportValidError-confirmButtonText-01',
    description: '다시 생성하기',
    defaultMessage: '다시 생성하기',
  },
  취소: {
    id: '99-AlertDialog-ReportValidError-cancelButtonText-01',
    description: '취소 / Cancel',
    defaultMessage: '취소',
  },
  리포트에_스트립을_담아주세요: {
    id: '99-AlertDialog-dialogAlertValidationError-title',
    description: '리포트에 스트립을 담아주세요.',
    defaultMessage: '리포트에 스트립을 담아주세요.',
  },
  STRING_완료를_위해_누락된_스트립을_최소_1개씩_담아주세요: {
    id: '99-AlertDialog-dialogAlertValidationError-message-01',
    description: '검토 완료를 위해 누락된 스트립을 최소 1개씩 담아주세요.',
    defaultMessage:
      '{purposeText} 완료를 위해 누락된 스트립을 최소 1개씩 담아주세요.',
    value: (value) => value,
  },
  확인: {
    id: '99-AlertDialog-dialogAlertValidationError-confirmButtonText-01',
    description: '확인',
    defaultMessage: '확인',
  },
};

const DialogMessage = styled.div`
  color: ${({ theme }) => theme.color.COOL_GRAY_90};
  line-height: 130%;
`;

export default useValidateReportAndStatistics;
