import { floor } from 'mathjs';

const numberFormatter = new Intl.NumberFormat('en-US');

const NumberUtil = {
  fillZero: (number, targetDigit) => {
    return (new Array(targetDigit).fill(0).join('') + number).slice(
      -targetDigit
    );
  },
  threeDigitComma(number) {
    if (!number || !isNumber(number)) {
      return number;
    }
    return numberFormatter.format(number);
    // return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
  phone(phoneNum) {
    return phoneNum.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  },
  getNumbers(str) {
    return str.replace(/\D/g, '');
  },
};

export default NumberUtil;

export function isNumber(value) {
  const result = typeof value === 'number' && !Number.isNaN(value);
  return result;
}

export function isPositiveNumber(value) {
  const result = isNumber(value) && value >= 0;
  return result;
}

/**
 * 숫자 값을 특정 소수점 자리수까지 표현된 문자열로 변환
 * @param {number} floatValue 변환할 숫자값
 * @param {number} decimalPlace 문자열로 표현되어야 하는 소수점 자리수
 * @returns {string}
 */
export function getStringFloat(floatValue, decimalPlace = 0) {
  if (typeof floatValue === 'number') {
    return floor(floatValue, decimalPlace).toFixed(decimalPlace);
  }

  return '-';
}
