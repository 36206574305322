import {
  BEAT_REVIEW_FETCHING_OPTION,
  CHART_EDIT_CONST,
  OFFSET_POSITION_ARRAY,
  POSITION_MOVE_TYPE,
} from 'constant/ChartEditConst';
import { isEmpty } from 'util/validation/ValidationUtil';

export function reArrangeOfPosition({
  clickedEventType,
  currentPositionIndex,
  sidePanelState,
  eventOnsetWaveformIndexList,
  rawAndEventList,
}) {
  try {
    let result = currentPositionIndex;
    const waveformIndexOfCurrentPositionIndex =
      eventOnsetWaveformIndexList[clickedEventType][currentPositionIndex];
    const hasRawAndEventCurrentPositionIndex = !isEmpty(
      rawAndEventList[clickedEventType][waveformIndexOfCurrentPositionIndex]
    );

    if (!hasRawAndEventCurrentPositionIndex) {
      result = sidePanelState.currentPositionIndex;
    }
    return result;
  } catch (error) {
    console.error('error during reArrangeOfPosition');
  }
}

export function getDirectionOfMovement(prevPositionIndex, nextPositionIndex) {
  return prevPositionIndex - nextPositionIndex > 0
    ? CHART_EDIT_CONST.TEN_SEC_STRIP_MOVE_DIR.PREV
    : CHART_EDIT_CONST.TEN_SEC_STRIP_MOVE_DIR.NEXT;
}

export const beatReviewUpdatePostProcess = {
  patchBeatEventUpdate: (
    deepCopy_RawAndEventListOfCurrentPositionIndex: any,
    editedEventWaveformIndex: any,
    updateWaveformIndexList: [{ waveformIndex: [], hr?: [], beatType?: [] }],
    i: number
  ) => {
    const indexOfReqWaveformIndexInCurrentPositionIndexRawAndEvent =
      deepCopy_RawAndEventListOfCurrentPositionIndex.beats.waveformIndex.indexOf(
        editedEventWaveformIndex
      );
    deepCopy_RawAndEventListOfCurrentPositionIndex.beats.beatType[
      indexOfReqWaveformIndexInCurrentPositionIndexRawAndEvent
    ] = updateWaveformIndexList.beatType[i];
    deepCopy_RawAndEventListOfCurrentPositionIndex.beats.hr[
      indexOfReqWaveformIndexInCurrentPositionIndexRawAndEvent
    ] = updateWaveformIndexList.hr[i];

    return deepCopy_RawAndEventListOfCurrentPositionIndex;
  },
  deleteBeatEventUpdate: (
    deepCopy_RawAndEventListOfCurrentPositionIndex: any,
    editedEventWaveformIndex: any,
    updateWaveformIndexList: [waveformIndex],
    i: number
  ) => {
    const indexOfReqWaveformIndexInCurrentPositionIndexRawAndEvent =
      deepCopy_RawAndEventListOfCurrentPositionIndex.beats.waveformIndex.indexOf(
        editedEventWaveformIndex
      );
    deepCopy_RawAndEventListOfCurrentPositionIndex.beats.waveformIndex.splice(
      indexOfReqWaveformIndexInCurrentPositionIndexRawAndEvent,
      1
    );
    deepCopy_RawAndEventListOfCurrentPositionIndex.beats.beatType.splice(
      indexOfReqWaveformIndexInCurrentPositionIndexRawAndEvent,
      1
    );
    deepCopy_RawAndEventListOfCurrentPositionIndex.beats.hr.splice(
      indexOfReqWaveformIndexInCurrentPositionIndexRawAndEvent,
      1
    );

    return deepCopy_RawAndEventListOfCurrentPositionIndex;
  },
  postBeatEventUpdate: (
    deepCopy_RawAndEventListOfCurrentPositionIndex: any,
    editedEventWaveformIndex: any,
    updateWaveformIndexList: {
      waveformIndex: [waveformIndex],
      hr?: [number],
      beatType?: [number],
    },
    i: number
  ) => {
    const indexOfReqWaveformIndexInCurrentPositionIndexRawAndEvent =
      deepCopy_RawAndEventListOfCurrentPositionIndex.beats.waveformIndex.findIndex(
        (v) => v > editedEventWaveformIndex
      );
    deepCopy_RawAndEventListOfCurrentPositionIndex.beats.waveformIndex.splice(
      indexOfReqWaveformIndexInCurrentPositionIndexRawAndEvent,
      0,
      updateWaveformIndexList.waveformIndex[0]
    );
    deepCopy_RawAndEventListOfCurrentPositionIndex.beats.beatType.splice(
      indexOfReqWaveformIndexInCurrentPositionIndexRawAndEvent,
      0,
      updateWaveformIndexList.beatType[0]
    );
    deepCopy_RawAndEventListOfCurrentPositionIndex.beats.hr.splice(
      indexOfReqWaveformIndexInCurrentPositionIndexRawAndEvent,
      0,
      updateWaveformIndexList.hr[0]
    );

    return deepCopy_RawAndEventListOfCurrentPositionIndex;
  },
};

export const getRawAndEventProcess = {
  getLastOfFetchedIndex,
  getInfoSliceInfo,
};

export function getLastOfFetchedIndex(
  currentPositionIndex,
  poolingStartIndexOfWaveformIndexList
) {
  let result;
  if (currentPositionIndex) {
    //  case3. current position jump
    result = currentPositionIndex;
  } else {
    // case1. 이벤트 클릭시 (비트리뷰 탭에서 사이드 패널)
    // case2. data pooling (포지션 이동시)
    result = poolingStartIndexOfWaveformIndexList;
  }
  return result;
}

export function getInfoSliceInfo(
  positionMoveType,
  lastOfFetchedIndex,
  basisFetchingCount
) {
  let sliceInfo = { begin: undefined, end: undefined };
  const offsetPositions = OFFSET_POSITION_ARRAY;
  const backwardFetchingCount =
    BEAT_REVIEW_FETCHING_OPTION.BACKWARD_FETCHING_COUNT;

  if (positionMoveType === POSITION_MOVE_TYPE.PREV) {
    sliceInfo.begin = lastOfFetchedIndex + offsetPositions - basisFetchingCount;
    sliceInfo.end = lastOfFetchedIndex + offsetPositions;
  } else if (
    positionMoveType === POSITION_MOVE_TYPE.INIT ||
    positionMoveType === POSITION_MOVE_TYPE.NEXT
  ) {
    sliceInfo.begin = lastOfFetchedIndex;
    sliceInfo.end = lastOfFetchedIndex + basisFetchingCount;
  } else if (positionMoveType === POSITION_MOVE_TYPE.JUMP) {
    sliceInfo.begin =
      lastOfFetchedIndex - backwardFetchingCount >= 0
        ? lastOfFetchedIndex - backwardFetchingCount
        : 0;
    sliceInfo.end = lastOfFetchedIndex + offsetPositions + basisFetchingCount;
  }
  return sliceInfo;
}

/**
 * @typedef waveformIndex
 * @type {number}
 */
