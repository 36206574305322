import { connect } from 'react-redux';
import { showDialog, hideDialog } from 'redux/duck/dialogDuck';
import UserManagementDialog from 'component/dialog/UserManagementDialog';
import {
  createMedicalStaffRequested,
  updateMedicalStaffRequested,
  removeMedicalStaffRequested,
} from 'redux/duck/medicalStaffsDuck';

const mapStateToProps = (state, ownProps) => {
  return {
    passwordState: state.passwordReducer,
    medicalStaffCUState: state.medicalStaffsReducer.cu,
    medicalStaffRemoveState: state.medicalStaffsReducer.remove,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    // Dialog
    showDialog: (dialogKey, params, callback) => {
      dispatch(showDialog(dialogKey, params, callback));
    },
    hideDialog: (dialogKey) => {
      dispatch(hideDialog(dialogKey));
    },
    // Medical Staff
    createMedicalStaff: (
      firstName,
      email,
      staffType,
      accessLevel,
      isHospitalAdmin
    ) =>
      dispatch(
        createMedicalStaffRequested(
          firstName,
          email,
          staffType,
          accessLevel,
          isHospitalAdmin
        )
      ),
    updateMedicalStaff: (username, staffType, accessLevel) =>
      dispatch(updateMedicalStaffRequested(username, staffType, accessLevel)),
    removeMedicalStaff: (username) =>
      dispatch(removeMedicalStaffRequested(username)),
  };
};

const UserManagementDialogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManagementDialog);

export default UserManagementDialogContainer;
