import { Redirect, RouteComponentProps } from 'react-router-dom';

/**
 * App.jsx 에서 특정 조건을 만족할 때만 해당 route로 접근할 수 있도록 하는 HOC
 * 사용예제 : WithConditionAccess(adminPage, () => !!admin)
 */
const WithConditionAccess = <P extends RouteComponentProps>({
  AccessComponent,
  conditionFn,
}: {
  AccessComponent: React.ComponentType<P>;
  conditionFn: () => boolean;
}): React.FC<P> => {
  return (props: P) => {
    if (conditionFn()) return <AccessComponent {...props} />;

    return <Redirect to="/not-found" />;
  };
};

export default WithConditionAccess;
