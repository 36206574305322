import { useRef, useState, useImperativeHandle, forwardRef } from 'react';
import styled from 'styled-components';

import Const from 'constant/Const';

const Wrapper = styled.div`
  display: flex;
  width: 200px;
  background: ${(props) => props.theme.color.WHITE};
  position: relative;
  & > * {
    font-size: 12px;
  }
`;

const StartIconContainer = styled.div`
  position: absolute;
  top: 8px;
  left: 16px;
  :hover {
    cursor: pointer;
  }
  & > * {
    font-size: 20px;
    color: ${(props) => props.theme.color.LIGHT_GREY_BB};
  }
`;

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  border: 1px solid ${(props) => props.theme.color.COOL_GRAY_40};
  border-radius: 6px;
  ${(props) => props.textInputBorder && `border: ${props.textInputBorder};`}
  ${(props) =>
    props.startMargin &&
    `
        padding-left: 32px;
    `}
  ${(props) =>
    props.endMargin &&
    `
        padding-right: 32px;
    `}
    :hover {
    border-color: ${(props) => props.theme.color.PRIMARY};
  }
  ${(props) =>
    props.focused &&
    `
        border-color: ${props.theme.color.PRIMARY};
    `}
  transition: border-color 0.2s ease-in-out;
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

const StyledInput = styled.input`
  width: calc(100% - 24px);
  padding: 8px 12px;
  background: transparent;
  outline: none;
  border: none;
  font-size: 12px;
  line-height: 130%;
  color: ${(props) => props.theme.color.BLACK};
`;

const PlaceholderText = styled.div`
  position: absolute;
  top: 6px;
  left: 12px;
  color: ${(props) => props.theme.color.MEDIUM_DARK};
  ${(props) =>
    props.startMargin &&
    `
        padding-left: 32px;
    `}
  ${(props) =>
    props.endMargin &&
    `
        padding-right: 32px;
    `}
`;

const InputAdornment = styled.div`
  display: flex;
  align-items: center;
  padding-right: 12px;
  color: ${({ theme }) => theme.color.COOL_GRAY_80};
`;

const EndIconContainer = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
  :hover {
    cursor: pointer;
  }
  & > * {
    font-size: 20px;
    color: ${(props) => props.theme.color.LIGHT_GREY_BB};
  }
`;

function TextInput(props, ref) {
  const {
    style,
    styledInputStyle,
    placeholderTextStyle,
    endIconStyle,
    textInputBorder,
    inputType,
    placeholder,
    startIcon,
    onClickStartIcon,
    endIcon,
    onClickEndIcon,
    onSubmit,
    onFocus = () => {},
    onBlur = () => {},
    adornment,
    maxLength,
  } = props;

  const inputRef = useRef(null);

  const [isInputFocused, setIsInputFocused] = useState(false);

  useImperativeHandle(ref, () => ({
    getInputRef: () => {
      return inputRef.current;
    },
    focus: () => {
      inputRef.current?.focus();
    },
  }));

  const onEnterClick = (event) => {
    if (event.key === Const.KEY_MAP.ENTER) {
      submit();
    }
  };

  const submit = () => {
    if (onSubmit && typeof onSubmit == 'function') {
      onSubmit();
    }
  };

  return (
    <Wrapper style={style}>
      {!!startIcon && (
        <StartIconContainer onClick={onClickStartIcon || submit}>
          {startIcon}
        </StartIconContainer>
      )}

      <InputContainer
        textInputBorder={textInputBorder}
        startMargin={!!startIcon}
        endMargin={!!endIcon}
        focused={isInputFocused}>
        <StyledInput
          style={styledInputStyle}
          ref={inputRef}
          type={inputType || 'text'}
          value={props.value}
          onChange={(event) =>
            props.onChange && props.onChange(event.target.value, event)
          }
          onKeyDown={onEnterClick}
          onFocus={() => {
            onFocus();
            setIsInputFocused(true);
          }}
          onBlur={() => {
            onBlur();
            setIsInputFocused(false);
          }}
          maxLength={maxLength}
        />

        {!props.value && placeholder && (
          <PlaceholderText
            style={placeholderTextStyle}
            className="searchPlaceholderText"
            startMargin={!!startIcon}
            endMargin={!!endIcon}
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }}>
            {placeholder}
          </PlaceholderText>
        )}
        {!!adornment && <InputAdornment>{adornment}</InputAdornment>}
      </InputContainer>

      {!!endIcon && (
        <EndIconContainer
          styled={endIconStyle}
          onClick={onClickEndIcon || submit}>
          {endIcon}
        </EndIconContainer>
      )}
    </Wrapper>
  );
}

export default forwardRef(TextInput);
