import { isNumber, isPositiveNumber } from '../NumberUtil';

export const isPositiveWaveformIndexPair = (onset, termination) =>
  isPositiveNumber(onset) && isPositiveNumber(termination);
export const isNumberWaveformIndexPair = (onset, termination) =>
  isNumber(onset) && isNumber(termination);

/* undefined, empty object, empty array 체크 */
export const isEmpty = (param) => {
  return !param || Object.keys(param).length === 0 || param.length === 0;
};
