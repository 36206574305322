/**
 * ❗️❗️❗️
 * *주의*
 * ❗️❗️❗️
 *
 * 하기 코드는 파트너 웹과 병원 웹 간 차이가 있습니다.
 */
const API_URL = `${window.location.origin}/api`;
const WITHOUT_PROXY_API_URL =
  process.env.NODE_ENV === 'production'
    ? `${(() => {
        if (process.env.REACT_APP_CUSTOM_ENV === 'prod') {
          return process.env.REACT_APP_PROD_API_SERVER_URL;
        } else if (process.env.REACT_APP_CUSTOM_ENV === 'qa') {
          return process.env.REACT_APP_QA_API_SERVER_URL;
        } else if (process.env.REACT_APP_CUSTOM_ENV === 'dev') {
          return process.env.REACT_APP_DEV_API_SERVER_URL;
        } else {
          return process.env.REACT_APP_ADHOC_API_SERVER_URL;
        }
      })()}/api/v3`
    : API_URL;

const UrlList = {
  /**
   * Authentication
   **/
  getAuthUrl: function () {
    return `${API_URL}/auth`;
  },
  getEmailCheckUrl: function () {
    return `${this.getAuthUrl()}/email-check`;
  },
  getPasswordResetUrl: function () {
    return `${this.getAuthUrl()}/password-reset`;
  },
  getPasswordResetEmailUrl: function () {
    return `${this.getAuthUrl()}/password-reset-email`;
  },
  getPasswordResetEmailCompleteUrl: function () {
    return `${this.getAuthUrl()}/password-reset-email-complete`;
  },
  getPasswordResetNextTimeUrl: function () {
    return `${this.getAuthUrl()}/password-reset-next-time`;
  },
  getPasswordResetWithOldPasswordUrl: function () {
    return `${this.getAuthUrl()}/password-reset-with-old-password`;
  },
  getLoginUrl: function () {
    return `${this.getAuthUrl()}/token/login`;
  },
  getLogoutUrl: function () {
    return `${this.getAuthUrl()}/token/logout`;
  },
  getTokenRefreshUrl: function () {
    return `${this.getAuthUrl()}/token/refresh`;
  },
  getUserConsentUrl: function () {
    return `${this.getAuthUrl()}/user-consent`;
  },
  getReadMyInformationURL: function () {
    return `${API_URL}/me`;
  },

  /**
   * Medical Staffs
   **/
  getMedicalStaffsUrl: function () {
    return `${API_URL}/medical-staffs`;
  },
  getMedicalStaffsUserNameUrl: function (userName) {
    return `${this.getMedicalStaffsUrl()}/${userName}`;
  },
  /**
   * User
   **/
  getUserUrl: function () {
    return `${API_URL}/user`;
  },
  getUserForgotPasswordUrl: function () {
    return `${this.getUserUrl()}/forgotpassword`;
  },
  getUserChangePasswordUrl: function () {
    return `${this.getUserUrl()}/changePassword`;
  },

  /**
   * Device
   **/
  getDeviceUrl: function () {
    return `${API_URL}/device`;
  },
  getDeviceIdUrl: function (deviceId) {
    return `${this.getDeviceUrl()}/${deviceId}`;
  },

  /**
   * Markings
   **/
  getDeviceMarkingsUrl: function (deviceId) {
    return `${this.getDeviceIdUrl(deviceId)}/markings`;
  },
  getDeviceMarkingsIdUrl: function (deviceId, markingsId) {
    return `${this.getDeviceMarkingsUrl(deviceId)}/${markingsId}`;
  },

  /**
   * Common
   **/
  getStaffPatientsUrl: function () {
    return `${API_URL}/staff/fetch-patient`;
  },

  /**
   * Patch ECGs
   */
  getEcgTotalUrl: function (tid) {
    return `${API_URL}/patch-ecgs/${tid}/explorer`;
  },
  getPatchEcgsUrl: function () {
    return `${API_URL}/patch-ecgs`;
  },
  getPatchEcgsIdUrl: function (ecgTestId) {
    return `${this.getPatchEcgsUrl()}/${ecgTestId}`;
  },
  getPatchEcgsIdDailyStatChart: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/daily-stat-chart`;
  },
  getPatchEcgsIdExplorerUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/explorer`;
  },
  getPatchRawEcgsUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/raw`;
  },
  getGetEventCountsUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/event-count`;
  },
  bulkConfirmUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/bulk-confirm`;
  },
  getLimitUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/limit`;
  },
  getRevertUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/revert`;
  },
  getPatchEcgsIdStatistics: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/statistics`;
  },

  // Ectopic
  getEctopicUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/ectopic`;
  },
  getEctopicWaveformIndexesUrl: function (ecgTestId) {
    return `${this.getEctopicUrl(ecgTestId)}/onset-waveform-indexes`;
  },
  getEctopicFilterTypeUrl: function (ecgTestId) {
    return `${this.getEctopicUrl(ecgTestId)}/filter/type`;
  },
  getEctopicFilterBulkTypeUrl: function (ecgTestId) {
    return `${this.getEctopicUrl(ecgTestId)}/filter-bulk/type`;
  },
  getEctopicFilterWaveformIndexUrl: function (ecgTestId) {
    return `${this.getEctopicUrl(ecgTestId)}/filter/waveform-index`;
  },
  getEctopicFilterWaveformIndexRangeUrl: function (ecgTestId) {
    return `${this.getEctopicUrl(ecgTestId)}/filter/waveform-index-range`;
  },

  // Geminy
  getGeminyUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/geminy`;
  },
  getGeminyFilterTypeUrl: function (ecgTestId) {
    return `${this.getGeminyUrl(ecgTestId)}/filter/type`;
  },
  getGeminyFilterWaveformIndexUrl: function (ecgTestId) {
    return `${this.getGeminyUrl(ecgTestId)}/filter/waveform-index`;
  },

  // Beats
  getBeatsUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/beats`;
  },
  getAddBeatsUrl: function (ecgTestId) {
    return `${this.getBeatsUrl(ecgTestId)}/add-beats`;
  },
  getUpdateBeatsByBetweenIndexUrl: function (ecgTestId) {
    return `${this.getBeatsUrl(ecgTestId)}/update-beats/between-waveform-index`;
  },
  getUpdateBeatsByIndexesUrl: function (ecgTestId) {
    return `${this.getBeatsUrl(ecgTestId)}/update-beats/waveform-indexes`;
  },
  getRemoveBeatsUrl: function (ecgTestId) {
    return `${this.getBeatsUrl(ecgTestId)}/remove-beats`;
  },
  getPatchEcgsIdBeatsUrl: function (ecgTestId, suffix) {
    return `${this.getBeatsUrl(ecgTestId)}/${suffix}`;
  },
  getBeatsFilterWaveformIndexRangeUrl: function (ecgTestId) {
    return `${this.getBeatsUrl(ecgTestId)}/filter/waveform-index-range`;
  },
  getBeatPostprocessUrl: function (ecgTestId) {
    return `${WITHOUT_PROXY_API_URL}/patch-ecgs/${ecgTestId}/beats/postprocess`;
  },
  // Shape Review
  getFormListUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/forms`;
  },
  getWaveformIndexesOfFormUrl: function (formId) {
    return `${API_URL}/forms/${formId}/waveform-indexes`;
  },
  getUpdateBeatsByFormIdsUrl: function (ecgTestId) {
    return `${this.getBeatsUrl(ecgTestId)}/update-beats/form-ids`;
  },
  getIsArrangeRequiredUrl: function (ecgTestId) {
    return `${this.getBeatsUrl(ecgTestId)}/is-arrange-required`;
  },
  getClusteringStatisticsUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/clustering-statistics`;
  },
  /* Patch ECGs > Histogram */
  getHrHistogramUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/hr-histogram`;
  },
  getHrHistogramBinDetailUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/hr-histogram/bin`;
  },
  getRrHistogramUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/rr-histogram`;
  },
  getRrHistogramBinDetailUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/rr-histogram/bin`;
  },

  /**
   * ECG Tests
   */
  getEcgTestsUrl: function () {
    return `${API_URL}/ecg-tests`;
  },
  getEcgTestsIdUrl: function (ecgTestId) {
    return `${this.getEcgTestsUrl()}/${ecgTestId}`;
  },
  getEcgTestsIdRevertUrl: function (ecgTestId) {
    return `${this.getEcgTestsIdUrl(ecgTestId)}/revert-customer-confirm`;
  },
  getEcgTestsIdRevertToPartnerEditDoneUrl: function (ecgTestId) {
    return `${this.getEcgTestsIdUrl(ecgTestId)}/revert-partner-edit-done`;
  },
  getEcgTestsValidateUploadUrl: function () {
    return `${this.getEcgTestsUrl()}/validate-upload`;
  },
  getEcgTestsUploadEcgUrl: function () {
    return `${this.getEcgTestsUrl()}/upload-ecg`;
  },
  getEcgTestsIdReturnDeviceUrl: function (ecgTestId) {
    return `${this.getEcgTestsIdUrl(ecgTestId)}/return-device`;
  },
  getEcgTestsIdEditDoneReviewUrl: function (ecgTestId) {
    // only for partner
    return `${this.getEcgTestsIdUrl(ecgTestId)}/cloud-status/edit-done`;
  },
  getEcgTestsIdConfirmReviewUrl: function (ecgTestId) {
    return `${this.getEcgTestsIdUrl(ecgTestId)}/confirm`;
  },
  getEcgTestsIdCompleteUploadUrl: function (ecgTestId) {
    return `${this.getEcgTestsIdUrl(ecgTestId)}/complete-upload`;
  },
  getEcgTestsIdFindingsTemplateUrl: function (ecgTestId) {
    return `${this.getEcgTestsIdUrl(ecgTestId)}/findings-template`;
  },

  // Patient Events
  getPatientEventsUrl: function () {
    return `${API_URL}/patient-events`;
  },
  getPatientEventIdUrl: function (pteId) {
    return `${API_URL}/patient-events/${pteId}`;
  },

  /**
   * Events
   */
  getEventsUrl: function () {
    return `${API_URL}/events`;
  },
  confirmEventUrl: function (eventId) {
    return `${this.getEventsUrl()}/${eventId}/confirm`;
  },
  getEventDetailUrl: function (eventId) {
    return `${WITHOUT_PROXY_API_URL}/events/${eventId}`;
  },

  /**
   * Time Events
   */
  getTimeEventsDirectUrl: function () {
    return `${WITHOUT_PROXY_API_URL}/time-events`;
  },
  getTimeEventsUrl: function () {
    return `${API_URL}/time-events`;
  },
  getTimeEventsIdUrl: function (timeEventId) {
    return `${this.getTimeEventsUrl()}/${timeEventId}`;
  },

  /**
   * Reports
   */
  getReportsUrl: function () {
    return `${API_URL}/reports`;
  },
  getReportDetailURL: function (reportId) {
    return `${this.getReportsUrl()}/${reportId}`;
  },
  validateConfirmEventUrl: function (reportId) {
    return `${this.getReportsUrl()}/${reportId}/is-valid`;
  },
  getReportSelectedURL: function (reportId) {
    return `${this.getReportDetailURL(reportId)}/selected-events`;
  },
  getReportsStatistics: function (reportId) {
    return `${this.getReportsUrl()}/${reportId}/statistics`;
  },

  /**
   * Report Events
   */
  getReportEventsUrl: function () {
    return `${API_URL}/report-events`;
  },
  getReportEventsIdUrl: function (reportEventId) {
    return `${API_URL}/report-events/${reportEventId}`;
  },

  /**
   * filter
   */
  getHospitalsHidUrl: function () {
    return `${API_URL}/hospitals/hids`;
  },

  /**
   *  Test Notification
   */
  getTestNotificationUrl: function () {
    return `${API_URL}/test-notification`;
  },
  getPatchReadByTidUrl: function () {
    return `${this.getTestNotificationUrl()}/set-read-by-tids`;
  },
  getPatchReadByDateRangeUrl: function () {
    return `${this.getTestNotificationUrl()}/set-read-by-date-range`;
  },

  /*
   * For Ops
   */
  getUpdateBeatsByEctopicTypeUrl: function (ecgTestId) {
    return `${this.getBeatsUrl(ecgTestId)}/update-beats/ectopic-type`;
  },
  getUpdateCoupletSeparatelyUrl: function (ecgTestId) {
    return `${this.getBeatsUrl(ecgTestId)}/update-couplet-separately`;
  },
  getInverseUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/inverse`;
  },
  getRefreshPauseUrl: function (ecgTestId) {
    return `${this.getPatchEcgsIdUrl(ecgTestId)}/ops/refresh-pause`;
  },
  getRevertSavePointUrl: function (ecgTestId) {
    return `${this.getBeatsUrl(ecgTestId)}/revert-save-point`;
  },
};

export default UrlList;
