import DialogDict from 'component/dialog';

// Actions
export const SHOW_DIALOG = 'memo-web/dialog/SHOW_DIALOG';
export const RE_SHOW_DIALOG = 'memo-web/dialog/RE_SHOW_DIALOG';
export const HIDE_DIALOG = 'memo-web/dialog/HIDE_DIALOG';

// Reducer
let dialogState = {
  isSomethingOpen: false,
};
Object.keys(DialogDict).forEach((dialogKey) => {
  dialogState[dialogKey] = {
    isOpen: false,
    params: {},
    callback: null,
  };
});

const initialState = dialogState;

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_DIALOG:
      return {
        ...state,
        [action.dialogKey]: {
          isOpen: true,
          params: action.params || {},
          callback: action.callback,
        },
        isSomethingOpen: true,
      };
    case RE_SHOW_DIALOG:
      return {
        ...state,
        [action.dialogKey]: {
          isOpen: true,
          params: state[action.dialogKey].params,
          callback: action.callback,
        },
        isSomethingOpen: true,
      };
    case HIDE_DIALOG:
      const semiFinalState = {
        ...state,
        [action.dialogKey]: {
          isOpen: false,
          params: state[action.dialogKey].params,
          callback: null,
        },
      };
      // 열려있는 Dialog 체크..
      if (Object.values(semiFinalState).filter((x) => x.isOpen).length === 0) {
        return {
          ...semiFinalState,
          isSomethingOpen: false,
        };
      }
      return semiFinalState;
    default:
      return state;
  }
}

// Action Creators
export function showDialog(dialogKey, params, callback) {
  return {
    type: SHOW_DIALOG,
    dialogKey: dialogKey,
    params: params,
    callback: callback,
  };
}

export function reShowDialog(dialogKey, callback) {
  return {
    type: RE_SHOW_DIALOG,
    dialogKey: dialogKey,
    callback: callback,
  };
}

export function hideDialog(dialogKey) {
  return { type: HIDE_DIALOG, dialogKey: dialogKey };
}
