import React, { useMemo } from 'react';
import styled from 'styled-components';
import { ReactComponent as AvatarIcon } from 'static/icon/icon-avatar.svg';

const Wrapper = styled.div`
  display: flex;
  border-radius: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

const StyledImage = styled.img``;

function Avatar(props) {
  const { style, size, imgSrc } = props;

  const imageSize = useMemo(() => size || 28, [size]);

  if (!imgSrc) {
    return <AvatarIcon width={imageSize} height={imageSize} />;
  }

  return (
    <Wrapper style={style} size={imageSize}>
      <StyledImage src={imgSrc} />
    </Wrapper>
  );
}

export default Avatar;
