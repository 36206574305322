import React, { useRef, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { DialogWrapper, DialogButtonWrapper } from 'component/ui/dialog/Dialog';
import TextButton from 'component/ui/button/TextButton';

const TitleText = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 8px;
  color: ${(props) => props.theme.color.BLACK};
`;

const MessageText = styled.div`
  white-space: pre-wrap;
  margin-bottom: 16px;
  font-size: 12px;
  color: ${(props) => props.theme.color.BLACK};
`;

function AlertTimerDialog(props) {
  const intl = useIntl();

  const { open, params, callback, onClose, zIndex } = props;
  const { title, message, confirmButtonText, closeAfterThisTime } = params;

  const btnRef = useRef(null);

  useEffect(() => {
    if (closeAfterThisTime) {
      const dialogTimer = setTimeout(() => {
        onClose();
      }, closeAfterThisTime);
      return () => clearTimeout(dialogTimer);
    }
  }, []);

  return (
    <DialogWrapper zIndex={zIndex ?? 12} open={open} maxWidth={360}>
      {title && <TitleText>{title}</TitleText>}

      {message && <MessageText>{message}</MessageText>}

      <DialogButtonWrapper>
        <TextButton
          ref={btnRef}
          title={
            confirmButtonText ||
            intl.formatMessage({
              id: '99-Dialog-Button-title-close',
              description: '팝업 Dialog의 닫기 버튼',
              defaultMessage: '닫기',
            })
          }
          onClick={() => {
            onClose();
            if (typeof callback === 'function') {
              callback();
            }
          }}
        />
      </DialogButtonWrapper>
    </DialogWrapper>
  );
}

export default AlertTimerDialog;
