import { ReactNode, CSSProperties, Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import DateUtil from 'util/DateUtil';
import { highlightChar } from 'util/TestNotification/testNotificationUtil';
import { type ITheme } from 'theme/StyledComponentsTheme';
import { type ITestNotification } from 'redux/container/TestNotificationHistoryPageContainer';
import { type ITestNotificationHistoryPageProps } from 'component/page/TestNotificationHistoryPage';

import DropdownBase from 'component/ui/dropdown/DropdownBase';
import Switch from 'component/ui/switch/Switch';
import Tooltip from 'component/ui/tooltip/Tooltip';
import {
  AlarmIcon,
  NotificationSentBadgeIcon,
  OpenInNewIcon,
  VisibilityIcon,
  VisibilityOffIcon,
} from 'component/ui/icons';

interface ITestNotificationDropdownFragmentProps {
  showDropdown: boolean;
  setShowDropdown: Dispatch<SetStateAction<boolean>>;
  isSwitchToggled: boolean;
  setIsSwitchToggled: Dispatch<SetStateAction<boolean>>;
  notiCount: number;
  filteredByReadStatus: ITestNotification[];
  todaysNotiList: ITestNotification[];
  beforeTodayNotiList: ITestNotification[];
  handleReadAll: () => void;
}

function TestNotificationDropdownFragment({
  showDropdown,
  setShowDropdown,
  isSwitchToggled,
  setIsSwitchToggled,
  notiCount,
  filteredByReadStatus,
  todaysNotiList,
  beforeTodayNotiList,
  handleReadAll,
}: ITestNotificationDropdownFragmentProps) {
  return (
    <DropdownBase
      show={showDropdown}
      setShow={setShowDropdown}
      style={{ marginRight: 11 }}
      dropdownDirection="right"
      contentCloseReason="clickAway"
      anchorProps={{ style: { border: 'none', minWidth: 0, padding: 0 } }}
      contentProps={{ style: { border: 'none' } }}
      label={
        <>
          <NotiIconWrapper />
          {!!notiCount && <CountBadge notiCount={notiCount} />}
        </>
      }>
      <ContentWrapper>
        <HeaderWrapper>
          <HeaderLeftWrapper>
            <ContentText>알림</ContentText>
          </HeaderLeftWrapper>
          <HeaderRightWrapper>
            <UnreadItemToggle
              isSwitchToggled={isSwitchToggled}
              setIsSwitchToggled={setIsSwitchToggled}
            />
            <Tooltip
              option={{ maxWidth: 200 }}
              title={'새 탭에서 모든 알림 확인하기'}>
              <BgWrapper>
                <OpenInNewIconWrapper
                  onClick={() => {
                    window.open(
                      `${window.location.origin}/test-notification-history`,
                      '_blank'
                    );
                  }}
                />
              </BgWrapper>
            </Tooltip>
          </HeaderRightWrapper>
        </HeaderWrapper>
        <ContentBody>
          <VisibilityWrapper show={!!filteredByReadStatus.length}>
            <ItemDivider>
              <ItemDividerText hide={!todaysNotiList.length}>
                오늘
              </ItemDividerText>
              <ItemDividerText
                style={{ textDecorationLine: 'underline', cursor: 'pointer' }}
                onClick={handleReadAll}>
                모두 읽음으로 표시
              </ItemDividerText>
            </ItemDivider>
            {todaysNotiList.map(
              ({
                shortTid,
                tid,
                createdAt,
                notificationMessage,
                isHospitalUserReadNotification,
                isNotificationSentToHospitalUser,
                isInternalUserReadNotification,
              }) => (
                <NotiItem
                  key={tid}
                  tid={tid}
                  shortTid={shortTid}
                  createdAt={createdAt}
                  notificationMessage={notificationMessage}
                  isHospitalUserReadNotification={
                    isHospitalUserReadNotification
                  }
                  isNotificationSentToHospitalUser={
                    isNotificationSentToHospitalUser
                  }
                  isInternalUserReadNotification={
                    isInternalUserReadNotification
                  }
                />
              )
            )}
            {!!todaysNotiList.length && !!beforeTodayNotiList.length && (
              <>
                <ContentDivider />
                <ItemDivider style={{ paddingTop: 6 }}>
                  <ItemDividerText>이전</ItemDividerText>
                </ItemDivider>
              </>
            )}
            {beforeTodayNotiList.map(
              ({
                shortTid,
                tid,
                createdAt,
                notificationMessage,
                isHospitalUserReadNotification,
                isNotificationSentToHospitalUser,
                isInternalUserReadNotification,
              }) => (
                <NotiItem
                  key={tid}
                  tid={tid}
                  shortTid={shortTid}
                  createdAt={createdAt}
                  notificationMessage={notificationMessage}
                  isHospitalUserReadNotification={
                    isHospitalUserReadNotification
                  }
                  isNotificationSentToHospitalUser={
                    isNotificationSentToHospitalUser
                  }
                  isInternalUserReadNotification={
                    isInternalUserReadNotification
                  }
                />
              )
            )}
            <ShowMoreNoties />
          </VisibilityWrapper>
          <VisibilityWrapper show={!filteredByReadStatus.length}>
            <TextWrapper>
              <ItemDividerText style={{ fontWeight: 500 }}>
                받은 긴급 알림이 없습니다.
              </ItemDividerText>
            </TextWrapper>
          </VisibilityWrapper>
        </ContentBody>
      </ContentWrapper>
    </DropdownBase>
  );
}

export default TestNotificationDropdownFragment;

// styles
const CountBadge = styled.div<{ notiCount: number }>`
  position: absolute;
  width: 20px;
  height: 13px;
  top: -3px;
  left: 8px;
  border-radius: 100px;

  font-weight: 700;
  font-size: 10px;
  text-align: center;
  line-height: 140%;
  background-color: ${({ theme }) => theme.color.RED};
  color: ${({ theme }) => theme.color.WHITE};
  ${({ notiCount }) => `::after { content: '${notiCount}' }`}
`;

const BgWrapper = styled.div`
  display: flex;
  :hover {
    background-color: ${({ theme }) => theme.color.COOL_GRAY_30};
  }
`;

const ContentWrapper = styled.div`
  width: 370px;
  max-height: calc(100vh - 120px);
  border-radius: 6px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: column;
`;
export const ContentDivider = styled.div<{ width?: number }>`
  width: ${({ width }) => width || 330}px;
  height: 1px;
  border-bottom: 1px solid ${({ theme }) => theme.color.COOL_GRAY_40};
`;
export const ContentBody = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 24px;
  overflow-y: scroll;
  &::-webkit-scrollbar-track {
    border-radius: 6px;
  }
`;
export const ContentText = styled.div<{ notiCount?: number }>`
  font-size: 14px;
  font-weight: 700;
  line-height: 130%;
  color: ${({ theme }) => theme.color.COOL_GRAY_90};

  ::after {
    content: ${({ notiCount }) =>
      typeof notiCount === 'number' && `" ${notiCount}"`};
    color: ${({ theme }) => theme.color.BLUE_70};
  }
`;

export const HeaderWrapper = styled.div<{ width?: number }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${({ width }) => width || 330}px;
  height: 20px;
  padding: 15px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.color.COOL_GRAY_40};
`;
export const HeaderLeftWrapper = styled.div`
  display: flex;
  gap: 12px;
`;
export const HeaderRightWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

const ItemWrapper = styled.div<{
  width?: number;
  isInternalUserReadNotification: boolean;
}>`
  width: ${({ width }) => width || 329}px;
  padding: 6px;
  margin-left: 14px;
  font-size: 12px;
  font-weight: 500;
  line-height: 130%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  cursor: pointer;
  border-radius: 4px;
  :hover {
    background-color: ${({ theme }) => theme.color.COOL_GRAY_30};
  }
  color: ${({ theme, isInternalUserReadNotification }) =>
    isInternalUserReadNotification
      ? theme.color.COOL_GRAY_60
      : theme.color.COOL_GRAY_90};
`;
const ItemTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ItemTitle = styled.div`
  font-weight: 700;
`;
const ItemBody = styled.div``;
const ItemTime = styled.div<{ isInternalUserReadNotification: boolean }>`
  margin-top: 2px;
  font-size: 11px;
  color: ${({ theme, isInternalUserReadNotification }) =>
    isInternalUserReadNotification
      ? theme.color.COOL_GRAY_60
      : theme.color.COOL_GRAY_70};
`;
export const ItemDivider = styled.div<{ width?: number }>`
  width: ${({ width }) => width || 330}px;
  height: 16px;
  padding: 18px 5px 2px 20px;
  display: flex;
  justify-content: space-between;
`;
export const ItemDividerText = styled.div<{
  hide?: boolean;
  style?: CSSProperties;
}>`
  font-size: 12px;
  font-weight: 700;
  line-height: 130%;
  visibility: ${({ hide }) => (hide ? 'hidden' : 'inherit')};
  color: ${({ theme }) => theme.color.COOL_GRAY_70};
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 364px;
`;

export const NotificationBadge = styled.div<{
  isInternalUserReadNotification?: boolean;
}>`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin: 4px;
  background-color: ${({ theme, isInternalUserReadNotification }) =>
    isInternalUserReadNotification
      ? theme.color.COOL_GRAY_50
      : theme.color.RED_70};
`;

const ShowMoreNotiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 20px 55px 4px 54px;
  font-size: 12px;
  line-height: 130%;
  text-align: center;
`;
const ShowMoreNotiesText = styled.div`
  font-weight: 500;
  color: ${({ theme }) => theme.color.COOL_GRAY_90};
`;
const ShowMoreNotiesButton = styled.div`
  font-weight: 700;
  text-decoration-line: underline;
  cursor: pointer;
  color: ${({ theme }) => theme.color.BLUE_70};
`;

const UnreadItemToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 130%;
  color: ${({ theme }) => theme.color.COOL_GRAY_70};
`;

const IconWrapper = styled.div<{ isInternalUserReadNotification: boolean }>`
  display: flex;
  gap: 6px;
  svg {
    width: 16px;
    height: 16px;
  }
  & path {
    fill: ${({ theme, isInternalUserReadNotification }) =>
      isInternalUserReadNotification
        ? theme.color.COOL_GRAY_50
        : theme.color.SVE_70};
  }
`;
const NotiIconWrapper = styled(AlarmIcon)`
  width: 24px;
  height: 24px;
`;
const OpenInNewIconWrapper = styled(OpenInNewIcon)`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

// components
interface INotiItem extends ITestNotification {
  width?: number;
}
export const NotiItem = ({
  tid,
  shortTid,
  createdAt,
  notificationMessage,
  isHospitalUserReadNotification,
  isNotificationSentToHospitalUser,
  isInternalUserReadNotification,
  width,
}: INotiItem) => {
  const theme = useTheme() as ITheme;

  return (
    <ItemWrapper
      width={width}
      isInternalUserReadNotification={isInternalUserReadNotification}
      onClick={() => {
        window.location.href = `/test/${tid}?tab=general`;
      }}>
      <ItemTitleWrapper>
        <ItemTitle>[{shortTid}] 빠른 확인이 필요한 증상이 있습니다.</ItemTitle>
        <IconWrapper
          isInternalUserReadNotification={isInternalUserReadNotification}>
          {isNotificationSentToHospitalUser &&
            (isHospitalUserReadNotification ? (
              <VisibilityIcon />
            ) : (
              <VisibilityOffIcon />
            ))}
          {isNotificationSentToHospitalUser ? (
            <NotificationSentBadgeIcon />
          ) : (
            <NotificationBadge
              isInternalUserReadNotification={isInternalUserReadNotification}
            />
          )}
        </IconWrapper>
      </ItemTitleWrapper>
      <ItemBody>
        {highlightChar(notificationMessage, '|', {
          color: theme.color.COOL_GRAY_60,
        })}
      </ItemBody>
      <ItemTime isInternalUserReadNotification={isInternalUserReadNotification}>
        {DateUtil.formatDateToKorean(new Date(createdAt))}
      </ItemTime>
    </ItemWrapper>
  );
};

export const UnreadItemToggle = ({
  isSwitchToggled,
  setIsSwitchToggled,
}: Pick<
  ITestNotificationHistoryPageProps,
  'isSwitchToggled' | 'setIsSwitchToggled'
>) => (
  <UnreadItemToggleWrapper>
    읽지 않은 항목만 표시
    <Switch
      onChange={() => setIsSwitchToggled((prev: boolean) => !prev)}
      checked={isSwitchToggled}
    />
  </UnreadItemToggleWrapper>
);

export const ShowMoreNoties = () => {
  const history = useHistory();

  return (
    <ShowMoreNotiesWrapper>
      <ShowMoreNotiesText>
        최근 30일 동안 받은 알림을 모두 확인했습니다.
      </ShowMoreNotiesText>
      <ShowMoreNotiesButton
        onClick={() => {
          history.push('/test-notification-history');
        }}>
        모든 알림 확인하기
      </ShowMoreNotiesButton>
    </ShowMoreNotiesWrapper>
  );
};

export const VisibilityWrapper = ({
  show,
  children,
}: {
  show: boolean;
  children: ReactNode;
}) => {
  if (!show) return null;

  return <>{children}</>;
};
