import { defineMessages } from 'react-intl';

export enum HR_REVIEW_HISTOGRAM_TYPE {
  HR = 'HR',
  RR = 'RR',
}
export const FULL_WORD_HR_REVIEW_HISTOGRAM_TYPE_TEXT_LOOKUP = {
  [HR_REVIEW_HISTOGRAM_TYPE.HR]: 'Heart Rate',
  [HR_REVIEW_HISTOGRAM_TYPE.RR]: 'R-R Interval',
};

export const RR_MAX_BIN_KEY = 5000;

export enum SELECTABLE_VALUE_TYPE {
  BIN_KEY = 'BIN_KEY',
  POSITION = 'POSITION',
  SORT_ORDER = 'SORT_ORDER',
}

export enum ONCHANGE_OPTIONS {
  NOTI_CHANGE_SORT_ORDER = 'notiChangeSortOrder',
  CAUSE_TYPE_CHANGE_SORT_ORDER = 'causeTypeChangeSortOrder',
}

export enum MIN_AVG_MAX_BADGE_TYPE {
  NONE = 'NONE',
  MAX = 'MAX',
  AVG = 'AVG',
  MIN = 'MIN',
}
export const BADGE_TYPE_TEXT_LOOKUP = {
  [MIN_AVG_MAX_BADGE_TYPE.NONE]: 'None',
  [MIN_AVG_MAX_BADGE_TYPE.MAX]: 'Max',
  [MIN_AVG_MAX_BADGE_TYPE.AVG]: 'Avg',
  [MIN_AVG_MAX_BADGE_TYPE.MIN]: 'Min',
};
export const FULL_WORD_BADGE_TYPE_TEXT_LOOKUP = {
  [MIN_AVG_MAX_BADGE_TYPE.NONE]: 'None',
  [MIN_AVG_MAX_BADGE_TYPE.MAX]: 'Maximum',
  [MIN_AVG_MAX_BADGE_TYPE.AVG]: 'Average',
  [MIN_AVG_MAX_BADGE_TYPE.MIN]: 'Minimum',
};

export const BIN_SIZE_LOOKUP = {
  // HR Histogram 의 Bin 구간은 크기 1 로 균등 간격
  [HR_REVIEW_HISTOGRAM_TYPE.HR]: 1,
  // R-R Histogram 의 Bin 구간은 크기 5(0.02초 의 Waveform 단위) 로 균등 간격
  [HR_REVIEW_HISTOGRAM_TYPE.RR]: 5,
};

export const LABEL_INTERVAL_LOOKUP = {
  // HR Histogram 은 10bpm 단위로 Label 표시
  [HR_REVIEW_HISTOGRAM_TYPE.HR]: 10,
  // R-R Histogram 은 0.2초(50 waveform length) 단위로 Label 표시
  [HR_REVIEW_HISTOGRAM_TYPE.RR]: 50,
};

export const LABEL_HIGHLIGHT_INTERVAL_LOOKUP = {
  // HR Histogram 은 50bpm 단위로 Label 강조
  [HR_REVIEW_HISTOGRAM_TYPE.HR]: 50,
  // R-R Histogram 은 1초(250 waveform length) 단위로 Label 강조
  [HR_REVIEW_HISTOGRAM_TYPE.RR]: 250,
};

export const UNIT_TEXT_LOOKUP = {
  [HR_REVIEW_HISTOGRAM_TYPE.HR]: 'bpm',
  [HR_REVIEW_HISTOGRAM_TYPE.RR]: 'sec',
};

export const LABEL_TEXT_LOOKUP = {
  [HR_REVIEW_HISTOGRAM_TYPE.HR]: 'HR',
  [HR_REVIEW_HISTOGRAM_TYPE.RR]: 'R-R',
};

// HistogramChart's SVG String
export const SELECT_SVG = `<svg id="selectedSvg" width="16" height="16" fill="none">
<rect x="4" y="4" width="8" height="8" fill="white" stroke="#426AFF" stroke-width="1.5"/>
</svg>`;

export const MIN_SVG = `<svg width="16" height="16" fill="none">
<path d="M7.60616 4.72568C7.7812 4.42461 8.2188 4.42461 8.39384 4.72568L11.9384 10.8225C12.1134 11.1235 11.8946 11.4999 11.5446 11.4999H4.45544C4.10537 11.4999 3.88657 11.1235 4.06161 10.8225L7.60616 4.72568Z" fill="white" stroke="#565A5E" stroke-width="1.5"/>
</svg>`;

export const MAX_SVG = `<svg width="16" height="16" fill="none">
<path d="M8.39384 11.2742C8.2188 11.5753 7.7812 11.5753 7.60616 11.2742L4.0616 5.17742C3.88657 4.87635 4.10536 4.5 4.45544 4.5L11.5446 4.5C11.8946 4.5 12.1134 4.87634 11.9384 5.17742L8.39384 11.2742Z" fill="white" stroke="#565A5E" stroke-width="1.5"/>
</svg>`;

// HistogramChart's Static Sting Values
export const HISTOGRAM_STATIC_STRING = {
  CROSSHAIR_CLASS: 'histogram-crosshair',
  MAX_PLOT_LINE_ID: 'maxBin',
  MIN_PLOT_LINE_ID: 'maxBin',
  SELECTED_PLOT_LINE_ID: 'selectedBin',
};

export const INTL_MAP = defineMessages({
  HR_HELP: {
    id: '99-HrReview-hrHistButton-Tooltip-text',
    defaultMessage: 'Beat의 전후 5초, 총 10초 동안의 평균 심박(HR) 분포도',
    description: 'Beat의 전후 5초, 총 10초 동안의 평균 심박(HR) 분포도',
  },
  RR_HELP: {
    id: '99-HrReview-rrHistButton-Tooltip-text',
    defaultMessage: '이전 Beat와의 R-R 간격 분포도',
    description: '이전 Beat와의 R-R 간격 분포도',
  },
  [`${HR_REVIEW_HISTOGRAM_TYPE.HR}_Max`]: {
    id: '99-HrReviewSidePanel-hrMaxView-Snackbar-message',
    description:
      'HR 빠른 순으로 정렬 기준이 변경되었습니다. / Episodes are now sorted by the Fastest HR.',
    defaultMessage: 'HR 빠른 순으로 정렬 기준이 변경되었습니다.',
  },
  [`${HR_REVIEW_HISTOGRAM_TYPE.HR}_Min`]: {
    id: '99-HrReviewSidePanel-hrMinView-Snackbar-message',
    description:
      'HR 느린 순으로 정렬 기준이 변경되었습니다. / Episodes are now sorted by the Slowest HR.',
    defaultMessage: 'HR 느린 순으로 정렬 기준이 변경되었습니다.',
  },
  [`${HR_REVIEW_HISTOGRAM_TYPE.RR}_Max`]: {
    id: '99-HrReviewSidePanel-rrMaxView-Snackbar-message',
    description:
      'R-R 간격 넓은 순으로 정렬 기준이 변경되었습니다. / Episodes are now sorted by the Longest R-R interval.',
    defaultMessage: 'R-R 간격 넓은 순으로 정렬 기준이 변경되었습니다.',
  },
  [`${HR_REVIEW_HISTOGRAM_TYPE.RR}_Min`]: {
    id: '99-HrReviewSidePanel-rrMinView-Snackbar-message',
    description:
      'R-R 간격 좁은 순으로 정렬 기준이 변경되었습니다. / Episodes are now sorted by the Shortest R-R interval.',
    defaultMessage: 'R-R 간격 좁은 순으로 정렬 기준이 변경되었습니다.',
  },
  REPORT_REMOVED: {
    id: '99-HrReviewSidePanel-report-strip-removed-message',
    description:
      "HR 값이 변경되어 리포트의 HR 페이지에서 스트립이 삭제되었습니다. / The strip was removed from the report's {histType} page due to {histType} value change.",
    defaultMessage:
      '{histType} 값이 변경되어 리포트의 {histType} 페이지에서 스트립이 삭제되었습니다.',
  },
  REPORT_REMOVED_MANUALLY: {
    id: '99-HrReviewSidePanel-report-strip-removed-manually-message',
    description:
      "리포트의 HR 페이지에서 스트립이 삭제되었습니다. / The strip was removed from the report's {histType} page.",
    defaultMessage: '리포트의 {histType} 페이지에서 스트립이 삭제되었습니다.',
  },
  LIMIT_CONFIRM_TITLE: {
    id: '99-HrReviewSidePanel-changeLimitButton-ConfirmDialog-title',
    description:
      '4.881초를 Max R-R로 설정하시겠습니까? / Are you sure to set 4.881 seconds as the Max R-R interval?',
    defaultMessage: '{rri}초를 {setType} R-R로 설정하시겠습니까?',
  },
  LIMIT_CONFIRM_MESSAGE_MAX: {
    id: '99-HrReviewSidePanel-changeLimitButton-ConfirmDialog-message-setMax',
    description:
      'R-R interval이 4.881초를 초과하는 Beat는 Q beat로 편집됩니다. / Beats with an R-R interval exceeding 4.881 seconds will be edited to Q Beat.',
    defaultMessage:
      'R-R interval이 {rri}초를 초과하는 Beat는 Q beat로 편집됩니다.',
  },
  LIMIT_CONFIRM_MESSAGE_MIN: {
    id: '99-HrReviewSidePanel-changeLimitButton-ConfirmDialog-message-setMin',
    description:
      'R-R interval이 1.614초 미만인 Beat는 Q beat로 편집됩니다. / Beats with an R-R interval of less than 1.614 seconds will be edited to Q Beat.',
    defaultMessage: 'R-R interval이 {rri}초 미만인 Beat는 Q beat로 편집됩니다.',
  },
  LIMIT_CONFIRM_CANCEL: {
    id: '99-HrReviewSidePanel-changeLimitButton-ConfirmDialog-cancelButtonText',
    description: '취소 / Cancel',
    defaultMessage: '취소',
  },
  LIMIT_CONFIRM_CONFIRM: {
    id: '99-HrReviewSidePanel-changeLimitButton-ConfirmDialog-confirmButtonText',
    description: 'Max R-R로 설정 / Set as Max R-R',
    defaultMessage: '{setType} R-R로 설정',
  },
  LIMIT_SNACKBAR_MESSAGE: {
    id: '99-HrReviewSidePanel-changeLimitButton-Snackbar-message',
    description:
      'Max R-R이 설정되었습니다. / The Max R-R interval has been successfully set.',
    defaultMessage: '{setType} R-R이 설정되었습니다.',
  },
  LIMIT_SNACKBAR_ACTION: {
    id: '99-HrReviewSidePanel-changeLimitButton-Snackbar-actionText',
    description: '실행 취소 / Undo',
    defaultMessage: '실행 취소',
  },
  INVALID_REVERT_ALERT_MESSAGE: {
    id: '99-HrReviewSidePanel-unsetButton-AlertDialog-message',
    description:
      'Max 또는 Min R-R 설정 이후 이벤트 편집이 발생하여 Max 또는 Min R-R을 되돌릴 수 없습니다. / Due to event editing, the Max or Min R-R settings cannot be reversed.',
    defaultMessage:
      'Max 또는 Min R-R 설정 이후 이벤트 편집이 발생하여 Max 또는 Min R-R을 되돌릴 수 없습니다.',
  },
  INVALID_REVERT_ALERT_BUTTON: {
    id: '99-HrReviewSidePanel-unsetButton-AlertDialog-button',
    description: '닫기 / Close',
    defaultMessage: '닫기',
  },
  LIMIT_TOOLTIP: {
    id: '99-HrReviewSidePanel-changeLimitButton-Tooltip-text',
    description: 'Max R-R로 설정 / Set as Max R-R',
    defaultMessage: '{setType} R-R로 설정',
  },
  REVERT_DEFAULT_TOOLTIP: {
    id: '99-HrReviewSidePanel-unsetButton-default-Tooltip-text',
    description: 'Min 또는 Max R-R 설정 해제 / Unset Min or Max R-R',
    defaultMessage: 'Min 또는 Max R-R 설정 해제',
  },
  REVERT_DISABLED_TOOLTIP: {
    id: '99-HrReviewSidePanel-unsetButton-disabled-Tooltip-text',
    description:
      'Min, Max R-R을 설정하지 않았거나 설정 이후 편집이 발생한 경우 설정을 해제할 수 없습니다. / Unavailable if Min or Max R-R is not set or has been edited after being set.',
    defaultMessage:
      'Min, Max R-R을 설정하지 않았거나 설정 이후 편집이 발생한 경우 설정을 해제할 수 없습니다.',
  },
  CHANGE_LIMIT_EXCEPTION: {
    id: '99-HrReview-Alert-changeLimitException-message',
    description:
      'Min~Max RR 구간 내 모든 비트가 Noise로 변경되었습니다. \nUnset 버튼을 클릭하여 이전 상태로 복원해주세요. / All beats in the R-R Interval range were changed to Noise(Q) \nPlease click "Unset" button to revert.',
    defaultMessage:
      'Min~Max RR 구간 내 모든 비트가 Noise로 변경되었습니다. \nUnset 버튼을 클릭하여 이전 상태로 복원해주세요.',
  },
  EDIT: {
    id: '99-HrReviewSidePanel-infoTitle-edit',
    description: '편집 / Edit',
    defaultMessage: '편집',
  },
  VIEW_UNIT_TYPE: {
    id: '99-HrReviewSidePanel-unitButton-tooltip-title',
    description: 'Max HR 보기 / View {unitType} {labelText}',
    defaultMessage: '{unitType} {labelText} 보기',
  },
  ADD_REPORT_DISABLED_TOOLTIP: {
    id: '99-HrReviewSidePanel-reportsInfoWrapper-button-Tooltip-disabled-title',
    description:
      'Max, Min, Avg HR 스트립만 리포트에 담을 수 있습니다. / Only {reportTypeList} {histType} strips can be added to the report.',
    defaultMessage:
      '{reportTypeList} {histType} 스트립만 리포트에 담을 수 있습니다.',
  },
  ADD_TO_REPORT_TOOLTIP: {
    id: '99-HrReviewSidePanel-reportsInfoWrapper-button-Tooltip-title',
    description:
      '리포트에 담기 (Max R-R) / Add to Report ({unitType} {labelText})',
    defaultMessage: '리포트에 담기 ({unitType} {labelText})',
  },
  VIEW_ADDED_STRIP: {
    id: '99-HrReviewSidePanel-OverallUnitWrapper-viewButton-tooltip',
    description: '담은 스트립 보기 / View added strip',
    defaultMessage: '담은 스트립 보기',
  },
  NO_STRIP_ADDED: {
    id: '99-HrReviewSidePanel-OverallUnitWrapper-ReportAmountTextWrapper-tooltip',
    description:
      '리포트에 담은 스트립이 없습니다. / No strip is added to the report.',
    defaultMessage: '리포트에 담은 스트립이 없습니다.',
  },
  CHANGE_REPORT_CONFIRM_TITLE: {
    id: '99-HrReviewSidePanel-reportInfo-button-ConfirmDialog-title',
    description:
      '리포트에 담은 {담으려는 스트립} 스트립을 변경할까요? / Change the {unitType} {labelText} strip in the report?',
    defaultMessage: '리포트에 담은 {unitType} {labelText} 스트립을 변경할까요?',
  },
  CHANGE_REPORT_CONFIRM_MESSAGE: {
    id: '99-HrReviewSidePanel-reportInfo-button-ConfirmDialog-message',
    description:
      '기존에 담은 스트립은 삭제되고 해당 스트립이 리포트에 담깁니다. / The previously added strip will be deleted and this strip will be added to the report.',
    defaultMessage:
      '기존에 담은 스트립은 삭제되고 해당 스트립이 리포트에 담깁니다.',
  },
  CHANGE_REPORT_CONFIRM_BUTTON_TEXT: {
    id: '99-HrReviewSidePanel-reportInfo-button-ConfirmDialog-confirm-button-text',
    description: '변경 / Change',
    defaultMessage: '변경',
  },
});
