import { useSelector, shallowEqual } from 'react-redux';

export default function useShallowEqualSelector(
  selector,
  customCompareFunction
) {
  const compareFunction =
    typeof customCompareFunction === 'function'
      ? customCompareFunction
      : shallowEqual;
  return useSelector(selector, compareFunction);
}
