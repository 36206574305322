import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import ApiManager from 'network/ApiManager';
import { GetClassInfo } from 'util/ClassLabelUtil';
// import { transformCamelToSnake } from 'util/Utility';

// Actions
// Fetch
const FETCH_EVENTS_REQUESTED = 'memo-web/FETCH_EVENTS_REQUESTED';
const FETCH_EVENTS_SUCCEED = 'memo-web/FETCH_EVENTS_SUCCEED';
const FETCH_EVENT_DETAIL_REQUESTED = 'memo-web/FETCH_EVENT_DETAIL_REQUESTED';
const FETCH_EVENT_DETAIL_SUCCEED = 'memo-web/FETCH_EVENT_DETAIL_SUCCEED';
// 이벤트 목록 저장
const CONFIRM_EVENT_REQUESTED = 'memo-web/CONFIRM_EVENT_REQUESTED';
const CONFIRM_EVENT_SUCCEED = 'memo-web/CONFIRM_EVENT_SUCCEED';
const FETCH_EVENT_FAILED = 'memo-web/FETCH_EVENT_FAILED';
// // 북마크된 모든 Event 목록 조회
// const GET_PREV_SELECTED_EVENTS_MAP_REQUESTED =
//   'memo-web/event/GET_PREV_SELECTED_EVENTS_MAP_REQUESTED';
// const GET_PREV_SELECTED_EVENTS_MAP_SUCCEED =
//   'memo-web/event/GET_PREV_SELECTED_EVENTS_MAP_SUCCEED';
// const GET_PREV_SELECTED_EVENTS_MAP_FAILED =
//   'memo-web/event/GET_PREV_SELECTED_EVENTS_MAP_FAILED';

const INITIALIZE_DETAIL_REQUESTED = 'memo-web/INITIALIZE_DETAIL';
const SET_CURRENT_EDIT_CLASS_REQUESTED = 'memo-web/SET_CURRENT_EDIT_CLASS';

// View
const SET_SHOW_BOOKMARKED_CHECKED =
  'memo-web/events/SET_SHOW_BOOKMARKED_CHECKED';
const SET_SHOW_UNCONFIRMED_CHECKED =
  'memo-web/events/SET_SHOW_UNCONFIRMED_CHECKED';

const eventDetail = {
  pending: false,
  result: {
    id: null,
    aiPrediction: null,
    internalDiagnosis: null,
    partnerDiagnosis: null,
    customerDiagnosis: null,
    finalDiagnosis: null,
    isConfirmed: null,
    index10s: null,
    eventTimestamp: null,
    hrAvg: null,
    hrMax: null,
    hrMin: null,
    bookmarkLevel: null,
    customerBookmarkLevel: null,
    partnerWebUserBookmarkLevel: null,
    byAi: null,
    isPatientTriggered: null,
    annotation: null,
    ecg: {
      title: '',
      startTimestamp: null,
      endTimestamp: null,
      ecgData: [],
    },
  },
};

// Reducer
const initialState = {
  events: {
    pending: false,
    count: 0,
    next: '',
    previous: '',
    results: [],
    lastModifiedEventId: null,
    nextFocusIndex: undefined,
  },
  eventDetail,
  view: {
    isShowUnconfirmedChecked: false,
    isShowBookmarkedChecked: false,
    currentEditClassType: null,
  },
  prevSelectedEventMap: {
    pending: false,
    data: null,
    error: null,
  },
  error: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    // Fetch
    case FETCH_EVENTS_REQUESTED:
      return {
        ...state,
        events: {
          ...state.events,
          pending: true,
        },
      };
    case FETCH_EVENTS_SUCCEED:
      return {
        ...state,
        events: {
          ...state.events,
          pending: false,
          lastModifiedEventId: null,
          nextFocusIndex: undefined,
          ...action.data,
        },
      };
    case CONFIRM_EVENT_REQUESTED:
      return {
        ...state,
        events: {
          ...state.events,
          pending: true,
        },
      };
    case CONFIRM_EVENT_SUCCEED:
      /* 
        - 최대 이벤트 개수 : 24 * 60 * 60 / 10 = 8640  (10: 10초 마다 event를 classification)
        - action.eventId: 현재 수정한 event Id
        - state.view.currentEditClassType: 현재 수정중인 classType(: number)
        - state.events.results: update하기전 event results list
      */
      const {
        data: {
          result: { id: eventId, eventTimestamp },
        },
      } = action;

      const { isShowBookmarkedChecked, isShowUnconfirmedChecked } = state.view;

      const currentEditClassType = state.view.currentEditClassType;
      const updatedEventList = state.events.results.map((event) => {
        if (event.id === eventId) {
          event = { ...action.data.result };
        }
        return { ...event };
      });

      let filteredEventList,
        isConfirmedAll,
        nextFocusEvent = 0,
        nextFocusIndex;

      try {
        const { eventTimestamp: editingEventTimestamp } = action.data.result;

        let eventList;
        if (typeof currentEditClassType === 'number') {
          eventList = updatedEventList.filter(
            (v) => v.finalDiagnosis === currentEditClassType
          );
        } else {
          eventList = state.events.results;
        }

        if (action.eventId == eventList[eventList.length - 1].id) {
          nextFocusIndex = eventList.length - 1;
        } else {
          // case D-1: edit - non last event
          // case B-1: confirmed All

          nextFocusIndex = eventList.findIndex(
            (v) => v.eventTimestamp > editingEventTimestamp
          );
        }
        /* 
          * case A-1: filtered class(filter를 선택 한 상태)
            - case B-1: confirmed All
            - case B-2: non confirmed All
              - case C-1: confirmed를 다하지 않음 
                          && 수정한 이벤트 이후 이벤트 id중 모두 수정 되어 있을 때
                             (수정 이전 이벤트에서 수정 안한 이벤가 있다.)

          * case A-2: non filtered class(= ALL 상태)
            - case D-2: edit - last event
            - case D-1: edit - non last event
              - case B-1: confirmed All
              - case B-2: non confirmed All
              - case C-1: confirmed를 다하지 않음 
                          && 수정한 이벤트 이후 이벤트 id중 모두 수정 되어 있을 때
                             (수정 이전 이벤트에서 수정 안한 이벤가 있다.)
        */
        // case A-1: filtered class
        // if (typeof currentEditClassType === 'number') {
        //   filteredEventList = updatedEventList.filter(
        //     (v) => v.finalDiagnosis === currentEditClassType
        //   );
        //   isConfirmedAll = !filteredEventList.some((v) => !v.isConfirmed);
        //   if (isConfirmedAll) {
        //     // case B-1: confirmed All
        //     nextFocusEvent = state.events.results.find(
        //       (v) =>
        //         v.id > action.eventId &&
        //         v.finalDiagnosis === currentEditClassType
        //     );
        //   } else {
        //     // case B-2: non confirmed All
        //     nextFocusEvent = state.events.results.find(
        //       (v) =>
        //         v.id > action.eventId &&
        //         v.finalDiagnosis === currentEditClassType &&
        //         !v.isConfirmed
        //     );
        //     // case C-1: confirmed를 다하지 않음 && 수정한 이벤트 이후 이벤트 id중 모두 수정 되어 있을 때(수정 이전 이벤트에서 수정 안한 이벤가 있다.)
        //     // 수정 한 이벤트 다음 이벤트로 이동
        //     if (!nextFocusEvent) {
        //       nextFocusEvent = state.events.results.find(
        //         (v) =>
        //           v.id > action.eventId &&
        //           v.finalDiagnosis === currentEditClassType
        //       );
        //     }
        //   }
        //   // case D-1: edit - non last event
        //   if (!!nextFocusEvent) {
        //     nextFocusIndex = filteredEventList.findIndex(
        //       (v) => v.id === nextFocusEvent.id
        //     );
        //   } else {
        //     // case D-2: edit - last event
        //     nextFocusIndex = filteredEventList.length - 1;
        //   }
        // } else {
        //   // case A-2: non filtered class
        //   // case D-2: edit - last event
        //   if (
        //     action.eventId ==
        //     state.events.results[state.events.results.length - 1].id
        //   ) {
        //     nextFocusIndex = state.events.results.length - 1;
        //   } else {
        //     // case D-1: edit - non last event
        //     // case B-1: confirmed All
        //     isConfirmedAll = !state.events.results.some((v) => !v.isConfirmed);
        //     if (isConfirmedAll) {
        //       nextFocusIndex = state.events.results.findIndex(
        //         (v) => v.id > action.eventId
        //       );
        //     } else {
        //       // case B-2: non confirmed All
        //       nextFocusIndex = state.events.results.findIndex(
        //         (v) => v.id > action.eventId && !v.isConfirmed
        //       );
        //       // case C-1: confirmed를 다하지 않음 && 수정한 이벤트 이후 이벤트 id중 모두 수정 되어 있을 때(수정 이전 이벤트에서 수정 안한 이벤가 있다.)
        //       if (nextFocusIndex < 0) {
        //         nextFocusIndex = state.events.results.findIndex(
        //           (v) => v.id > action.eventId
        //         );
        //       }
        //     }
        //   }
        // }
      } catch (error) {
        console.error(error);
      }

      return {
        ...state,
        events: {
          ...state.events,
          lastModifiedEventId: eventId,
          nextFocusIndex: nextFocusIndex,
          results: updatedEventList,
          pending: false,
        },
      };
    case FETCH_EVENT_DETAIL_REQUESTED:
      return {
        ...state,
        eventDetail: {
          ...state.eventDetail,
          pending: true,
        },
      };
    case FETCH_EVENT_DETAIL_SUCCEED:
      return {
        ...state,
        eventDetail: {
          ...action.data,
          pending: false,
        },
      };
    case FETCH_EVENT_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case INITIALIZE_DETAIL_REQUESTED:
      return {
        ...state,
        eventDetail,
      };
    case SET_CURRENT_EDIT_CLASS_REQUESTED:
      return {
        ...state,
        view: {
          ...state.view,
          currentEditClassType: action.classType,
        },
      };
    case SET_SHOW_UNCONFIRMED_CHECKED:
      return {
        ...state,
        view: {
          ...state.view,
          isShowUnconfirmedChecked: action.isChecked,
        },
        events: {
          ...state.events,
          nextFocusIndex: undefined,
        },
      };
    case SET_SHOW_BOOKMARKED_CHECKED:
      return {
        ...state,
        view: {
          ...state.view,
          isShowBookmarkedChecked: action.isChecked,
        },
        events: {
          ...state.events,
          nextFocusIndex: undefined,
        },
      };
    // 북마크된 모든 Event 목록 조회
    // case GET_PREV_SELECTED_EVENTS_MAP_REQUESTED:
    //   return {
    //     ...state,
    //     prevSelectedEventMap: {
    //       pending: true,
    //       data: null,
    //       error: null,
    //     },
    //   };
    // case GET_PREV_SELECTED_EVENTS_MAP_SUCCEED:
    //   return {
    //     ...state,
    //     prevSelectedEventMap: {
    //       pending: false,
    //       data: action.data,
    //       error: null,
    //     },
    //   };
    // case GET_PREV_SELECTED_EVENTS_MAP_FAILED:
    //   return {
    //     ...state,
    //     prevSelectedEventMap: {
    //       pending: false,
    //       data: null,
    //       error: action.error,
    //     },
    //   };
    default:
      return state;
  }
}

// Fetch
export function fetchEventsRequested(
  tid,
  eventTimestampMin,
  eventTimestampMax,
  pageSize
) {
  return {
    type: FETCH_EVENTS_REQUESTED,
    tid,
    eventTimestampMin,
    eventTimestampMax,
    pageSize,
  };
}
export function fetchEventsSucceed(data) {
  return {
    type: FETCH_EVENTS_SUCCEED,
    data,
  };
}
export function fetchEventDetailRequested(eventId) {
  return {
    type: FETCH_EVENT_DETAIL_REQUESTED,
    eventId,
  };
}
export function fetchEventDetailSucceed(data) {
  return {
    type: FETCH_EVENT_DETAIL_SUCCEED,
    data,
  };
}
export function confirmEventRequested(params) {
  return { type: CONFIRM_EVENT_REQUESTED, params };
}
export function confirmEventSucceed(data, eventId) {
  return { type: CONFIRM_EVENT_SUCCEED, data, eventId };
}

export function fetchEventFailed(error) {
  return { type: FETCH_EVENT_FAILED, error };
}

export function initializeEventDetail() {
  return { type: INITIALIZE_DETAIL_REQUESTED };
}
export function setCurrentEditClassType(classType) {
  return { type: SET_CURRENT_EDIT_CLASS_REQUESTED, classType };
}

// view
export function setShowBookmarkedChecked(isChecked) {
  return { type: SET_SHOW_BOOKMARKED_CHECKED, isChecked };
}
export function setShowUnconfirmedChecked(isChecked) {
  return { type: SET_SHOW_UNCONFIRMED_CHECKED, isChecked };
}

// 북마크된 모든 Event 목록 조회
/**
 *
 * @param {{tid, rid, selectedEvents}} payload
 * @returns
 */
// export function getPrevSelectedEventsMapRequested(payload, callback) {
//   return { type: GET_PREV_SELECTED_EVENTS_MAP_REQUESTED, ...payload, callback };
// }
// function getBookmarkedEventListSucceed(data) {
//   return { type: GET_PREV_SELECTED_EVENTS_MAP_SUCCEED, data };
// }
// function getBookmarkedEventListFailed(error) {
//   return { type: GET_PREV_SELECTED_EVENTS_MAP_FAILED, error };
// }

function* getEvents(action) {
  try {
    const { tid, eventTimestampMin, eventTimestampMax, pageSize } = action;
    const { data } = yield call(ApiManager.getEventList, {
      tid,
      eventTimestampMin,
      eventTimestampMax,
      pageSize,
    });

    yield put(fetchEventsSucceed(data));
  } catch (error) {
    yield put(
      fetchEventFailed({
        name: error.name,
        status: error.status,
        message: error.message,
      })
    );
  }
}
function* confirmEvent(action) {
  try {
    const { data } = yield call(
      ApiManager.confirmEvent,
      action.params.eventId,
      action.params.body
    );
    yield put(confirmEventSucceed(data, action.params.eventId));
  } catch (error) {
    yield put(
      fetchEventFailed({
        name: error.name,
        status: error.status,
        message: error.message,
      })
    );
  }
}
function* getEventDetail(action) {
  try {
    const { data } = yield call(ApiManager.getEventDetail, action.eventId);

    yield put(fetchEventDetailSucceed(data));
  } catch (error) {
    yield put(
      fetchEventFailed({
        name: error.name,
        status: error.status,
        message: error.message,
      })
    );
  }
}
// function* getPrevSelectedEventsMap(action) {
//   try {
//     const { tid, rid, selectedEvents: camelSelectedEvents, callback } = action;

//     let selectedEvents;
//     if (camelSelectedEvents) {
//       selectedEvents = transformCamelToSnake(camelSelectedEvents);
//     } else {
//       const {
//         data: { result },
//       } = yield call(ApiManager.readReportSelected, { rid });
//       selectedEvents = transformCamelToSnake(result);
//     }
//     // XXX: 준호 - 북마크된 Event 가 10,000개를 넘을 경우 문제 발생!
//     const { data } = yield call(ApiManager.getEventList, {
//       tid,
//       partnerWebUserBookmarkLevel: 1,
//       pageSize: 10000,
//       page: 1,
//       withEcg: false,
//     });
//     const bookmarkedEventList = data.results;

//     const result = [...Object.keys(selectedEvents)].reduce((acc, cur) => {
//       let newAcc = { ...acc };
//       const diagnosis = GetClassInfo(cur.split('_')[1]).value;

//       newAcc[cur] = {
//         detail: selectedEvents[cur].detail.filter((eventId) =>
//           bookmarkedEventList.some(
//             (eventInfo) =>
//               eventInfo.id === eventId && eventInfo.finalDiagnosis === diagnosis
//           )
//         ),
//         summary: selectedEvents[cur].summary.filter((eventId) =>
//           bookmarkedEventList.some(
//             (eventInfo) =>
//               eventInfo.id === eventId && eventInfo.finalDiagnosis === diagnosis
//           )
//         ),
//       };

//       return newAcc;
//     }, {});

//     yield put(getBookmarkedEventListSucceed(result));
//   } catch (error) {
//     yield put(getBookmarkedEventListFailed(error));
//   }
// }

export function* saga() {
  yield takeLatest(FETCH_EVENTS_REQUESTED, getEvents);
  yield takeLatest(CONFIRM_EVENT_REQUESTED, confirmEvent);
  yield takeLatest(FETCH_EVENT_DETAIL_REQUESTED, getEventDetail);
  // yield takeLatest(
  //   GET_PREV_SELECTED_EVENTS_MAP_REQUESTED,
  //   getPrevSelectedEventsMap
  // );
}
