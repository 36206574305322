import ApiManager from 'network/ApiManager';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PAGE_SIZE, GetInitRequest } from 'util/ClassLabelUtil';

// findings API 통해서 클래스 별 strip 데이터 받아옴
// 리포트 생성 요청
// 기존 리포트 생성 내용 획득??

// Actions
// Get Findings
const GET_ECG_EVENTS_REQUESTED = 'memo-web/report/GET_ECG_EVENTS_REQUESTED';
const GET_ECG_EVENTS_SUCCEED = 'memo-web/report/GET_ECG_EVENTS_SUCCEED';
const GET_ECG_EVENTS_FAILED = 'memo-web/report/GET_ECG_EVENTS_FAILED';
// Request Print Report
const REQUEST_PRINT_REPORT_REQUESTED =
  'memo-web/report/REQUEST_PRINT_REPORT_REQUESTED';
const REQUEST_PRINT_REPORT_SUCCEED =
  'memo-web/report/REQUEST_PRINT_REPORT_SUCCEED';
const REQUEST_PRINT_REPORT_FAILED =
  'memo-web/report/REQUEST_PRINT_REPORT_FAILED';
// Request Get Report Detail
const GET_REPORT_DETAIL_REQUESTED =
  'memo-web/report/GET_REPORT_DETAIL_REQUESTED';
const GET_REPORT_DETAIL_SUCCEED = 'memo-web/report/GET_REPORT_DETAIL_SUCCEED';
const GET_REPORT_DETAIL_FAILED = 'memo-web/report/GET_REPORT_DETAIL_FAILED';

// Reducer
const initialState = {
  list: {
    pending: false,
    data: null,
    error: null,
  },
  request: {
    pending: false,
    data: null,
    error: null,
  },
  selected: {
    pending: false,
    data: null,
    error: null,
  },
  detail: {
    pending: false,
    data: null,
    error: null,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    // Request Print Report
    case REQUEST_PRINT_REPORT_REQUESTED:
      return {
        ...state,
        request: {
          pending: true,
          error: null,
        },
      };
    case REQUEST_PRINT_REPORT_SUCCEED:
      return {
        ...state,
        request: {
          pending: false,
          data: action.data,
        },
      };
    case REQUEST_PRINT_REPORT_FAILED:
      return {
        ...state,
        request: {
          pending: false,
          error: action.error,
        },
      };
    // Get Findings
    case GET_ECG_EVENTS_REQUESTED:
      return {
        ...state,
        list: {
          pending: true,
          data: state.list.data,
          error: null,
        },
      };
    case GET_ECG_EVENTS_SUCCEED:
      return {
        ...state,
        list: {
          pending: false,
          data: action.data,
        },
      };
    case GET_ECG_EVENTS_FAILED:
      return {
        ...state,
        list: {
          pending: false,
          error: action.error,
        },
      };
    // Request Get Report Detail
    case GET_REPORT_DETAIL_REQUESTED:
      return {
        ...state,
        detail: {
          pending: true,
        },
      };
    case GET_REPORT_DETAIL_SUCCEED:
      return {
        ...state,
        detail: {
          pending: false,
          data: action.data,
        },
      };
    case GET_REPORT_DETAIL_FAILED:
      return {
        ...state,
        detail: {
          pending: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}

// Action Creators
// Request Print Report
export function requestPrintReportRequested(tid, requestBody) {
  return {
    type: REQUEST_PRINT_REPORT_REQUESTED,
    tid,
    requestBody,
  };
}
function requestPrintReportSucceed(data) {
  return {
    type: REQUEST_PRINT_REPORT_SUCCEED,
    data,
  };
}
function requestPrintReportFailed(error) {
  return {
    type: REQUEST_PRINT_REPORT_FAILED,
    error,
  };
}
// Get Findings
export function getEcgEventsRequested(tid, finalDiagnosis, page) {
  return {
    type: GET_ECG_EVENTS_REQUESTED,
    tid,
    finalDiagnosis,
    page,
  };
}
function getEcgEventsSucceed(data) {
  return {
    type: GET_ECG_EVENTS_SUCCEED,
    data,
  };
}
function getEcgEventsFailed(error) {
  return {
    type: GET_ECG_EVENTS_FAILED,
    error,
  };
}
// Request Get Report Detail
export function getReportDetailRequested(rid) {
  return {
    type: GET_REPORT_DETAIL_REQUESTED,
    rid,
  };
}
function getReportDetailSucceed(data) {
  return {
    type: GET_REPORT_DETAIL_SUCCEED,
    data,
  };
}
function getReportDetailFailed(error) {
  return {
    type: GET_REPORT_DETAIL_FAILED,
    error,
  };
}

// Sagas
/**
 * 검사에서 부정맥으로 판단되는 strip 조회. 단, bookmark 된것만
 * @param {*} action
 * @returns
 */
function* getEcgEvents(action) {
  try {
    // bookmark 된 strip 만 검색
    const { data } = yield call(ApiManager.getEventList, {
      partnerWebUserBookmarkLevel: 1,
      withEcg: true,
      pageSize: PAGE_SIZE,
      tid: action.tid,
      finalDiagnosis: action.finalDiagnosis,
      page: action.page,
    });
    const result = {
      count: data.count,
      results: data.results,
    };

    yield put(getEcgEventsSucceed(result));
  } catch (error) {
    yield put(
      getEcgEventsFailed({
        name: error.name,
        status: error.status,
        message: error.message,
      })
    );
  }
}

function* requestPrintReport(action) {
  try {
    const { data } = yield call(ApiManager.requestPrintReport, {
      tid: action.tid,
      ...action.requestBody,
    });

    // FIXME(준호): 성공 처리 수정
    const result = {
      // count: data.count,
      // results: data.results,
    };
    yield put(requestPrintReportSucceed(result));
  } catch (error) {
    yield put(
      requestPrintReportFailed({
        name: error.name,
        status: error.status,
        message: error.message,
      })
    );
  }
}

function* getReportDetail(action) {
  try {
    const { rid } = action;
    const {
      data: { result },
    } = yield call(ApiManager.readReportDetail, { rid });
    yield put(getReportDetailSucceed(result));
  } catch (error) {
    yield put(getReportDetailFailed(error));
  }
}

export function* saga() {
  yield takeLatest(GET_ECG_EVENTS_REQUESTED, getEcgEvents);
  yield takeLatest(REQUEST_PRINT_REPORT_REQUESTED, requestPrintReport);
  yield takeLatest(GET_REPORT_DETAIL_REQUESTED, getReportDetail);
}
