import AppColors from 'theme/AppColors';
const {
  SVE_100,
  SVE_400,
  SVE_600,
  VE_100,
  VE_400,
  VE_600,
  AF_100,
  AF_400,
  AF_600,
  AVB_100,
  AVB_400,
  AVB_600,
  PAUSE_100,
  PAUSE_400,
  PAUSE_600,
  OTHERS_100,
  OTHERS_400,
  OTHERS_600,
  PRIMARY_BLUE,
  LIGHT_PRIMARY_BLUE,
} = AppColors;

export const DIAGNOSIS_TYPE = {
  NORMAL: 0,
  APC: 1,
  VPC: 2,
  AF: 3,
  OTHERS: 4,
  NOISE: 5,
  SVT: 6,
  VT: 7,
  AVBLOCK: 8,
  PAUSE: 9,
  LEAD_OFF: -1,
  NONE: 99,
};

const DIAGOSIS_TYPE = {};
for (let key in DIAGNOSIS_TYPE) {
  DIAGOSIS_TYPE[DIAGNOSIS_TYPE[key]] = key;
}
export const GET_DIAGNOSIS_TYPE = (number) => DIAGOSIS_TYPE[number];

export const getDiagnosisLabelTitle = (diagnosis) => {
  let result = '';

  // prettier-ignore
  switch (diagnosis) {
    case DIAGNOSIS_TYPE.NORMAL: result = 'Normal'; break;
    case DIAGNOSIS_TYPE.APC: result = 'APC'; break;
    case DIAGNOSIS_TYPE.VPC: result = 'VPC'; break;
    case DIAGNOSIS_TYPE.AF: result = 'AF'; break;
    case DIAGNOSIS_TYPE.OTHERS: result = 'Others'; break;
    case DIAGNOSIS_TYPE.NOISE: result = 'Noise'; break;
    case DIAGNOSIS_TYPE.SVT: result = 'SVT'; break;
    case DIAGNOSIS_TYPE.VT: result = 'VT'; break;
    case DIAGNOSIS_TYPE.AVBLOCK: result = 'AV Block'; break;
    case DIAGNOSIS_TYPE.PAUSE: result = 'Pause'; break;
    case DIAGNOSIS_TYPE.LEAD_OFF: result = 'Lead off'; break;
    case DIAGNOSIS_TYPE.NONE: result = 'None'; break;
    default:
      break;
  }
  return result;
};

/**
 * # 구간 편집 가능한 diagnosis중 strip에서의 위치
 *    > label여부와 위치를 결정
 *
 *    * START: label은 strip의 앞쪽에 위치
 *    * MIDDLE: label없음
 *    * END: label이 strip의 끝쪽에 위치
 */
export const DIAGNOSIS_LABEL_POSITION_IN_STRIP = {
  START: 0,
  MIDDLE: 1,
  END: 2,
};

/**
 * # episode: 연속 으로 편집할 수 있는 diagnosis
 *           : 라인, 레이블 모두 표기
 *           : 레이블 위치는 onset, termination에 따라서 위치가 정해진다.
 *              : onset-strip 앞쪽
 *              : termination-strip 뒤쪽
 *              : 중간(onset, termination이 아닌경우) 라벨 표기를 해주지 않는다.
 * # individual: 10초 단위 편집 할 수 있는 diagnosis
 *             : 라인 없이 레이블로 표기
 *             : 10초 strip 한개 당 하나씩 표기
 */
export const DIAGNOSIS_STRIP_TYPE = {
  //-1 -  empty box - Lead off
  [DIAGNOSIS_TYPE.LEAD_OFF]: '',
  //99 -  UI 작업 제외
  [DIAGNOSIS_TYPE.NONE]: '',
  //0 -  표기 없음
  [DIAGNOSIS_TYPE.NORMAL]: '',
  //5 -  표기 없음
  [DIAGNOSIS_TYPE.NOISE]: '',
  //3 -  Label-color: PINK [episode]
  [DIAGNOSIS_TYPE.AF]: { type: 'episode', color: '', backgroundColor: '' },
  //6 -  Label-color: ORANGE [episode]
  [DIAGNOSIS_TYPE.SVT]: { type: 'episode', color: '', backgroundColor: '' },
  //7 -  Label-color: GREEN [episode]
  [DIAGNOSIS_TYPE.VT]: { type: 'episode', color: '', backgroundColor: '' },
  //8 -  Label-color: SKY BLUE [episode]
  [DIAGNOSIS_TYPE.AVBLOCK]: { type: 'episode', color: '', backgroundColor: '' },
  //9 -  Label-color: VIOLET [episode]
  [DIAGNOSIS_TYPE.PAUSE]: { type: 'episode', color: '', backgroundColor: '' },

  //1 -  Label-color: ORANGE [individual]
  [DIAGNOSIS_TYPE.APC]: { type: 'individual', color: '', backgroundColor: '' },
  //2 -  Label-color: GREEN [individual]
  [DIAGNOSIS_TYPE.VPC]: { type: 'individual', color: '', backgroundColor: '' },
  //4 - Label-color: GRAY [individual]
  [DIAGNOSIS_TYPE.OTHERS]: {
    type: 'individual',
    color: '',
    backgroundColor: '',
  },
};

/**
 * DIAGNOSIS_TYPE별로 entire ECG chart에 보여줄 label 정보
 * */
export const DIAGNOSIS_LABEL_INFOS = {
  getClassLabelInfo: function (diagnosis) {
    return this[diagnosis];
  },
  getPatientTriggerLabelInfo: function () {
    return this.patientTrigger;
  },

  patientTrigger: {
    color: PRIMARY_BLUE,
    stripeColor: '',
    backgroundColor: LIGHT_PRIMARY_BLUE,
  },

  [DIAGNOSIS_TYPE.LEAD_OFF]: {
    type: '',
    stripeColor: '',
    backgroundColor: '',
    color: '',
    diagnosisTitle: getDiagnosisLabelTitle(DIAGNOSIS_TYPE.LEAD_OFF),
  },
  [DIAGNOSIS_TYPE.NONE]: {
    type: '',
    stripeColor: '',
    backgroundColor: '',
    color: '',
    diagnosisTitle: getDiagnosisLabelTitle(DIAGNOSIS_TYPE.NONE),
  },
  [DIAGNOSIS_TYPE.NORMAL]: {
    type: '',
    stripeColor: '',
    backgroundColor: '',
    color: '',
    diagnosisTitle: getDiagnosisLabelTitle(DIAGNOSIS_TYPE.NORMAL),
  },
  [DIAGNOSIS_TYPE.NOISE]: {
    type: '',
    stripeColor: '',
    backgroundColor: '',
    color: '',
    diagnosisTitle: getDiagnosisLabelTitle(DIAGNOSIS_TYPE.NOISE),
  },

  [DIAGNOSIS_TYPE.AF]: {
    type: 'episode',
    stripeColor: AF_400,
    backgroundColor: AF_100,
    color: AF_600,
    diagnosisTitle: getDiagnosisLabelTitle(DIAGNOSIS_TYPE.AF),
  },
  [DIAGNOSIS_TYPE.SVT]: {
    type: 'episode',
    stripeColor: SVE_400,
    backgroundColor: SVE_100,
    color: SVE_600,
    diagnosisTitle: getDiagnosisLabelTitle(DIAGNOSIS_TYPE.SVT),
  },
  [DIAGNOSIS_TYPE.VT]: {
    type: 'episode',
    stripeColor: VE_400,
    backgroundColor: VE_100,
    color: VE_600,
    diagnosisTitle: getDiagnosisLabelTitle(DIAGNOSIS_TYPE.VT),
  },
  [DIAGNOSIS_TYPE.AVBLOCK]: {
    type: 'episode',
    stripeColor: AVB_400,
    backgroundColor: AVB_100,
    color: AVB_600,
    diagnosisTitle: getDiagnosisLabelTitle(DIAGNOSIS_TYPE.AVBLOCK),
  },
  [DIAGNOSIS_TYPE.PAUSE]: {
    type: 'episode',
    stripeColor: PAUSE_400,
    backgroundColor: PAUSE_100,
    color: PAUSE_600,
    diagnosisTitle: getDiagnosisLabelTitle(DIAGNOSIS_TYPE.PAUSE),
  },
  [DIAGNOSIS_TYPE.APC]: {
    type: 'individual',
    stripeColor: SVE_400,
    backgroundColor: SVE_100,
    color: SVE_600,
    diagnosisTitle: getDiagnosisLabelTitle(DIAGNOSIS_TYPE.APC),
  },
  [DIAGNOSIS_TYPE.VPC]: {
    type: 'individual',
    stripeColor: VE_400,
    backgroundColor: VE_100,
    color: VE_600,
    diagnosisTitle: getDiagnosisLabelTitle(DIAGNOSIS_TYPE.VPC),
  },
  [DIAGNOSIS_TYPE.OTHERS]: {
    type: 'individual',
    stripeColor: OTHERS_400,
    backgroundColor: OTHERS_100,
    color: OTHERS_400,
    diagnosisTitle: getDiagnosisLabelTitle(DIAGNOSIS_TYPE.OTHERS),
  },
};
/**
 * # 리포트 생성 관련
 *  */
export const CLASS_LABEL_INFOS = [
  {
    value: DIAGNOSIS_TYPE.NORMAL,
    label: 'Normal',
    shortcut: 'Q',
    key: 'normal',
    title: 'Normal',
    subTitle: 'Normal',
    fullName: '',
  },
  {
    value: DIAGNOSIS_TYPE.APC,
    label: 'APC',
    shortcut: 'W',
    key: 'apc',
    title: 'APC',
    subTitle: 'APC',
    fullName: 'APC',
  },
  {
    value: DIAGNOSIS_TYPE.VPC,
    label: 'VPC',
    shortcut: 'E',
    key: 'vpc',
    title: 'VPC',
    subTitle: 'VPC',
    fullName: 'VPC',
  },
  {
    value: DIAGNOSIS_TYPE.NOISE,
    label: 'Noise',
    shortcut: 'R',
    key: 'noise',
    title: 'Noise',
    subTitle: 'Noise',
    fullName: '',
  },
  {
    value: DIAGNOSIS_TYPE.AVBLOCK,
    label: 'AV Block',
    shortcut: 'T',
    key: 'avb',
    title: 'AV Block',
    subTitle: 'AV Conduction',
    fullName: 'AV Block',
  },
  // 줄바꿈
  {
    value: DIAGNOSIS_TYPE.AF,
    label: 'AF',
    shortcut: 'A',
    key: 'af',
    title: 'AF',
    subTitle: 'AF',
    fullName: 'Atrial Fibrillation',
  },
  {
    value: DIAGNOSIS_TYPE.PAUSE,
    label: 'Pause',
    shortcut: 'S',
    key: 'pause',
    title: 'Pause',
    subTitle: 'Pause',
    fullName: 'Pause',
  },
  {
    value: DIAGNOSIS_TYPE.OTHERS,
    label: 'Others',
    shortcut: 'D',
    key: 'others',
    title: 'Others',
    subTitle: 'Other Arrhythmias',
    fullName: '',
  },
  {
    value: DIAGNOSIS_TYPE.SVT,
    label: 'SVT',
    shortcut: 'F',
    key: 'svt',
    title: 'SVT',
    subTitle: 'SVT',
    fullName: 'Supraventricular Tachycardia',
  },
  {
    value: DIAGNOSIS_TYPE.VT,
    label: 'VT',
    shortcut: 'G',
    key: 'vt',
    title: 'VT',
    subTitle: 'VT',
    fullName: 'Ventricular Tachycardia',
  },
];

export const GetClassInfo = (key) => {
  const filtered = CLASS_LABEL_INFOS.filter((x) => x.key === key);
  return filtered.length > 0 ? filtered[0] : null;
};

export const GetClassInfoByValue = (value) => {
  const filtered = CLASS_LABEL_INFOS.filter((x) => x.value === value);
  return filtered.length > 0 ? filtered[0] : null;
};

const INIT_SELECTION_SEQUENCES = [
  ['avb', 4, true],
  ['avb', 3, false],
  ['apc', 2, true],
  ['apc', 2, false],
  ['vpc', 2, true],
  ['vpc', 2, false],
  ['others', 8, true],
  ['af', 3, false],
  ['svt', 3, false],
  ['vt', 3, false],
  ['pause', 3, false],
];
// const temp = {};
// SELECTION_SEQUENCE.forEach((e) => {
//   temp[`${e[0]}Summary`] = [];
//   temp[`${e[0]}Detail`] = [];
// });
// tid: '{{ECG_TEST_ID}}',
const sample = {
  selected_avb: {
    summary: [],
    detail: [],
  },
  selected_apc: {
    summary: [],
    detail: [],
  },
  selected_vpc: {
    summary: [],
    detail: [],
  },
  selected_others: {
    summary: [],
    detail: [],
  },
  selected_af: {
    summary: [],
    detail: [],
  },
  selected_svt: {
    summary: [],
    detail: [],
  },
  selected_vt: {
    summary: [],
    detail: [],
  },
  selected_pause: {
    summary: [],
    detail: [],
  },
};

// const INIT_REQUEST_STATEMENT = JSON.stringify(temp);

export const GetClassKeys = () => {
  const result = [];
  INIT_SELECTION_SEQUENCES.forEach((v, i) => {
    if (!result.includes(v[0])) {
      result.push(v[0]);
    }
  });
  return result;
};
export const GetInitRequest = () => JSON.parse(JSON.stringify(sample));

export const GetCustomSequence = (eventCounts) => {
  const noneBookmarkedList = [];
  if (eventCounts) {
    const { eventCount, bookmarkedCount } = eventCounts;
    const customSequences = INIT_SELECTION_SEQUENCES.reduce((acc, cur) => {
      const classInfo = GetClassInfo(cur[0]);
      if (eventCount[`${classInfo.value}`] > 0) {
        acc.push(cur);
        if (
          bookmarkedCount[`${classInfo.value}`] === 0 &&
          !noneBookmarkedList.includes(classInfo.title)
        ) {
          noneBookmarkedList.push(classInfo.title);
        }
      }
      return acc;
    }, []);
    return { customSequences, noneBookmarkedList };
  } else {
    return { customSequences: INIT_SELECTION_SEQUENCES, noneBookmarkedList };
  }
};

export const PAGE_SIZE = 10;
