export const BeatType = {
  NORMAL: 0,
  APC: 1,
  VPC: 2,
  NOISE: 3,
} as const;
export type BeatType = typeof BeatType[keyof typeof BeatType];

export const EctopicType = {
  ISOLATE: 'ISOLATE',
  COUPLET: 'COUPLET',
  RUN: 'RUN',
} as const;
export type EctopicType = typeof EctopicType[keyof typeof EctopicType];

export const EventSection = {
  AF: 'AF',
  PAUSE: 'PAUSE',
  OTHERS: 'OTHERS',
  PTE: 'PTE',
  //
  IsoAPC: 'IsoAPC',
  CoupletAPC: 'CoupletAPC',
  SVT: 'SVT',
  IsoVPC: 'IsoVPC',
  CoupletVPC: 'CoupletVPC',
  VT: 'VT',
} as const;
export type EventSection = typeof EventSection[keyof typeof EventSection];

export const EventConstTypes = {
  AF: 'EVENT-TYPE-AF',
  PAUSE: 'EVENT-TYPE-PAUSE',
  OTHERS: 'EVENT-TYPE-OTHERS',
  //
  ISO_APC: 'EVENT-TYPE-ISOLATE-1',
  ISO_VPC: 'EVENT-TYPE-ISOLATE-2',
  COUPLET_APC: 'EVENT-TYPE-COUPLET-1',
  COUPLET_VPC: 'EVENT-TYPE-COUPLET-2',
  VT: 'EVENT-TYPE-RUN-2',
  SVE: 'EVENT-TYPE-X-RUN-1',
  SVT: 'EVENT-TYPE-RUN-1',
  VE: 'EVENT-TYPE-X-RUN-2',
  //
  PATIENT: 'EVENT-TYPE-PATIENT',
  NOTABLE: 'EVENT-TYPE-SUMMARY',
  ADDITIONAL: 'EVENT-TYPE-ADDITIONAL',
  NORMAL: 'EVENT-TYPE-0',
  NOISE: 'EVENT-TYPE-3',
  LEAD_OFF: 'EVENT-TYPE-LEAD_OFF',
} as const;
export type EventConstTypes =
  typeof EventConstTypes[keyof typeof EventConstTypes];

// shape-review
export const ShapeReviewEventType = {
  ISO_VPC: EventConstTypes.ISO_VPC,
  COUPLET_VPC: EventConstTypes.COUPLET_VPC,
  ISO_APC: EventConstTypes.ISO_APC,
  COUPLET_APC: EventConstTypes.COUPLET_APC,
} as const;
export type ShapeReviewEventType =
  typeof ShapeReviewEventType[keyof typeof ShapeReviewEventType];

export const ShapeReviewEventSection = {
  ISO_VPC: EventSection.IsoAPC,
  COUPLET_VPC: EventSection.CoupletAPC,
  ISO_APC: EventSection.IsoAPC,
  COUPLET_APC: EventSection.CoupletAPC,
} as const;
export type ShapeReviewEventSection =
  typeof ShapeReviewEventSection[keyof typeof ShapeReviewEventSection];
