import { connect } from 'react-redux';
import { showDialog, hideDialog } from 'redux/duck/dialogDuck';
import CentralizedDialog from 'component/dialog/CentralizedDialog';

const mapStateToProps = (state, ownProps) => {
  return {
    dialog: state.dialogReducer,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showDialog: (dialogKey, params, callback) => {
      dispatch(showDialog(dialogKey, params, callback));
    },
    hideDialog: (dialogKey) => {
      dispatch(hideDialog(dialogKey));
    },
  };
};

const CentralizedDialogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CentralizedDialog);

export default CentralizedDialogContainer;
